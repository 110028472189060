import { css } from '@emotion/react';
import t from 'react-translate';

const ValidationErrorMessage = (props: { text: string, header?: string }) => (
  <div className='flex-column'>
    <div className='mx-auto text-danger text-small text-center'>{props.header ?? t.FORM.WARNING()}</div>
    <div className='mt-2'>{props.text}</div>
  </div>
);

export default ValidationErrorMessage;
