
import { css } from '@emotion/react';
import React, { useEffect, useContext, useCallback, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { isEmpty } from 'underscore';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';

// Schemas
import { RootState } from 'redux/schemas';

// Actions
import { getFullCourseTimelineWithProgress } from 'redux/actions/timeline';
import { getCourseActivities, resetLecturePageExpandedInAutomatedCommunications,
  enableWelcomeEmail, getCourseWelcomeEmail,
} from 'redux/actions/course-communications';
import { getInstitutionData } from 'redux/actions/institutions';

// Selectors
import { getCurrentUserId, getLectureSubsectionIds, getLectureSectionIds } from 'redux/selectors/timeline';
import { isTimelineLoadedInAutomatedCommunications } from 'redux/selectors/course-communications';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';

// Styles
import { gray4, primary } from 'styles/global_defaults/colors';
import { tripleSpacing } from 'styles/global_defaults/scaffolding';

// Components
import TimelineSection from 'timelines/components/timeline-section';
import TimelineSubsection from 'timelines/components/timeline-subsection';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import { CommunicationAction, CommunicationDispatch, TriggerType } from 'communications/course_communications/contexts/communication-context';
import { CommunicationType } from 'redux/schemas/models/course-communication';
import EmptyList from 'shared/components/empty-list';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import WelcomeEmailRow from './welcome-email-row';
import LoadingPlaceholder from './loading-placeholder';

type AutomatedCommunicationsProps = {};

type StateProps = {
  currentUserId: number,
};

const mapDispatchToProps = {
  getTimeline: getFullCourseTimelineWithProgress,
  getActivities: getCourseActivities,
  resetExpanded: resetLecturePageExpandedInAutomatedCommunications,
  enableCourseWelcomeEmail: enableWelcomeEmail,
  getInstitution: getInstitutionData,
  getWelcomeEmail: getCourseWelcomeEmail,
  alertMessage: addAlertMessage,
};

const styles = css`
  .icon-display {
    display: inline-block;
    color: ${primary};
  }

  .intro-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .enable-email {
    height: ${tripleSpacing}px;
    border-top: 1px solid ${gray4};
    border-bottom: 1px solid ${gray4};
  }
`;

const AutomatedCommunications = (props: AutomatedCommunicationsProps & StateProps & typeof mapDispatchToProps) => {
  const { currentUserId, getTimeline, getActivities, resetExpanded,
    getInstitution, enableCourseWelcomeEmail, getWelcomeEmail, alertMessage } = props;

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const aliases = useSelector((state) => getCourseAliases(state));
  const isTimelineLoaded = useSelector((state) => isTimelineLoadedInAutomatedCommunications(state));
  const course = useSelector((state) => getCurrentCourse(state));
  const institution = useSelector((state) => getCurrentInstitution(state));
  const institutionId = useSelector((state) => state.app.currentInstitutionId);

  const { CurrentCourseManager } = useContext(AngularServicesContext);
  const { dispatch } = useContext(CommunicationDispatch);
  const [isLoading, setLoading] = useState(false);

  const [prefix, suffix] = t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.AUTOMATED.INTRO_SECOND({ ...aliases.lectureAliases, ...aliases.courseAliases }).split('SQUARE_ICON');

  useEffect(() => {
    if (catalogId && currentUserId) {
      resetExpanded();
      getTimeline({
        catalogId,
        userId: currentUserId,
        outlineOnly: true,
        editMode: false,
      });
    }
  }, [catalogId, currentUserId, getTimeline, resetExpanded]);

  useEffect(() => {
    if (catalogId && !isTimelineLoaded) {
      getActivities({
        catalogId,
      });
    }
  }, [catalogId, isTimelineLoaded, getActivities]);

  useEffect(() => {
    getInstitution({ institutionId });
  }, [getInstitution, institutionId]);

  const fetchWelcomeEmail = useCallback(async () => {
    const result = await getWelcomeEmail({ catalogId }) as any;

    if (result?.error) {
      return false;
    }
    return true;
  }, [catalogId, getWelcomeEmail]);

  useEffect(() => {
    if (institution.welcomeEmailEnabled && course.welcomeEmailEnabled) {
      fetchWelcomeEmail();
    }
  }, [course.welcomeEmailEnabled, fetchWelcomeEmail, institution.welcomeEmailEnabled]);

  // Sync welcomeEmailEnabled from redux to Angular
  useEffect(() => {
    CurrentCourseManager.setWelcomeEmail(course.welcomeEmailEnabled);
  }, [CurrentCourseManager, course.welcomeEmailEnabled]);

  const onEnableWelcomeEmail = async () => {
    setLoading(true);
    const response = await enableCourseWelcomeEmail({
      courseId: course.id,
      catalogId,
    }) as unknown as { payload: { result: boolean, catalogId: string } };

    if (response?.payload?.result) {
      const isLoaded = await fetchWelcomeEmail();
      if (isLoaded) {
        dispatch({
          type: CommunicationAction.SHOW_MODAL,
          triggerType: TriggerType.CREATE,
          communicationType: CommunicationType.COURSE_WELCOME_EMAIL,
        });
      } else {
        alertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.FORM.ERROR_SOMETHING_WRONG(),
        });
      }
    }
    setLoading(false);
  };

  const lectureSubsectionIds = useSelector((state) => getLectureSubsectionIds(state));
  const lectureSectionIds = useSelector((state) => getLectureSectionIds(state));

  return (
    <React.Fragment>
      { !isTimelineLoaded && <LoadingPlaceholder /> }
      { isTimelineLoaded && (
        <React.Fragment>
          <div css={styles}>
            <div className='page-title mt-6 text-center'>{t.COURSE_COMMUNICATIONS.TABS.TITLE.AUTOMATED()}</div>
            <div className='text-body my-4 intro-description'>
              <p>{t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.AUTOMATED.INTRO_FIRST({ ...aliases.lectureAliases })}</p>
              <p>
                {prefix}
                <span className='icon-display'><NvIcon icon='add-square' size='xss-smallest' /></span>
                {suffix}
              </p>
            </div>
            {institution.welcomeEmailEnabled && (
              <React.Fragment>
                {course.welcomeEmailEnabled ? (
                  <WelcomeEmailRow />
                ) : (
                  <div className='enable-email d-flex justify-content-center align-items-center text-primary mb-4'>
                    {isLoading ? (
                      <div className='gray-3'>{t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ENABLING()}</div>
                    ) : (
                      <ClickableContainer
                        onClick={() => onEnableWelcomeEmail()}
                        aria-label={t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ENABLE()}
                      >
                        {t.COURSE_COMMUNICATIONS.WELCOME_EMAIL.ENABLE()}
                      </ClickableContainer>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          {isEmpty(lectureSectionIds) && isEmpty(lectureSubsectionIds)
            ? <EmptyList icon='read' description={t.COURSE_COMMUNICATIONS.NO_CONTENT.AUTOMATED(aliases.courseAliases)} />
            : (
              <React.Fragment>
                {
                  // Lecture subsections
                  lectureSubsectionIds.map(lectureSubsectionId => (
                    <TimelineSubsection key={lectureSubsectionId.toString()} lectureSubsectionId={lectureSubsectionId} />
                  ))
                }
                {
                  // Lecture sections
                  lectureSectionIds.map(lectureSectionId => (
                    <TimelineSection key={lectureSectionId.toString()} lectureSectionId={lectureSectionId} />
                  ))
                }
              </React.Fragment>
            )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
const ConnectedAutomatedCommunications = connect<StateProps, typeof mapDispatchToProps>(
  (state: RootState) => ({
    currentUserId: getCurrentUserId(state),
  }),
  mapDispatchToProps,
)(AutomatedCommunications as any);

export default ConnectedAutomatedCommunications;
