/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FetchSinglePostRequest,
} from 'redux/schemas/api/post';
import { Post } from 'redux/schemas/models/post';
import { makeQueryParamString } from 'redux/actions/helpers';

export const fetchSinglePost = createAsyncThunk<Post, FetchSinglePostRequest>(
  'FETCH_SINGLE_POST',
  async (params) => {
    const { includeUserBar } = params;
    const queryString = makeQueryParamString({ includeUserBar });
    const response = await axios.get(`${params.catalogId}/topics/${params.postId}?${queryString}`);
    /* Temporary placeholder to update points and progress on timeline manager with new points data
    * Tracked in https://novoed.atlassian.net/browse/NOV-66502. Need to be called after response for
    * fetch single post returns
    */
    params.callback?.(response.data.result);
    return response.data.result;
  },
);
