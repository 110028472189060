import { css, SerializedStyles } from '@emotion/react';
import { PagedDataQueryParams, InfiniteTableLoadingParams, InfiniteTableDataPage } from 'redux/create-action-creators';
import React, {
  FunctionComponent, Fragment, useState, useEffect, ReactNode, useRef, CSSProperties, HTMLAttributes, Ref, useContext,
} from 'react';
import { gray7, gray5, gray6, gray4 } from 'styles/global_defaults/colors';
import {
  standardSpacing, quarterSpacing, halfSpacing, createGridStyles,
} from 'styles/global_defaults/scaffolding';
import _, { without } from 'underscore';
import t from 'react-translate';
import { tablet, isHandheld, isTouchDevice } from 'styles/global_defaults/media-queries';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useAppDispatch } from 'redux/store';
import ClickableContainer from 'components/clickable-container';
import { NvTooltip } from './nv-tooltip';
import { NvNoResults, NvNoResultsProps } from './nv-no-results-panel';

export const RowsHoverContext = React.createContext(null);

export const useIsRowHovered = (rowKey) => {
  const { rowsHoverState } = React.useContext(RowsHoverContext);

  return !!rowsHoverState[rowKey];
};

export type NvResponsiveTableColumn = {
  /** The label displayed for this column in the header */
  name: string,
  /** Custom header column content */
  content?: ReactNode,
  /** The CSS classname used to generate hover styles for the options button */
  className: string,
  /** Whether or not to show a sorting UI */
  sortable?: boolean,
  // for columns that can be sortable but not enabled
  disabled?: boolean,
  /** The column width passed to grid-template-columns */
  gridWidth?: string,
  /** Mouseover tooltip text */
  headerTooltip?: string,
  /** Header classname */
  headerClassName?: string,
  /** Data Qa */
  dataQa?: string,
};

/* A mapping of column indexes to booleans where false === descending sort and true === ascending sort
 * only columns with `sortable` set true should have keys set. Sortable columns with no sorting enabled
 * should be omitted */
export type ColumnSortings = { [index: number]: boolean };

// `T[keyof T]` means that the value must be a valid key of type T. So the `keys` of this CacheLookup function must
// be strings which can be used to acces properties from the given type T
export type CacheLookup<T> = (state: RootState, keys: T[keyof T][]) => { [id: string]: T };

export type NvTableRowProps<T, V={}, U={}> = {
  data: T,
  originalIndex: number,
  rowIndex: number,
  disabled: boolean,
  /** The data for the associated "extra" row added above this one, if provided */
  extraObject?: V,
  /** Extra props passed into the table row */
  extraProps?: U,
  children?: ReactNode,
  rowKey?: string,
};

export type NvTableCellProps<T, V={}, U={}> = {
  reactKey: string,
  divProps: HTMLAttributes<HTMLDivElement>
  serializedStyles: SerializedStyles,
  rowClasses?: string, // Classes set in className for this row
} & NvTableRowProps<T, V, U>;

/** A mapping of string cache keys to caches, where each cache consists of:
 * dataKeys: a list of strings used to look up a row's data in redux
 * isLastPageLoaded: whether we've loaded all possible data for the given cache key, or if there are more pages ]
 * Note that this cache does _not_ contain the actual data for the table, just the lookup info */
export type DataCache<T> = { [cacheKey: string]: { dataKeys: T[keyof T][], expandedRows: Record<number, boolean>, isLastPageLoaded: boolean, } };

/** The generic T is the type of data to be displayed in each row, and U is an object with properties that will be passed in as arguments to the rowComponent function on row render.
 * V is the type of data for 'extra' rows; rows in the table can be displayed with an a duplicate row displayed
 * above them that get extra properties. This is currently used for course cloning indicators. */
type NvResponsiveTableProps<T, U, V={}> = {
  columns: NvResponsiveTableColumn[],
  columnSortings?: ColumnSortings,
  /** Callback fired whenever a sortable column header is clicked */
  onSortClicked?: (columnIndex: number) => void,
  fetchData: AsyncThunk<{ response: T[], totalCount?: number }, any, {}>
  /*
    {
      institutionId?: number;
      permission?: CurrentLearningPermission;
    } & InfiniteTableLoadingParams<T>
  */
  /* Params that control the paged data fetching logic */
  pagedFetchParams: PagedDataQueryParams,
  /* Other params to pass w/ the paged data fetch request that aren't included in pagedFetchParams */
  // TODO: This is messy; the values in this get passed into the fetchData function. Specifically right now these are the
  // params that get inserted into the URL, but currently it's untyped. Needs improved
  fetchParams: any,
  /** Component rendered for each row */
  rowComponent: FunctionComponent<NvTableRowProps<T, V, U>>,
  /** Extra properties passed as args when calling rowComponent */
  rowProps: U,
  /* A string key name of the property on the data object to use in generating row keys */
  dataKey: keyof T,
  /** The name of the property on the data object used to indicate cached values in the cache */
  cacheDataKey: keyof T,
  /** A function to be used when looking up cached data. Will typically pull data from Redux state */
  cacheLookup: CacheLookup<T>,
  /** Component rendered for the loading indicator */
  loadingComponent: FunctionComponent<{ rowIndex: number }>,
  /** If present, the grid will insert invisible, hidden rows as every odd-numbered (1-based)
   * rows. These can then be displayed by changing the display property of the row
   * TODO: I don't think this currently supports omitting this component; probably creates expandable rows regardless */
  expandableRowComponent?: FunctionComponent<{ data: T, rowIndex: number, style: React.CSSProperties }>,
  /** Disabled backgrown shading hover styles on mouse over */
  hoverDisabled?: boolean,
  rowHoverTooltip?: (data: T, isExpanded: boolean, isDisabled: boolean) => string,
  clearSearch: () => void,
  onResults?: (showNoResults: boolean) => void,
  /** A function called on every row to determine if it should be
   * disabled with the .disabled class applied */
  checkRowDisabled?: (data: T) => boolean,
  style?: CSSProperties,
  /** A map of "extra" items to display in the table in addition to what's returned by fetchData. Placed in
   * the table by calling extraRowLookup */
  extraRowData?: Record<keyof T, V[]>,
  extraRowTooltip?: (item: T, extraItem: V) => string,
  noResultsText?: string,
  noResultsIcon?: string,
  noResultsIconSize?: string,
  noResultsInTable?: boolean,
  pageSize?: number,
  /**
   * Component displayed in addition to the rowComponent in the table
   * Placed at the bottom of the table
   */
  extraRowComponent?: FunctionComponent<{ rowIndex: number }>,
  /**
   * To display the extra row without fetching data.
   * This is expecting an array of keys in the Redux state.
   * The corresponding row of these keys only display if the data exist in the store.
   */
  extraDataKeys?: T[keyof T][],
  /**
   * To show a summary header section on top of the table.
   * Which also has a button to clear search.
   */
  showSearchSummaryHeader?: boolean,
  className?: string,
  backgroundColor?: string,
  /**
   * Pagination params can be different accross fetchData
   * default is `pageIndex` and 'pageSize'
   */
  currentPageNameParam?: string;
  currentPageSizeNameParam?: string;
  currentSearchQueryNameParam?: string;
  // some implementations use GET and searcghQuery must be enconded, some use POST and encoded is not needed.
  encodeSearchQuery?: boolean;
  /*
    destructureFilters is used to destructure filters of pagedFetchParams to send them to fetchData function
    false means sending filters as { filters: {...} }
    true means sending filters as { active: 1, future: 1, etc }
  */
  destructureFilters?: boolean;
  /**
   * disabling the table is used, eg, when the data in the table is being updated
   * in the backend, and all user action should be disallowed until it's finished
   */
  disabled?: boolean;
  /**
   * A wrapper that allows you to add special features to default background
   */
  rowBackgroundWrapper?: FunctionComponent<{ data: T }>;
  dataQaClearSearch?: string;
  hoverStyles?: SerializedStyles;
  onTotalCountChange?: (number) => void;
} & Pick<NvNoResultsProps, 'noResultsTextComponent' | 'noResultsText' | 'noResultsIcon' | 'noResultsClearTextDataQA' | 'hideClearSearch'>;

const DEFAULT_PAGE_NAME_PARAM = 'pageIndex';
const DEFAULT_PAGE_SIZE_NAME_PARAM = 'pageSize';

/** # of elements to request for each data page */
const DEFAULT_PAGE_SIZE = 20;
// The % of the grid's total scroll height where scrolling past will trigger another data page load;
const SCROLL_THRESHOLD = 0.8;
/** The shaded background row sits above the table cell rows. Applying hover behavior
 * onto elements inside our table cells requires placing them above this z-index, and
 * disabling/enabling pointer-events in the target element's container
 */
export const BKG_ROW_Z_INDEX = 1;

// eslint-disable-next-line arrow-body-style
/** Generates adjacent sibling selectors to make the background row show its styles whenever mousing over or
 * activating any individual cell */
export const generateRowStyles = (columnCount: number, styles: SerializedStyles) => _.range(columnCount).map((v) => css`
    div[class*="-cell"]:hover:not(.disabled)${(new Array(v)).fill(' + div[class*="-cell"]').join('')},
    &.active${(new Array(v)).fill(' + div[class*="-cell"]').join('')} + .bkg-row {
      ${styles};
    }
  `);

export const borderStyle = `solid 1px ${gray5}`;

/**
 * Creates an infinite-loading & responsive data table with default look & feel with support for loading via search query, applying data filters, and data sorting.
 * The table is created with display: grid and each cell has values set for gridColumn and gridRow for IE11 support. Data requested via this component should be saved
 * in redux so it can be looked up via props.cacheLookup; this table caches the keys to be used in this lookup for subsequent requests, but does not store a duplicate copy of the
 * data we store in redux.
 * Also supports collapsable/expandable rows by creating an 'extra' row after each normal row used for the data set.
 * Does not currently contain logic for an Options column + dropdown. See .option-cell in course-row.tsx for an example of this. */
export const NvResponsiveTable = <T, U={}, V={}>(props: NvResponsiveTableProps<T, U>) => {
  const backgroundColor = props.backgroundColor ?? gray7;
  /** Creates the string used to separate data sets into different caches in the DataCache. Currently, every unique request uses a different cache */
  const calculateCacheKey = () => {
    const filterString = _.flatten(_.pairs(props.pagedFetchParams.filters)).join();
    return filterString + props.pagedFetchParams?.sorting?.join() + props.pagedFetchParams?.searchQuery;
  };

  // Our backend APIs expect 1-indexing for data pages
  // -1 is a dummy value only used on page load to make pageIndex !== newPageIndex
  // which triggers the initial data load in the useEffect
  const [pageIndex, setPageIndex] = useState(-1);
  const [newPageIndex, setNewPageIndex] = useState(1);
  // const [data, setData] = useState<T[]>([]);
  const [cache, setCache] = useState<DataCache<T>>({});
  const cacheRef = React.useRef<DataCache<T>>();
  cacheRef.current = cache;
  const [, setPreviousCacheKey] = useState(calculateCacheKey());
  // Whether we are loading additional data to the current data set
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  // Whether we are refreshing the entire data set (not an additional load as with isLoadingMOre)
  const [isReloading, setIsReloading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const tableRef = useRef<HTMLDivElement>(null);
  const pageSize = props.pageSize ?? DEFAULT_PAGE_SIZE;
  const [rowsHoverState, setRowsHoverState] = React.useState<{ [key: string]: boolean }>({});

  /* Create the grid-template-columns string for this grid */
  let gridTemplateColumns: string = '';
  props.columns.forEach((c) => {
    const width = c.gridWidth ?? 'auto';
    // For some reason, using a template string here breaks syntax highlighting for
    // this file in the Chrome dev tools
    // eslint-disable-next-line prefer-template
    gridTemplateColumns += width.toString() + ' ';
  });
  // One extra column for the background row
  // gridTemplateColumns += ' 0px';

  const hoverStyles = props.hoverDisabled ? [] : generateRowStyles(props.columns.length, css`
    box-shadow: 0 0 10px 0 rgba(29,33,38,0.2);
  `);

  const styles = css`
    max-height: 100%;
    background-color: ${backgroundColor};
    overflow-y: auto;
    position: relative;
    -ms-overflow-style: scrollbar;

    @media screen and (min-width:0\0) {
      padding-bottom: 200px;
    }

    .grid {
      display: -ms-grid;
      display: grid;
      grid-template-columns: ${gridTemplateColumns};
      -ms-grid-columns: ${gridTemplateColumns};
      grid-auto-rows: auto;
      position: relative;

      div[class*="-cell"] {
        ${(!props.hoverDisabled && !!props.expandableRowComponent) ? css`
          &:not(.disabled) {
            cursor: pointer;
          }
        ` : ''};
        /* By default, all cells sit behind the bkg-row cell. */
        z-index: ${BKG_ROW_Z_INDEX - 1};

        /* This must be padding and not margin because we apply the row borders to each cell */
        padding-right: ${standardSpacing}px;

        ${tablet(css`
          padding-right: ${halfSpacing}px;
        `)};

        &.header-cell {
          display: flex;
          /* Sticky column headers that float above the table content */
          /* Note: This is not supported in IE. This table needs to fall back to non-sticky behavior for unsupported browsers */
          position: sticky;
          z-index: 10;
          top: 0;
          background-color: ${backgroundColor};
          align-items: center;
          height: ${standardSpacing * 2}px;
          cursor: unset;

          &.sortable {
            cursor: pointer;
          }

          padding-top: ${halfSpacing}px;
          padding-bottom: ${halfSpacing}px;

          /* Display sorting icons inline */
          > .icon {
            padding-left: 2px;
            display: inline;
          }

          .icon.icon-sorting-inactive {
            opacity: 0.3;
          }
        }

        /* The leftmost column */
        /* the first +1 is accounting for the bkg row */
        /* the second +1 is accounting for the collapsed rows */
        /* TODO: Make collapsed rows optional via config, and change this selector to account for that */
        &:nth-of-type(${props.columns.length + 1 + 1}n+2) {
          padding-left: ${standardSpacing}px;
        }

        border-bottom: ${borderStyle};

        /* A utility class not used in this file but available by users of the nv-responsive table. Hides elements until
        * the row is hovered */
        .show-on-hover {
          display: none;
        }

        &.disabled > div{
          opacity: 0.5;
        }

        ${hoverStyles.map((s) => s.styles).join('\n')};
      }

      .finished-row {
        margin-top: ${quarterSpacing}px;
        margin-left: ${standardSpacing}px;
        margin-bottom: ${standardSpacing * 2}px;
      }

      /* Hide the expandable rows by default. See const ExpandableRow for documenation on expandable rows */
      .expanded-row {
        /* border: 0; */
        visibility: hidden;
        overflow: hidden;
        height: 0;
        transition: all 0.1s ease-out;

        background: white;
        box-shadow: inset 0 5px 10px 0 rgba(29,33,38,0.1);
      }

      .bkg-row {
        z-index: ${BKG_ROW_Z_INDEX};

        &.disabled {
          pointer-events: none;
          cursor: unset;
        }
      }

      ${!props.hoverDisabled && css`
        .bkg-row:hover:not(.disabled) {
          box-shadow: 0 0 10px 0 rgba(29,33,38,0.2);

          ${!!props.expandableRowComponent && css`
            cursor: pointer;
          `};
        }
      `};

      ${cellHoverStyles(props.columns, props.hoverStyles)};
    }

    .width-fit-content {
      width: fit-content;
    }
  `;

  const dispatch = useAppDispatch();

  /** Load another page of data into the grid
   * @param newIndex The page index to get data for
   * @param reset Whether or not we're clearing all data out of the table and loading the initial page for this query.
   * Forces the page index to be 1 and sets the initial loading state.
   */
  const requestDataPage = (newIndex: number = 1, reset?: boolean) => {
    if (!reset) {
      setIsLoadingMore(true);
    } else {
      newIndex = 1;
      setPageIndex(1);
      setNewPageIndex(1);
      setIsReloading(true);
      tableRef.current.scrollTop = 0;
    }

    const cacheKey = calculateCacheKey();
    const cacheForQuery = cacheRef.current[cacheKey];

    // If we've already loaded the last page or if the data being requested is already in the cache, read from the cache
    // instead of doing a new query
    if (cacheForQuery && (cacheForQuery.isLastPageLoaded || cacheForQuery.dataKeys.length >= (newIndex * pageSize))) {
      // const cachedData = props.cacheLookup(keysFromCache);
      // mutateData(cachedData, cacheKey, currentPageIndex, newIndex);
      setPageIndex(newIndex);
      // total was not being updated on cached search. so adding the count here.
      setTotalCount(cacheForQuery.dataKeys?.length);
      props.onTotalCountChange?.(cacheForQuery.dataKeys?.length);
      setPreviousCacheKey(cacheKey);
      setIsLoadingMore(false);
      setIsReloading(false);
      return;
    }

    // Fire a new data load query via the configured fetchData function
    const handleDataPage = (handledCacheKey, handledPageIndex, handledNewPageIndex, response: T[], count?: number) => {
      setPreviousCacheKey(handledCacheKey);
      setIsLoadingMore(false);
      setIsReloading(false);

      /* Assume we've loaded the final data page if there's no valid data in the response */
      if (!response) {
        setCache((prevState) => {
          if (!prevState[handledCacheKey]) {
            prevState[handledCacheKey] = { dataKeys: [], expandedRows: {}, isLastPageLoaded: true };
          } else {
            prevState[handledCacheKey].isLastPageLoaded = true;
          }

          return prevState;
        });
        return;
      }

      /**
       * Using the total count only in search summary header.
       * So the total count is only set if the summary header has been enabled.
       */
      if (props.showSearchSummaryHeader && props.pagedFetchParams?.searchQuery) {
        setTotalCount(count);
        props.onTotalCountChange?.(count);
      }

      const newData = response;
      const newCacheData: T[keyof T][] = newData.map(d => d[props.cacheDataKey]);

      // Record that we've loaded all data if we get less than a full data page back
      // If there are no pagedFetchParams, no need to check for more data
      const allDataLoaded = newData.length < pageSize || _.isEmpty(props.pagedFetchParams);

      setCache((prevState) => {
        const prevCache = { ...prevState };
        let cacheForKey = prevCache[handledCacheKey];

        if (!cacheForKey) {
          cacheForKey = { dataKeys: newCacheData, expandedRows: {}, isLastPageLoaded: false };
          prevCache[handledCacheKey] = cacheForKey;
        } else {
          cacheForKey.dataKeys = _.union(cacheForKey.dataKeys, newCacheData);
        }

        cacheForKey.isLastPageLoaded = allDataLoaded;
        return prevCache;
      });

      if (handledCacheKey === cacheKey) {
        // mutateData(newData, handledCacheKey, handledPageIndex, handledNewPageIndex);
        setPageIndex(newIndex);
      }
    };

    const { filters, searchQuery, ...pagedParams } = props.pagedFetchParams;
    const filtersParam = props.destructureFilters ? {
      ...filters,
    } : {
      filters,
    };
    // encode if passed encodeSearchQuery for GET search implementations.
    const searchQueryToSend = props.encodeSearchQuery ? encodeURIComponent(searchQuery) : searchQuery;

    const searchQueryParam = props.currentSearchQueryNameParam && searchQuery ? {
      [props.currentSearchQueryNameParam]: searchQueryToSend,
    } : {
      ...(searchQuery ? { searchQuery: searchQueryToSend } : {}),
    };


    const pagedFetchedParams = {
      ...pagedParams,
      ...filtersParam,
      ...searchQueryParam,
    };

    dispatch(props.fetchData({
      ...props.fetchParams,
      ...pagedFetchedParams,
      [props.currentPageNameParam ?? DEFAULT_PAGE_NAME_PARAM]: newIndex,
      [props.currentPageSizeNameParam ?? DEFAULT_PAGE_SIZE_NAME_PARAM]: pageSize,
    })).then((action) => {
      const { response, totalCount: count } = action.payload;
      handleDataPage(cacheKey, pageIndex, newPageIndex, response, count);
    });
  };

  /** Fetch new data in an async function to prevent hanging th UI */
  const loadTableData = (loadNewPageIndex, reset) => {
    async function getNewData() {
      requestDataPage(loadNewPageIndex, reset);
    }

    getNewData();
  };

  /* Blank out the page and show a loading indicator whenever the fetch params change. Then load
  the first data page */
  useEffect(() => {
    setIsReloading(true);
    loadTableData(1, true);
  }, [props.pagedFetchParams.filters, props.pagedFetchParams.sorting, props.pagedFetchParams.searchQuery]);

  /** Load new table data when the page index changes */
  useEffect(() => {
    if (!isReloading) {
    // Load a new data page for the current fetch params
      loadTableData(newPageIndex, false);
    }
  }, [newPageIndex]);

  useEffect(() => {
    if (props?.extraDataKeys?.length > 0) {
      const cachKey = calculateCacheKey();
      const newKeys = without(props.extraDataKeys, ...cache[cachKey]?.dataKeys ?? []);

      if (newKeys.length > 0) {
        setCache((prevState) => {
          const prevCache = { ...prevState };

          if (!prevState[cachKey]) {
            prevState[cachKey] = { dataKeys: [...props.extraDataKeys], expandedRows: {}, isLastPageLoaded: true };
          } else {
            props.extraDataKeys.forEach((extraKey) => {
              if (!prevState[cachKey].dataKeys.includes(extraKey)) {
                prevState[cachKey].dataKeys.push(extraKey);
              }
            });
          }
          return prevCache;
        });
      }
    }
  }, [props.extraDataKeys]);

  const RowComponent = props.rowComponent;
  const LoadingComponent = props.loadingComponent;
  const ExtraRowComponent = props.extraRowComponent;
  const RowBackgroundWrapper = props.rowBackgroundWrapper;

  const sortingIcon = (colIndex: number) => {
    if (!props.columns[colIndex].sortable) {
      return null;
    }

    const sorting = props.columnSortings && props.columnSortings[colIndex];

    // Show the inactive state when a column is sortable but it's key is not present in the sortings object
    if (sorting === undefined) {
      return <span className='icon icon-xsmall icon-sorting-inactive' />;
    }

    return <span className={`icon icon-xsmall icon-sorting-${sorting ? 'down' : 'up'}-active`} />;
  };

  const onHeaderClicked = (colIndex: number) => {
    if (props.columns[colIndex].sortable && props.onSortClicked && !props.disabled) {
      props.onSortClicked(colIndex);
    }
  };

  const headers = [];
  headers.push(<div key='header-bkg' className='header-bkg-row' style={createGridStyles(1)} />);
  props.columns.forEach((c, i) => {
    const header = (
      <div
        className={`header-cell ${c.sortable ? 'sortable' : ''} ${c.disabled ? 'disabled' : ''} ${c.headerClassName ?? ''} h-auto`}
        key={i.toString()}
        style={createGridStyles(i + 1, 1)}
        onClick={c.disabled ? null : () => onHeaderClicked(i)}
        data-qa={c.dataQa}
      >
        {c.content ?? c.name}
        {sortingIcon(i)}
      </div>
    );

    if (c.headerTooltip) {
      headers.push(<NvTooltip key={`${c.name}-tooltip`} text={c.headerTooltip} preventOverflow={false}>{header}</NvTooltip>);
    } else {
      headers.push(header);
    }
  });
  headers.push(<div key='expandable-header' style={{ display: 'none' }} />);

  // Create an empty div for each expandalbe row if one isn't provided
  const ExpandableRow = props.expandableRowComponent; // ?? (() => <div />);

  /** Expand or collapse the expandable row beneath this background row */
  const handleBkgRowClicked = (e: React.MouseEvent<HTMLDivElement>, rowIndex: number) => {
    // Do nothing on handheld devices; expandable rows are not supported on mobile
    if (isHandheld()) {
      return;
    }

    setCache((prevState) => {
      const prevCache = { ...prevState };
      const cacheForKey = prevCache[calculateCacheKey()];
      cacheForKey.expandedRows[rowIndex] = !cacheForKey.expandedRows[rowIndex];
      return prevCache;
    });
  };

  const cacheForQuery = cache[calculateCacheKey()];

  // TODO: This 'string' key in the record should be something like `T[typeof T]`, but that breaks because it
  // isn't `string | number | symbol`
  const storeData = useSelector<RootState, Record<string, T>>((state) => {
    if (cacheForQuery) {
      return props.cacheLookup(state, cacheForQuery.dataKeys);
    }
    return {};
  });

  /** Tracks whether a row is a 'normal' data item or whether it's an 'extra' item created from some parent data item */
  type DataItem<X, Y={}> = {
    type: 'item' | 'extraItem',
    /** The item itself */
    val: X,
    /** The data the this item relates to, if an extra item */
    source?: Y,
  };

  // Determines if the item is for a "normal" row, or an "extra" one outside of the normal data set.
  function isT(item: DataItem<T | V>): item is DataItem<T> {
    return item.type === 'item';
  }

  function isV(item: DataItem<T | V>): item is DataItem<V> {
    return item.type === 'extraItem';
  }

  let data: DataItem<T | V>[] = [];

  if (cacheForQuery) {
    cacheForQuery.dataKeys.forEach((k) => {
      const item = storeData[k as unknown as string];
      // Don't display the extra row if we don't actually have the parent row's data loaded.
      // It's important that we never attempt to render an extra row that doesn't have associated item data
      // because extra rows can (and currently do, for CourseRows) reference that data
      if (!item) {
        return;
      }

      const extraItems: V[] = props.extraRowData?.[k as unknown as string];

      if (extraItems?.length) {
        data = data.concat(extraItems.map(ei => ({
          type: 'extraItem',
          val: ei,
          source: item,
        })));
      }

      if (item) {
        data.push({
          type: 'item',
          val: item,
        });
      }
    });
  }

  const getRowTooltip = (d: T, rowIndex: number, isDisabled: boolean) => {
    if (!props.rowHoverTooltip) {
      return null;
    }
    return props.rowHoverTooltip(d, cache[calculateCacheKey()].expandedRows[rowIndex], isDisabled);
  };

  const rows = [];
  const startIndex = 0;
  const endIndex = data.length;

  for (let i = startIndex; i < endIndex; i += 1) {
    const item = data[i];

    if (isT(item)) {
      const d = item.val;
      const disabled = props.checkRowDisabled && props.checkRowDisabled(d);
      const bkgRowDisabled = disabled || props.hoverDisabled;

      const isRowExpanded = cache[calculateCacheKey()].expandedRows?.[i];
      const expandedRowStyles: React.CSSProperties = {
        visibility: isRowExpanded ? 'visible' : 'hidden',
        height: isRowExpanded ? '108px' : '0px',
        borderBottom: borderStyle,
      };

      const rowKey = `row-${d[props.dataKey]}`;

      // The "background row". Exptends the full width of the table and sits above the normal cell rows.
      // Used to display shading on hovered rows, display centered row tooltips, etc.
      const rowBackground = (
        <div
          key={`bkg-row-${d[props.dataKey]}`}
          className={`bkg-row${bkgRowDisabled ? ' disabled' : ''}`}
          style={createGridStyles(1, i * 2 + 2, props.columns.length + 1)}
          onClick={(e) => !disabled && handleBkgRowClicked(e, i)}
          onMouseEnter={() => setRowsHoverState((prev) => ({ ...prev, [rowKey]: true }))}
          onMouseLeave={() => setRowsHoverState((prev) => ({ ...prev, [rowKey]: false }))}
        />
      );


      rows.push(
        <Fragment key={`frag-key-${d[props.dataKey]}`}>
          {/* Row hover tooltip. Disabled on touch devices */}
          <NvTooltip text={props.rowHoverTooltip && getRowTooltip(d, i, disabled)} enabled={!!props.rowHoverTooltip && !isTouchDevice()}>
            {/* The "background row". Exptends the full width of the table and sits above the normal cell rows.
            Used to display shading on hovered rows, display centered row tooltips, etc. */}
            {RowBackgroundWrapper ? (
              <RowBackgroundWrapper
                data={d}
              >
                {rowBackground}
              </RowBackgroundWrapper>
            ) : rowBackground}
          </NvTooltip>
          <RowComponent
            key={rowKey}
            data={d}
            extraProps={props.rowProps}
            originalIndex={i}
            rowIndex={i * 2 + 2}
            disabled={disabled}
            rowKey={rowKey}
            // isExtraRow={isV(item)}
          />
        </Fragment>,
      );
      if (ExpandableRow) {
        rows.push(
          <ExpandableRow
            style={expandedRowStyles}
            key={`expandable-row-${d[props.dataKey]}`}
            data={d}
            {...props.rowProps}
            rowIndex={i * 2 + 3}
          />,
        );
      } else {
        // TODO: refactor
        rows.push(
          <DefaultExpandableRow
            style={expandedRowStyles}
            key={`expandable-row-${d[props.dataKey]}`}
            data={d}
            {...props.rowProps}
            rowIndex={i * 2 + 3}
          />,
        );
      }
    } else if (isV(item)) {
      // If this is an extra row, render a normal row of type T but pass in the extra row specific data
      // in as extraObject
      rows.push(
        <Fragment key={`frag-key-${i}`}>
          <NvTooltip text={props.extraRowTooltip(item.source as T, item.val)} enabled={!isTouchDevice()}>
            <div
              key={`bkg-row-${i}`}
              className='bkg-row disabled'
              style={createGridStyles(1, i * 2 + 2, props.columns.length + 1)}
            />
          </NvTooltip>
          <RowComponent
            key={`row-${i}-extra-row`}
            data={item.source as T}
            originalIndex={i}
            rowIndex={i * 2 + 2}
            disabled
            extraObject={item.val}
            extraProps={props.rowProps}
          />
        </Fragment>,
      );

      // Fake expandable row
      rows.push(<div key={`row-${i}-extra-row-expandable`} />);
    }
  }

  if (props.extraRowComponent) {
    rows.push(
      <ExtraRowComponent rowIndex={(rows.length + 2)} key='extra-row' />,
    );
  }

  if (cache[calculateCacheKey()]?.isLastPageLoaded) {
    rows.push(<div key='finished-row' style={createGridStyles(1, rows.length + 2, props.columns.length + 1)} className='finished-row'>{t.SEARCH.ALL_LOADED()}</div>);
  }

  /** Request data when after scrolling past a certain % of the scroll bar. Debounced for performance */
  const handleScroll = _.debounce(() => {
    const table = tableRef.current;
    const scrollDiff = table.scrollTop - lastScroll;
    const pctScrolled = (table.scrollTop / (table.scrollHeight - table.clientHeight));
    if (!isLoadingMore && pctScrolled > SCROLL_THRESHOLD) {
      setNewPageIndex(pageIndex + 1);
    } else if (!isLoadingMore && pageIndex > 1 && scrollDiff < 0 && (1 - pctScrolled) < SCROLL_THRESHOLD) {
      // TODO: Re-enable after supporting incremental loads on scroll up
      // setPrevPageIndex(pageIndex);
      // setPageIndex(pageIndex - 1);
      // setNewPageIndex(pageIndex - 1);
      // requestDataPage(pageIndex - 1);
    }

    lastScroll = table.scrollTop;
  }, 100);

  const currentCache = cache[calculateCacheKey()];
  const isFinishedLoading = currentCache?.isLastPageLoaded;

  // Do not display on !isFinishedLoading to avoid NOV-64471. Long-term we should find a way to cancel the previous request (which is setting isReloading to false on a double request)
  const showNoResultsState = data.length === 0 && !isReloading && !isLoadingMore && isFinishedLoading;
  props.onResults?.(!showNoResultsState);
  const noResults = (
    <NvNoResults
      action={props.clearSearch}
      fetchParams={props.pagedFetchParams}
      hideClearSearch={props.hideClearSearch}
      noResultsText={props.noResultsText}
      noResultsIcon={props.noResultsIcon}
      noResultsIconSize={props.noResultsIconSize}
      noResultsClearTextDataQA={props.noResultsClearTextDataQA}
      noResultsTextComponent={props.noResultsTextComponent}
    />
  );

  const contextValue = {
    reload: () => {
      setCache({});
      loadTableData(1, true);
    },
  };

  return (
    <NvResponsiveTableContext.Provider value={contextValue}>
      <div
        css={styles}
        ref={tableRef}
        style={props.style}
        onScroll={() => handleScroll()}
        className={`nv-responsive-table${props.className ? ` ${props.className}` : ''}`}
      >
        {props.disabled ? (
          <Fragment>
            <div className='grid text-small gray-2'>
              {headers}
              <LoadingComponent rowIndex={2} />
            </div>
            <div className='my-6 mx-auto width-fit-content'>{t.TEAM_FACILITATION.BULK_UPLOAD.UPDATING_IN_PROGRESS()}</div>
          </Fragment>
        ) : (
          <Fragment>
            {!showNoResultsState && (
              <Fragment>
                {props.showSearchSummaryHeader && props.pagedFetchParams?.searchQuery && (
                  <div className='d-flex flex-column align-items-center mb-6'>
                    <div className='text-xl font-weight-light'>
                      {isReloading
                        ? t.SEARCH.LOADING_RESULTS()
                        : t.SEARCH.SEARCH_RESULTS_FOR_NUM_QUERY_HIGHLIGHT(totalCount, props.pagedFetchParams.searchQuery)}
                    </div>
                    <ClickableContainer
                      className='mt-1 text-primary text-small font-weight-bold'
                      onClick={() => props.clearSearch()}
                      data-qa={props.dataQaClearSearch}
                    >
                      {t.SEARCH.CLEAR()}
                    </ClickableContainer>
                  </div>
                )}
                <RowsHoverContext.Provider
                  value={{
                    rowsHoverState,
                    setRowsHoverState,
                  }}
                >
                  <div className='grid text-small gray-2'>
                    {headers}
                    {!isReloading && rows}
                    {!isFinishedLoading && <LoadingComponent rowIndex={(rows.length + 2)} />}
                  </div>
                </RowsHoverContext.Provider>
              </Fragment>
            )}
            {/* A 'no results' state shown when there's no data to display */}
            {showNoResultsState && (
              props.noResultsInTable ? (
                <div className='grid text-small gray-2'>
                  {headers}
                  <div style={createGridStyles(1, 2, props.columns.length + 1)}>
                    {props.extraRowComponent ? <ExtraRowComponent rowIndex={(rows.length + 2)} key='extra-row' /> : noResults}
                  </div>
                </div>
              ) : noResults
            )}
          </Fragment>
        )}
      </div>
    </NvResponsiveTableContext.Provider>
  );
};

let lastScroll = 0;

/** An empty expandable row to be used when one isn't defined.
 * TODO: Remove this */
const DefaultExpandableRow = (props) => (
  <div />
);

/** Styles applied to each table cell on hover */
const cellHoverStyles = (columns: NvResponsiveTableColumn[], hoverStyles?) => {
  const cellStyleNames = columns.map(c => c.className);

  hoverStyles ??= css`
    & {
      background-color: ${gray6};
      color: ${gray4};
      .icon {
        display: block;
      }
    }
  `;
  const cellStyles = [];

  function makeSiblingSelector(startIndex: number) {
    return cellStyleNames.slice(startIndex, cellStyleNames.length).join(' + .');
  }

  /* Generate css selectors to select adjacent siblings up to and including
  the options-cell when hovering any individual cell. This enables the options-cell's
  blue hover styles when mousing over any individual cell */
  for (let i = 0; i < cellStyleNames.length; i += 1) {
    const styleName = `.${cellStyleNames[i]}`;
    let siblingSelector = '';

    if (i < cellStyleNames.length - 1) {
      siblingSelector = ` + .${makeSiblingSelector(i + 1)}`;
    }

    cellStyles.push(css`
      ${styleName}:hover:not(.disabled)${siblingSelector} {
        ${hoverStyles};
      }
    `);

    if (i === cellStyleNames.length - 1) {
      cellStyles.push(css`
        .bkg-row:hover:not(.disabled) + .${makeSiblingSelector(0)} {
          ${hoverStyles};
        }
      `);
    }
  }

  cellStyles.push(css`
    .options-cell.active {
      ${hoverStyles};
    }
  `);

  return cellStyles.map(s => s.styles).join('\n');
};

const NvResponsiveTableContext = React.createContext(null);

export const useResponsiveTableContext = () => React.useContext(NvResponsiveTableContext);

export default NvResponsiveTable;
