/* eslint-disable react/require-default-props */
import React from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { getCourseAliases } from 'redux/selectors/course';
import { CourseAliases } from 'redux/schemas/models/course';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { gray2, gray3, success } from 'styles/global_defaults/colors';
import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

type Props = {
  totalPoints: number;
  label?: string;
  pointsText?: string;
  pointsReceived?: number;
};

/**
 * This component is part of lecture component activities UI, it displays
 * points, showing it grayed out if activity hasn't been completed
 * (isComponent = false) and showing it with success green color when activity
 * has been completed (isComponent = true)
 */
const ActivityPoints = (props: Props) => {
  const {
    label,
    pointsText,
    totalPoints,
    pointsReceived,
  } = props;


  const arePointsEarned = !!pointsReceived;

  const pointsColor = arePointsEarned ? success : gray2;

  const aliases = useSelector<RootState, CourseAliases>(
    (state: RootState) => getCourseAliases(state),
  );

  const styles = css`
    font-size: 12px;
    line-height: 15px;

    display: flex;
    align-items: center;

    .points-icon {
      color: ${pointsColor};
      margin-right: ${halfSpacing}px;
    }

    .points-text {
      color: ${pointsColor};
    }

    .dot-separator {
      display: inline-block;
      margin: 2px ${halfSpacing}px 0 ${halfSpacing}px;
      border: 1px solid ${gray3};
      width: ${quarterSpacing}px;
      height: ${quarterSpacing}px;
      border-radius: 50%;
    }

    .points-label {
      display: inline-block;
      color: ${gray3};
      font-weight: ${semiBoldFontWeight};
    }
  `;

  return !!(totalPoints || pointsReceived) && (
    <div css={styles}>
      <i
        className={`points-icon icon-${arePointsEarned ? 'highlight' : 'points'
        }`}
      />
      {arePointsEarned && <span className='sr-only'>{t.TIMELINE.EARNED()}</span>}
      <span className='points-text'>
        {pointsText
          ?? t.LECTURE_PAGES.COMPONENTS.ACTIVITY.POINTS({
            ...aliases.pointsAliases,
            pointsCount: pointsReceived || totalPoints,
          })}
      </span>
      {label && (
        <React.Fragment>
          <div className='dot-separator' />
          <div className='points-label'>
            {label}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ActivityPoints;
