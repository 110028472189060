import React from 'react';
import { css } from '@emotion/react';

import { gray5 } from 'styles/global_defaults/colors';

const Divider = () => {
  const itemStyles = css`
    height: 1px;
    border-top: 1px solid ${gray5};
  `;

  return <div css={itemStyles} />;
};

export default Divider;
