import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import t from 'react-translate';

import { authorRatingsSorted } from 'redux/selectors/skills-feedback';
import { getCurrentUser } from 'redux/selectors/users';

import ClickableContainer from 'components/clickable-container';
import NvUserAvatar from 'components/nv-user-avatar';
import NvIcon from '../nv-icon';
import ViewFeedback from './view-feedback';

type AuthorFeedbackProps = {
  ownerId: number
  ownerKey: string
  createdAt: string
  submittedUserName: string
  onDelete: () => void
  pendoTag?: string
  showTag?: boolean
};

const AuthorFeedback = ({
  ownerId,
  ownerKey,
  createdAt,
  submittedUserName,
  onDelete,
  pendoTag,
  showTag,
}: AuthorFeedbackProps) => {
  const authorRatings = useSelector(state => authorRatingsSorted(state, ownerId, ownerKey));
  const author = useSelector(state => getCurrentUser(state));
  return (
    <div className='d-flex'>
      { author && (
        <div className='d-none d-md-block'>
          <NvUserAvatar
            borderType='round'
            size='md'
            user={author}
            displayName
            className='mx-4 mb-1 gray-2 text-small'
            directToOrgLevelProfile
            displayRoleBadge
          />
        </div>
      )}
      <div className='own-feedback-container w-100 flex-column'>
        { author && (
          <div className='d-block d-md-none mb-4'>
            <NvUserAvatar
              displayName
              inlineName
              displayRoleBadge={false}
              alignNameRight
              user={author}
              size='sm'
              borderType='round'
              directToOrgLevelProfile
            />
          </div>
        )}
        {showTag && (
          <div className='d-flex align-items-center mb-4 ml-2'>
            <NvIcon icon='skills-feedback-activity' size='medium' className='gray-2' />
            <div className='bg-gray-2 rounded px-2 py-1 text-small text-white ml-2'>
              {t.LECTURE_PAGES.COMPONENTS.PEER_EVALUATION.VIDEO_PRACTICE.SUBMISSION_FEEDBACK_TAG.SKILLS_FEEDBACK_SUBMISSION()}
            </div>
          </div>
        )}
        {authorRatings?.map(rating => (
          <ViewFeedback key={rating} ratingId={rating} isAuthor />
        ))}
        <div className='d-flex justify-content-between'>
          <ClickableContainer
            className='own-feedback d-flex gray-3 align-items-center'
            onClick={() => onDelete()}
            pendo-tag-name={pendoTag}
          >
            <NvIcon icon='trash' size='xss-smallest' className='ml-2 mr-1' />
            {t.NOVOED.DELETE()}
          </ClickableContainer>
          <div className='text-date gray-2'>
            {moment(createdAt).format('lll')}
          </div>
        </div>
        <div className='text-small gray-3 ml-2 mt-6 '>
          {t.SKILLS_FEEDBACK.FEEDBACK_VISIBILITY.AUTHOR(submittedUserName)}
        </div>
      </div>
    </div>
  );
};

export default AuthorFeedback;
