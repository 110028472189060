import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getBadges, getPracticeFeedbackActivity, getScenario, getSubmission } from 'redux/selectors/video-practice';
import { useAppDispatch } from 'redux/store';
import { postVideoHasViewed } from 'redux/actions/video-practice';
import { getCurrentUserId } from 'redux/selectors/users';
import { setShowFeedbackInstruction } from 'redux/actions/video-practice-feedback';
import { css } from '@emotion/react';
import { warning, gray4 } from 'styles/global_defaults/colors';
import {
  doubleSpacing, standardSpacing, quarterSpacing, halfSpacing,
  largeSpacing, threeQuartersSpacing, tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { useQuery } from 'shared/hooks/use-query';
import { RecordingFormat, SubmissionTab } from 'redux/schemas/models/video-practice';
import NvIcon from 'shared/components/nv-icon';
import NvVideoPreview from 'shared/components/nv-video-preview';
import { ActionTypes, PracticeSubmissionContext, bgGradient, rtlBgGradient, useFilterComments, usePrevious } from '../utils';
import NvCommentButton from '../nv-comment-button';
import { FeedbackInstructionModal, FeedbackInstructionPopover } from '../feedback-instruction';
import { config } from '../../../../../config/pendo.config.json';
import SocialActions from './social-actions';

const styles = (isAudio) => css`
 .featured {
    width: ${doubleSpacing}px;
    height: ${standardSpacing}px;
    background-color: ${warning};
    right: -${quarterSpacing}px;
    top:-${quarterSpacing}px;
    z-index: 1;
    &:after {
      content: '';
      width: 0;
      height: 0;
      background: transparent;
      position: absolute;
      bottom: -${quarterSpacing}px;
      right: 0;
      border-style: solid;
      border-color: transparent transparent transparent ${gray4};
      border-width: 0 0 ${quarterSpacing}px ${quarterSpacing}px;

      html[dir="rtl"] & {
        border-color: ${gray4} transparent transparent transparent;
        border-width: ${quarterSpacing}px 0px 0px ${quarterSpacing}px;
      }
    }
  }
  .player-container {
    ${isAudio && css`
      .nv-video-preview {
        video {
          height: 55px;
        }
      }
    `}

    .comment, .comment-and-feedback {
      position: absolute;
      bottom: 5%;
      /*! @noflip */
      right: 100px;
    }

    .jwplayer-container {
      ${isAudio && css`

        .jwplayer {
          height: 100px !important;

          .jw-controlbar {
            height: 100px !important;
            max-height: 100px !important;

            align-content: center;
          }
        }
      `}

      .nv-seekbar-badge-wrapper {
        width: 100%;
        display: flex;
        margin: 0 12px;
        position: relative;

        .nv-seekbar-badge {
          position: absolute;
          top: -${standardSpacing}px;
          transform: translateX(-${halfSpacing}px);
          cursor: pointer;

          &.highlighted {
            top: -${largeSpacing}px;
            transform: translateX(-${threeQuartersSpacing}px);
          }
        }
      }
    }
  }
  .jwplayer-container {
    .jwplayer:not(.jw-state-playing) {
      .jw-media {
        opacity: 0.5;
      }
    }
  }
  .skill-feedback {
    display: flex;
    justify-content: flex-end;
    height: ${tripleSpacing}px;
    width: ${tripleSpacing}px;
    border-radius: ${largeSpacing}px;
    background: ${bgGradient};
    cursor: pointer;
    box-shadow: 0px ${quarterSpacing}px ${standardSpacing}px rgba(0, 154, 192, 0.3), 0px ${quarterSpacing}px ${standardSpacing}px rgba(29, 33, 38, 0.2);

    html[dir="rtl"] & {
      background: ${rtlBgGradient};
    }
  }
`;

const SubmissionContainer = () => {
  const [{
    submissionId, scenarioId, isPracticeFeedback,
    showCommentButton, lectureComponentId, practiceSubmissionRefs,
  }, practiceSubmissionDispatch] = useContext(PracticeSubmissionContext);
  const { practiceFeedbackCriteriaId, showFeedbackInstruction } = useSelector(state => state.app.videoPracticeFeedback);
  const { featured, videoFile, user } = useSelector((state) => getSubmission(state, submissionId));
  const {
    commentsOnceLoaded,
  } = useSelector((state) => state.app.videoPracticeSubmissions[submissionId]) ?? {};
  const {
    submissionId: selectedSubmissionId,
    userId: currentUser,
    newNotification,
    userInteractedWith,
  } = useSelector((state) => state.app.practiceRoom.params);
  const badges = useSelector((state) => getBadges(state, submissionId));
  const currentUserId = useSelector(getCurrentUserId);
  const {
    recordingFormat,
  } = useSelector((state) => getScenario(state, scenarioId));
  const practiceFeedbackActivity = useSelector(state => getPracticeFeedbackActivity(
    state, { lectureComponentId },
  ));
  const query = useQuery();
  const [showInstructionPopover, setShowInstructionPopover] = useState(false);
  const [isCommentButtonExpanded, setCommentButtonExpanded] = useState(showFeedbackInstruction && isPracticeFeedback);
  const [showFeedbackInstructionModal, setFeedbackInstructionModal] = useState(false);

  const { playerInstanceRef, videoRef } = practiceSubmissionRefs;
  const prevUser = usePrevious(currentUser);
  const isMyPractice = user.id === currentUserId;
  const dispatch = useAppDispatch();
  const { filterCommentsOfTime } = useFilterComments();
  // If this is same submission selected (submissionId is in url), then try
  // to scroll to it
  useEffect(() => {
    const showInstantInsights = query.submissionView === SubmissionTab.INSIGHTS && query.stickyInsights === 'true';
    if (!showInstantInsights) {
      if (
        commentsOnceLoaded && selectedSubmissionId && selectedSubmissionId === submissionId && !newNotification?.commentId
      ) {
        if (prevUser === currentUser && videoRef?.current) {
          videoRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else {
          setTimeout(() => {
            if (videoRef?.current) {
              videoRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }
            // A timeout of 500 millisec to finish the transition (if any) before scrolling
          }, 500);
        }
        if (playerInstanceRef.current && userInteractedWith) {
          playerInstanceRef.current.play(true);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    commentsOnceLoaded,
    selectedSubmissionId,
    submissionId,
    prevUser,
    currentUser,
    userInteractedWith,
    newNotification?.commentId,
    query.submissionView,
    query.stickyInsights,
  ]);

  useEffect(() => {
    // close popover when comment button collapsed.
    // also  hide popover for an activity once it is displayed.
    if (!isCommentButtonExpanded) {
      setShowInstructionPopover(false);
      if (showFeedbackInstruction) {
        dispatch(setShowFeedbackInstruction({ show: false, practiceFeedbackCriteriaId }));
      }
    }
  }, [dispatch, practiceFeedbackCriteriaId, isCommentButtonExpanded, showFeedbackInstruction]);

  useEffect(() => {
    // close comment button if feedback instruction is false for an activity (eg: while sliding )
    if (!showFeedbackInstruction) {
      setCommentButtonExpanded(false);
    }
  }, [showFeedbackInstruction, submissionId]);

  const onPlayVideo = useCallback((event) => {
    if (playerInstanceRef.current && !playerInstanceRef.current.hasPlayed && !isMyPractice) {
      dispatch(postVideoHasViewed({ scenarioId, submissionId }));
    }
  }, [dispatch, isMyPractice, scenarioId, submissionId]);

  const onReady = useCallback(() => {
    // show popover initially after a delay to position popover in place
    if (isCommentButtonExpanded && showFeedbackInstruction) {
      setShowInstructionPopover(true);
    }
    if (!showFeedbackInstruction) {
      setShowInstructionPopover(false);
    }
  }, [isCommentButtonExpanded, showFeedbackInstruction]);

  const pauseVideo = useCallback(() => {
    // Pause the player and get current time stamp
    if (playerInstanceRef.current) {
      const playerState = playerInstanceRef.current.getState();
      if (playerState === 'playing' || playerState === 'paused') {
        if (playerState === 'playing') {
          // Pause the player
          playerInstanceRef.current.play(false);
        }
        const currentTime = playerInstanceRef.current.getPosition();
        if (currentTime >= 1) {
          practiceSubmissionDispatch({
            type: ActionTypes.SET_TIME_STAMP,
            payload: currentTime,
          });
        } else {
          practiceSubmissionDispatch({
            type: ActionTypes.SET_TIME_STAMP,
            payload: null,
          });
        }
      } else {
        practiceSubmissionDispatch({
          type: ActionTypes.SET_TIME_STAMP,
          payload: null,
        });
      }
    }
  }, [practiceSubmissionDispatch]);

  const onClickComment = useCallback((type: 'text' | 'video') => {
    if (showFeedbackInstruction) {
      dispatch(setShowFeedbackInstruction({ show: false }));
    }
    if (playerInstanceRef.current.getState() === 'playing'
      || playerInstanceRef.current.getState() === 'paused') {
      pauseVideo();
    }
    setCommentButtonExpanded(false);
    practiceSubmissionDispatch({ type: ActionTypes.SET_SHOW_COMMENT_BUTTON, payload: false });
    if (type === 'text') {
      practiceSubmissionDispatch({ type: ActionTypes.SET_SHOW_COMMENT_INPUT_ROW, payload: true });
    } else {
      practiceSubmissionDispatch({ type: ActionTypes.SET_SHOW_RECORD_VIDEO_COMMENT, payload: true });
    }
  }, [dispatch, pauseVideo, practiceSubmissionDispatch, showFeedbackInstruction]);

  return (
    <div
      className='d-flex flex-column mt-1 position-relative'
      css={styles(recordingFormat === RecordingFormat.AUDIO)}
    >
      {featured && !practiceFeedbackCriteriaId && (
        <div className='featured d-flex flex-column justify-content-center align-items-center position-absolute'>
          <NvIcon
            icon='highlight'
            size='xss-smallest'
            className='text-white'
          />
        </div>
      )}
      <div className='player-container' ref={videoRef}>
        <NvVideoPreview
          ref={playerInstanceRef}
          file={videoFile}
          badges={badges}
          onClickOnBadge={filterCommentsOfTime}
          badgePendoTag={config.pendo.practice.commentFocusFromPracticePlayer}
          onPlay={onPlayVideo}
          onReady={onReady}
          isAudio={recordingFormat === RecordingFormat.AUDIO}
        />
        <div
          className='comment d-flex'
          dir='ltr'
        >
          {showCommentButton && (
            <FeedbackInstructionPopover
              enabled={showInstructionPopover && isCommentButtonExpanded}
              show={showInstructionPopover}
              title={practiceFeedbackActivity?.title}
              setShowModal={setFeedbackInstructionModal}
            >
              <NvCommentButton
                onTextComment={() => onClickComment('text')}
                onVideoComment={() => onClickComment('video')}
                pauseVideo={pauseVideo}
                isExpanded={isCommentButtonExpanded}
                setExpanded={setCommentButtonExpanded}
              />
            </FeedbackInstructionPopover>
          )}
        </div>
      </div>
      <SocialActions isMyPractice={isMyPractice} />
      {practiceFeedbackActivity?.title && (
        <FeedbackInstructionModal
          title={practiceFeedbackActivity.title}
          setShowModal={setFeedbackInstructionModal}
          showModal={showFeedbackInstructionModal && !!videoRef?.current}
        />
      )}
    </div>
  );
};
export default SubmissionContainer;
