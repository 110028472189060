import React from 'react';
import t from 'react-translate';
import { css } from '@emotion/core';
import * as yup from 'yup';

// redux
import { useSelector } from 'react-redux';
import {
  ResponseOption,
  NQuizQuestion,
} from 'redux/schemas/models/progressive-quiz';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { RootState } from 'redux/schemas';
import { getQuizQuestionOptions } from 'redux/selectors/quizzes';
import {
  addQuizQuestionOption,
  deleteQuizQuestionOption,
  editQuizQuestionOption,
} from 'redux/actions/quizzes';
import { wrapThunkAction } from 'redux/utils';
import { useAppDispatch } from 'redux/store';

import {
  danger,
  gray2,
  gray4,
  gray5,
  gray6,
  gray7,
  primary,
  success,
  white,
} from 'styles/global_defaults/colors';
import {
  halfSpacing,
  quarterSpacing,
} from 'styles/global_defaults/scaffolding';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import NvPopover from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NvTooltip from 'shared/components/nv-tooltip';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import NvPillWithInput from 'shared/components/nv-pill-with-input';
import ClickableContainer from 'components/clickable-container';
import ViewAnswerFeedBack from 'quizzes/components/feedback-components/view-answer-feedback';
import AnswerFeedback from '../../feedback-components/answer-feedback';
import { config } from '../../../../../config/pendo.config.json';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
};

const ShortAnswerSection = (props: QuizQuestionProps) => {
  const { currentQuestion } = props;
  const {
    answerState,
    setAnswerState,
  } = React.useContext(QuestionContext);
  const courseAliases = useSelector(getFlatCourseAliases);
  const dispatch = useAppDispatch();

  const MIN_CHARACTER_LENGTH = 1;
  const MAX_CHARACTER_LENGTH = 255;

  const validationSchema = yup.string()
    .min(MIN_CHARACTER_LENGTH, t.QUIZZES.SHORT_ANSWER_SETTING_VALIDATION.LENGTH(
      MAX_CHARACTER_LENGTH.toString(),
      MIN_CHARACTER_LENGTH.toString(),
    ))
    .max(MAX_CHARACTER_LENGTH, t.QUIZZES.SHORT_ANSWER_SETTING_VALIDATION.LENGTH(
      MAX_CHARACTER_LENGTH.toString(),
      MIN_CHARACTER_LENGTH.toString(),
    ));

  const {
    savingIndicatorTimeoutRef,
    currentQuestionResponse,
    setSavingStatus,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isEditMode,
    isAnswerMode,
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const [correctAnswerFeedback, setCorrectAnswerFeedback] = React.useState(responseOptions[0]?.explanation);
  const [incorrectAnswerFeedback, setIncorrectAnswerFeedback] = React.useState(responseOptions[0]?.secondaryExplanation);
  const [shortAnswer, setShortAnswer] = React.useState('');
  const [isAddMode, setIsAddMode] = React.useState(false);
  const [isAnswerAdding, setIsAnswerAdding] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [triggerShowFeedback, setTriggerShowFeedback] = React.useState(false);

  const showFeedbackIcon = !(correctAnswerFeedback !== null && incorrectAnswerFeedback !== null);
  const isOnlyOneShortAnswerPresent = responseOptions.length === 1;

  const getBorderColor = () => {
    if (currentQuestionResponse) {
      if (currentQuestionResponse.isCorrect) {
        return success;
      }
      return danger;
    }
    return gray6;
  };

  const styles = css`
    .input-style {
      font-size: 16px;
      border-color: ${getBorderColor()};
      border-radius: ${quarterSpacing}px;
      background-color: ${white};

      ::placeholder {
        color: ${isEditMode ? gray4 : gray2};
      }
    }
    .input-style:focus {
      border: 1px solid ${primary};
    }
    .answers-setting-container {
      border: 1px solid ${gray5};
      border-radius: ${quarterSpacing}px;
      min-height: 65px;
      .correct-answers-container {
        flex-wrap: wrap;
        width: 90%;
        align-items: flex-start;
      }
      .feedback-icon-container {
        width: 10%;
        justify-content: end;
        .comments-icon {
          opacity: 0;
        }
      }
      &:hover .comments-icon {
        opacity: 1 !important;
      }
      .short-answer-capsule {
        margin-bottom: ${halfSpacing}px;
        margin-right: ${halfSpacing}px;
        border-radius: 18px;
        min-height: 35px;
        cursor: pointer;
        background-color: ${gray7};
      }
      .in-view {
        border: 1px solid ${gray5};
        max-width: 100%;
        background-color: ${gray7};
      }
      .input-mode {
        border: 1px solid ${primary} !important;
        background-color: ${gray6}; !important;
      }
    }
  `;

  const handleShowFeedBackInput = () => {
    setTriggerShowFeedback(true);
  };

  const response: string = currentQuestionResponse?.response?.toString();

  const handleChange = (e) => {
    setAnswerState(e.target.value);
  };

  const getValue = () => {
    if (currentQuestionResponse) {
      return response;
    }
    if (typeof answerState !== 'string') {
      return '';
    }
    return answerState;
  };

  const handleClose = () => {
    setIsAddMode(false);
    setShortAnswer('');
  };

  const changehandler = (val) => {
    if (val === '') {
      setError(true);
    } else {
      setError(false);
    }
    setShortAnswer(val);
  };

  const handleDelete = (id) => {
    if (isAnswerAdding) {
      return;
    }
    if (isOnlyOneShortAnswerPresent) {
      editShortAnswer(responseOptions[0].id, '');
      return;
    }
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    wrapThunkAction(dispatch(deleteQuizQuestionOption(id))).then(() => {
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const editShortAnswer = (id, newValue) => {
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    wrapThunkAction(dispatch(editQuizQuestionOption(
      { id,
        patch: { isCorrect: true, optionContent: newValue },
      },
    ))).then(() => {
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const saveShortAnswer = () => {
    if (isAnswerAdding) {
      return;
    }
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    setIsAnswerAdding(true);
    clearTimeout(savingIndicatorTimeoutRef.current);
    wrapThunkAction(dispatch(addQuizQuestionOption(
      { questionId: currentQuestion.id,
        optionContent: shortAnswer,
      },
    ))).then(() => {
      setShortAnswer('');
      setIsAddMode(false);
      setSavingStatus(SavingIndicator.SUCCESS_STATUS);
    }).catch(() => {
      setSavingStatus(SavingIndicator.ERROR_STATUS);
    }).finally(() => {
      setIsAnswerAdding(false);

      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const handleOnAdd = () => {
    if (isOnlyOneShortAnswerPresent && !responseOptions[0].optionContent) {
      editShortAnswer(responseOptions[0].id, shortAnswer);
      setShortAnswer('');
      setIsAddMode(false);
      return;
    }
    saveShortAnswer();
  };

  return (
    <div css={styles}>
      <div className='mb-5'>
        <NvTextInput
          disabled={!isAnswerMode || !!currentQuestionResponse}
          inputClassName='py-5 input-style'
          placeholder={(isReviewMode && !getValue())
            ? ''
            : t.QUIZZES.INPUT_ANSWER_PLACEHOLDER()}
          value={getValue()}
          onChange={handleChange}
        />
        { currentQuestionResponse && (
        <ViewAnswerFeedBack currentQuestion={currentQuestion} />
        )}
      </div>
      { isEditMode && (
        <div className='d-flex mb-2'>
          <p className='semi-bold mb-0'>{t.QUIZZES.CORRECT_ANSWERS()}: </p>
          <NvPopover
            showOnHover
            className='d-flex align-items-center'
            placement='top'
            content={(
              <div className=''>
                {t.QUIZZES.SHORT_ANSWER_QUESTION.SETTING_SHORT_ANSWER_INFO(courseAliases)}
              </div>
              )}
          >
            <NvIcon className='d-flex' icon='info ml-1' size='xs-smallest text-primary' />
          </NvPopover>
        </div>
      )}
      { isEditMode && (
        <div className='px-3 pt-3 pb-1 answers-setting-container d-flex'>
          <div className='d-flex correct-answers-container'>
            {!!responseOptions.length
            && responseOptions
              .filter((eachCorrectShortAnswer) => eachCorrectShortAnswer.optionContent !== null && eachCorrectShortAnswer.optionContent !== '')
              .map((eachCorrectShortAnswer) => (
                <NvPillWithInput
                  key={eachCorrectShortAnswer.id}
                  maxLength={MAX_CHARACTER_LENGTH}
                  value={eachCorrectShortAnswer.optionContent}
                  onRemove={() => { handleDelete(eachCorrectShortAnswer.id); }}
                  onEdit={(newValue) => { editShortAnswer(eachCorrectShortAnswer.id, newValue); }}
                  placeholder={t.QUIZZES.SHORT_ANSWER_QUESTION.CORRECT_ANSWER_PLACEHOLDER()}
                  className='short-answer-capsule py-1 border-gray-5 px-4 mr-2 font-weight-bold'
                  editModeClassName='input-mode'
                  validationSchema={validationSchema}
                  dataQaRemove={config.pendo.activities.progressiveQuiz.questionModal.shortAnswerQuestion.deleteShortAnswer}
                  isAdded
                  canEdit
                />
              ))}
            {!isAddMode ? (
              <ClickableContainer
                tabIndex={0}
                role='button'
                onClick={() => setIsAddMode(true)}
                className='short-answer-capsule bg-gray-6 align-items-center d-flex px-4 py-1'
                data-qa={config.pendo.activities.progressiveQuiz.questionModal.shortAnswerQuestion.addShortAnswer}
              >
                <NvIcon size='xss-smallest' icon='create-new-post' className='text-primary semi-bold' />
                {(!responseOptions[0].optionContent && responseOptions.length === 1)
                  && (<p className='ml-2 mb-0 text-primary semi-bold'>{t.QUIZZES.ADD_CORRECT_ANSWER()}</p>)}
              </ClickableContainer>
            ) : (
              <NvPillWithInput
                value={shortAnswer}
                maxLength={MAX_CHARACTER_LENGTH}
                onChange={changehandler}
                onRemove={handleClose}
                onAdd={handleOnAdd}
                className='short-answer-capsule bg-gray-6 py-1 input-mode px-4 mr-2 font-weight-bold'
                placeholder={t.QUIZZES.SHORT_ANSWER_QUESTION.CORRECT_ANSWER_PLACEHOLDER()}
                disabled={isAnswerAdding}
                validationSchema={validationSchema}
              />
            )}
          </div>
          <div className='d-flex feedback-icon-container'>
            {showFeedbackIcon && (
            <NvTooltip text={t.QUIZZES.FEEDBACK_DEFAULT_INFO(courseAliases)}>
              <NvIcon
                onClick={handleShowFeedBackInput}
                className='text-primary mr-1 comments-icon'
                icon='comments'
                size='small'
                data-qa={config.pendo.activities.progressiveQuiz.questionModal.addFeedback}
              />
            </NvTooltip>
            )}
          </div>
        </div>
      )}
      {isEditMode
        && (
        <AnswerFeedback
          currentQuestion={currentQuestion}
          correctAnswerFeedback={correctAnswerFeedback}
          incorrectAnswerFeedback={incorrectAnswerFeedback}
          setCorrectAnswerFeedback={setCorrectAnswerFeedback}
          setIncorrectAnswerFeedback={setIncorrectAnswerFeedback}
          triggerShowFeedback={triggerShowFeedback}
          setTriggerShowFeedback={setTriggerShowFeedback}
        />
        )}
    </div>
  );
};

export default ShortAnswerSection;
