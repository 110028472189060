import { css } from '@emotion/react';
import { JourneyCardLite } from 'offerings/components/journey-card-lite';
import React from 'react';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import t from 'react-translate';
import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import {
  getCourseInstitutionColors,
  getCourseJourneys,
  getCurrentCourse,
} from 'redux/selectors/course';
import { getVisitedLearningJourney } from 'redux/selectors/learning-journeys';
import {
  NvDropdown,
  NvDropdownAlign,
  NvDropdownButtonStyle,
  NvDropdownCustomItem,
  NvDropdownDivider,
  NvDropdownLinkItem,
  NvDropdownOption,
} from 'shared/components/inputs/nv-dropdown';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import useAngularExpression from 'shared/hooks/use-angular-expression';
import useScrollTop from 'shared/hooks/use-scroll';
import {
  black,
  compareHexColors,
  gray2,
  gray3,
  gray4,
  primary,
  white,
} from 'styles/global_defaults/colors';
import {
  boldFontWeight,
  openSans,
  openSansCondensed,
  semiBoldFontWeight,
  textMediumFontSize,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import { screenMdMin, screenSmMin } from 'styles/global_defaults/media-queries';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { updateVisitedJourney } from 'redux/actions/learning-journeys';
import { useAppDispatch } from 'redux/store';
import { isEmpty } from 'underscore';
import { config } from '../../../config/pendo.config.json';

type JourneysDataSchema = {
  total: number;
  journeys: Array<VisitedLearningJourneySchema>;
};

const JOURNEYS_TO_SHOW = 3;
const BADGE_MARGIN_RIGHT = 40;
const DEFAULT_BADGE_STYLES = {
  color: black,
  backgroundColor: white,
  border: `1px solid ${gray4}`,
};

export const JourneyBadge = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLDivElement>();
  const timeoutRef = React.useRef<number>();
  const journeysData: JourneysDataSchema = useSelector(getCourseJourneys);
  const institutionColors = useSelector(getCourseInstitutionColors);
  const visitedJourneyInfo: VisitedLearningJourneySchema = useSelector(getVisitedLearningJourney);
  let dropdownItems: NvDropdownOption[] = [];
  const journeysItems: Array<
  NvDropdownCustomItem | NvDropdownDivider | NvDropdownLinkItem
  > = [];
  const [shouldShowBadgeLabel, setShouldShowBadgeLabel] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('100%');
  const [dynamicBadgeSyles, setDynamicBadgeSyles] = React.useState(
    DEFAULT_BADGE_STYLES,
  );
  const [showDropdownMenu, setShowDropdownMenu] = React.useState(false);
  const currentCourse = useSelector(getCurrentCourse);

  // Getting the screen size
  const isTabletAndMobile = useMediaQuery({
    query: `(max-width: ${screenMdMin}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${screenSmMin}px)`,
  });

  /**
   * Checking whether the current course meets the conditions for displaying the journey badge:
   * 1. There are journeys in journeysData.total
   * 2. The user is enrolled in both the journey and the course
   */

  // Checking whether the current course has journeys items in the in_journeys property
  const courseHasJourneys = journeysData.total > 0;

  // Checking if we keep track of the visited learning journey
  const visitedJourneyId = visitedJourneyInfo?.catalogId;

  // Checking if the user is enrolled in the visited learning journey. The visited journey should exist in the in_journeys property of the course
  const isUserEnrolledInVisitedJourney = courseHasJourneys && visitedJourneyId && (journeysData.journeys?.findIndex(journey => journey?.catalogId === visitedJourneyId) !== -1);

  const shouldDisplayBadge = !!currentCourse?.visitedHome && !(!courseHasJourneys || (courseHasJourneys && visitedJourneyId && !isUserEnrolledInVisitedJourney));

  // Getting the label and items of the badge
  let badgeLabel = '';
  let showDropdownArrow = false;

  if (shouldDisplayBadge) {
    if (isUserEnrolledInVisitedJourney && visitedJourneyId) {
      badgeLabel = visitedJourneyInfo?.name;
    } else if (journeysData?.total === 1) {
      badgeLabel = journeysData?.journeys[0]?.name;
    } else {
      showDropdownArrow = true;
      badgeLabel = t.LEARNING_JOURNEYS.COURSE_HOME.JOURNEY_NAVIGATION.VIEW_LEARNING_JOURNEYS();
    }
  }

  // Cheking if there is top scrolling
  const isCompactHeader = useScrollTop(document.getElementById('main-panel'));

  // Cheking if it's a lecture page
  const currentStateName = useAngularExpression(() => $state.current.name);
  const isLecturePage = currentStateName === 'lecture-page';

  // Cheking if there's an updated data of the visited journey
  if (visitedJourneyId && journeysData.journeys?.length > 0 && journeysData.journeys.find(journey => journey.catalogId === visitedJourneyId)) {
    const updatedVisitedJourney = journeysData.journeys.find(journey => journey.catalogId === visitedJourneyId);

    if (!visitedJourneyInfo?.completionStats && !isEmpty(updatedVisitedJourney?.completionStats)) {
      dispatch(updateVisitedJourney(updatedVisitedJourney));
    }
  }

  // Calculating the width of the badge dynamically to meet the design condition of mantaining a 40px right margin
  const calculateMaxWidth = React.useCallback(() => {
    // Delay the max width calculation to ensure that the layout has been calculated
    timeoutRef.current = window.setTimeout(() => {
      const headerSections = ref?.current?.closest(
        '.top-header',
      ) as HTMLDivElement;
      const headerWidth = headerSections?.offsetWidth;
      const centerHeader = headerSections?.getElementsByClassName(
        'center-content',
      )[0] as HTMLDivElement;
      const rightHeader = headerSections?.getElementsByClassName(
        'right-content',
      )[0] as HTMLDivElement;
      const rightChildWidth = (rightHeader?.firstChild as HTMLDivElement)
        ?.offsetWidth;

      let totalSpaceToTheRight = 0;

      // If it's mobile there isn't center content
      if (isMobile) {
        totalSpaceToTheRight = centerHeader?.offsetWidth + rightChildWidth;
      } else {
        const centerChildWidth = (centerHeader?.firstChild as HTMLDivElement)
          ?.offsetWidth;
        totalSpaceToTheRight = (centerHeader?.offsetWidth - centerChildWidth) / 2
          + centerChildWidth
          + rightHeader?.offsetWidth;
      }

      setMaxWidth(
        `${headerWidth - (totalSpaceToTheRight + BADGE_MARGIN_RIGHT)}px`,
      );
    }, 150);
  }, []);

  // Showing/hiding badge label per design and setting styles per design
  React.useEffect(() => {
    setShouldShowBadgeLabel(
      !(isTabletAndMobile && (isCompactHeader || isLecturePage)),
    );
    calculateMaxWidth();

    if (isCompactHeader || isLecturePage) {
      let isWhiteBackground = null;
      const journeyInfo = (journeysData?.total === 1 && !visitedJourneyId) ? journeysData?.journeys[0] : visitedJourneyInfo;

      if (journeyInfo?.catalogId && journeyInfo?.headerColor && !journeyInfo?.headerBackground && !journeyInfo?.sameColors) {
        isWhiteBackground = compareHexColors(white, journeyInfo?.headerColor);

        setDynamicBadgeSyles({
          ...dynamicBadgeSyles,
          color: journeyInfo?.nameFontColor,
          backgroundColor: journeyInfo?.headerColor,
          border: !isWhiteBackground ? 'none' : DEFAULT_BADGE_STYLES.border,
        });
      } else if (!institutionColors.sameColors) {
        isWhiteBackground = compareHexColors(white, institutionColors.backgroundColor);

        setDynamicBadgeSyles({
          ...dynamicBadgeSyles,
          color: institutionColors.color,
          backgroundColor: institutionColors.backgroundColor,
          border: !isWhiteBackground ? 'none' : DEFAULT_BADGE_STYLES.border,
        });
      } else {
        setDynamicBadgeSyles(DEFAULT_BADGE_STYLES);
      }
    } else {
      setDynamicBadgeSyles(DEFAULT_BADGE_STYLES);
    }

    return () => clearInterval(timeoutRef.current);
  }, [calculateMaxWidth, isTabletAndMobile, isCompactHeader, isLecturePage]);

  React.useEffect(() => {
    window.addEventListener('resize', calculateMaxWidth);

    return () => window.removeEventListener('resize', calculateMaxWidth);
  }, [calculateMaxWidth]);

  React.useEffect(() => {
    if (journeysData?.total === 1 && !visitedJourneyId) {
      dispatch(updateVisitedJourney(journeysData?.journeys[0]));
    }
  }, []);

  const badgeStyles = css`
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: ${(isCompactHeader || isLecturePage) ? '160px' : maxWidth};
    padding: ${quarterSpacing}px ${halfSpacing + 2}px;
    margin-right: ${halfSpacing}px;
    font-family: ${openSansCondensed};
    font-size: ${textMediumFontSize}px;
    font-weight: ${boldFontWeight};
    background: ${dynamicBadgeSyles.backgroundColor};
    color: ${dynamicBadgeSyles.color};
    border: ${dynamicBadgeSyles.border};
    border-radius: ${standardSpacing}px;
    transition: all 0.3s cubic-bezier(0.61, 1, 0.88, 1);

    ${!isCompactHeader && !isLecturePage
      && `
        :hover {
          color: ${primary};
          border-color: ${primary};
        }
      `}

    ${!shouldShowBadgeLabel
      && `
        width: 40px;
        height: 40px;
        padding: ${halfSpacing + 2}px;
        margin-right: ${halfSpacing}px;
        border-radius: 50%;
      `}
  `;
  const journeysBoxStyles = css`
    .bs4-dropdown {
      .bs4-dropdown-menu {
        display: ${showDropdownMenu ? 'flex' : 'none'} !important;
        flex-direction: column;
        gap: ${largeSpacing}px;
        width: 300px;
        margin-top: ${quarterSpacing}px !important;
        padding: ${standardSpacing}px !important;
        border: none;
        background: linear-gradient(
            315.42deg,
            rgba(220, 253, 253, 0.3) 0%,
            rgba(247, 251, 232, 0.3) 99.28%
          ),
          ${white};
        box-shadow: 0px 10px 20px rgba(29, 33, 38, 0.2);
        border-radius: ${standardSpacing}px;
        cursor: default;

        .journey-info {
          align-self: center;
          font-family: ${openSans};
          font-weight: ${semiBoldFontWeight};
          color: ${gray2} !important;
          margin: 0 !important;
        }

        .jouneys-divider {
          border: 0.5px solid ${gray3};
        }

        > a.bs4-dropdown-item {
          align-self: center;
          width: fit-content;
          padding: ${quarterSpacing}px ${halfSpacing}px;
          font-family: ${openSans};
          font-weight: ${semiBoldFontWeight};
          font-size: ${textSmallFontSize}px;
          color: ${white};
          background: ${primary};
          border-radius: ${threeQuartersSpacing}px;
          border: none;
        }
      }
    }
  `;

  // Setting the journeys items and separate them with a divider if there are multiple items
  if (shouldDisplayBadge) {
    if (isUserEnrolledInVisitedJourney) {
      const visitedJourneyData = !visitedJourneyId ? journeysData.journeys?.filter(journey => journey?.catalogId === visitedJourneyId)[0] : visitedJourneyInfo;

      journeysItems.push({
        type: 'custom',
        customItem: (
          <JourneyCardLite
            name={visitedJourneyData?.name}
            releaseDate={visitedJourneyData?.releaseDate}
            closeDate={visitedJourneyData?.closeDate}
            completionStats={visitedJourneyData?.completionStats}
            journeyHomeLink={$state.href('learning-journey-home', {
              catalogId: visitedJourneyData?.catalogId,
            })}
          />
        ),
      });

      journeysItems.push({
        type: 'link',
        text: t.LEARNING_JOURNEYS.COURSE_HOME.JOURNEY_NAVIGATION.VIEW_JOURNEY_HOME(),
        link: $state.href('learning-journey-home', {
          catalogId: visitedJourneyData?.catalogId,
        }),
        dataQa: config.pendo.learningJourneys.viewJourneyHome,
      });
    } else {
      journeysData?.journeys?.slice(0, JOURNEYS_TO_SHOW).forEach((journey, index) => {
        journeysItems.push({
          type: 'custom',
          customItem: (
            <JourneyCardLite
              name={journey?.name}
              releaseDate={journey?.releaseDate}
              closeDate={journey?.closeDate}
              completionStats={journey?.completionStats}
              journeyHomeLink={$state.href('learning-journey-home', {
                catalogId: journey?.catalogId,
              })}
            />
          ),
        });

        if (index < Math.min(journeysData?.journeys?.length, JOURNEYS_TO_SHOW) - 1) {
          journeysItems.push({
            type: 'divider',
            class: 'jouneys-divider',
          });
        }

        if (journeysData?.total === 1) {
          journeysItems.push({
            type: 'link',
            text: t.LEARNING_JOURNEYS.COURSE_HOME.JOURNEY_NAVIGATION.VIEW_JOURNEY_HOME(),
            link: $state.href('learning-journey-home', {
              catalogId: journey.catalogId,
            }),
            dataQa: config.pendo.learningJourneys.viewJourneyHome,
          });
        }
      });
    }
  }

  // Setting the dropdown items based on UI design to be passed to the NvDropdown component if there is not track of the learning journey
  function getDropdownItems() {
    if (!visitedJourneyId && journeysData?.total > 1) {
      dropdownItems.push({
        type: 'header',
        title: t.LEARNING_JOURNEYS.COURSE_HOME.JOURNEY_NAVIGATION.MULTIPLE_JOURNEYS(),
        class: 'journey-info',
      });
    }

    dropdownItems = [...dropdownItems, ...journeysItems];

    if (!visitedJourneyId && journeysData?.total > JOURNEYS_TO_SHOW) {
      dropdownItems.push({
        type: 'header',
        title: `+${journeysData?.total
          - JOURNEYS_TO_SHOW} ${t.LEARNING_JOURNEYS.COURSE_HOME.JOURNEY_NAVIGATION.MORE_JOURNEYS(journeysData?.total - JOURNEYS_TO_SHOW)}`,
        class: 'journey-info',
      });
    }
  }

  getDropdownItems();

  if (!shouldDisplayBadge) {
    return null;
  }

  return (
    <div css={journeysBoxStyles}>
      <NvDropdown
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        items={dropdownItems}
        align={NvDropdownAlign.LEFT}
        onToggle={setShowDropdownMenu}
        customTarget={props => (
          <NvTooltip
            text={badgeLabel}
            enabled={!!visitedJourneyId || journeysData?.total === 1}
            placement='right'
          >
            <div
              css={badgeStyles}
              ref={ref}
              data-qa={config.pendo.learningJourneys.journeysBadge}
            >
              <NvIcon icon='path' size='smallest' />

              {shouldShowBadgeLabel && (
                <>
                  <span
                    className='ellipsis'
                    data-qa={config.pendo.learningJourneys.journeysBadgeLabel}
                  >
                    {badgeLabel}
                  </span>

                  {showDropdownArrow && (
                    <div className='icon text-xs icon-dropdown-arrow' />
                  )}
                </>
              )}
            </div>
          </NvTooltip>
        )}
      />
    </div>
  );
};

export default JourneyBadge;
