/* @ngInject */
export default function DiscussionsManager(
  TopicModel,
  PostModel,
  CommentModel,
  ReplyModel,
  _,
  $state,
  $uibModal,
  $q,
  PusherManager,
  $timeout,
  $filter,
  DiscussionsResources,
  CurrentCourseManager,
  MentionablesModel,
  CurrentUserManager,
  TimelinesManager,
  ReactTimelineService,
  PubSubDiscussions,
  CourseModel,
) {
  const manager = {
    // data
    allTopics: [],
    currentPosts: [],
    rhsTrendingTopics: [],
    rhsInitialized: false,
    currentReport: null,
    loading: false,
    topicsLoaded: false,
    pusherEventsInitialized: false,
    hasMorePostsToLoad: true,
    activeUsersInDiscussions: [],
    topicDropdownIsOpen: false,
    topicDropdownHovered: false,
    activeStudentsFetched: false,
    privateMentionListFetched: false,
    managerInitialized: false,
    loadingSearchResults: false,
    showSearchResults: false,
    previousCatalogId: null,
    previousExerciseId: null,
    previosOwnerId: null,
    displayQueryTerm: null,
    searchMode: false,
    newTopicDropdownOpen: false,
    preModalParams: {},
    prevParams: {},

    // functions
    getAllTopics,
    getSingleTopic,
    setTopicData,
    loadPosts,
    loadMorePosts,
    loadSinglePost,
    setPostData,
    setSinglePostData,
    createComment,
    editComment,
    setCommentData,
    removeCommentData,


    initialize,
    resetPreModalParams,
    initializeMentions,
    initializePusherEvents,
    unsubscribeFromPusherEvents,
    markTopicRead,
    resetData,
    showNewTopicModal,
    showNewPostModal,
    addNewPost,
    createTopic,
    addNewTopic,
    updateTopic,
    deleteTopic,
    lockTopic,
    unlockTopic,
    topicIsLocked,
    deletePost,
    createReply,
    setReplyData,
    deleteReply,
    removeReplyData,
    followSelectedTopic,
    unfollowSelectedTopic,
    getUsersActiveInDiscussions,
    setDirectLinkHighlight,
    loadPreviousComments,
    loadNextComments,
    loadPreviousReplies,
    loadNextReplies,
    getTrendingTopics,
    findTopic,
    topicOptionFilterMatch,
    mapTopicIdsToTopics,
    rhsCustomFilter,
    toggleSearchMode,

    // search functions
    selectTopicFilter,
    setCurrentTopicId,
    selectSortOrder,
    setSortOrder,
    setSortDefault,
    selectFilterOption,
    setFilterOption,
    clearSearchTerm,
    countFilters,
    resetSearchParams,
    onHandheldFilterClose,
    filtersAreDefault,
    goToState,

    // for ui scroll
    firstScrollIndex: 1,
    lastScrollIndex: 1,
    getUIScrollItems,

    // search data
    topicOptions: [],
    sortOrders: [
      {
        key: 'trending',
        translateKey: 'DISCUSSIONS.SORT_OPTIONS.TRENDING',
        value: 'trending',
        default: true,
      }, {
        key: 'recent',
        translateKey: 'DISCUSSIONS.SORT_OPTIONS.RECENT',
        value: 'created',
      }, {
        key: 'like',
        translateKey: 'DISCUSSIONS.SORT_OPTIONS.LIKE',
        value: 'likes',
      }, {
        key: 'comment',
        translateKey: 'DISCUSSIONS.SORT_OPTIONS.COMMENT',
        value: 'comments',
      },
    ],
    filterOptions: [
      {
        key: 'all',
        translateKey: 'DISCUSSIONS.FILTER_OPTIONS.ALL',
        value: 'all',
        ignoreInSearch: true,
        default: true,
      }, {
        key: 'post',
        translateKey: 'DISCUSSIONS.FILTER_OPTIONS.POST',
        value: 'my_posts',
      }, {
        key: 'subscribe',
        translateKey: 'DISCUSSIONS.FILTER_OPTIONS.SUBSCRIBE',
        value: 'my_follows',
      }, {
        key: 'comment',
        translateKey: 'DISCUSSIONS.FILTER_OPTIONS.COMMENT',
        value: 'my_comments',
      }, {
        key: 'like',
        translateKey: 'DISCUSSIONS.FILTER_OPTIONS.LIKE',
        value: 'my_likes',
      },
    ],
  };

  const allTopicsOption = {
    title: null,
    id: 0,
    position: -1,
    ignoreInSearch: true,
    default: true,
  };

  const SEARCH_PAGE_SIZE = 20;

  resetSearchParams();


  function initialize(params) {
    allTopicsOption.titleKey = 'DISCUSSIONS.FILTER_OPTIONS.ALL_TOPICS';

    manager.previousCatalogId = manager.catalogId;
    manager.previousExerciseId = manager.currentReport?.exerciseId;
    manager.previosOwnerId = manager.currentReport?.ownerId;

    // Storing the previous params when initializing discussion manager
    // from team workspace or report modal. So we can go back to this state when closing
    // team workspace flyout panel or
    if ((params.context === 'teamWorkspace' || params.context === 'report') && !manager?.preModalParams?.catalogId) {
      manager.preModalParams = manager.prevParams;
    }

    manager.prevParams = params;

    if (manager.previousCatalogId && manager.previousCatalogId !== params.catalogId && manager.managerInitialized) {
      manager.resetData();
    }

    _.extend(manager, params);

    manager.allowMentions = manager.allowMentions !== false;

    manager.managerInitialized = true;
  }

  function resetPreModalParams() {
    manager.preModalParams = {};
  }

  async function initializeMentions() {
    if (manager.allowMentions) {
      if (manager.context === 'report' && (manager.currentReport?.privacySetting === 'shared_with_instructor' || manager.currentReport?.privacySetting === 'shared_with_instructor_and_team')) {
        // Shared with instructor submissions are not shared with team and not available in team workspace
        if (!manager.previousExerciseId || manager.previousExerciseId !== manager.currentReport.exerciseId || manager.previosOwnerId !== manager.currentReport?.ownerId || !manager.privateMentionListFetched) {
          let privateMentionList = [];
          if (manager.currentReport.ownerType === 'Team') {
            privateMentionList = manager.currentReport.exercise?.collaborators ?? [];
          } else {
            privateMentionList = [manager.currentReport.owner];
          }
          if (manager.currentReport.privacySetting === 'shared_with_instructor_and_team') {
            await CourseModel.getActiveStudentsForCourse(manager.catalogId).then((studentList) => {
              manager.currentReport.mentionableUserIds.forEach((id) => studentList[id] && privateMentionList.push(studentList[id]));
            });
          }
          if (CurrentCourseManager.course && manager.catalogId === CurrentCourseManager.course.catalogId) {
            // If currentCourseManager course and course from the top level ( l3.5 and l4 ) course is same.
            // Teaching team list is cached for the course
            CurrentCourseManager.course.getTeachingTeamMembers().then((teachingTeamMemberList) => {
              teachingTeamMemberList?.forEach((member) => privateMentionList.push(member.user));
              MentionablesModel.setMentionableUsers(_.uniq(privateMentionList, 'id').filter((user) => user.id !== CurrentUserManager.user.id), 'discussion');
              manager.privateMentionListFetched = true;
              manager.activeStudentsFetched = false;
            });
          } else {
            CourseModel.getTeachingTeamMembersForCourse(manager.catalogId).then((teachingTeamMemberList) => {
              teachingTeamMemberList?.forEach((member) => privateMentionList.push(member.user));
              MentionablesModel.setMentionableUsers(_.uniq(privateMentionList, 'id').filter((user) => user.id !== CurrentUserManager.user.id), 'discussion');
              manager.privateMentionListFetched = true;
              manager.activeStudentsFetched = false;
            });
          }
        }
      } else if (!manager.previousCatalogId
        || manager.previousCatalogId !== manager.catalogId
        || !manager.activeStudentsFetched
        || MentionablesModel.getLastUpdatedSource() !== 'discussion') {
        CourseModel.getActiveStudentsForCourse(manager.catalogId).then((studentList) => {
          // Omitting current user to prevent self mentioning
          MentionablesModel.setMentionableUsers(_.toArray(_.omit(studentList, [CurrentUserManager.user.id])), 'discussion');
          manager.activeStudentsFetched = true;
          manager.privateMentionListFetched = false;
        });
      }
    }
    return $q.when();
  }

  function initializePusherEvents(courseId, reportId) {
    const pusherEventNameMap = {
      LOCKED_TOPIC: 'forum_flip_lock',

      NEW_POST: 'new_topic',
      DELETE_POST: 'delete_topic',
      UPDATE_POST: 'update_topic',
      LIKE_POST: 'topic_vote',


      NEW_COMMENT: 'new_post',
      DELETE_COMMENT: 'delete_post',
      UPDATE_COMMENT: 'update_post',
      LIKE_COMMENT: 'post_vote',

      NEW_REPLY: 'new_comment',
      DELETE_REPLY: 'delete_comment',
      UPDATE_REPLY: 'update_comment',
      LIKE_REPLY: 'comment_vote',

      LIKE_SUBMISSION: 'submission_vote',


    };

    let discussionChannel;

    if (courseId) {
      discussionChannel = PusherManager.initializeDiscussionsChannelForCourse(courseId);
    } else if (reportId) {
      discussionChannel = PusherManager.initializeDiscussionsChannelForReport(reportId);
    }
    manager.pusherEventsInitialized = true;

    discussionChannel.bind(pusherEventNameMap.LOCKED_TOPIC, (data) => {
      data = _backendTermsToFrontendTerms(data);

      findTopic(data.topicId).locked = data.locked;

      if (data.topicId === manager.searchParams.selectedTopic.id) {
        manager.searchParams.selectedTopic.locked = data.locked;
      }
    });

    /* future proofing: topic events */
    /*
        new topic: bind('new_forum', addNewTopic);
        delete topic: bind('delete_forum', function(data) {


          manager.allTopics.splice(_.findIndex(manager.allTopics, { id: data.id }), 1);
          manager.topicOptions.splice(_.findIndex(manager.topicOptions, { id: data.id }), 1);

          if (manager.selectedTopic.id === data.id) {
            $state.go('discussions-index');
          }
        }

        update topic: bind('update_forum', function(data) {

          //if the update is happening on the current topic, we need to swap the whole thing out
          if (manager.searchParams.selectedTopic.id === data.id) {
            manager.allTopics.splice()
          }
        })
      */

    /* post events */
    discussionChannel.bind(pusherEventNameMap.NEW_POST, (data) => {
      data = _backendTermsToFrontendTerms(data);
      const topic = findTopic(data.topicId);
      let post = _.findWhere(manager.currentPosts, { id: data.postId });

      // if the post is in the currently viewed topic and the backend hasn't already added it
      // AND if not in direct link mode
      if (
        (manager.searchParams.selectedTopic.id === data.topicId || manager.searchParams.selectedTopic.id === 0)
          && manager.context !== 'directLink'
          && !post) {
        loadSinglePost(data.postId).then((postData) => {
          post = _.extend(postData, { owner: topic }); // ewwww

          // increment counts of new/total posts
          if (topic) {
            addNewPost(post, false);
          }
        });
      } else if (topic) {
        const sanityCheckPromise = post ? sanityCheckUnique(topic, post) : $q.when();
        post = data;

        sanityCheckPromise.then(() => {
          setTopicCountsFromBackend();
        });
      }
    });

    discussionChannel.bind(pusherEventNameMap.DELETE_POST, (data) => {
      data = _backendTermsToFrontendTerms(data);
      let topic;
      const post = _.findWhere(manager.currentPosts, { id: data.postId });

      if (post) {
        removePostData(data.postId);
      } else {
        topic = findTopic(data.topicId);

        if (topic) {
          setTopicCountsFromBackend();
        }
      }
    });

    discussionChannel.bind(pusherEventNameMap.UPDATE_POST, (data) => {
      data = _backendTermsToFrontendTerms(data);

      const existingPost = _.findWhere(manager.currentPosts, { id: data.postId });
      if (existingPost) {
        // if updating post has moved it to a different topic, just remove the data
        if (manager.searchParams.selectedTopic.id > 0 && data.topicId !== manager.searchParams.selectedTopic.id) {
          removePostData(existingPost.id);
        } else {
          loadSinglePost(data.postId).then((post) => {
            manager.setSinglePostData(
              post,
              true,
              manager.context !== 'workspace' && manager.context !== 'report' && manager.context !== 'workspace-direct-link' ? existingPost.topicId : existingPost.owner.id,
            );
          });
        }
      } else {
        $.noop();

        // TODO: need another backend property from the pusher event to know what the old topic id was so we can increment/decrement properly
      }
    });

    discussionChannel.bind(pusherEventNameMap.LIKE_POST, (data) => {
      data = _backendTermsToFrontendTerms(data);

      const post = _.findWhere(manager.currentPosts, { id: data.postId });

      if (post) {
        post.votesCount = data.votesCount;
      }
    });

    /* comment events */
    discussionChannel.bind(pusherEventNameMap.NEW_COMMENT, (data) => {
      data = _backendTermsToFrontendTerms(data);
      let owner = null;
      const { userId } = data;


      if (manager.context !== 'report' && data.postId) {
        owner = _.findWhere(manager.currentPosts, { id: data.postId });
      } else if (data.reportId) {
        // report
        owner = manager.currentReport;
      }

      // Check whether there is an owner here. If the owner is not in this
      // context then no need to fetch the comment.
      if (!owner) {
        return;
      }

      if (manager.context === 'directLink') {
        if (owner.additionalCommentsAfterCount > 0) {
          // assume it's in the later portion, don't display
          owner.additionalCommentsAfterCount += 1;
        } else if (userId !== CurrentUserManager.user.id) {
          fetchComment(data.commentId, owner);
        }
      } else if (!_.findWhere(owner.comments, { id: data.commentId })
          && userId !== CurrentUserManager.user.id) {
        fetchComment(data.commentId, owner);
      }

      function fetchComment(commentId, commentOwner) {
        return CommentModel.getSingleComment({
          catalogId: manager.catalogId,
          id: commentId,
        }).then((response) => {
          setCommentData(response.result, commentOwner, false, true);
        });
      }
    });

    discussionChannel.bind(pusherEventNameMap.DELETE_COMMENT, (data) => {
      data = _backendTermsToFrontendTerms(data);

      let owner;
      let comment;
      const { userId } = data;

      if (manager.context !== 'report' && data.postId) {
        owner = _.findWhere(manager.currentPosts, { id: data.postId });
      } else if (data.reportId) {
        // report
        owner = manager.currentReport;
      }

      if (owner) {
        comment = _.findWhere(owner.comments, { id: data.commentId });
      }

      // if the comment is in the list
      if (comment) {
        if (manager.context !== 'report' && data.postId) {
          if (userId !== CurrentUserManager.user.id) {
            manager.removeCommentData(comment, owner);
          }
        } else if (data.reportId) {
          owner.removeCommentData(comment);
        }
      } else if (owner && !owner.userHasRemovedComment(data.commentId)) {
        // the comment is in the 'load more before' or 'load more after'
        owner.numCommentsAndReplies -= 1;
      }
    });

    discussionChannel.bind(pusherEventNameMap.UPDATE_COMMENT, (data) => {
      data = _backendTermsToFrontendTerms(data);
      let owner;
      let comment;

      if (manager.context !== 'report' && data.postId) {
        owner = _.findWhere(manager.currentPosts, { id: data.postId });

        if (owner) {
          comment = _.findWhere(owner.comments, { id: data.commentId });
        }
      } else if (data.reportId) {
        // report
        comment = _.findWhere(manager.currentReport.comments, { id: data.commentId });
        owner = manager.currentReport;
      }

      if (owner && comment) { // &&
        //! _.findWhere(manager.pendingComments, { id: data.commentId })) {
        CommentModel.getSingleComment({
          catalogId: manager.catalogId,
          id: data.commentId,
        }).then((response) => {
          if (response.result.body !== comment.body) {
            $timeout(() => {
              _.extend(comment, response.result);
            });
          }
        });
      }
    });

    discussionChannel.bind(pusherEventNameMap.LIKE_COMMENT, (data) => {
      data = _backendTermsToFrontendTerms(data);
      let post;
      let comment;

      if (manager.context !== 'report') {
        post = _.findWhere(manager.currentPosts, { id: data.postId });

        if (post) {
          comment = _.findWhere(post.comments, { id: data.commentId });
        }
      } else {
        // report
        comment = _.findWhere(manager.currentReport.comments, { id: data.commentId });
      }

      if (comment) {
        comment.votesCount = data.votesCount;
      }
    });


    /* replies */
    discussionChannel.bind(pusherEventNameMap.NEW_REPLY, (data) => {
      data = _backendTermsToFrontendTerms(data);
      let post;
      let comment;
      const { userId } = data;

      if (manager.context !== 'report' && data.postId) {
        post = _.findWhere(manager.currentPosts, { id: data.postId });

        if (post) {
          comment = _.findWhere(post.comments, { id: data.commentId });
        }
      } else if (data.reportId) {
        // report
        comment = _.findWhere(manager.currentReport.comments, { id: data.commentId });
      }


      if (comment
          && !_.findWhere(comment.replies, { id: data.replyId })
          && userId !== CurrentUserManager.user.id) { // &&
        //! _.findWhere(manager.pendingReplies, { id: data.replyId })) {
        ReplyModel.getSingleReply({
          catalogId: manager.catalogId,
          id: data.replyId,
        }).then((response) => {
          setReplyData(response.result, comment, false, true);
        });
      } else if (!comment && post?.commentsFetched && userId !== CurrentUserManager.user.id) {
        CommentModel.getSingleComment({
          catalogId: manager.catalogId,
          id: data.commentId,
        }).then((response) => {
          comment = setCommentData(response.result, post, false, true);

          if (comment.repliesFetched) {
            ReplyModel.getSingleReply({
              catalogId: manager.catalogId,
              id: data.replyId,
            }).then((replyResponse) => {
              setReplyData(replyResponse.result, comment, false, true);
            });
          } else {
            if (post) {
              post.numCommentsAndReplies += 1;
            } else if (manager.context === 'report' && manager.currentReport) {
              manager.currentReport.numCommentsAndReplies += 1;
            }

            comment.replyCount += 1;
          }
        });
      } else if (userId !== CurrentUserManager.user.id) {
        // just increment the count
        if (post) {
          post.numCommentsAndReplies += 1;
        }
      }
    });

    discussionChannel.bind(pusherEventNameMap.DELETE_REPLY, (data) => {
      data = _backendTermsToFrontendTerms(data);
      let post;
      let comment;

      if (manager.context !== 'report' && data.postId) {
        post = _.findWhere(manager.currentPosts, { id: data.postId });

        if (post) {
          comment = _.findWhere(post.comments, { id: data.commentId });
        }
      } else if (data.reportId) {
        // report
        comment = _.findWhere(manager.currentReport.comments, { id: data.commentId });
      }

      if (comment) {
        manager.removeReplyData(data.replyId, comment);
      }
    });

    discussionChannel.bind(pusherEventNameMap.UPDATE_REPLY, (data) => {
      data = _backendTermsToFrontendTerms(data);
      let post;
      let comment;
      let reply;

      if (manager.context !== 'report' && data.postId) {
        post = _.findWhere(manager.currentPosts, { id: data.postId });

        if (post) {
          comment = _.findWhere(post.comments, { id: data.commentId });
        }
      } else if (data.reportId) {
        // report
        comment = _.findWhere(manager.currentReport.comments, { id: data.commentId });
      }

      if (comment) {
        reply = _.findWhere(comment.replies, { id: data.replyId });
      }

      if (comment && reply) {
        ReplyModel.getSingleReply({
          catalogId: manager.catalogId,
          id: data.replyId,
        }).then((response) => {
          $timeout(() => {
            _.extend(reply, response.result);
          });
        });
      }
    });

    discussionChannel.bind(pusherEventNameMap.LIKE_REPLY, (data) => {
      data = _backendTermsToFrontendTerms(data);

      let post;
      let comment;
      let reply;

      if (manager.context !== 'report') {
        post = _.findWhere(manager.currentPosts, { id: data.postId });

        if (post) {
          comment = _.findWhere(post.comments, { id: data.commentId });
        }
      } else {
        // report
        comment = _.findWhere(manager.currentReport.comments, { id: data.commentId });
      }

      if (comment) {
        reply = _.findWhere(comment.replies, { id: data.replyId });
      }

      if (reply) {
        reply.votesCount = data.votesCount;
      }
    });


    discussionChannel.bind(pusherEventNameMap.LIKE_SUBMISSION, (data) => {
      data = _backendTermsToFrontendTerms(data);

      if (manager.currentReport.id === data.reportId) {
        manager.currentReport.votesCount = data.votesCount;
      }
    });

    // helper function to change backend discussions terms to frontend discussions terms
    function _backendTermsToFrontendTerms(rawObject) {
      /* eslint-disable camelcase */
      const mapping = {
        forum_id: 'topicId',
        topic_id: 'postId',
        post_id: 'commentId',
        comment_id: 'replyId',
        num_likes: 'votesCount',
        report_id: 'reportId',
        user_id: 'userId',
      };

      const toOverride = _.pick(rawObject, _.keys(mapping));
      const mappedProperties = {};

      // swap the old key for the correct frontend key
      _.each(toOverride, (value, key) => {
        mappedProperties[mapping[key]] = rawObject[key];
      });

      // merge the newly created correct object with the raw object, removing the old entries
      return _.extend(_.omit(rawObject, _.keys(mapping)), mappedProperties);
    }
  }

  function unsubscribeFromPusherEvents(courseId, reportId) {
    if (courseId) {
      PusherManager.removeDiscussionsChannelForCourse(courseId);
    } else if (reportId) {
      PusherManager.removeDiscussionsChannelForReport(reportId);
    }
    manager.pusherEventsInitialized = false;
  }

  function markTopicRead(topic) {
    topic.numNewTopics = 0; // backend terms don't match frontend terms!
    topic.numNewReleasedTopics = 0;
  }

  function resetData() {
    manager.currentPosts = [];
    manager.firstScrollIndex = 1;
    manager.lastScrollIndex = 1;
    manager.activeUsersInDiscussions = [];
    manager.rhsInitialized = false;
    manager.hasMorePostsToLoad = true;
    manager.activeStudentsFetched = false;
    manager.privateMentionListFetched = false;
    manager.rhsTrendingTopics = [];
    manager.displayQueryTerm = '';
  }

  function getAllTopics(topicToSet, searchParams) {
    return TopicModel.getAllTopicsForCourse(_.extend({ catalogId: manager.catalogId }, searchParams)).then((topics) => {
      setTopicData(topics, topicToSet);

      return topics;
    });
  }

  function getSingleTopic(topicId) {
    const existingTopic = findTopic(topicId);

    if (existingTopic) {
      manager.topicsLoaded = true;
      return $q.when(existingTopic);
    }

    return TopicModel.getTopic(manager.catalogId, topicId);
  }

  function setTopicData(topics, topicToSet) {
    manager.allTopics = _.map(topics, (topic) => _.extend(topic, { catalogId: manager.catalogId }));

    manager.allTopics.unshift(allTopicsOption);

    // ewwwww
    if (topicToSet) {
      setCurrentTopicId(topicToSet);
      manager.topicOptions = [manager.searchParams.selectedTopic.id];
    } else {
      manager.topicOptions = _.pluck($filter('orderBy')(topics, 'position'), 'id');
    }

    manager.topicsLoaded = true;

    return topics;
  }

  function loadPosts() {
    // todo check if already loading what was last loaded etc

    manager.searchParams.page = 1;
    manager.loading = true;
    manager.showSearchResults = false;
    manager.hasMorePostsToLoad = true;


    manager.searchParams.queryTerm = manager.displayQueryTerm;
    manager.loadingSearchResults = manager.searchParams.queryTerm?.length;


    return PostModel.getPosts(manager.catalogId, manager.searchParams).then(setPostData);
  }

  function loadMorePosts() {
    if (!manager.loadingMore && !manager.loading) {
      manager.searchParams.page += 1;
      manager.loadingMore = true;


      return PostModel.getPosts(manager.catalogId, manager.searchParams).then(appendPostData);
    }
    return $q.when();
  }

  // load single post for direct link
  function loadSinglePost(postId) {
    return PostModel.getSinglePost(manager.catalogId, manager.searchParams.selectedTopic.id, postId);
  }

  // setting post data on new search
  function setPostData(posts) {
    if (posts.length >= SEARCH_PAGE_SIZE && manager.context !== 'directLink') {
      manager.hasMorePostsToLoad = true;
    } else {
      manager.hasMorePostsToLoad = false;
    }
    /**
     * When we take a direct link, it is possible to have this controller initialized twice
     * when the first page dataClasses.scrollable. This is because switching that dataClass
     * causes a switch in the main ui-view (in main-content.jade), and so the controller is
     * rendered once for each. Setting the current posts again would mess with the UX.
     * See NOV-74045 for more information.
     */
    if (manager.context !== 'directLink' || manager.currentPosts[0]?.id !== posts[0].id) {
      manager.currentPosts = _.uniq(posts, (post) => post.id);
    }

    manager.firstScrollIndex = 1;
    manager.lastScrollIndex = manager.currentPosts.length;
    _.each(manager.currentPosts, (post, index) => {
      post.scrollIndex = index + 1;
    });
    if (manager.uiScrollAdapter?.reload) {
      manager.uiScrollAdapter.reload();
    }

    if (manager.searchParams.queryTerm) {
      manager.loadingSearchResults = false;
      manager.showSearchResults = true;
    }
    manager.loading = false;
    manager.loadingMore = false;
  }

  // update existing post
  function setSinglePostData(post, isUpdating, oldTopicId) {
    if (isUpdating) {
      const updatedPost = new PostModel(_.extend(post, { catalogId: manager.catalogId }));

      // this unfortunate use of $timeout is because we need to modify the properties in-place (don't want a transition as we delete and then add a new object),
      // and modifying the properties from Pusher doesn't otherwise trigger a digest cycle
      $timeout(() => {
        const indexOfOldPost = _.findIndex(manager.currentPosts, { id: post.id });
        const oldPost = manager.currentPosts[indexOfOldPost];
        _.extend(oldPost, updatedPost, { post: true, updateAvatar: oldPost.user.id !== updatedPost.user.id, comments: oldPost.comments });

        if (manager.uiScrollAdapter) {
          manager.uiScrollAdapter.applyUpdates(oldPost.scrollIndex, [updatedPost]);
        }
      }, 0);


      // if the old topic is the one currently being viewed, need to remove the stale post data

      if (oldTopicId) {
        const newOwner = post.owner;
        const oldOwner = findTopic(oldTopicId);

        if (oldTopicId === manager.searchParams.selectedTopic.id && newOwner.id !== oldOwner.id) {
          removePostData(post.id);
        }
      }


      // update topic counts if switching which topic the post is in
      // if (oldTopicId) {


      //   //if the old topic is the one currently being viewed, need to remove the stale post data
      //   if (oldTopicId === manager.searchParams.selectedTopic.id && newOwner.id !== oldOwner.id) {
      //     removePostData(post.id);
      //   }

      //   if (newOwner && oldOwner && newOwner.id !== oldOwner.id) {

      //     decrementTopicCounts(post, oldOwner);
      //     incrementTopicCounts(post, newOwner);

      //   }
      // }
    } else {
      addNewPost(post, true);
    }
  }

  function appendPostData(posts) {
    if (!posts.length || posts.length < SEARCH_PAGE_SIZE) {
      manager.hasMorePostsToLoad = false;
    }

    _.each(posts, (post, index) => {
      if (!_.findWhere(manager.currentPosts, { id: post.id })) {
        manager.lastScrollIndex -= 1;
        post.scrollIndex = manager.lastScrollIndex;
        manager.currentPosts.push(post);
      }
    });

    if (manager.uiScrollAdapter) {
      if (manager.uiScrollAdapter.isEOF()) {
        manager.uiScrollAdapter.append(posts);
      }
    }
    manager.loadingMore = false;
    manager.loading = false;
  }

  function createComment(commentData, post, insertAtFront, includeVideoTimestamp, second, mentionedIds) {
    // manager.pendingComments.push(commentData);
    return CommentModel.create({
      catalogId: manager.catalogId,
      postId: post.id,
      content: commentData,
      includeVideoTimestamp,
      videoTimestamp: includeVideoTimestamp ? second : null,
      mentionedIds,
    }).then((response) => {
      // remove comment from pending
      // _spliceItem(manager.pendingComments, commentData);

      // add to frontend data list

      setCommentData(response.result, post, insertAtFront, true);
    });
  }

  function setCommentData(comment, post, insertAtFront, isNewComment) {
    const newComment = new CommentModel(_.extend(comment, { catalogId: manager.catalogId, owner: post, replyCount: comment.commentsCount }));
    const newPost = new PostModel(_.extend(post, { catalogId: manager.catalogId, newPost: true }));

    sanityCheckUnique(newPost.comments, comment).then(() => {
      if (insertAtFront) {
        newPost.comments.unshift(newComment);
      } else {
        newPost.comments.push(newComment);
      }

      if (isNewComment) {
        newPost.numCommentsAndReplies += 1;

        // if comment was made by current user, set post.commentedByUser to true
        if (newComment.user.id === CurrentUserManager.user.id) {
          newPost.commentedByUser = true;
        }

        if (!newPost.pointsReceived && comment.pointsReceived && newComment.user.id === CurrentUserManager.user.id) {
          newPost.pointsReceived = comment.pointsReceived;
          newPost.leaderboardPoints = comment.leaderboardPoints;
          newPost.leaderboardRank = comment.leaderboardRank;
          newPost.priorLeaderboardRank = comment.priorLeaderboardRank;
        }

        const member = newPost?.findMemberByUserId(comment.user.id);
        if (member) {
          member.totalContributions += 1;
        }
      }
    });

    return newComment;
  }

  function removeCommentData(comment, post) {
    const indexOfComment = _.findIndex(post.comments, { id: comment.id });
    if (comment && indexOfComment > -1) {
      _spliceItem(post.comments, comment.id);
    }

    post.numCommentsAndReplies -= 1;
  }

  function editComment(comment, ownerId) {
    if (manager.context !== 'report') {
      _.findWhere(manager.currentPosts, { id: ownerId }).updateComment(comment);
    }
  }

  function loadPreviousComments(post) {
    return CommentModel.getCommentsList({
      catalogId: manager.catalogId,
      postId: post.id,
      owner: post,
      order: post.sortedByLikes ? 'likes' : null,
      beforeId: _.first(post.comments).id,
      pageSize: post.loadMorePageSize,
    }).then((response) => {
      _.chain(response.posts)
        .reverse()
        .each((newComment) => {
          setCommentData(newComment, post, true);
        });

      post.additionalCommentsBeforeCount = response.additionalPostsBeforeCount;
      post.additionalNewCommentsBeforeCount = response.additionalNewPostsBeforeCount;
    });
  }

  function loadNextComments(post) {
    return CommentModel.getCommentsList({
      catalogId: manager.catalogId,
      postId: post.id,
      owner: post,
      order: post.sortedByLikes ? 'likes' : null,
      afterId: _.last(post.comments).id,
      pageSize: post.loadMorePageSize,
    }).then((response) => {
      _.each(response.posts, (newComment) => {
        setCommentData(newComment, post, false, false);
      });

      post.additionalCommentsAfterCount = response.additionalPostsAfterCount;
    });
  }

  function loadPreviousReplies(comment) {
    return comment.fetchReplies({
      catalogId: manager.catalogId,
      commentId: comment.id,
      beforeId: _.first(comment.replies).id,
    }).then((result) => {
      _.chain(result.comments)
        .reverse()
        .each((newReply) => {
          setReplyData(newReply, comment, true, false);
        });

      comment.additionalRepliesBeforeCount = result.additionalCommentsBeforeCount;
      comment.additionalNewRepliesBeforeCount = result.additionalNewCommentsBeforeCount;
    });
  }

  function loadNextReplies(comment) {
    return comment.fetchReplies({
      catalogId: manager.catalogId,
      commentId: comment.id,
      afterId: _.last(comment.replies).id,
    }).then((result) => {
      _.each(result.comments, (newReply) => {
        setReplyData(newReply, comment, false, true);
      });

      comment.additionalRepliesAfterCount = result.additionalCommentsAfterCount;
    });
  }

  function setDirectLinkHighlight(directLinkParams) {
    manager.directLink = directLinkParams;
  }

  /* Search Functions */
  function goToState(params, options) {
    if (!CurrentCourseManager.course.isProgram) {
      $state.go('discussions-index', {
        topicId: params?.topic ? params.topic.id : manager.searchParams.selectedTopic.id,
        sortOrder: params?.sortOrder ? params.sortOrder.key : manager.searchParams.sortOrder.key,
        filterOption: params?.filterOption ? params.filterOption.key : manager.searchParams.filterOption.key,
      }, options || {});
    } else {
      $state.go('program-home', {
        topicId: params?.topic ? params.topic.id : manager.searchParams.selectedTopic.id,
        sortOrder: params?.sortOrder ? params.sortOrder.key : manager.searchParams.sortOrder.key,
        filterOption: params?.filterOption ? params.filterOption.key : manager.searchParams.filterOption.key,
      }, options || {});
    }
  }

  function selectTopicFilter(topic, shouldGoToState) {
    if (shouldGoToState !== false) {
      goToState({ topic });
    }
  }

  function setCurrentTopicId(topicId) {
    let selectedTopic = findTopic(topicId);
    if (!selectedTopic) {
      selectedTopic = allTopicsOption;
    }

    manager.searchParams.selectedTopic = selectedTopic;

    return selectedTopic;
  }

  function selectSortOrder(sortOrder, shouldGoToState) {
    manager.keepSearchParams = true;

    if (shouldGoToState !== false) {
      goToState({ sortOrder });
    }
  }

  function setSortOrder(sortOrderString) {
    let selectedSortOrder = _.findWhere(manager.sortOrders, { key: sortOrderString });
    if (!selectedSortOrder) {
      selectedSortOrder = manager.sortOrders[0];
    }

    manager.searchParams.sortOrder = selectedSortOrder;
  }

  function setSortDefault(sortOrderString) {
    const sortOrderToDefault = _.findWhere(manager.sortOrders, { key: sortOrderString });

    if (sortOrderToDefault) {
      const oldDefault = _.findWhere(manager.sortOrders, { default: true });
      oldDefault.default = false;
      sortOrderToDefault.default = true;
    }
  }

  function selectFilterOption(filterOption, shouldGoToState) {
    manager.keepSearchParams = true;

    if (shouldGoToState !== false) {
      goToState({ filterOption });
    }
  }

  function setFilterOption(filterOptionString) {
    let selectedFilterOption = _.findWhere(manager.filterOptions, { key: filterOptionString });
    if (!selectedFilterOption) {
      selectedFilterOption = manager.filterOptions[0];
    }

    manager.searchParams.filterOption = selectedFilterOption;
  }

  function clearSearchTerm(doFreshSearch) {
    manager.keepSearchParams = false;
    manager.resetSearchParams();
    manager.displayQueryTerm = null;
    manager.searchMode = false;

    if (doFreshSearch !== false) {
      manager.posts = [];
      loadPosts();
    }
  }

  function countFilters() {
    return (manager.searchParams.selectedTopic.id === 0 ? 0 : 1)
        + (manager.searchParams.filterOption.default ? 0 : 1);
  }

  function resetSearchParams() {
    if (manager.keepSearchParams) {
      manager.searchParams.page = 1;
      manager.keepSearchParams = false;
    } else {
      manager.searchParams = {
        sortOrder: _.findWhere(manager.sortOrders, { default: true }),
        filterOption: _.findWhere(manager.filterOptions, { default: true }),
        selectedTopic: allTopicsOption,
        queryTerm: null,
        page: 1,
      };
    }
  }

  function onHandheldFilterClose(isOpen) {
    if (!isOpen) {
      loadPosts();
    }
  }

  function filtersAreDefault() {
    return (manager.countFilters() === 0)
      && manager.searchParams.sortOrder.default
      && !manager.searchParams.queryTerm;
  }


  /* Topic Functions */
  function showNewTopicModal(existingTopic) {
    const newTopicModal = $uibModal.open({
      templateUrl: 'discussions/templates/new-topic-modal.html',
      windowClass: 'discussions-modal',
      controller: 'NewTopicModalCtrl as vm',
      resolve: {
        selectedTopic() {
          return _.clone(existingTopic);
        },
      },
    });

    newTopicModal.closed.then(() => {
      manager.newTopicDropdownOpen = false;
    });
  }

  function showNewPostModal(post) {
    const newPostModal = $uibModal.open({
      templateUrl: 'discussions/templates/new-post-modal.html',
      windowClass: 'discussions-modal',
      controller: 'NewPostModalCtrl as vm',
      resolve: {
        selectedTopic() {
          return manager.searchParams.selectedTopic;
        },
        post() {
          return _.clone(post);
        },
      },
    });

    newPostModal.closed.then(() => {
      manager.newTopicDropdownOpen = false;
    });
  }

  // adds new post to the list in the manager only (no backend call)
  function addNewPost(postData, byUser) {
    sanityCheckUnique(manager.currentPosts, postData).then(() => {
      const topic = findTopic(postData.owner.id);

      setTopicCountsFromBackend();

      if ((!byUser
          || (!manager.searchParams.selectedTopic.id || manager.searchParams.selectedTopic.id === postData.owner.id))
          && manager.context !== 'directLink') {
        const newPost = new PostModel(_.extend(postData, { catalogId: manager.catalogId, newPost: true }));
        manager.firstScrollIndex -= 1;
        newPost.scrollIndex = manager.firstScrollIndex;
        manager.currentPosts.unshift(newPost);

        if (manager.uiScrollAdapter?.isBOF()) {
          manager.uiScrollAdapter.prepend([newPost]);
        }
      } else if (byUser) {
        if (manager.searchParams.selectedTopic.id !== topic.id || manager.searchParams.sortOrder.key !== 'recent') {
          goToState({
            topic,
            sortOrder: _.findWhere(manager.sortOrders, { key: 'recent' }),
          }, {
            reload: manager.searchParams.sortOrder === _.findWhere(manager.sortOrders, { key: 'recent' }),
          });
        }
      }
    });
  }

  // creates a new topic on the backend, then adds to topics list in manager
  function createTopic(newTopic) {
    return TopicModel.create(_.extend(newTopic, { catalogId: manager.catalogId, new: true })).then(
      (response) => {
        addNewTopic(response.result, true, true);
      },
      (error) => $q.reject(error),
    );
  }

  // adds new topic to list in manager only
  function addNewTopic(newTopicData, shouldRedirect) {
    const createdTopic = new TopicModel(_.extend(newTopicData, { catalogId: manager.catalogId }));

    if (createdTopic.released || CurrentUserManager.isAdmin()) {
      sanityCheckUnique(manager.allTopics, createdTopic).then(() => {
        manager.allTopics.splice(createdTopic.afterPosition, 0, createdTopic);

        manager.rhsTrendingTopics.unshift(createdTopic.id);
      });
    }

    if (shouldRedirect) {
      if (CurrentCourseManager.course.isProgram) {
        $state.go('program-home', { topicId: createdTopic.id, newTopicId: createdTopic.id, forceLoad: true });
      } else {
        $state.go('discussions-index', { topicId: createdTopic.id, newTopicId: createdTopic.id });
      }
    }

    CurrentCourseManager.course.numForumsReleased += 1;
  }

  function updateTopic(newTopic) {
    return TopicModel.update(_.extend(newTopic, { catalogId: manager.catalogId })).then((response) => {
      const updatedTopic = new TopicModel(_.extend(response.result, { catalogId: manager.catalogId }));
      const indexOfExisting = _.findIndex(manager.allTopics, { id: updatedTopic.id });
      const indexOfExistingRHS = _.findIndex(manager.rhsTrendingTopics, { id: updatedTopic.id });

      manager.allTopics.splice(indexOfExisting, 1);
      manager.allTopics.splice(!!updatedTopic.position || 0, 0, updatedTopic);

      // RHS doesn't care about description or position
      _.extend(_.findWhere(manager.rhsTrendingTopics, { id: updatedTopic.id }), {
        title: updatedTopic.title,
        releaseDate: updatedTopic.releaseDate,
        released: updatedTopic.released,
      });

      manager.searchParams.selectedTopic = updatedTopic;
    });
  }

  function deleteTopic(topic) {
    return (topic || manager.searchParams.selectedTopic).remove().then(() => {
      _spliceItem(manager.allTopics, topic.id);
      _spliceItem(manager.rhsTrendingTopics, topic.id);
      manager.topicOptions = _.without(manager.topicOptions, topic.id);


      if (topic.id === manager.searchParams.selectedTopic.id) {
        if (CurrentCourseManager.course.isProgram) {
          $state.go('program-home', { topicId: 0 });
        } else {
          $state.go('discussions-index', { topicId: 0 });
        }
      }
    });
  }

  function lockTopic(topic) {
    (topic || manager.searchParams.selectedTopic).lock();
  }

  function unlockTopic(topic) {
    (topic || manager.searchParams.selectedTopic).unlock();
  }

  function topicIsLocked(topic) {
    return (topic || manager.searchParams.selectedTopic).locked;
  }

  function deletePost(post) {
    const deleteModal = $uibModal.open({
      backdropClass: 'modal-overlay-backdrop',
      templateUrl: 'discussions/templates/nv-delete-post-confirmation-overlay.html',
      windowClass: 'modal-overlay',
      controller: function ctrl($scope) {
'ngInject';
        $scope.hasComments = post.numCommentsAndReplies > 0;
      },
    });

    return deleteModal.result.then(() => {
      post.remove().then((response) => {
        // somehow this is unnecessary??
        removePostData(response.result.id);
      });
    });
  }

  function removePostData(postId) {
    const postIndex = _.findIndex(manager.currentPosts, { id: postId });
    let post;
    if (postIndex > -1) {
      post = manager.currentPosts[postIndex];

      _spliceItem(manager.currentPosts, postId);

      for (let j = postIndex; j < manager.currentPosts.length; j += 1) {
        manager.currentPosts[j].scrollIndex -= 1;
      }
      manager.lastScrollIndex -= 1;

      if (manager.uiScrollAdapter) {
        // manager.uiScrollAdapter.applyUpdates(post.scrollIndex, []);
        manager.uiScrollAdapter.applyUpdates((item) => {
          if (item.id === postId) {
            return [];
          }

          return item;
        });
      }
    }

    setTopicCountsFromBackend();


    if (manager.context === 'directLink') {
      if (CurrentCourseManager.course.isProgram) {
        $state.go('program-home', { topicId: manager.searchParams.selectedTopic.id });
      } else {
        $state.go('discussions-index', { topicId: manager.searchParams.selectedTopic.id });
      }
    }
  }

  function createReply(replyData, parentComment, mentionedIds, isReplytoReply) {
    return ReplyModel.create(manager.catalogId, replyData, parentComment.id, mentionedIds, isReplytoReply);
  }

  function setReplyData(replyData, parentComment, insertAtFront, isNew, isUpdating) {
    let checkUniquenessPromise;

    if (isNew) {
      checkUniquenessPromise = sanityCheckUnique(parentComment.replies, replyData);
    } else {
      checkUniquenessPromise = $q.when();
    }

    return checkUniquenessPromise.then(() => {
      const reply = new ReplyModel(_.extend(replyData, { catalogId: manager.catalogId, owner: parentComment }));

      if ((isNew || !isUpdating) && !_.findWhere(parentComment.replies, { id: reply.id })) {
        if (insertAtFront) {
          parentComment.replies.unshift(reply);
        } else {
          parentComment.replies.push(reply);
        }

        if (isNew) {
          PubSubDiscussions.publish('reply.create', {
            reply,
            ownerType: parentComment.owner.type,
          });

          if (reply.pointsReceived) {
            if (!parentComment.pointsReceived) {
              parentComment.pointsReceived = reply.pointsReceived;
              parentComment.leaderboardPoints = reply.leaderboardPoints;
            }

            if (parentComment.owner && !parentComment.owner.pointsReceived) {
              parentComment.owner.pointsReceived = reply.pointsReceived;
              parentComment.owner.leaderboardPoints = reply.leaderboardPoints;
            }
          }

          parentComment.replyCount += 1;

          if (parentComment.owner) {
            parentComment.owner.numCommentsAndReplies += 1;
          }
          const member = manager.context !== 'report' // no need to find contribution count for reports
            && parentComment.owner?.findMemberByUserId(reply.user.id);
          if (member) {
            member.totalContributions += 1;
          }
        }
      } else {
        // updating
        _spliceItem(parentComment.replies, { id: reply.id }, reply);
      }
    });
  }

  function deleteReply(reply) {
    const deleteConfirmationModal = $uibModal.open({
      backdropClass: 'modal-overlay-backdrop',
      templateUrl: 'discussions/templates/nv-delete-reply-confirmation-overlay.html',
      windowClass: 'modal-overlay',
      controller: function ctrl($scope) {
'ngInject';
        $scope.hasPoints = reply.pointsReceived;
      },
    });

    return deleteConfirmationModal.result.then(() => {
      reply.remove().then((response) => {
        const post = reply.owner.owner;
        const deletedReply = response.result;
        reply.owner.deleteReply(reply);

        PubSubDiscussions.publish('reply.delete', {
          reply,
          ownerType: reply.owner.owner.type,
        });

        // decrement post/report count
        if (post) {
          post.numCommentsAndReplies -= 1;

          if (deletedReply.topic) {
            post.pointsReceived = deletedReply.topic.pointsReceived;
            post.progress = deletedReply.topic.progress;

            if (post.metaContent?.lecturePage) {
              ReactTimelineService.updateTimeline(post.metaContent.lecturePage.id);
              TimelinesManager.updateComponentPointsAndProgress(post.metaContent.lecturePage.id, 'topic', post.id, post.pointsReceived, null, post.progress);
            }
          }
        }
      });
    });
  }

  function removeReplyData(replyId, comment) {
    const indexOfReply = _.findIndex(comment.replies, { id: replyId });
    const reply = comment.replies[indexOfReply];

    if (reply && indexOfReply > -1 && reply.user?.id !== CurrentUserManager.user.id) {
      _spliceItem(comment.replies, replyId);

      // decrement post/report count
      if (reply.owner.owner) {
        reply.owner.owner.numCommentsAndReplies -= 1;
      }

      comment.replyCount -= 1;
    }
  }

  function followSelectedTopic(topic) {
    if (topic) {
      topic.follow();
    } else {
      manager.searchParams.selectedTopic.follow();
    }
  }

  function unfollowSelectedTopic(topic) {
    if (topic) {
      topic.unfollow();
    } else {
      manager.searchParams.selectedTopic.unfollow();
    }
  }

  function getUsersActiveInDiscussions() {
    manager.rhsInitialized = true;
    return DiscussionsResources.getUsersActiveInDiscussions({
      catalogId: manager.catalogId,
    }).$promise.then((response) => {
      manager.activeUsersInDiscussions = response.result;
    });
  }

  function getTrendingTopics($stateParams) {
    return manager.getAllTopics(null, { order: 'popular' }).then((trendingTopics) => {
      manager.rhsTrendingTopics = rhsCustomFilter(mapTopicIdsToTopics(_.pluck(trendingTopics, 'id')), $stateParams);
    });
  }

  /* helper method for updating and deleting */
  function _spliceItem(list, itemIdToFind, itemToAdd) {
    const foundItem = _.findIndex(list, { id: itemIdToFind });

    if (foundItem === -1) {
      return;
    }

    if (itemToAdd) {
      list.splice(foundItem, 1, itemToAdd);
    } else {
      list.splice(foundItem, 1);
    }
  }

  // helper method
  function findTopic(topicId) {
    if (topicId === 0) {
      return allTopicsOption;
    }

    return _.findWhere(manager.allTopics, { id: topicId });
  }

  // helper method
  function sanityCheckUnique(list, item) {
    const alreadyExists = _.findWhere(list, { id: item.id });

    if (alreadyExists) {
      return $q.reject();
    }
    return $q.resolve();
  }

  // helper method
  function incrementTopicCounts(post, topic) {
    const postInNonCurrentTopic = _.findWhere(manager.postsAddedInNonCurrentTopics, { id: post.id });
    if (!postInNonCurrentTopic || postInNonCurrentTopic.topicId !== topic.id) {
      topic.incrementPostCount();
      topic.incrementNewPostCount();

      if (post.released) {
        topic.incrementReleasedPostCount();
        topic.incrementNewReleasedPostCount();
      }

      if (topic) {
        if (postInNonCurrentTopic?.topicId !== topic.id) {
          postInNonCurrentTopic.topicId = topic.id;
        } else {
          manager.postsAddedInNonCurrentTopics.push(_.extend(post, { topicId: topic.id }));
        }
      }
    }
  }

  function decrementTopicCounts(post, topic) {
    const postInNonCurrentTopic = _.findWhere(manager.postsDeletedInNonCurrentTopics, { id: post.id });

    if (!postInNonCurrentTopic || postInNonCurrentTopic.topicId !== topic.id) {
      if (topic?.getPostsCount() > 0) {
        topic.decrementPostCount();
        topic.decrementNewPostCount();

        if (post.released && topic.getReleasedPostsCount() > 0) {
          topic.decrementReleasedPostCount();
          topic.decrementNewReleasedPostCount();
        }

        if (topic) {
          if (postInNonCurrentTopic?.topicId !== topic.id) {
            postInNonCurrentTopic.topicId = topic.id;
          } else {
            manager.postsDeletedInNonCurrentTopics.push(_.extend(post, { topicId: topic.id }));
          }
        }
      }
    }
  }

  function setTopicCountsFromBackend() {
    TopicModel.getAllTopicsForCourse(_.extend({ catalogId: manager.catalogId })).then((topicsList) => {
      _.each(manager.rhsTrendingTopics, (topic) => {
        const matchingFromBackend = _.findWhere(topicsList, { id: topic.id });
        if (!_.isEmpty(matchingFromBackend) && !_.isEmpty(topic)) {
          topic.setPostCount(matchingFromBackend.getPostsCount());
          topic.setNewPostCount(matchingFromBackend.getNumNewPosts());
          topic.setReleasedPostCount(matchingFromBackend.getReleasedPostsCount());
          topic.setNewReleasedPostCount(matchingFromBackend.getNumNewReleasedPosts());
        }
      });

      // also update currently selected topic

      if (manager.searchParams.selectedTopic?.id) {
        const matchingCurrentTopic = _.findWhere(topicsList, { id: manager.searchParams.selectedTopic.id });

        manager.searchParams.selectedTopic.setPostCount(matchingCurrentTopic.getPostsCount());
        manager.searchParams.selectedTopic.setNewPostCount(matchingCurrentTopic.getNumNewPosts());
        manager.searchParams.selectedTopic.setReleasedPostCount(matchingCurrentTopic.getReleasedPostsCount());
        manager.searchParams.selectedTopic.setNewReleasedPostCount(matchingCurrentTopic.getNumNewReleasedPosts());
      }
    });
  }

  // helper filter
  function topicOptionFilterMatch(idList, allowAll) {
    return function (topic) {
      if (topic.id === 0) {
        return allowAll;
      }

      const foundTopic = _.contains(idList, topic.id);
      return foundTopic && (topic.released || CurrentUserManager.isAdmin());
    };
  }

  // helper filter
  function mapTopicIdsToTopics(idList) {
    return _.map(idList, (id) => findTopic(id));
  }

  // helper filter
  function rhsCustomFilter(topics, $stateParams) {
    // console.log('trending topics before filtering: ', topics);

    const filtered = _.filter(topics, (topic) => topic && (CurrentUserManager.isAdmin() || topic.released));

    // console.log('filtered ', filtered);

    const ordered = _.sortBy(filtered, (topic, index) => {
      if ($stateParams.newTopicId && $stateParams.newTopicId === topic.id) {
        return -1;
      }
      return index;
    });

    // console.log('ordered ', ordered);

    return ordered;
  }

  function toggleSearchMode(boolSearchMode) {
    manager.searchMode = boolSearchMode;
  }

  function getUIScrollItems(startIndex, count) {
    const endIndex = startIndex + count - 1;
    let currentItem;
    const items = [];

    if (startIndex <= endIndex) {
      for (let currentScrollIndex = startIndex; currentScrollIndex <= endIndex; currentScrollIndex += 1) {
        currentItem = _.findWhere(manager.currentPosts, { scrollIndex: currentScrollIndex });

        if (currentItem) {
          items.push(currentItem);
        }
      }
    }

    return items;
  }

  return manager;
}
