/* @ngInject */
export default function DiscussionLectureComponentModel(
  _,
  AbstractDiscussionLectureComponentModel,
  $translate,
  LectureComponentBaseModel,
  DiscussionsManager,
  PostModel,
  config,
) {
  class DiscussionLectureComponent extends AbstractDiscussionLectureComponentModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'DiscussionLectureComponent',
        editFormController: 'TopicModel',
        formName: 'discussionForm',
        post: {
          title: '',
          body: '',
          topic: null,
          starterId: null,
          expandedOnLoad: true,
        },
        isActivity: true,
        canBeCopied: true,
        addTopicToDiscussionManager: true,
      };

      super(_.extend({}, defaults, attributes), isSample, useDefaults);
    }

    getPayload() {
      const payload = {
        topic: _.pick(this.post, ['id', 'body', 'expandedOnLoad', 'isTodo', 'starterId', 'title']),
      };

      if (this.post.topic) {
        if (this.post.topic.isNewTopic) {
          payload.topic.forumTitle = this.post.topic.title;
        } else {
          payload.topic.forumId = this.post.topic.id;
        }
      } else {
        payload.topic.forumId = this.post.topicId;
      }

      return payload;
    }
  }

  return DiscussionLectureComponent;
}
