import React from 'react';
import { css } from '@emotion/react';
import { gray6, gray7 } from 'styles/global_defaults/colors';
import { extraLargeSpacing, halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import t from 'react-translate';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvIcon from 'shared/components/nv-icon';
import { useFormContext } from 'react-hook-form';
import NvTooltip from 'shared/components/nv-tooltip';
import { config } from '../../../config/config.json';

const OrderCollectionsLock = ({
  name,
  visible,
  style: limitedWidthContainerStyles,
}) => {
  const { watch } = useFormContext();
  const locked = watch(name);
  const cssLock = css`
    padding-top: ${standardSpacing}px;
    padding-bottom: ${tripleSpacing}px;
    ${limitedWidthContainerStyles};
    .lock-content {
      border: 1px solid ${gray6};
      padding: ${halfSpacing}px;
      &:hover, &.active {
        background-color: ${gray7};
      }
      .status {
        text-align: center;
        width: ${extraLargeSpacing * 2}px;
        .icon {
          padding-top: ${threeQuartersSpacing / 2}px;
        }
      }
      .lock-text {
        align-self: center;
        .order-switch {
          padding: 0px ${quarterSpacing}px;
        }
      }
    }
  `;

  return (
    <React.Fragment>
      {
        visible
          ? (
            <div css={cssLock}>
              <div className='lock-content d-flex gray-2'>
                <div className='status'>
                  <NvIcon icon={locked ? 'locked' : 'unlocked'} size='medium' />
                  <div className='text-small'>
                    {locked
                      ? t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED()
                      : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED()}
                  </div>
                </div>
                <div className='lock-text'>
                  <div className='header d-flex'>
                    <div className='font-weight-bold'>
                      {t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_TITLE()}
                    </div>
                    <NvTooltip
                      text={locked
                        ? t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED_TOOLTIP()
                        : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED_TOOLTIP()}
                    >
                      <NvSwitch
                        withForm
                        name={name}
                        data-qa={config.pendo.learningJourneys.toggleLock}
                        pendo-tag-name={config.pendo.learningJourneys.toggleLock}
                        className='order-switch'
                        enabledLabel={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED().toUpperCase()}
                        disabledLabel={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED().toUpperCase()}
                      />
                    </NvTooltip>
                  </div>
                  <div className='detail'>
                    {locked
                      ? t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_LOCKED_DESCRIPTION()
                      : t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_ORDER_UNLOCKED_DESCRIPTION()}
                  </div>
                </div>
              </div>
            </div>
          )
          : null
      }
    </React.Fragment>
  );
};
export default OrderCollectionsLock;
