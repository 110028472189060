import { css } from '@emotion/react';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';

import { AngularContext, AngularServicesContext } from 'react-app';
import useAngularExpression from 'shared/hooks/use-angular-expression';
import { useAppDispatch } from 'redux/store';
import { CombinedCourse, RootState } from 'redux/schemas';
import { Enrollment } from 'redux/schemas/models/my-account';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';
import { resetPortableCompletionPanelData, selectPortableCompletionPanelTab } from 'redux/actions/timeline';

import prodPathReplace from 'shared/prod-path-rewrite';
import { gray6, gray7, success } from 'styles/global_defaults/colors';
import {
  cardIconSize, doubleSpacing, halfSpacing, largeSpacing, quarterSpacing, standardSpacing,
  threeQuartersSpacing, tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import useCompletionCriteria from '../use-completion-criteria';
import PanelGauges from './panel-gauges';
import PanelContent from './panel-content';

const l1NavHeight = tripleSpacing - 5; // 5px for the top course/institution border

const styles = css`
  background-color: rgba(58,66,77,.1);
  border-top: 1px solid #e4e7ec;
  height: calc(100vh - ${l1NavHeight}px);
  top: ${l1NavHeight}px;
  left: 0px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1011;

  .completion-criteria-panel-wrapper {
    background-color: #fff;
  }
`;

enum UserCourseCompletionStatus {
  NOT_COMPLETED = 0,
  AUTO = 1,
  MANUAL = 2,
}

const PortableCompletionTitle = ({
  showSoa,
  isCompleted,
  course,
  completionStatus,
}) => {
  const {
    $state,
  } = useContext(AngularServicesContext);

  const currentStateName = useAngularExpression(() => $state.current.name);
  const { enabledFactorCount } = useCompletionCriteria();

  // Copied from nv-completion-criteria
  // Progress text should visible in lecture page whether tasks is completed or not
  // But it is not displayed in course-home
  const showProgress = !showSoa
    && (!isCompleted || currentStateName !== 'course-home')
    && completionStatus !== UserCourseCompletionStatus.MANUAL
    && !course.inArchiveMode;

  return (
    <React.Fragment>
      {showProgress && (
        <div className={`pb-3 text-center ${currentStateName === 'course-home' && enabledFactorCount > 1 ? 'card-subtitle' : 'card-title'}`}>
          { t.COMPLETION_CRITERIA.PROGRESS_TOWARDS_COURSE_COMPLETION() }
        </div>
      )}
    </React.Fragment>
  );
};

const PortableCompletionSOA = ({
  showSoa,
  isCompleted,
  course,
  completionStatus,
}) => {
  const { courseAliases } = useSelector((state) => getCourseAliases(state));
  const userCourse = useSelector((state) => state.models.enrollments[course?.userCourse]);

  const catalogId = course?.catalogId;
  const userId = userCourse?.userId;
  const { injectServices } = useContext(AngularContext);
  const [$state, CourseHomeManager] = injectServices(['$state', 'CourseHomeManager']);

  let selectCase = 'both';
  let credlyLink = '';
  let soaLink = '';
  const soaText = t.COURSE_ADMIN_MEGA_MENU.STATEMENT_OF_ACCOMPLISHMENT.LABEL();
  const credlyBadgeEnabled = completionStatus && !!(CourseHomeManager?.courseHome?.credlyBadge);

  if (credlyBadgeEnabled) {
    selectCase = 'credly';
    credlyLink = CourseHomeManager?.courseHome?.credlyBadge;
  }

  if (showSoa) {
    selectCase = credlyBadgeEnabled ? 'both' : 'soa';
    soaLink = $state.href('statement-modal', { catalogId, userId });
  }

  const showCertificate = showSoa || credlyBadgeEnabled;

  return (
    <React.Fragment>
      { (showSoa || completionStatus || isCompleted) && (
        <div className='pt-2 d-flex align-items-center row congrats-container'>
          <div className='col-md-4'>
            <img
              className='congrats-image'
              src={prodPathReplace('images/congratulations.png')}
              alt='Certification of Completion Badge'
            />
          </div>
          <div className='col-md-8 ml-2 d-flex flex-column'>
            <div className='page-subtitle'>{t.COURSE_HOME.STATEMENT_OF_ACCOMPLISHMENT.HEADER()}</div>
            <div className='blue-links mt-2'>
              {
                (showCertificate) && (
                  <div>
                    {t.COURSE_HOME.STATEMENT_OF_ACCOMPLISHMENT.VIEWABLE_BODY({
                      selectCase,
                      credlyLink,
                      soaLink,
                      courseAlias: courseAliases.courseAlias,
                      soaText,
                    })}
                  </div>
                )
              }
              {
                (!showCertificate && (isCompleted || completionStatus)) && (
                  <div>
                    {t.COMPLETION_CRITERIA.FINISHED_ALL_REQUIREMENTS({
                      courseAlias: courseAliases.CourseAlias,
                    })}
                  </div>
                )
              }
              {
                (course.inArchiveMode) && (
                  <div className='mt-2'>
                    {t.COURSE_HOME.ARCHIVE_MODE.BODY({
                      courseName: course.name,
                      courseAlias: courseAliases.courseAlias,
                    })}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )}
      {/* Archive mode and shown if learner hasn't completed course */}
      {(course.inArchiveMode && !showSoa && !completionStatus) && (
        <div className='archived-not-completed section d-flex'>
          <i className='icon icon-success' />
          <div className='d-flex align-items-center ml-4'>{t.COURSE_HOME.ARCHIVE_MODE.BODY({
            courseName: course.name,
            courseAlias: courseAliases.courseAlias,
          })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const loaderStyles = css`
  padding-bottom: ${standardSpacing}px;

  @keyframes shineAnimation {
    0% {
      background-position: 0% 50%;
    }

    100% {
      background-position: -100% 50%;
    }
  }

  .loading {
    background: linear-gradient(90deg, ${gray6} 35%, ${gray7} 45%, ${gray6} 55%);
    background-size: 600% 600%;
    animation: shineAnimation 1.5s infinite;
  }

  .title-bar {
    width: 60%;
    height: ${halfSpacing}px;
    margin-bottom: ${doubleSpacing}px;
    margin-left: 20%;
  }

  .placeholder-bar {
    width: 100%;
    height: ${quarterSpacing}px;
    margin: ${threeQuartersSpacing}px auto;
  }

  .short-bar {
    margin-left: 0;
    margin-right: 0;
    width: 80%;
  }
`;

const CompletionLoader = () => (
  <div css={loaderStyles}>
    <div className='loading title-bar' />
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar' />
    <div className='loading placeholder-bar short-bar' />
  </div>
);

const headerStyles = css`
  &.completion-criteria-panel-header {
    background: linear-gradient(to right, rgba(247,251,232, 0.3), rgba(220, 253, 253, 0.3));
    border-bottom: 1px solid ${gray6};
    padding: ${threeQuartersSpacing}px ${3 * standardSpacing}px ${largeSpacing}px;

    .archived-not-completed {
      .icon-success {
        font-size: ${doubleSpacing}px;
        color: ${success}
      }
    }

    .congrats-container {
      height: 90px;
    }
    .congrats-image {
      height: ${cardIconSize}px;
      width: ${cardIconSize}px;
      float: right;
    }
  }
`;

export const PortableCompletionHeader = () => {
  // Completion and SOA
  const { injectServices } = useContext(AngularContext);
  const [$state, CourseHomeManager] = injectServices(['$state', 'CourseHomeManager']);
  const showSoa = $state.current.name === 'course-home'
    && CourseHomeManager?.courseHome?.showStatementOfAccomplishment;
  const { isCompleted } = useCompletionCriteria();
  const currentCourse: CombinedCourse = useSelector(getCurrentCourse);
  const userCourse: Enrollment = useSelector((state: RootState) => state.models.enrollments[currentCourse.userCourse]);
  const isCourseCompletionProgressLoading = useSelector((state: RootState) => state.app.timeline.isCourseCompletionProgressLoading);
  const { completionStatus } = userCourse; // 0 - Not completed, 1 - Auto, 2 - Manual

  const showGauges = !currentCourse.inArchiveMode;

  return (
    <div css={headerStyles} className='completion-criteria-panel-header'>
      <div className='body-text-wrapper'>
        {isCourseCompletionProgressLoading
          ? (
            <CompletionLoader />
          )
          : (
            <React.Fragment>
              <PortableCompletionTitle
                showSoa={showSoa}
                isCompleted={isCompleted}
                course={currentCourse}
                completionStatus={completionStatus}
              />
              <PortableCompletionSOA
                showSoa={showSoa}
                isCompleted={isCompleted}
                course={currentCourse}
                completionStatus={completionStatus}
              />
              {showGauges && (
                <PanelGauges />
              )}
            </React.Fragment>
          )}
      </div>
    </div>
  );
};

const PortableCompletionPanel = () => {
  const dispatch = useAppDispatch();

  const portableCompletionPanelSelectedTab: CompletionCriteriaType = useSelector((state: RootState) => state.app.timeline.portableCompletionPanelSelectedTab);
  const currentCourse: CombinedCourse = useSelector(getCurrentCourse);
  const catalogId = currentCourse?.catalogId;


  useEffect(() => () => {
    // Reset the tab selected
    dispatch(selectPortableCompletionPanelTab(null));
    // Clear all the data
    dispatch(resetPortableCompletionPanelData(catalogId));
  }, [dispatch, catalogId]);

  return (
    <div css={styles}>
      <div className='completion-criteria-panel-wrapper'>
        <PortableCompletionHeader />
        {portableCompletionPanelSelectedTab && (
          <PanelContent />
        )}
      </div>
    </div>
  );
};

export default PortableCompletionPanel;
