import CommentsTab from 'practice_room/components/comments-tab';
import FeedbackTabs from 'practice_room/components/feedbacks/feedback-tabs';
import React, { useContext } from 'react';
import { SubmissionTab } from 'redux/schemas/models/video-practice';
import InsightsTab from '../insights/insights-tab';
import { PracticeSubmissionContext } from '../utils';
import PracticeSubmissionTabs from './practice-submission-tabs';

const SubmissionTabs = ({ setShowPracticeAgainModal }: { setShowPracticeAgainModal?: (show: boolean) => void }) => {
  const [{ isAdmin, selectedView, showCommentInputRow, showFeedbackForm }] = useContext(PracticeSubmissionContext);

  if ((!showFeedbackForm || isAdmin) && !showCommentInputRow) {
    return (
      <React.Fragment>
        <PracticeSubmissionTabs />
        <div>
          {selectedView === SubmissionTab.COMMENTS && (
            <CommentsTab />
          )}
          {(selectedView === SubmissionTab.ALL_FEEDBACK
            || selectedView === SubmissionTab.AUTHOR_FEEDBACK
          ) && (
            <FeedbackTabs />
          )}
          {selectedView === SubmissionTab.INSIGHTS && (
            <InsightsTab setShowPracticeAgainModal={setShowPracticeAgainModal} />
          )}
        </div>
      </React.Fragment>
    );
  }
  return null;
};

export default SubmissionTabs;
