import { TextAlign } from 'shared/components/nv-tooltip';
import t from '../../../react-translate';
import submitQuiz, { saveQuiz } from '../../services/component-submit-quiz.ts';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    linkedEditMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    $scope,
    $controller,
    $q,
    $stateParams,
    RailsRoutes,
    AlertMessages,
    ConfirmationOverlays,
    nvUtil,
    $timeout,

    CurrentCourseManager,
    CurrentUserManager,
    TimelinesManager,
    CurrentPermissionsManager,
    ReactTimelineService,
    config,
    $state,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    vm.isContentManagementCollection = CurrentCourseManager?.course?.isContentManagementCollection ?? false;
    const { contentManagementCollection, contentManagementLecturePage } = vm.lectureComponent.lecturePage;

    vm.config = config;

    vm.retry = (currentQuiz) => {
      let modalInstanceCtrl;
      const { pointsReceived } = currentQuiz.quiz;
      const pointsAlias = CurrentCourseManager.course.pointsName;
      const totalPoints = vm.nvUtil.getCurrentTotalPoints(currentQuiz.quiz.totalPoints, currentQuiz.quiz.releaseDate, CurrentCourseManager.course.isDecayEnabled());

      if (pointsReceived > totalPoints) {
        // eslint-disable-next-line no-shadow
        modalInstanceCtrl = ['$scope', 'pointsReceived', 'pointsAlias', 'totalPoints', function ($scope, pointsReceived, pointsAlias, totalPoints) {
          $scope.pointsReceived = pointsReceived;
          $scope.pointsAlias = pointsAlias;
          $scope.totalPoints = totalPoints;
        }];

        const modalInstance = ConfirmationOverlays.openConfirmationModal(
          'quizzes/templates/modal-decayed-points-warning-overlay.html',
          modalInstanceCtrl,
          { pointsReceived, pointsAlias, totalPoints },
        );
        modalInstance.result.then(() => {
          currentQuiz.quiz.resetResponses(currentQuiz.quiz.submission.responses);
          currentQuiz.shouldShowFeedback = false;

          scrollToTop(true);
        });
      } else {
        currentQuiz.quiz.resetResponses(currentQuiz.quiz.submission.responses);
        currentQuiz.shouldShowFeedback = false;

        scrollToTop(true);
      }
    };

    vm.save = ($event, lectureComponent) => {
      $event.preventDefault();

      saveQuiz(lectureComponent)
        .then((response) => {
          lectureComponent.quiz.saved = true;
          lectureComponent.quizForm.$setPristine();
          ReactTimelineService.updateTimeline($stateParams.id);
          TimelinesManager.updateComponentProgress($stateParams.id, lectureComponent.type, lectureComponent.id, lectureComponent.quiz.progress);
        }).catch(
          (error) => {
            AlertMessages.error('', 'FORM.ERROR');
          },
        );
    };

    vm.submit = (lectureComponent) => {
      let modalInstance;
      const unansweredCount = lectureComponent.quiz.unansweredCount();
      const unansweredCountModalDeffered = $q.defer();
      const finalAttemptModalDeffered = $q.defer();
      let modalInstanceCtrl;


      /* Submit with blank responses */
      if (unansweredCount) {
        // eslint-disable-next-line no-shadow
        modalInstanceCtrl = ['$scope', 'unansweredCount', function ($scope, unansweredCount) {
          $scope.unansweredCount = unansweredCount;
        }];
        const modalInstanceTemplate = 'quizzes/templates/modal-questions-blank-submit.html';
        modalInstance = ConfirmationOverlays.openConfirmationModal(modalInstanceTemplate, modalInstanceCtrl, { unansweredCount });
        modalInstance.result.then(() => {
          unansweredCountModalDeffered.resolve();
        }, () => {
          unansweredCountModalDeffered.reject();
        });
      } else {
        unansweredCountModalDeffered.resolve();
      }


      /* Submit final attempt warning */
      if ((!lectureComponent.quiz.submission.length && lectureComponent.quiz.maximumAttempts === 1)
        || (lectureComponent.quiz.submission.numberOfAttempts + 1 === lectureComponent.quiz.maximumAttempts)) {
        unansweredCountModalDeffered.promise.then(() => {
          modalInstance = ConfirmationOverlays.openConfirmationModal('quizzes/templates/modal-final-attempt.html');
          modalInstance.result.then(() => {
            finalAttemptModalDeffered.resolve();
          }, () => {
            finalAttemptModalDeffered.reject();
          });
        }, () => {
          finalAttemptModalDeffered.reject();
        });
      } else {
        finalAttemptModalDeffered.resolve();
      }

      $q.all([unansweredCountModalDeffered.promise, finalAttemptModalDeffered.promise]).then(() => {
        submitQuiz(lectureComponent)
          .then((response) => {
            const { quiz } = lectureComponent;
            lectureComponent.shouldShowFeedback = true;
            quiz.pointsReceived = quiz.submission.pointsReceived;

            if (response.quiz?.progress === 'completed') {
              if (CurrentCourseManager.course.gamificationEnabled) {
                let totalPoints = vm.nvUtil.getCurrentTotalPoints(quiz.totalPoints, quiz.releaseDate, CurrentCourseManager.course.isDecayEnabled());

                if (quiz.graded && quiz.maximumAttempts && quiz.submission.numberOfAttempts >= quiz.maximumAttempts && quiz.submission.pointsReceived < totalPoints) {
                  totalPoints = quiz.submission.pointsReceived;
                }

                ReactTimelineService.updateTimeline($stateParams.id);
                TimelinesManager.updateComponentPointsAndProgress($stateParams.id, lectureComponent.type, lectureComponent.id,
                  quiz.submission.pointsReceived, totalPoints);

                if (quiz.submission.pointsReceived) {
                  const quizScoreInfo = {
                    submissionScore: quiz.submission.score,
                    maxScore: quiz.submission.maxScore,
                    rewardsPointsProportionally: quiz.pointsConfiguration.rewardsPointsProportionally,
                    threshold: quiz.pointsConfiguration.threshold,
                    pointsReceivedFromPreviousAttempt: quiz.submission.pointsReceivedFromPreviousAttempt,
                    lowerScoreThanPreviousAttempt: quiz.submission.pointsReceived
                      <= quiz.submission.pointsReceivedFromPreviousAttempt,
                  };

                  vm.showPointsModal(quiz.submission, quizScoreInfo).then(scrollToTop);
                } else {
                  scrollToTop();
                }
              } else {
                ReactTimelineService.updateTimeline($stateParams.id);
                TimelinesManager.updateComponentProgress(
                  $stateParams.id,
                  lectureComponent.type,
                  lectureComponent.id,
                  'completed',
                );
                scrollToTop();
              }
            } else {
              lectureComponent.quizForm.$setPristine();
              lectureComponent.quiz.progress = response.quiz?.progress || 'in_progress';
              ReactTimelineService.updateTimeline($stateParams.id);
              TimelinesManager.updateComponentProgress(
                $stateParams.id,
                lectureComponent.type,
                lectureComponent.id,
                response.quiz?.progress || 'in_progress',
              );
              scrollToTop();
            }
            if (!CurrentUserManager.isAdmin()) {
              nvUtil.addUserToHeads(CurrentUserManager.user, lectureComponent.quiz.usersRecentlyCompleted);
            }
          }).catch(
            (error) => {
              AlertMessages.error('', 'FORM.ERROR');
            },
          );
      });
    };

    /* Helper */
    function scrollToTop(formSection = false) {
      $timeout(() => {
        let $scrollTo = $(`#lecture-component-${vm.lectureComponent.id}`);
        if (formSection) {
          $scrollTo = $scrollTo.find('form');
        }

        if ($scrollTo.length) {
          $('.main-panel-scrollable').scrollToElementAnimated($scrollTo, null, 400);
        }
      });
    }

    vm.setEditMenu = () => {
      const { sharedProps } = this.context;
      const extraOptions = [];

      extraOptions.push(
        sharedProps.extraOptions.getEditOption(
          t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
          vm.lectureComponent.lecturePage.isLinked,
          {
            disabledTooltip: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
            tooltipTextAlign: TextAlign.LEFT,
          },
        ),
        {
          type: 'link',
          link: RailsRoutes.editQuestionSetPath(vm.$stateParams.catalogId, vm.lectureComponent.quiz.id),
          text: t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.EDIT_QUESTIONS(),
          disabled: vm.lectureComponent.lecturePage.isLinked,
          tooltip: {
            text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
            textAlign: TextAlign.LEFT,
            enabled: !!vm.lectureComponent.lecturePage.isLinked,
            placement: 'left',
            offset: 20,
          },
        },
      );

      if (vm.lectureComponent.quiz.graded && !vm.CurrentCourseManager.course.isSelfPaced) {
        extraOptions.push({
          type: 'text',
          text: vm.lectureComponent.hasDeadline() ? t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.ADD_DEADLINE(),
          callback: () => {
            vm.toggleDeadline();
            vm.setEditMenu();
          },
          disabled: vm.isContentManagementCollection,
          tooltip: {
            enabled: vm.isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            offset: 20,
          },
        });
      }

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
        },
      };
    };

    vm.setEditMenu();

    vm.hasBuilderPermissionInCollection = () => (contentManagementCollection.userCourse
      && CurrentPermissionsManager.hasCollectionBuilderPermissions(contentManagementCollection.userCourse));

    vm.goToCollectionLesson = () => {
      const url = $state.href('lecture-page-edit', {
        catalogId: contentManagementCollection.catalogId,
        id: contentManagementLecturePage.id,
        lectureActivityId: vm.lectureComponent.cloneParentId,
      });

      window.open(url, '_blank');
    };

    $scope.$watch('vm.lectureComponent.quiz.graded && !vm.CurrentCourseManager.course.isSelfPaced', () => vm.setEditMenu());
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-quiz-lecture-component.html',
};
