import React from 'react';

import { animate, FrameAnimation } from 'shared/utils';

type Props = {
  current: number,
  children: React.ReactElement[],
  layerClassName?: string,
  transitionDuration?: number,
};

type Layers = { [key: string]: HTMLElement };

export const DEFAULT_TRANSITION_DURATION = 250;

const LayersTransitioner = (props: Props) => {
  const {
    current,
    children,
    layerClassName,
    transitionDuration = DEFAULT_TRANSITION_DURATION,
  } = props;

  const childrenCopy = [...children];
  const childrenRefs = React.useRef<Layers>({});
  const childrenCopyRef = React.useRef(childrenCopy);
  const currentLayerRef = React.useRef<HTMLElement>();
  const previousLayerRef = React.useRef<HTMLElement>();
  const currentLayerAnimationRef = React.useRef<FrameAnimation>();
  const previousLayerAnimationRef = React.useRef<FrameAnimation>();
  // Moving current active layer element to last index, in order to render it
  // over all the layers.
  childrenCopy.push(childrenCopy.splice(current, 1)[0]);

  React.useLayoutEffect(() => {
    const layers = childrenCopyRef.current;

    layers.forEach((child, index) => {
      const layerElement = childrenRefs.current[index];

      layerElement.style.opacity = (index === (layers.length - 1) ? 1 : 0).toString();
    });
  }, []);

  React.useEffect(() => {
    currentLayerAnimationRef.current?.stopAnimation();
    previousLayerAnimationRef.current?.stopAnimation();

    if (currentLayerRef.current) {
      previousLayerRef.current = currentLayerRef.current;
    }

    currentLayerRef.current = childrenRefs.current[children.length - 1];

    if (previousLayerRef.current) {
      const previousLayer = previousLayerRef.current;

      previousLayerAnimationRef.current = animateLayer(previousLayer, false);
    }

    const currentLayer = currentLayerRef.current;

    currentLayerAnimationRef.current = animateLayer(currentLayer, true);
  }, [current, children.length]);

  const animateLayer = (element: HTMLElement, show: boolean) => {
    const startAnimation = animate(DEFAULT_TRANSITION_DURATION, (duration, progress) => {
      if (show) {
        element.style.opacity = ((1 / duration) * progress).toString();
      } else {
        element.style.opacity = (1 - (1 / duration) * progress).toString();
      }
    });

    return startAnimation();
  };

  return (
    <React.Fragment>
      {childrenCopy.map((child, index) => React.cloneElement(child, {
        className: [
          child.props.className,
          layerClassName,
        ].filter(Boolean).join(' ') || undefined,
        ref(childRef) {
          childrenRefs.current[index] = childRef;
        },
        style: {
          ...(child.props.style || {}),
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
        },
      }))}
    </React.Fragment>
  );
};

export default LayersTransitioner;
