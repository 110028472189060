import { css } from '@emotion/react';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { setLeftPanelView } from 'redux/actions/lecture-pages';
import { useSelector } from 'react-redux';

import NvIcon from 'shared/components/nv-icon';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import GradientAiIcon from '../components/shared/gradient-ai-icon';

const Header = () => {
  const dispatch = useAppDispatch();
  const lectureComponentToReplace = useSelector(state => state.app.lecturePage.novoAI?.lectureComponentToReplace);

  const scrollingElement = document.getElementById('lecture-page-left-panel');
  const isScrolledFromHeader = useInfiniteScroll(scrollingElement, 20, false);

  const styles = css`
    & {
      background: ${isScrolledFromHeader ? 'linear-gradient(315deg, rgba(220, 253, 253, 0.30) 0%, rgba(247, 251, 232, 0.30) 99.28%), #FFF' : 'transparent'};
    }
    .back-button {
      ${lectureComponentToReplace && css`
        visibility: hidden;
      `}
    }
  `;

  const exitAiView = () => {
    dispatch(setLeftPanelView('add_top_level'));
  };

  return (
    <div
      className='d-flex justify-content-between sticky-top p-4 w-100'
      css={styles}
    >
      <div className='back-button'>
        <NvIcon
          className='text-gray-2'
          icon='collapse'
          size='small'
          onClick={exitAiView}
        />
      </div>
      <div className='d-flex align-items-center mx-auto'>
        <GradientAiIcon icon='gen-ai' size='small' className='mr-1' />
        <span className='text-large-body bold'>
          {t.LECTURE_PAGES.LHS.NOVO_AI.START_BUTTON()}
        </span>
      </div>
      <NvIcon
        className='text-gray-2'
        icon='close'
        size='small'
        onClick={() => hideAddUI(dispatch)}
      />
    </div>
  );
};

export default Header;
