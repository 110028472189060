import React, { useContext, useMemo } from 'react';
import moment from 'moment';
import t from 'react-translate';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { AngularContext, AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';

import { CompletionStats, CourseRegistrationType } from 'redux/schemas/models/course';
import { enrollInJourney, fetchJourney } from 'redux/actions/learning-journeys';
import { getCurrentEnrollment, getCurrentUserId } from 'redux/selectors/users';
import { getCurrentJourney } from 'redux/selectors/learning-journeys';

import { hexToRgbaString, success, primary, white } from 'styles/global_defaults/colors';
import { boldFontWeight, textLargeFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

import { mergeRefs } from 'shared/react-utils';
import { HomeContext } from 'learning_journeys/components/learning-journey';
import NvIcon from 'shared/components/nv-icon';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import LineDivider from './divider';
import { config } from '../../../config/pendo.config.json';
import { CompletionCriteria } from './completion-criteria-dropdown';

enum Status {
  USER_BANNED,
  START_JOURNEY_CTA,
  ClOSED_JOURNEY,
  CLOSED_ENROLLMENT,
  NO_MATCH_ENTITLEMENTS,
  COMPLETION_PROGRESS,
  COMPLETED_WITHOUT_CERTIFICATE,
  COMPLETED_WITH_CERTIFICATE,
}

type Props = {
  isStatic?: boolean,
  className?: string,
  condensed?: boolean,
};

const LearningStatus = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    isStatic = false,
    condensed = false,
  } = props;
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>();
  const { setScrollStatusTrigger } = useContext(HomeContext);
  const { injectServices } = React.useContext(AngularContext);
  const [$stateParams] = injectServices(['$stateParams']);
  const { $state, $scope, $timeout } = useContext(AngularServicesContext);
  const { catalogId } = $state.params;
  const userId = useSelector(getCurrentUserId);
  const currentJourney = useSelector(getCurrentJourney);
  const userCourse = useSelector((state) => state.models.enrollments[currentJourney?.userCourse]);
  const currentEnrollment = useSelector(getCurrentEnrollment);

  const styles = css`
    background-color: ${hexToRgbaString(white, 0.9)};

    .gradient-bg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(315deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99%);
    }

    .status-container {
      max-width: 815px;
      padding: ${standardSpacing}px;
    }

    .status-message {
      font-weight: ${boldFontWeight};
      text-align: center;
    }

    .progress-status-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .progress-title {
        font-size: ${textLargeFontSize}px;
        margin-bottom: ${halfSpacing}px;
      }

      .progress-status {
        font-weight: ${boldFontWeight};
      }
    }

    .completed-certificate-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .badge-container {
        display: flex;
        align-items: center;
        margin-bottom: ${standardSpacing}px;

        i {
          margin: 0 ${threeQuartersSpacing}px;
          color: ${success}
        }
      }

      .completed-certificate-message {
        font-weight: ${boldFontWeight};

        a {
          color: ${primary};
        }
      }
    }
  `;

  React.useEffect(() => {
    if (isStatic) {
      const { offsetTop, offsetHeight } = containerRef.current;

      setScrollStatusTrigger(offsetTop + offsetHeight);
    }
  }, [isStatic, setScrollStatusTrigger]);

  const webviewDismiss = () => {
    if ($stateParams.mobileapp) {
      const stateParams = { ...$stateParams };
      stateParams.webview_dismiss = true;
      $state.go($state.current.name, stateParams);
    }
  };

  const enroll = () => {
    wrapThunkAction(dispatch(enrollInJourney({ catalogId }))).then((action) => {
      if ($stateParams.mobileapp) {
        webviewDismiss();
        return;
      }

      if (action.payload) {
        dispatch(fetchJourney({ catalogId: currentJourney.catalogId }));
      }
      $timeout(() => {
        $scope.CurrentUserManager.user?.enrollments?.push(action.payload);
      });
    }).catch(webviewDismiss);
  };

  const getStatus = () => {
    const {
      closeDate,
      canTakeCourse,
      completionStats,
      typeOfRegistration,
      registrationClosed,
      soaCertificateEnabled,
    } = currentJourney || {};

    const isJourneyCompleted = !!currentEnrollment?.completionStatus
      || (completionStats?.required > 0 && completionStats.collections.every(({ completed, required }) => completed >= required));

    const currentMoment = moment();
    const isJourneyClosed = closeDate && moment(closeDate) < currentMoment;

    const isPublicEnrollment = typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT
      || typeOfRegistration === CourseRegistrationType.PAID_ENROLLMENT
      || typeOfRegistration === CourseRegistrationType.FREE_AND_PAID_CERTIFICATE_ENROLLMENT
      || typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION;
    const isClosedEnrollment = typeOfRegistration === CourseRegistrationType.CLOSED_ENROLLMENT;
    const isEntitlementBasedEnrollment = typeOfRegistration === CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS;


    // User has signed up before
    if (userCourse) {
      // banned
      if (userCourse.banned) return Status.USER_BANNED;
      // !banned or !dropped
      if (!(userCourse.banned || userCourse.dropped)) {
        // Journey completed
        if (isJourneyCompleted) {
          // Certificate enabled
          if (soaCertificateEnabled || userCourse?.credlyBadge) return Status.COMPLETED_WITH_CERTIFICATE;
          // Certificate not enabled
          return Status.COMPLETED_WITHOUT_CERTIFICATE;
        }
        // Journey in progres
        return Status.COMPLETION_PROGRESS;
      }
    }
    // User has not signed up before
    if (!userCourse) {
      // Journey concluded
      if (isJourneyClosed) return Status.ClOSED_JOURNEY;
      // Journey not closed
      // Type of registrations
      if (isClosedEnrollment) return Status.CLOSED_ENROLLMENT;
      if (isPublicEnrollment) {
        // Show enroll CTA
        if (!registrationClosed) return Status.START_JOURNEY_CTA;
      }
      if (isEntitlementBasedEnrollment) {
        if (canTakeCourse) {
          // Show enroll CTA
          if (!registrationClosed) return Status.START_JOURNEY_CTA;
        }
        // No match entitlements
        return Status.NO_MATCH_ENTITLEMENTS;
      }
    }

    return null;
  };

  const status = useMemo(getStatus, [currentJourney, currentEnrollment, userCourse]);

  return (
    <div
      css={styles}
      className={className}
      ref={mergeRefs(ref, containerRef)}
    >
      {status !== null && (
        <div className='gradient-bg'>
          <div className='status-container'>
            {{
              [Status.ClOSED_JOURNEY]: <ClosedJourneyMessage />,
              [Status.CLOSED_ENROLLMENT]: <ClosedEnrollment />,
              [Status.START_JOURNEY_CTA]: <StartMyJourneyBtn enroll={enroll} />,
              [Status.NO_MATCH_ENTITLEMENTS]: <NoMatchEntitlements />,
              [Status.USER_BANNED]: <UserBanned teamingTeamAlias={currentJourney.teachingTeamName.downcasedSingularized} />,
              [Status.COMPLETION_PROGRESS]: <CompletionProgress completionStats={currentJourney.completionStats} condensed={condensed} />,
              [Status.COMPLETED_WITH_CERTIFICATE]: <CompletedWithCertificate angularState={$state} catalogId={catalogId} userId={userId} currentJourney={currentJourney} />,
              [Status.COMPLETED_WITHOUT_CERTIFICATE]: <CompletedWithoutCertificate />,
            }[status]}
          </div>
        </div>
      )}
    </div>
  );
});

const StartMyJourneyBtn = ({ enroll }: { enroll: () => void }) => (
  <Button
    variant='primary'
    onClick={enroll}
    pendo-tag-name={config.pendo.learningJourneys.startMyJourney}
  >
    {t.LEARNING_JOURNEYS.DETAILS.STATUS.START_JOURNEY()}
  </Button>
);

const CompletionProgress = ({ condensed, completionStats }: { condensed: boolean, completionStats: CompletionStats }) => {
  // We should only consider non optional collections in the requiredCoursesCompleted count.
  const nonOptionalCollections = completionStats.collections.filter(collection => collection.completionSettings !== CompletionCriteria.OPTIONAL);
  const requiredCoursesCompleted = nonOptionalCollections.reduce((coursesCompleted, { completed, required }) => coursesCompleted + Math.min(completed, required), 0);

  return (
    <div className='progress-status-container'>
      <span className='progress-title'>
        {!condensed && t.LEARNING_JOURNEYS.DETAILS.STATUS.PROGRESS_TOWARDS_COURSE_COMPLETION()}
      </span>
      <span className='progress-status'>
        {t.LEARNING_JOURNEYS.DETAILS.STATUS.JOURNEY_COMPLETION(requiredCoursesCompleted, completionStats.required)}
      </span>
    </div>
  );
};

const CompletedWithCertificate = ({ angularState, catalogId, userId, currentJourney }: { angularState: any, catalogId: string, userId: number, currentJourney: any }) => {
  let selectCase: 'both' | 'credly' | 'soa' = 'both';
  let credlyLink = '';
  let soaLink = '';

  const userCourse = useSelector((state) => state.models.enrollments[currentJourney.userCourse]);

  if (userCourse?.credlyBadge) {
    selectCase = 'credly';
    credlyLink = userCourse?.credlyBadge;
  }

  if (currentJourney?.soaCertificateEnabled) {
    selectCase = userCourse?.credlyBadge ? 'both' : 'soa';
    soaLink = angularState.href('journey-statement-modal', { catalogId, userId });
  }

  return (
    <div className='completed-certificate-container'>
      <div className='badge-container'>
        <LineDivider className='separator' />
        <NvIcon icon='badge' size='large' />
        <LineDivider className='separator' />
      </div>
      <span className='completed-certificate-message'>
        {t.LEARNING_JOURNEYS.DETAILS.STATUS.COMPLETION_CELEBRATION_WITH_CERTIFICATE(
          credlyLink, selectCase, soaLink,
        )}
      </span>
    </div>
  );
};

const CompletedWithoutCertificate = () => (
  <div className='completed-certificate-container'>
    <div className='badge-container'>
      <LineDivider className='separator' />
      <NvIcon icon='success' size='large' />
      <LineDivider className='separator' />
    </div>
    <span className='completed-certificate-message'>
      {t.LEARNING_JOURNEYS.DETAILS.STATUS.COMPLETION_CELEBRATION_WITHOUT_CERTIFICATE()}
    </span>
  </div>
);

const NoMatchEntitlements = () => (
  <div className='status-message'>
    {t.LEARNING_JOURNEYS.DETAILS.STATUS.ENTITLEMENTS_NO_MATCH()}
  </div>
);

const ClosedJourneyMessage = () => (
  <div className='status-message'>
    {t.LEARNING_JOURNEYS.DETAILS.STATUS.JOURNEY_CONCLUDED()}
  </div>
);

const ClosedEnrollment = () => (
  <div className='status-message'>
    {t.LEARNING_JOURNEYS.DETAILS.STATUS.CLOSED_ENROLLMENT()}
  </div>
);

const UserBanned = ({ teamingTeamAlias }: { teamingTeamAlias: string }) => (
  <div className='status-message'>
    {t.FLYER.COURSE.ACTION.BANNED({ teamingTeamAlias })}
  </div>
);

export default LearningStatus;
