import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCourseCompletionCriteria } from 'redux/actions/courses';
import { getCourseCompletionProgress } from 'redux/actions/timeline';
import { CombinedCourse } from 'redux/schemas';
import { getCurrentCourse } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';

/**
 * The purpose of this wrapper is send out the api call on mount. Completion
 * criteria on the lecture page is loaded through header, and list is displayed
 * on clicking on each gauge. Whereas in the course outline, the large gauges
 * are displayed by default.
 *
 */

type ApiWrapperProps = {
  children: ReactNode,
};

const ApiWrapper = ({ children }: ApiWrapperProps) => {
  const dispatch = useAppDispatch();
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentCourse: CombinedCourse = useSelector(getCurrentCourse);

  const { automaticCompletionCriteria } = currentCourse;

  useEffect(() => {
    // Need to send API only if we don't have automaticCompletionCriteria in
    // current course. Most probably it will have it as the GET course will
    // return the automaticCompletionCriteria too.
    if (!automaticCompletionCriteria) {
      dispatch(getCourseCompletionCriteria({
        catalogId,
      }));
    }
  }, [dispatch, catalogId, automaticCompletionCriteria]);

  useEffect(() => {
    dispatch(getCourseCompletionProgress({
      catalogId,
    }));
  }, [dispatch, catalogId]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default ApiWrapper;
