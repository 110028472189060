import React, { useEffect, useState } from 'react';
import store, { useAppDispatch } from 'redux/store';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { getAllSkillTags } from 'redux/actions/skill-tags';

import { bs4Components } from 'components/bs4-theme-provider';

import { render } from 'react-dom';


import _ from 'underscore';
import omit from 'lodash/omit';
import { ThemeProvider } from 'react-bootstrap';
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-plusplus */
import { css } from '@emotion/react';
import { quarterSpacing, halfSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { gray3 } from 'styles/global_defaults/colors';
import NvDropdown, { NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';
import t from 'react-translate';
import bootstrapReact, { unmountReact } from 'react-app';
import { getMetadataFilters } from 'redux/actions/institutions';
import useForceUpdate from 'shared/hooks/use-force-update';
import { i18nJoin, JoinMode } from 'shared/services/i18n-utils';
import CourseHomeHeader from 'courses/components/course-form-header';
import NvCheckboxDropdown from 'shared/components/inputs/nv-checkbox-dropdown';
import ConfigureEntitlementsModal from 'offerings/components/configure-entitlements-modal';
import { providerTypes } from '../../institutions/components/advanced-settings/integrations/lms-integration';
import { config } from '../../../config/config.json';

/* @ngInject */
export default function CourseFormBaseCtrl(
  $q,
  $timeout,
  nvUtil,
  moment,
  validationConstants,
  CourseModel,
  AlertMessages,
  CoursesManager,
  CurrentUserManager,
  InstitutionsManager,
  CurrentPermissionsManager,
) {
  const vm = this;

  vm.errors = {};
  vm.initialize = initialize;
  vm.institution = InstitutionsManager.institution;
  vm.CourseModel = CourseModel;
  vm.CurrentUserManager = CurrentUserManager;
  vm.moment = moment;
  vm.validationConstants = validationConstants;
  vm.courseTypeChanged = courseTypeChanged;
  vm.usedForChanged = usedForChanged;
  vm.defaultTypeOfRegistration = defaultTypeOfRegistration;
  vm.isRegistrationOptionAvailable = isRegistrationOptionAvailable;
  vm.getEntitlementProfileSettingName = getEntitlementProfileSettingName;
  vm.getEntitlementValuesText = getEntitlementValuesText;
  vm.isCalendarActiveFromInstitution = isCalendarActiveFromInstitution;
  vm.setDirty = setDirty;
  vm.setParentCourseId = setParentCourseId;

  /** The possible types of course duration. Note that the keys must be
   * in lowercase to match the course.durationType value received from the backend */
  vm.durationUnits = {
    hours: 'TIME.HOURS',
    days: 'TIME.DAYS',
  };

  vm.currentDurationUnit = vm.durationUnits.hours;
  vm.handleErrorResponse = handleErrorResponse;
  vm.baseSaveCourse = baseSaveCourse;
  vm.removeCoverPhoto = removeCoverPhoto;
  vm.setCourseDurationUnit = setCourseDurationUnit;
  vm.setTags = setTags;
  vm.setMetadataTags = setMetadataTags;
  vm.setRequiredField = setRequiredField;
  vm.removeRequiredField = removeRequiredField;
  vm.setHeaderDraft = setHeaderDraft;
  vm.renderReactCheckboxDropdown = renderReactCheckboxDropdown;
  vm.renderReactDropdown = renderReactDropdown;
  vm.pendingRequiredFields = pendingRequiredFields;
  vm.updateFormHeader = () => {};
  vm.reactConfigureEntitlements = null;
  vm.configureEntitlements = () => {
    vm.reactConfigureEntitlements?.();
  };
  vm.nameIsValid = nameIsValid;
  vm.onChangeCatalogId = onChangeCatalogId;

  vm.onActivityCodeChange = onActivityCodeChange;
  vm.validateActivityCode = validateActivityCode;
  vm.disableValidateButton = disableValidateButton;
  vm.disableActivityCode = disableActivityCode;
  vm.hasBeenReleased = hasBeenReleased;
  vm.showSubmitButton = showSubmitButton;
  vm.hasSumTotalIntegration = hasSumTotalIntegration;
  vm.hasEdcastIntegration = hasEdcastIntegration;
  vm.getEdcastLastSyncUp = getEdcastLastSyncUp;
  vm.isActivityValidated = isActivityValidated;
  vm.showValidationError = showValidationError;
  vm.showSuccessMessage = showSuccessMessage;
  vm.showValidateBtn = showValidateBtn;
  vm.getValidateText = getValidateText;
  vm.shouldShowPrimaryOption = shouldShowPrimaryOption;
  vm.getIsRegistrationTypeDisabled = getIsRegistrationTypeDisabled;
  vm.getRegistrationDataQa = getRegistrationDataQa;

  /** The maximum course/program name length, enforced by the backend */
  const MAX_NAME_LENGTH = 340;
  vm.hasLmsCourse = hasLmsCourse;
  vm.hasLxpCourse = hasLxpCourse;
  vm.isMsCalendarDisabled = isMsCalendarDisabled;
  vm.msCalendar = true;

  /** Array of required fields with their values */
  vm.requiredFields = {};

  vm.config = config;

  const profileSettings = InstitutionsManager.institution.profileSettings.orgLevel.filter(
    profileSetting => profileSetting.isIntegrated || profileSetting.isCsvManaged,
  );

  function initialize($scope) {
    InstitutionsManager.requestCourses(true);

    const EntitlementsModal = () => {
      const [show, setShow] = useState(false);

      useEffect(() => {
        vm.reactConfigureEntitlements = () => setShow(true);
      }, []);

      const closeModal = () => setShow(false);

      const handleEntitlementsModalClose = (entitlements) => {
        closeModal();
        $timeout(() => {
          this.courseDraft.entitlements = entitlements;
          this.courseForm.$setDirty();
        });
      };

      return (
        <ConfigureEntitlementsModal
          show={show}
          onCancel={closeModal}
          profileSettings={profileSettings}
          onClose={handleEntitlementsModalClose}
          entitlements={this.courseDraft.entitlements}
        />
      );
    };

    $scope.EntitlementsModal = EntitlementsModal;

    const CourseFormHeader = () => {
      this.updateFormHeader = useForceUpdate();

      return (
        <CourseHomeHeader
          course={this.courseDraft}
          institution={InstitutionsManager.institution}
          setHeaderDraft={this.setHeaderDraft.bind(this)}
        />
      );
    };

    $scope.CourseFormHeader = CourseFormHeader;
  }
  // When we get a 'catalog id taken' error from backend, on change, we need to set the validity of the form to true to remove the error message.
  function onChangeCatalogId() {
    if (!this.courseForm.catalogId.$valid) {
      this.courseForm.catalogId.$setValidity('catalogId', true, this.courseForm.catalogId);
      this.courseForm.catalogId.$showValidationMessage = false;
    } else {
      this.setDirty();
    }
  }
  function handleErrorResponse(response) {
    if (response.status === 403 && response.data.error?.code === 'error.catalog_id_taken') {
      // manually triggering catalog id field validation
      this.courseForm.catalogId.$setValidity('catalogId', false, this.courseForm.catalogId);
      this.courseForm.catalogId.$showValidationMessage = true;
    } else {
      AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
    }
  }
  function baseSaveCourse() {
    const { course } = CoursesManager;

    if (course.typeOfRegistration === CourseModel.FREE_ENROLLMENT || course.typeOfRegistration === CourseModel.FREE_ENROLLMENT_IN_INSTITUTION) {
      course.rate = undefined;
      course.beingOffered = undefined;
      course.paymentsStartAt = undefined;
      course.paymentsEndAt = undefined;
    } else if (course.typeOfRegistration === CourseModel.FREE_AND_PAID_CERTIFICATE_ENROLLMENT) {
      course.paymentsStartAt = undefined;
      course.paymentsEndAt = undefined;
    } else if (course.typeOfRegistration === CourseModel.CLOSED_ENROLLMENT) {
      course.rate = undefined;
      course.beingOffered = undefined;
      course.paymentsStartAt = undefined;
      course.paymentsEndAt = undefined;
      course.registrationCloseDate = undefined;
    }

    if (!nvUtil.isValidHex(course.headerColor)) {
      course.headerColor = null;
    }

    // Clear the duration type that is set to a default during initialization if
    // the user didn't end up inputting a duration value
    if (!course.duration) {
      course.durationType = null;
    }
    return course.save().then(
      (returnedCourse) => {
        vm.errors.catalogIdTaken = false;
        return $q.resolve(returnedCourse);
      },
      (response) => {
        if (response.status === 403 && response.data.error?.code === 'error.catalog_id_taken') {
          vm.errors.catalogIdTaken = true;
          $('#main-panel').scrollTop(0);
        }
        return $q.reject(response);
      },
    );
  }
  /**
   * Checks if there are any required fields that have not been fulfilled
   * @returns {boolean}
   */
  function pendingRequiredFields() {
    let pendingField = false;
    const valuesArray = Object.values(this.requiredFields);
    pendingField = valuesArray.some(element => (typeof element === 'undefined' || element === null));
    return pendingField;
  }
  /**
   * Before allowing save button with $setDirty(), it's necesary to check if
   * all the required fields have been completed
   */
  function setDirty() {
    // Only check if requiredFields are completed in a course type offering since program does not make use of requiredFields
    if (!this.courseDraft.isProgram && this.pendingRequiredFields()) {
      this.courseForm.$setPristine();
    } else {
      this.courseForm.$setDirty();
    }
  }

  function setParentCourseId() {
    if (!this.videoSharing) {
      this.courseDraft.parentCourseId = this.courseDraft.id;
    }
    this.setDirty();
  }

  function removeCoverPhoto(file) {
    const course = this.courseDraft ? this.courseDraft : CoursesManager.course;
    course.coverPhoto = undefined;
    course.coverPhotoUrl = undefined;
    this.setDirty();
  }

  function usedForChanged() {
    if (this.courseDraft?.usedFor === CourseModel.USED_FOR_DEMO && this.hasSumTotalIntegration()) {
      const defaultSumTotal = { lmsActivityCode: '', lmsActivityId: '' };
      _.extend(this.courseDraft, defaultSumTotal);
      _.extend(this.courseDraft.sumTotalIntegration, defaultSumTotal);
    }

    if (this.courseDraft.usedFor === CourseModel.USED_FOR_PRIMARY) {
      this.courseDraft.typeOfRegistration = CourseModel.CLOSED_ENROLLMENT;
    } else {
      this.courseDraft.typeOfRegistration = vm.defaultTypeOfRegistration();
    }

    this.setDirty();
  }

  function defaultTypeOfRegistration() {
    if (_.contains(InstitutionsManager.institution.courseEnrollmentTypes, '3')) {
      return 3;
    }
    return parseInt(InstitutionsManager.institution.courseEnrollmentTypes[0], 10);
  }

  function courseTypeChanged() {
    if (this.courseDraft.isProgram) {
      if (this.courseDraft.typeOfRegistration === CourseModel.OPEN_BASED_ON_ENTITLEMENTS) {
        this.courseDraft.typeOfRegistration = CourseModel.FREE_ENROLLMENT;
      }

      if (this.courseDraft.usedFor === CourseModel.USED_FOR_PRIMARY) {
        this.courseDraft.usedFor = CourseModel.USED_FOR_DEMO;
      }
    }

    // Update form state on each change of offering type
    this.setDirty();
  }

  function isRegistrationOptionAvailable(typeOfRegistration) {
    const course = this.courseDraft ? this.courseDraft : CoursesManager.course;
    const optionProvidedByAdmin = _.includes(vm.institution.courseEnrollmentTypes, typeOfRegistration.toString());

    if (optionProvidedByAdmin || (typeOfRegistration === CourseModel.CLOSED_ENROLLMENT && course.usedFor === CourseModel.USED_FOR_PRIMARY)) {
      if (course.isProgram) {
        return !_.includes([
          CourseModel.FREE_ENROLLMENT_IN_INSTITUTION,
          CourseModel.OPEN_BASED_ON_ENTITLEMENTS,
        ], typeOfRegistration.toString());
      }

      return true;
    }

    return false;
  }

  function getIsRegistrationTypeDisabled(typeOfRegistration) {
    if (this.courseDraft.usedFor === CourseModel.USED_FOR_PRIMARY) {
      return typeOfRegistration !== CourseModel.CLOSED_ENROLLMENT;
    }

    if (!vm.CurrentUserManager.user.admin && !_.includes(vm.institution.courseEnrollmentTypes, CoursesManager.course.typeOfRegistration.toString())) {
      return typeOfRegistration === CoursesManager.course.typeOfRegistration;
    }

    return false;
  }

  function getRegistrationDataQa(typeOfRegistration) {
    return ({
      [CourseModel.FREE_ENROLLMENT]: config.pendo.courseForm.registrationOpen,
      [CourseModel.CLOSED_ENROLLMENT]: config.pendo.courseForm.registrationClosed,
      [CourseModel.FREE_ENROLLMENT_IN_INSTITUTION]: config.pendo.courseForm.registrationOrgOpen,
      [CourseModel.OPEN_BASED_ON_ENTITLEMENTS]: config.pendo.courseForm.registrationEntitlements,
      // CourseModel.PAID_ENROLLMENT,
      // CourseModel.FREE_AND_PAID_CERTIFICATE_ENROLLMENT,
    })[typeOfRegistration];
  }

  function hasLmsCourse() {
    return !_.isEmpty(InstitutionsManager.institution?.lmsConnectionSettings) && CoursesManager.course?.lmsCourse;
  }

  function hasLxpCourse() {
    return !_.isEmpty(InstitutionsManager.institution?.degreedSettings) && CoursesManager.course?.degreedCourseId;
  }

  function isMsCalendarDisabled() {
    const result = this.courseDraft.isProgram || this.courseDraft.usedFor === CourseModel.USED_FOR_DEMO;
    if (result) {
      this.courseDraft.calendarEventsEnabled = false;
    } else {
      this.courseDraft.calendarEventsEnabled = this.msCalendar;
    }
    return result;
  }

  function isCalendarActiveFromInstitution() {
    return !this.courseDraft.isProgram && !this.courseDraft.isSelfPaced && this.institution.hasMicrosoftCalendarIntegration;
  }

  function hasSumTotalIntegration() {
    return vm.institution?.lmsConnectionSettings?.lms === providerTypes.SUMTOTAL;
  }

  function hasEdcastIntegration() {
    return !!this.courseDraft.edcastCourse;
  }

  function getEdcastLastSyncUp() {
    if (!this.courseDraft.edcastCourse.lastSyncUpAt) return '';
    const date = vm.moment(this.courseDraft.edcastCourse.lastSyncUpAt);
    return date.format('MOMENT.MONTH_DAY_YEAR_TIME');
  }

  function onActivityCodeChange() {
    const isEmpty = !this.courseDraft.lmsActivityCode;
    const isDifferent = getSumTotalProp.call(this, 'lmsActivityCode') !== this.courseDraft.lmsActivityCode;
    this.courseDraft.sumTotalIntegration.editing = !isEmpty && isDifferent;
  }

  function validateActivityCode() {
    const { lmsActivityCode } = this.courseDraft;
    const validatingActivity = { lmsActivityCode, validating: true, editing: false, validationError: false };
    let lmsActivityId = null;
    _.extend(this.courseDraft.sumTotalIntegration, validatingActivity);
    InstitutionsManager.validateLmsActivity({ lmsActivityCode })
      .then(({ result }) => {
        ({ lmsActivityId } = result);
      }).catch(() => {
        this.courseDraft.sumTotalIntegration.validationError = true;
      }).finally(() => {
        this.courseDraft.sumTotalIntegration.validating = false;
        this.courseDraft.sumTotalIntegration.lmsActivityId = lmsActivityId;
        this.courseDraft.lmsActivityId = lmsActivityId;
      });
  }

  function hasBeenReleased() {
    return this.courseDraft.released;
  }

  function getSumTotalProp(props) {
    const { sumTotalIntegration = {} } = this.courseDraft;
    return (typeof props === 'object')
      ? props.map((prop) => sumTotalIntegration[prop])
      : sumTotalIntegration[props];
  }

  function disableActivityCode() {
    const hasActivityId = !!this.courseDraft?.lmsCourse?.externalCourseId;
    const hasActivityIdAndReleased = hasActivityId && this.hasBeenReleased();
    const isDemo = this.courseDraft.usedFor === CourseModel.USED_FOR_DEMO;
    return hasActivityIdAndReleased || this.courseDraft.isProgram || isDemo;
  }

  function disableValidateButton() {
    const hasActivityId = !!this.courseDraft?.lmsCourse?.externalCourseId;
    const hasActivityIdAndReleased = hasActivityId && this.hasBeenReleased();
    const editing = getSumTotalProp.call(this, 'editing');
    return !editing || hasActivityIdAndReleased;
  }

  function showValidationError() {
    const [validationError, editing, validating] = getSumTotalProp.call(this, ['validationError', 'editing', 'validating']);
    return validationError && !editing && !validating && this.courseDraft.lmsActivityCode;
  }

  function showSuccessMessage() {
    const [editing, validating] = getSumTotalProp.call(this, ['editing', 'validating']);
    return !editing && !validating && this.courseDraft.lmsActivityId;
  }

  function showValidateBtn() {
    const [validationError, editing] = getSumTotalProp.call(this, ['validationError', 'editing']);
    return !validationError || editing || !this.courseDraft.lmsActivityCode;
  }

  function isActivityValidated() {
    const [validationError, validating] = getSumTotalProp.call(this, ['validationError', 'validating']);
    return this.disableValidateButton() && !validationError && !validating;
  }

  function getValidateText() {
    const validating = getSumTotalProp.call(this, 'validating');
    return `COURSES.FORM.SUMTOTAL_INTEGRATION.${validating ? 'VALIDATING' : 'VALIDATE'}`;
  }

  function showSubmitButton() {
    const isValidActivity = this.hasSumTotalIntegration() ? this.isActivityValidated() : true;
    return this.courseForm.$valid && this.courseForm.$dirty && !this.savingCourse && this.isNameValid && isValidActivity;
  }

  function setCourseDurationUnit(unit) {
    // We must specifically use `this` here so that the value updates on the
    // implementing controllers
    this.courseDraft.durationType = unit;
    this.setDirty();
  }

  function setTags(newTags) {
    this.courseDraft.skill_tags = newTags.map(tag => tag.id).toString();
    $timeout(() => {
      this.setDirty();
    });
  }
  /**
   * Saves the set of metadata values in the course_metadata field.
   * @param newTags
   */
  function setMetadataTags(newTags) {
    this.courseDraft.courseMetadata = newTags;
    $timeout(() => {
      this.setDirty();
    });
  }
  /**
   * If some field has the property 'required' it will be added / updated when
   * the page is loaded for the fisrt time or when the value is updated
   * @param {string} inputName
   * @param {number} value
   */
  function setRequiredField(inputName, value) {
    this.requiredFields[inputName] = value;
  }

  function removeRequiredField(inputName) {
    this.requiredFields = omit(this.requiredFields, [inputName]);
  }

  function nameIsValid(name) {
    return (name.length > 0 && name.length <= MAX_NAME_LENGTH) ?? false;
  }

  function setHeaderDraft(headerDraft) {
    _.extend(this.courseDraft, headerDraft);

    $timeout(() => {
      this.setDirty();
    });

    if (this.setNameValid && _.has(headerDraft, 'name')) {
      this.setNameValid(nameIsValid(headerDraft.name));
    }
  }

  /** We set this as a separate function instead of executing this during this file's initialize(), because this page uses angular.extend() to emulate subclassing this base-ctrl.
   * Any use of `this` or `vm` in this function will refer to the base-ctrl and _not_ the more specific class (like edit-course-ctrl) because angular.extend() copies props to the target
   * instead of modifying the source object */
  function renderReactCheckboxDropdown() {
    // list of skill tag object s
    const coursetags = CoursesManager.course.skillTags || [];
    render(
      <Provider store={store}>
        <AngularNvCheckboxDropdown
          courseTags={coursetags}
        // eslint-disable-next-line react/jsx-no-bind
          setTags={(items) => this.setTags(items)}
        />
      </Provider>,
      document.querySelector('#react-tags-dropdown'),
    );
  }

  /** We set this as a separate function instead of executing this during this file's initialize(), because this page uses angular.extend() to emulate subclassing this base-ctrl.
   * Any use of `this` or `vm` in this function will refer to the base-ctrl and _not_ the more specific class (like edit-course-ctrl) because angular.extend() copies props to the target
   * instead of modifying the source object */
  function renderReactDropdown() {
    const ReactDropdown = () => {
      const dispatch = useDispatch();
      const courseMetadataTags = CoursesManager.course.courseMetadata || [];
      const styles = css(`
        .tag-row {
          margin-top: ${threeQuartersSpacing}px;
        }
        span {
          padding: 0 ${quarterSpacing}px;
        }
        .bs4-dropdown-menu {
          width: 100%;
        }
      `);
      const [fields, setFields] = useState(InstitutionsManager.institution.metadataFields);
      useEffect(() => {
        dispatch(getMetadataFilters({ callback: setFields }));
      }, []);
      return (
        <div css={styles}>
          { fields.map((field) => (
            <div className='tag-row'>
              <label className='gray-label preserve-space'>
                { field.name }
                {
                  field.required && (
                  <span className='text-danger'>
                    { t.COURSES.FORM.ADDITIONAL_METADATA.REQUIRED() }
                  </span>
                  )
                }
              </label>
              <AngularNvDropdown
                field={field}
                setRequiredField={this.setRequiredField.bind(this)}
                removeRequiredField={this.removeRequiredField.bind(this)}
                courseMetadataTags={courseMetadataTags}
                setMetadataTags={this.setMetadataTags.bind(this)}
              />
            </div>
          ))}
        </div>
      );
    };
    render(
      <Provider store={store}>
        <ReactDropdown />
      </Provider>,
      document.querySelector('#react-metadata-tags-dropdown'),
    );
  }

  function getEntitlementProfileSettingName(entitlement) {
    return profileSettings.find((profileSetting) => (profileSetting.id === entitlement.profileSettingId))?.name;
  }

  function getEntitlementValuesText(entitlement) {
    return i18nJoin(entitlement.values, JoinMode.OR);
  }

  function shouldShowPrimaryOption() {
    return (
      CurrentPermissionsManager.hasOrgAdminPermissions()
      || CurrentPermissionsManager.hasCourseManagerPermissions()
    ) && !this.courseDraft.isProgram && !this.courseDraft.isCohort && (this.isEdit ? !this.courseDraft.isPrimary : true);
  }
}

/**
 * A react component that wraps <NvCheckboxDropdown/>, used for selecting institution tags for this course.
 * @param availableTags The list of tags available on this institution. A NvCheckboxDropdownItem[]
 * @param courseTags The tags already set on this course. A list of strings.
 * @param setTags A callback that updates the AngularJS model of which tags are currently selected.
 */
const AngularNvCheckboxDropdown = ({ courseTags, setTags }) => {
  useEffect(() => {
    dispatch(getAllSkillTags({ institutionId: currentInstitutionId }));
  }, []);

  const dispatch = useAppDispatch();
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );

  // const defaultTags = courseTags ? [...courseTags] : [];
  const allTags = Object.values(useSelector((state) => state.models.skillTags)).map(
    (tag, i) => ({
      id: tag.id,
      name: tag.name,
      label: tag.name,
    }),
  ).sort(((a, b) => a.name.localeCompare(b.name)));

  const selectedTags = courseTags ? [...courseTags] : [];
  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      tagsDropdown: selectedTags,
    },
  });

  // Update the current list of selected tags, and fire the tag selected callback
  const onTagClicked = (selecteditem, index, values) => {
    setTags(values);
  };

  const prefixes = {};

  bs4Components.forEach((component) => {
    prefixes[component] = `bs4-${component}`;
  });

  return (
    <ThemeProvider prefixes={prefixes}>
      <FormProvider {...methods}>
        <form>
          <NvCheckboxDropdown
            name='tagsDropdown'
            items={allTags}
            onChange={(item, index, values) => onTagClicked(item, index, values)}
            dropdownProps={{
              flip: false,
            }}
            prompt={t.INSTITUTIONS.SKILL_TAGS.COURSE_PROMPT()}
            noItemsText={t.INSTITUTIONS.SKILL_TAGS.COURSE_NO_TAGS()}
          />
        </form>
      </FormProvider>
    </ThemeProvider>
  );
};

/**
 * A react component that wraps <NvDropdown/>, used for selecting metadata tags for this course.
 * @param field The info of a metadata field, included metadata value tags available on this institution.
 * @param courseMetadataTags The metadata tags already set on this course. A list of Objects.
 * @param setMetadataTags A callback that updates the AngularJS model of which tags are currently selected.
 */
const AngularNvDropdown = ({ field, courseMetadataTags, setRequiredField, removeRequiredField, setMetadataTags }) => {
  const { required } = field;
  const nvDropDownName = `metadataTagsDropdown_${field.id}`;
  const defaultFormValues = {};
  const courseMetadataTag = courseMetadataTags.find((courseField) => courseField.metadataFieldId === field.id);
  const defaultSelected = field.metadataValues.findIndex(value => (value.id === courseMetadataTag?.metadataValueId));
  const [selectedValueIndex, setSelectedValueIndex] = useState(defaultSelected);
  const [show, setShow] = useState(false);
  if (required) {
    setRequiredField(nvDropDownName, courseMetadataTag?.metadataValueId);
  } else {
    removeRequiredField(nvDropDownName);
  }

  const availableTags = field.metadataValues?.map((value, index) => ({
    type: 'text',
    text: value.value,
    callback: () => onTagClicked(value, index),
    textClassName: 'ellipsis',
  }));
  availableTags.unshift({
    type: 'text',
    text: t.COURSES.FORM.ADDITIONAL_METADATA.PLEASE_SELECT_METADATA_TAG(),
    resetItem: true,
    callback: () => onTagClicked(),
    textClassName: 'text-gray-2',
  });
  defaultFormValues[nvDropDownName] = availableTags;
  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultFormValues,
  });
  /**
   * Returns a metadata object that matches with a given fieldId or with a fieldId from another
   * metadata object.
   * @param {Object} metadataTag
   * @param {number} fieldId
   * @returns {Object}
   */
  const getExistingTag = (metadataTag = null, fieldId) => {
    let existentTag;
    if (metadataTag) {
      existentTag = courseMetadataTags.find(courseMetadata => courseMetadata.metadataFieldId === metadataTag.metadataFieldId);
    } else {
      existentTag = courseMetadataTags.find(tag => tag.metadataFieldId === fieldId);
    }
    return existentTag;
  };
  /**
   * Update the current list of selected metadata tags, and fire the metadata tag selected callback
   * @param {Object} item
   */
  const onTagClicked = (item = null, index = -1) => {
    let itemId;
    setSelectedValueIndex(index);
    if (item) {
      const existentTag = getExistingTag(courseMetadataTag, field.id);
      if (existentTag) {
        existentTag.metadataValueId = item.id;
      } else {
        courseMetadataTags.push({
          metadataValueId: item.id,
          metadataFieldId: field.id,
        });
      }
      itemId = item.id;
    } else {
      const fieldIndex = courseMetadataTags.findIndex(tag => (field.id === tag.metadataFieldId));
      if (fieldIndex !== -1) {
        courseMetadataTags.splice(fieldIndex, 1);
      }
    }
    if (required) {
      setRequiredField(nvDropDownName, itemId);
    }
    setMetadataTags(courseMetadataTags);
  };

  const prefixes = {};

  bs4Components.forEach((component) => {
    prefixes[component] = `bs4-${component}`;
  });

  const style = css`
    &.nv-custom-dropdown {
      display: inline-block;
      width: 100%;
      .title {
        width: 100%;
        padding: ${halfSpacing}px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .bs4-dropdown {
        border: 1px solid ${gray3};
        border-radius: ${quarterSpacing}px;
      }
    }
  `;

  const getTitle = () => (
    <div className='title'>
      <div className={`ellipsis${selectedValueIndex < 0 ? ' text-gray-3' : ''}`}>{availableTags[selectedValueIndex + 1].text}</div>
      <div className='icon text-xs icon-dropdown-arrow text-gray-3' />
    </div>
  );

  return (
    <ThemeProvider prefixes={prefixes}>
      <FormProvider {...methods}>
        <form>
          <div css={style} className='nv-custom-dropdown'>
            <Controller
              name={nvDropDownName}
              render={() => (
                <NvDropdown
                  items={availableTags}
                  buttonStyle={NvDropdownButtonStyle.CUSTOM}
                  showSelectedIndicator
                  initialIndex={defaultSelected + 1}
                  customTarget={() => getTitle()}
                  onToggle={setShow}
                  show={show}
                />
              )}
            />
          </div>
        </form>
      </FormProvider>
    </ThemeProvider>
  );
};
