import { css } from '@emotion/react';
import { useContext, useState, useRef, useEffect, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { primary } from 'styles/global_defaults/colors';
import { standardSpacing, largeSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import NvExpandableSearchBar from 'shared/components/nv-expandable-search-bar';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { getCourseAliases } from 'redux/selectors/course';
import NvPopover from 'shared/components/nv-popover';
import { NvNoResults } from 'shared/components/nv-no-results-panel';
import { VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import LoadingPlaceholder from 'communications/course_communications/components/loading-placeholder';
import { debounce } from 'underscore';
import { useAppDispatch } from 'redux/store';
import { searchScenarios } from 'redux/actions/video-practice';
import { VideoPracticeOptionsModalContext } from './video-practice-options-modal';
import VideoPracticeScenarioCard from './video-practice-scenario-card';
import { config } from '../../../../config/config.json';

type SelectVideoPracticeOptionProps = {
  availableScenarios: VideoPracticeScenario[];
  totalResultsCount: number;
  onCreateNew: (e: any) => void;
  onSubmit: (e: any) => void;
  onViewDetails: (e: any) => void;
  availableScenariosLoading?: boolean
};

const SelectVideoPracticeOption = ({
  availableScenarios,
  totalResultsCount,
  onCreateNew,
  onSubmit,
  onViewDetails,
  availableScenariosLoading,
}: SelectVideoPracticeOptionProps) => {
  const styles = css`
    height: calc(100vh - 160px);
    overflow-y: auto;
    margin: -${largeSpacing}px -${tripleSpacing}px -${standardSpacing}px;

    .create {
      border: 1px solid ${primary};
      border-radius: 10px;
    }

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .white-space-nowrap {
      white-space: nowrap;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  `;

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const { catalogId, setTitle } = useContext(VideoPracticeOptionsModalContext);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scenarioList, setScenarioList] = useState([]);
  const [resultsCount, setResultsCount] = useState(totalResultsCount);
  const [pageIndex, setPageIndex] = useState(1);
  const [query, setQuery] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!availableScenariosLoading) {
      setScenarioList(availableScenarios);
    }
  }, [availableScenarios, availableScenariosLoading]);

  useEffect(() => {
    setTitle(t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.TITLE());
  }, [setTitle]);

  const clearSearchResults = () => {
    setScenarioList(availableScenarios);
    setResultsCount(totalResultsCount);
    setPageIndex(1);
    setQuery('');
    setIsSearchOpen(!isSearchOpen);
    setShowResults(false);
    inputRef.current.value = '';
  };

  const handleSearch = (str) => {
    if (str !== query) {
      setPageIndex(1);
      setScenarioList([]);
      setShowResults(true);
      setQuery(str);
    }
  };

  useEffect(() => {
    if ((query || (pageIndex !== 1 && scenarioList.length < resultsCount)) && availableScenarios.length > 0) {
      setIsLoading(true);
      dispatch(searchScenarios({
        searchQuery: query,
        pageIndex,
        callback: (res) => {
          setScenarioList([...scenarioList, ...res.scenarios]);
          setResultsCount(res.resultsCount);
          setIsLoading(false);
        },
      }));
    }
  }, [pageIndex, query, dispatch]);

  const handleScroll = debounce(() => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const pctScrolled = (scrollTop / (scrollHeight - clientHeight));
    if (!isLoading && scenarioList.length < resultsCount && pctScrolled > 0.8) {
      setPageIndex(pageIndex + 1);
    }
  }, 100);

  return (
    <div css={styles} ref={listRef} onScroll={handleScroll}>
      <div className='d-flex flex-column align-items-center mx-4 my-2'>
        <div className='create w-100 d-flex justify-content-between p-4 my-4'>
          <div className='d-flex align-items-center'>
            <div className='text-xl condensed font-weight-bold'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.CREATE_A_NEW_PRACTICE_SCENARIO()}</div>
            <NvPopover showOnHover className='ml-1 mt-1' content={<div>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.CREATE_NEW_INFO({ ...aliases.courseAliases, ...aliases.learnersAliases })}</div>}>
              <i className='icon icon-xss-smallest icon-info text-primary' aria-hidden='true' />
            </NvPopover>
          </div>
          <Button variant='primary' onClick={onCreateNew} data-qa={config.pendo.practiceActivity.create.createANewPracticeScenario}>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.CREATE_NEW()}</Button>
        </div>
        <div>— {t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.OR()} —</div>
        <div className='mt-1 w-100'>
          <div />
          <div className='d-flex justify-content-between'>
            {showResults
              ? (
                <div className='d-flex align-items-baseline'>
                  <div className='text-large-regular'>{t.SEARCH.SEARCH_RESULTS()}</div>
                  <div className='ml-2 font-weight-bold text-small text-primary cursor-pointer' onClick={clearSearchResults}>{t.SEARCH.CLEAR()}</div>
                </div>
              ) : <div className='text-large-regular'>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.SELECT_FROM_EXISTING()}</div>}
            <NvExpandableSearchBar onSearch={handleSearch} placeholder={t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.SEARCH_PLACEHOLDER()} isExpanded={isSearchOpen} onExpanded={clearSearchResults} ref={inputRef} />
          </div>
          <hr />
          <div>
            {scenarioList.map((scenario: VideoPracticeScenario) => (
              <Fragment key={scenario.id}>
                <div className='mt-5 mx-5'>
                  <div className='white-space-nowrap d-flex justify-content-between align-items-center'>
                    <div className='title text-xl condensed font-weight-bold'>{scenario.title}</div>
                    <div className='text-primary mr-auto px-2 cursor-pointer' onClick={() => onViewDetails(scenario)}>{t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.VIEW_DETAILS()}</div>
                    <Button variant='primary' onClick={() => onSubmit(scenario)} data-qa={config.pendo.practiceActivity.create.selectScenario}>
                      {scenario.reusable === 'reusable' || scenario.createdInCourse?.catalogId === catalogId
                        ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.ADD_TO_MY_LESSON()
                        : t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.DUPLICATE_SCENARIO_AND_ADD()}
                    </Button>
                  </div>
                  <VideoPracticeScenarioCard scenario={scenario} showSubmissionPrivacySetting />
                </div>
                <hr />
              </Fragment>
            ))}
            {!isLoading && !availableScenariosLoading && scenarioList.length === 0 && (
              <NvNoResults
                action={() => {}}
                hideClearSearch
                noResultsIcon='media-practice'
                noResultsText={availableScenarios?.length === 0 ? t.LECTURE_PAGES.COMPONENTS.VIDEO_PRACTICE.SELECT.NO_EXISTING_SCENARIOS() : undefined}
              />
            )}
            {(isLoading || availableScenariosLoading) && <LoadingPlaceholder />}
            {!isLoading && !availableScenariosLoading && !!resultsCount && scenarioList.length === resultsCount && <div className='text-gray-3 text-small ml-3'>{t.SEARCH.ALL_LOADED()}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectVideoPracticeOption;
