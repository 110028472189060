import { css } from '@emotion/react';
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react';
import { isEmpty } from 'underscore';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import t from 'react-translate';
import Button from 'react-bootstrap/Button';
import { BaseUser } from 'redux/schemas/models/my-account';
import NvUserAvatar from 'components/nv-user-avatar';
import { gray3, gray6, gray7, hexToRgbaString } from 'styles/global_defaults/colors';
import { standardSpacing, halfSpacing, threeQuartersSpacing, doubleSpacing } from 'styles/global_defaults/scaffolding';
import { handheld, notHandheld } from 'styles/global_defaults/media-queries';
import { NvFroala } from 'froala/components/nv-froala';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import { AngularServicesContext } from 'react-app';
import { createComment, updateComment, removeNewCommentId } from 'redux/actions/comments';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { getCurrentUser } from 'redux/selectors/users';
import { getCourseAliases } from 'redux/selectors/course';
import { CourseAliases } from 'redux/schemas/models/course';
import { RootState } from 'redux/schemas';
import ClickableContainer from 'components/clickable-container';
import { Comment } from 'redux/schemas/models/comment';
import { parseMentionables } from 'froala/helpers/tribute-options';
import { useAppDispatch } from 'redux/store';
import usePreventLecturePageNavigation from 'lecture_pages/hooks/use-prevent-lecture-page-navigation';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { config } from '../../../../config/pendo.config.json';
import { TeamDiscussionContext } from './team-discussion';
import { LecturePageMode } from '..';

interface DiscussionCommentFormProps {
  edit?: boolean;
  commentId?: number;
  showFroala?: number | boolean;
  afterSubmit?: Function;
  onCancel?: Function;
  mentionableMembers?: BaseUser[];
}

type DiscussionCommentForm = {
  comment: string;
};

const DiscussionCommentForm = (props: DiscussionCommentFormProps) => {
  const styles = css`
    display: flex;

    .new-comment-wrapper {
      flex: 1;

      ${notHandheld(css`
        margin-left: ${halfSpacing}px;
      `)};
    }

    ${handheld(css`
      .comment-avatar {
        margin-bottom: ${standardSpacing}px;
      }
    `)};

    ${notHandheld(css`
      .comment-avatar {
        width: 80px;
        padding-top: ${standardSpacing}px;
      }
    `)};

    .new-comment-body {
      position: relative;
      background-color: ${hexToRgbaString(gray6, 0.6)};
      padding: ${standardSpacing}px;
      border-left: 1px solid ${gray6};
      border-right: 1px solid ${gray6};
      border-bottom: 1px solid ${gray6};

      .fake-text-input {
        cursor: pointer;
        margin-bottom: 0;
        background: white;
        border: none;
        height: auto;
        color: ${gray3};
        padding: ${threeQuartersSpacing}px ${standardSpacing}px;
      }

      .comment-froala {
        background-color: white;
        padding: ${standardSpacing}px;
      }

      ${handheld(css`
        .text-body {
          margin-top: ${standardSpacing}px;
        }

        .fake-text-input {
          position: absolute;
          top: ${standardSpacing}px;
          right: ${standardSpacing}px;
          max-width: calc(100% - 90px);
          background: ${gray7};
          padding: ${halfSpacing}px;
        }
        & {
          padding-bottom: ${doubleSpacing}px;
        }
      `)};
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: ${standardSpacing}px;

      button.cancel {
        margin-right: ${standardSpacing}px;
      }
    }

    .buttons-xs {
      margin-top: ${standardSpacing}px;

      button {
        max-width: 480px;
        margin: 0 auto;

        &[type=submit] {
          margin-top: ${halfSpacing}px;
        }
      }
    }
  `;

  const froalaRef = useRef(null);
  const stateChangeRef = useRef(null);

  const dispatch = useAppDispatch();

  const { postId, mode, currentLecture } = useContext(TeamDiscussionContext);
  const { $scope, $uibModal, TimelinesManager } = useContext(AngularServicesContext);

  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentUser = useSelector((state) => getCurrentUser(state));
  const comment = useSelector((state) => state.models.comments[props.commentId]);
  const post = useSelector((state) => state.models.posts[postId]);

  const [loading, setLoading] = useState(false);
  const [showFroala, setShowFroala] = useState(false);

  const timelineService = useTimelineService();

  const validationSchema = yup.object().shape({
    comment: yup.string().required(),
  });

  const methods = useForm<DiscussionCommentForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      comment: comment?.body ?? '',
    },
  });
  const { handleSubmit, formState, getValues, watch } = methods;
  const commentBody = watch('comment');
  const { isValid } = formState;

  useEffect(() => {
    setShowFroala(!!props.showFroala);
    if (props.showFroala) {
      setTimeout(() => {
        froalaRef.current.focus();
      });
    }
  }, [props.showFroala]);

  const checkUnsavedChanges = () => {
    if (isEmpty(commentBody) && isEmpty(comment?.body)) {
      return false;
    }

    return commentBody !== comment?.body;
  };

  const checkUnsavedChangesRef = React.useRef<Function>();
  checkUnsavedChangesRef.current = checkUnsavedChanges;

  useEffect(() => {
    stateChangeRef.current = $scope.StateManager.registerStateChangeStart(
      checkUnsavedChangesRef.current,
      'shared/templates/modal-navigate-away.html',
      'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
    );

    const { current: deregister } = stateChangeRef;

    return () => {
      deregister();
    };
  }, [$scope.StateManager]);

  usePreventLecturePageNavigation(checkUnsavedChanges);

  const onComment = (data: DiscussionCommentForm, e) => {
    setLoading(true);
    if (props.edit) {
      dispatch(updateComment({
        commentId: props.commentId,
        catalogId,
        body: data.comment,
        mentionedIds: parseMentionables(data.comment, props.mentionableMembers),
      })).then(() => {
        props?.afterSubmit();
      }).finally(() => setLoading(false));
    } else {
      const previousPointsReceived = post.pointsReceived || 0;

      dispatch(createComment({
        postId,
        catalogId,
        body: data.comment,
        mentionedIds: parseMentionables(data.comment, props.mentionableMembers),
        userId: currentUser.id,
        // Update posts and progress of timeline after new post is fetched
        callback: (newPost) => {
          timelineService.updateTimeline(currentLecture.id);
          TimelinesManager.updateComponentPointsAndProgress(
            currentLecture.id,
            newPost.type,
            newPost.id,
            newPost.pointsReceived,
            newPost.totalPoints,
            newPost.progress,
          );
        },
      })).then(res => {
        const {
          pointsReceived,
          leaderboardPoints,
          leaderboardRank,
          priorLeaderboardRank,
          id,
        } = (res.payload as Comment);

        dispatch(removeNewCommentId({
          commentId: id,
        }));

        if (pointsReceived && pointsReceived > previousPointsReceived) {
          $uibModal.open({
            templateUrl: 'shared/templates/points-modal.html',
            windowClass: 'points-modal',
            controller: 'PointsModalCtrl as vm',
            resolve: {
              pointsReceived,
              leaderboardPoints,
              leaderboardRank,
              priorLeaderboardRank,
              extras: null,
            },
          });
        }

        setShowFroala(false);
        props.afterSubmit?.();
      }).finally(() => setLoading(false));
    }
  };

  const onShowFroala = () => {
    if (mode === LecturePageMode.VIEW) {
      setShowFroala(true);
      setTimeout(() => {
        froalaRef.current?.focus();
      });
    }
  };

  const hasUnsavedChanges = () => {
    const commentValue = getValues().comment;
    let isDirty = commentValue && !!commentValue.length;
    if (props.commentId) {
      isDirty = commentValue !== comment?.body;
    }
    return isDirty && !loading;
  };

  const cancelAndCloseFroala = () => {
    setShowFroala(false);
    props.onCancel?.();
  };

  const onCancel = () => {
    if (hasUnsavedChanges()) {
      dispatch(openConfirmationDialog({
        bodyText: t.FORM.UNSAVED_CHANGES.ARE_YOU_SURE(),
        confirmText: t.FORM.CLOSE_ANYWAY(),
        cancelText: t.FORM.DO_NOT_CLOSE(),
        icon: 'warning',
        onConfirm: cancelAndCloseFroala,
      }));
    } else {
      cancelAndCloseFroala();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onComment)}>
        <div css={styles}>
          {/* Hidden only on xs */}
          <div className='d-none d-md-block'>
            <NvUserAvatar
              user={currentUser}
              size='sm'
              borderType='round'
              className='comment-avatar'
              displayRoleBadge={false}
              pendoTagName={config.pendo.userProfiles.openProfile}
            />
          </div>
          <div className='new-comment-wrapper'>
            <div className='new-comment-body'>
              {/* Visible only on xs */}
              <div className='d-block d-md-none'>
                <NvUserAvatar
                  displayName={showFroala}
                  alignNameRight
                  user={currentUser}
                  size='sm'
                  borderType='round'
                  className='comment-avatar'
                  displayRoleBadge={false}
                  pendoTagName={config.pendo.userProfiles.openProfile}
                />
              </div>
              {showFroala ? (
                <React.Fragment>
                  <NvFroala
                    preset={FroalaViewMode.INLINE}
                    ref={froalaRef}
                    withForm
                    name='comment'
                    className='comment-froala'
                    placeholder={post.numPostsAndComments ? t.FROALA.TEXT_PLACEHOLDER() : t.DISCUSSIONS.LEAD_THE_WAY(aliases.teamAliases)}
                    mentionableUsers={props.mentionableMembers}
                    ariaLabel={`${t.DISCUSSIONS.COMMENT()} ${t.FROALA.BODY_PLACEHOLDER()}`}
                  />
                  {/* Visible only on xs */}
                  <div className='d-block d-md-none'>
                    <div className='buttons-xs'>
                      <Button block variant='secondary' size='sm' className='cancel' onClick={onCancel}>{t.FORM.CANCEL()}</Button>
                      <Button
                        block
                        type='submit'
                        variant='primary'
                        size='sm'
                        disabled={!isValid}
                        pendo-tag-name={config.pendo.teamDiscussion.comment}
                      >
                        {t.DISCUSSIONS.COMMENT()}
                      </Button>
                    </div>
                  </div>
                  {/* Hidden only on xs */}
                  <div className='d-none d-md-block'>
                    <div className='buttons'>
                      <Button variant='secondary' size='sm' className='cancel' onClick={onCancel}>{t.FORM.CANCEL()}</Button>
                      <Button
                        type='submit'
                        variant='primary'
                        size='sm'
                        disabled={!isValid || loading}
                        pendo-tag-name={config.pendo.teamDiscussion.comment}
                      >
                        {t.DISCUSSIONS.COMMENT()}
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <ClickableContainer className='fake-text-input form-control' onClick={onShowFroala}>
                  {post.numPostsAndComments ? t.DISCUSSIONS.POST_COMMENT() : t.DISCUSSIONS.LEAD_THE_WAY(aliases.teamAliases)}
                </ClickableContainer>
              )}
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default DiscussionCommentForm;
