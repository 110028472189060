import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import t from 'react-translate';
import { LoadingSpinnerType } from 'shared/components/loading-spinner';
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';

import { getSubmission } from 'redux/selectors/video-practice';
import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'redux/store';
import { generateInsights } from 'redux/actions/video-practice';
import {
  hasGetUserMedia,
  hasMediaRecorder,
  isSafari,
} from 'recording/services/media-stream';
import { getCurrentUser } from 'redux/selectors/users';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { useQuery } from 'shared/hooks/use-query';
import prodPathReplace from 'shared/prod-path-rewrite';
import { PracticeRoomTab } from 'redux/schemas/models/video-practice';
import { PracticeSubmissionContext } from '../utils';
import FillerWords from './filler-words/filler-words';
import KeyPhrases from './key-phrases/key-phrases';
import { config } from '../../../../../config/pendo.config.json';

const styles = css`
  .loading-image {
    display: block;
    height: 200px;
    margin: ${doubleSpacing}px auto;
  }
`;

const InsightsTab = ({
  setShowPracticeAgainModal,
}: {
  setShowPracticeAgainModal: (show: boolean) => void;
}) => {
  const [{ submissionId, scenarioId }] = useContext(PracticeSubmissionContext);
  const {
    transcriptionInsightsStatus,
    fillerInsightStatus,
  } = useSelector((state) => getSubmission(state, submissionId));
  const browserNotSupported = isSafari || !hasGetUserMedia() || !hasMediaRecorder();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const user = useSelector(getCurrentUser);
  const queryUserId = query?.user;
  const showPracticeAgainCTA = (query.selected === PracticeRoomTab.MY_PRACTICE || Number(queryUserId) === user.id);

  return (
    <div css={styles}>
      {/* Display CTA to generate insights if both insights are in `not_started` state */}
      {transcriptionInsightsStatus === 'not_started' ? (
        <div className='d-flex flex-column text-gray-1 text-small'>
          {t.PRACTICE_ROOM.INSIGHTS.GENERATE_INSIGHTS.INFO()}
          <Button
            variant='primary'
            className='align-self-center mt-6 text-small'
            onClick={() => dispatch(generateInsights({ submissionId, scenarioId }))}
            data-qa={config.pendo.practice.generateInsights}
          >
            {t.PRACTICE_ROOM.INSIGHTS.GENERATE_INSIGHTS.CTA()}
          </Button>
        </div>
      ) : (
      /**
       * Display the loading spinner till the status changes to `completed` in case of keyphrases(transcription),
       * and for filler words both `not_started` and `completed` states are loaded states,
       * and display CTA for `not_started`
       */
        <LoadingWrapper
          loaderType={LoaderType.SPINNER}
          spinnerType={LoadingSpinnerType.CIRCLE}
          isLoaded={transcriptionInsightsStatus === 'completed' || fillerInsightStatus === 'completed'}
          className='insights-loader d-flex flex-column align-items-center'
          loadingInfo={(
            <div className='page-title-small gray-2 mt-6'>
              {t.PRACTICE_ROOM.INSIGHTS.LOADING()}
            </div>
          )}
          loadingInfoNoRender
        >
          <KeyPhrases />
          <div className={`${showPracticeAgainCTA ? 'border-bottom border-gray-4' : ''}`}>
            {fillerInsightStatus !== 'in_progress' && (
              <div className='pb-6'>
                <FillerWords />
              </div>
            )}
            {fillerInsightStatus === 'in_progress' && (
              <div className='pt-6'>
                <p className='text-large-regular text-center font-weight-bolder text-gray-2 animated-paragraph'>{t.INSTANT_INSIGHT.DETERMINE_WORDS()}</p>
                <img
                  className='loading-image'
                  src={prodPathReplace('images/filler-words.gif')}
                  alt={t.INSTANT_INSIGHT.DETERMINE_WORDS()}
                />
              </div>
            )}
          </div>
          <div className='gray-1 text-small pt-6 text-center'>
            {t.PRACTICE_ROOM.INSIGHTS.DESCRIPTION()}
          </div>
          {showPracticeAgainCTA && (
            <div className='practice-again-section'>
              <Button
                variant='primary'
                size='lg'
                className='d-flex align-items-center'
                onClick={() => !browserNotSupported && setShowPracticeAgainModal(true)}
                pendo-tag-name={config.pendo.practice.practiceAgain}
                aria-label={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN()}
              >
                <div className='icon icon-media-practice mr-2' />
                {t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN()}
              </Button>
            </div>
          )}
        </LoadingWrapper>
      )}
    </div>
  );
};
export default InsightsTab;
