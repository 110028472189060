import store from 'redux/store';
import { ActivityKey } from 'redux/schemas/models/activity';
import { setActivityProgress } from 'redux/actions/lecture-components';

/* @ngInject */
export default function TimelineLiveSessionLectureComponentModelService(

  _,
  nvUtil,
) {
  const TimelineLiveSessionLectureComponentModel = function (attributes) {
    const _this = this;
    const base = {
      templateUrl: 'timelines/templates/nv-timeline-live-session.html',
    };

    /** Setting Up Basic Attributes * */
    _.extend(_this, base, attributes);
    // passed lecturePage, lectureSection, course
    preprocess();

    /** Public Functions * */
    _.extend(_this, {
      getStatus,
      isCompleted,
      isMissed,
      isTodoRequiredForCompletion,
      updateComponentPointsAndProgress,
      updateComponentProgress,
      getPayload,
    });

    /** Function Declarations * */
    /* Used Publicly */
    function getStatus() {
      return _this.liveSession.progress;
    }

    function isCompleted() {
      if (_this.liveSession.isTodo && !_this.liveSession.progress) {
        return _this.liveSession.pointsReceived >= _this.liveSession.totalPoints[0];
      }
      return _this.liveSession.progress && (_this.liveSession.progress === 'completed');
    }

    function isMissed() {
      return _this.liveSession.attendeeInfo?.status === 'missed';
    }

    function isTodoRequiredForCompletion() {
      return _this.liveSession.isRequiredForCompletion;
    }

    function updateComponentPointsAndProgress(componentType, componentId, pointsReceived, totalPoints, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'live_session' && _this.liveSession.id === componentId)) {
        if (progress) {
          _this.liveSession.attendeeInfo.status = progress;
        } else {
          _this.liveSession.attendeeInfo.status = 'completed';
        }

        store.dispatch(setActivityProgress({
          pointsReceived,
          activityKey: ActivityKey.LIVE_SESSION,
          activityPayloadId: _this.liveSession.id,
          progress: _this.liveSession.attendeeInfo.status,
        }));

        _this.liveSession.pointsReceived = pointsReceived;
        _this.pointsReceived = pointsReceived;

        if (_.isNumber(totalPoints)) {
          _this.currentTotalPoints = totalPoints;
        } else {
          _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);
        }

        viewPreprocess();

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function updateComponentProgress(componentType, componentId, progress) {
      if ((_this.type === componentType && _this.id === componentId) || (componentType === 'live_session', _this.liveSession.id === componentId)) {
        _this.liveSession.attendeeInfo.status = progress;
        store.dispatch(setActivityProgress({
          progress,
          activityKey: ActivityKey.LIVE_SESSION,
          activityPayloadId: _this.liveSession.id,
        }));

        _this.lecturePage.preprocessSelfAndParentPointData();
      }
    }

    function getPayload() {
      return _.pick(_this.liveSession, ['title']);
    }

    /* Used Privately */
    function preprocess() {
      _this.isTodo = _this.liveSession.isTodo;
      _this.hasStructuralIssues = _this.liveSession.hasStructuralIssues;
      _this.pointsReceived = _this.liveSession.pointsReceived;
      _this.totalPoints = _this.liveSession.totalPoints;
      _this.activityId = _this.liveSession.id;

      _this.currentTotalPoints = nvUtil.getCurrentTotalPoints(_this.totalPoints, _this.lecturePage.releaseDate, _this.course.expiringPointsEnabled);

      viewPreprocess();
    }

    function viewPreprocess() {
      if (isCompleted() || _this.pointsReceived > _this.currentTotalPoints) {
        _this.currentTotalPoints = _this.pointsReceived ? _this.pointsReceived : 0;
      }
      _this.displayTotalPointsOnly = !_this.pointsReceived && _this.currentTotalPoints;
    }
  };

  return TimelineLiveSessionLectureComponentModel;
}
