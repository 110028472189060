import { BookmarkType } from 'redux/schemas/models/bookmark';

/* @ngInject */
export default function NvVideoList(
  $document,
  $stateParams,
  $timeout,
  BookmarkModel,
  ConfirmationOverlays,
  CurrentCourseManager,
  CurrentUserManager,
  InteroperabilityRoutes,
  _,
  config,
  nvUtil,
) {
  return {
    restrict: 'A',
    scope: {
      videoListLectureComponent: '=',
      currentLectureVideo: '=',
      course: '=',
      useExisting: '=?', // paramter to pass to ui-sref so on state change we don't grab from the backend again
      selectLectureVideo: '&?', // for embedded videos, we don't show link, but do a click callback instead
      mobileCardView: '=?',
      showEdit: '=?',
    },
    replace: true,
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      vm.nvUtil = nvUtil;
      vm.config = config;
      vm.$stateParams = $stateParams;
      vm.InteroperabilityRoutes = InteroperabilityRoutes;
      vm.CurrentUserManager = CurrentUserManager;

      const outsideClickHandler = (event) => {
        $timeout(() => {
          const videosList = $('.videos-list');

          if (vm.videoListLectureComponent.reorderMode && videosList.length
                && !videosList[0].contains(event.target) && !$element[0].contains(event.target)) {
            // to prevent first click on dropdown option Reorder to trigger this
            if (vm.videoListLectureComponent.clickedReorder) {
              vm.videoListLectureComponent.clickedReorder = false;
            } else {
              vm.videoListLectureComponent.reorderMode = false;
              _.each(vm.videoListLectureComponent.lectureVideos, (lectureVideo, $index) => {
                lectureVideo.index = $index;
              });
              vm.videoListLectureComponent.save();
            }
          }
        });
      };

      $document.bind('click', outsideClickHandler);
      $scope.$on('$destroy', () => {
        $document.off('click', outsideClickHandler);
      });

      vm.moveUp = (componentIndex) => {
        const newIndex = componentIndex - 1;
        vm.videoListLectureComponent.lectureVideos.splice(newIndex, 0, vm.videoListLectureComponent.lectureVideos.splice(componentIndex, 1)[0]);
      };

      vm.moveDown = (componentIndex) => {
        const newIndex = componentIndex + 1;
        vm.videoListLectureComponent.lectureVideos.splice(newIndex, 0, vm.videoListLectureComponent.lectureVideos.splice(componentIndex, 1)[0]);
      };

      vm.initiateVideoDelete = (videoId) => {
        ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/components/delete-confirmation-overlay.html',
          'AttachModalResolvesToVmCtrl', {
            vmResolves() {
              return {
                lectureComponent: vm.videoListLectureComponent,
                CurrentCourseManager,
              };
            },
          }).result.then(() => {
          vm.videoListLectureComponent.deleteVideoFromComponent(videoId);
        });
      };

      vm.showDownloadColumn = (lectureVideo) => {
        if (lectureVideo?.video) {
          return (lectureVideo.video.canDownload
            && (
              lectureVideo.video.downloadLink
              || lectureVideo.video.highQualityUrl
            )
          )
            || lectureVideo.video.textTranscriptUrl
            || lectureVideo.video.slidesUrl;
        }
        return false;
      };

      vm.createBookmark = (lectureVideo) => {
        BookmarkModel.createBookmark({
          type: BookmarkType.VIDEO,
          catalogId: $stateParams.catalogId,
          componentId: lectureVideo.id,
        })
          .then(bookmark => {
            lectureVideo.bookmarkId = bookmark.id;
          });
      };

      vm.highlightBookmark = (lectureVideo) => {
        BookmarkModel.highlightBookmark(lectureVideo.bookmarkId);
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'lecture_videos/templates/nv-video-list.html',
  };
}
