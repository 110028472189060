import { css } from '@emotion/react';
import t from 'react-translate';
import { useMediaQuery } from 'react-responsive';

import { getEstimateReadout } from 'redux/selectors/lecture-page';

import { halfSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { gray5, primary } from 'styles/global_defaults/colors';
import { screenXsMax } from 'styles/global_defaults/media-queries';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import { PointsDisplay, InnerLectureNavigationItemProps } from './course-outline-navigation';
import { config } from '../../../../config/pendo.config.json';

const cardViewStyles = (isHandheld: boolean) => css`
  &.lecture-card {
    width: 33.33%;
    ${isHandheld && css`
      width: 100%;
    `}
    vertical-align: top;

    .icon-panel {
      height: 160px;
      position: relative;
    }
    .icon-check {
      // Float this check above the black-mask so it's not obscured
      z-index: 1;
    }
  }

  &.unreleased {
    opacity: .5;
  }

  .card-points-container {
    position: absolute;
    background: white;
    opacity: .8;
    right: 0;
    top: 0;
    bottom: 0;
    height: ${standardSpacing}px;
    align-items: center;
    border-top-left-radius: ${halfSpacing}px;
    border-bottom-left-radius: ${halfSpacing}px;
    top: ${threeQuartersSpacing}px;
  }

  .black-mask::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .3;
    content: '';
  }

  &:hover {
    cursor: pointer;
    .item-name {
      color: ${primary};
    }
  }

  .description-section {
    height: 80px;
  }

  .icon-panel {
    min-width: 60px;
  }
`;

const LectureCardViewNavItem = (props: InnerLectureNavigationItemProps) => {
  const usePhotoBkg = !!props.lecturePage.cardViewImageUrl;
  // This default color is not in the spec, but is used in the AngularJS lecture page
  const backgroundColor = props.lecturePage.viewOptions?.backgroundColor ?? gray5;
  const backgroundImage = usePhotoBkg ? `url("${props.lecturePage.cardViewImageUrl}")` : 'unset';
  const showBlackMask = props.lecturePageStatus === 'completed';
  const totalPoints = props.lecturePage?.totalPoints?.[0];

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  return (
    <div
      css={cardViewStyles(isHandheld)}
      className={`d-inline-flex align-items-center px-2 mb-0 bg-white flex-column position-relative lecture-card ${!props.lecturePage.released ? 'unreleased' : ''}`}
      data-qa={config.pendo.outline.timeline.lessonCard}
    >
      {props.lecturePageStatus === 'in-progress' && <InProgressRibbon />}
      <div
        className={`icon-panel d-flex mt-2 justify-content-center align-items-center w-100 text-center gray-3 ${showBlackMask ? 'black-mask' : ''}`}
        style={{
          backgroundColor,
          backgroundImage,
          backgroundPosition: 'center, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover, cover',
        }}
      >
        {!usePhotoBkg && props.lecturePageStatus !== 'completed' && (
          <NvIcon
            size='medium-larger'
            icon='read'
            data-qa={config.pendo.outline.timeline.lessonCardNotCompleted}
          />
        )}
        {props.lecturePageStatus === 'completed' && (
          <NvIcon
            size='medium-larger'
            icon='check'
            className='text-white'
            data-qa={config.pendo.outline.timeline.lessonCardCompleted}
          />
        )}
        {!!totalPoints && (
          <div className='d-flex pl-2 pr-1 page-title card-points-container'>
            <PointsDisplay
              totalPoints={totalPoints}
              pointsReceived={props.lecturePage.pointsReceived}
            />
          </div>
        )}
      </div>
      <div className='description-section mw-100 d-flex flex-column align-items-center pt-3'>
        <div
          className='item-name mw-100 text-truncate card-title-small'
          data-qa={config.pendo.outline.timeline.lessonTitle}
        >
          {props.item.name}
        </div>
        {props.lecturePage.estimation?.estimatedEffort > 0 && (
          <div className='text-small gray-2 pt-1'>
            {getEstimateReadout(props.lecturePage.estimation)}
          </div>
        )}
      </div>
    </div>
  );
};

export default LectureCardViewNavItem;

const InProgressRibbon = () => {
  const styles = css`
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    z-index: 1;

    .triangle {
      opacity: .8;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      border-right: 80px solid transparent;
      border-top: 80px solid white;
    }

    .icon-container {
      padding-left: 11px;
      padding-top: 11px;
      .icon {
        transform: rotate(-45deg);
        color: ${primary};
      }
    }
  `;
  return (
    <div>
      <NvTooltip text={t.TIMELINE.IN_PROGRESS_TOOLTIP()}>
        <div css={styles}>
          <div className='triangle' />
          <div className='icon-container position-absolute'>
            <NvIcon icon='in-progress' size='medium' />
          </div>
        </div>
      </NvTooltip>
    </div>
  );
};
