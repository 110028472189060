import { cloneDeepSerializable } from 'redux/store';

/* @ngInject */
export default function AdminControlsController(
  _,
  LearnerProfileModel,
  LearnerProfileManager,
  $state,
  $stateParams,
  AlertMessages,
  CurrentUserManager,
  CurrentCourseManager,
  ConfirmationOverlays,
  CurrentPermissionsManager,
  PageLevelManager,
  nvCurrentPage,
  $uibModal,
  validationConstants,
  CourseModel,
) {
  const vm = this;

  vm.manager = LearnerProfileManager;
  vm.$stateParams = $stateParams;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.validationConstants = validationConstants;

  const currentEnrollment = _.find(LearnerProfileManager.profileUser.enrollments, (e) => e.course.catalogId === CurrentCourseManager.course.catalogId);
  vm.unenrollVisible = !LearnerProfileManager.profileUser.unenrollUnavailable(currentEnrollment) && CurrentPermissionsManager.isConfigAndRegistrationRole();
  vm.viewPointsVisible = CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant();
  vm.adminOptionsVisible = ($stateParams.catalogId && (vm.unenrollVisible || vm.viewPointsVisible)) || CurrentUserManager.isNovoedAdmin();

  vm.showPointsBreakdownModal = function () {
    $uibModal.open({
      templateUrl: 'shared/templates/points-breakdown-modal.html',
      controller: 'PointsBreakdownModalController',
      windowClass: 'large-modal',
      controllerAs: 'vm',
      resolve: {
        userId: parseInt($stateParams.userId, 10),
        // We need to pass a course model instance and the instance needs to
        // have "userCourse" property to reflect the completion status in
        // nv-completion-criteria directive.
        course: new CourseModel({
          ...cloneDeepSerializable(CurrentCourseManager.course),
          ...LearnerProfileManager.profileUser.enrollments.find((enrollment) => {
            return enrollment.course.catalogId === CurrentCourseManager.course.catalogId;
          }).course,
        }),
        showOutline: true,
        selectedTab: () => 'Outline',
      },
    });
  };


  vm.unenroll = function () {
    const modalInstanceTemplate = 'learner_profiles/templates/modal-unenroll-confirmation.html';
    // eslint-disable-next-line no-shadow
    const modalInstanceCtrl = ['$scope', 'firstName', function ($scope, firstName) {
      $scope.firstName = firstName;
    }];
    const modalInstance = ConfirmationOverlays.openConfirmationModal(modalInstanceTemplate, modalInstanceCtrl,
      { firstName() { return vm.manager.profileUser.firstName; } });

    modalInstance.result.then(() => {
      LearnerProfileModel.unenroll(vm.$stateParams.catalogId, vm.manager.profileUser.id).then((response) => {
        let toState = 'learner-directory';
        let toParams = { catalogId: vm.$stateParams.catalogId };

        if (PageLevelManager.lastParent() && PageLevelManager.lastParent().state.parent === 'user-management') {
          // We were on User management. Navigate to user-management.

          PageLevelManager.ignoreNextPush();
          toState = PageLevelManager.lastParent().state.name;
          toParams = {
            ...PageLevelManager.lastParent().params,
            forceReload: true,
          };
        } else if (PageLevelManager.lastL4() && (nvCurrentPage.getParentState() === 'modal-page' || nvCurrentPage.getCurrentStateData().level === 4)) {
          // If we're on an L4 page and there's a state to navigate back into, navigate to that after
          // unenrolling the user instead of going into the learner directory.

          PageLevelManager.ignoreNextPush();
          toState = PageLevelManager.lastL4().state.name;
          toParams = PageLevelManager.lastL4().params;
        }

        $state.go(toState, toParams).then(() => {
          AlertMessages.success('', 'PROFILE.UNENROLL_SUCCESS', {}, { name: vm.manager.profileUser.firstName });
        });
      });
    });
  };


  vm.deleteAccount = function () {
    const modalInstance = ConfirmationOverlays.openConfirmationModal('learner_profiles/templates/modal-delete-account-confirmation.html');
    modalInstance.result.then(() => {
      LearnerProfileModel.delete(vm.manager.profileUser.id).then((response) => {
        // If from novoed profile, go to dashboard, else go to learner-directory
        if (vm.$stateParams.catalogId) {
          $state.go('learner-directory', { catalogId: vm.$stateParams.catalogId }).then(() => {
            AlertMessages.success('', 'PROFILE.DELETE_SUCCESS', {}, { name: vm.manager.profileUser.firstName });
          });
        } else {
          $state.go('dashboard').then(() => {
            AlertMessages.success('', 'PROFILE.DELETE_SUCCESS', {}, { name: vm.manager.profileUser.firstName });
          });
        }
      });
    });
  };
}
