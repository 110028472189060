import React, { useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import QuizAiQuestionsModal from 'lecture_pages/components/quiz/ai-questions-modal';
import GenerateButton from '../shared/generate-button';

const MODAL_WIDTH = 1080;

const modalStyles = css`
  .bs4-modal-body  {
    padding: 0 140px;
  }
`;

const GenerateQuiz = () => {
  const [showQuestionModal, setShowQuestionModal] = useState(false);

  const closeQuestionModal = () => {
    setShowQuestionModal(false);
  };

  return (
    <React.Fragment>
      <GenerateButton
        onClick={() => setShowQuestionModal(true)}
        className='mt-8'
      />
      <NvModal
        type={ModalType.DYNAMIC}
        width={MODAL_WIDTH}
        header={t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.QUIZ_QUESTIONS()}
        show={showQuestionModal}
        body={<QuizAiQuestionsModal onClose={closeQuestionModal} />}
        onClose={() => setShowQuestionModal(false)}
        modalStyles={modalStyles}
      />
    </React.Fragment>
  );
};

export default GenerateQuiz;
