import { createContext, useEffect, useState } from 'react';
import { nth, merge, isEmpty, omit } from 'lodash';

import { NovoEdFile } from 'shared/hooks/use-upload-file';

import { useAppDispatch } from 'redux/store';
import { searchScenarios } from 'redux/actions/video-practice';
import { NvDropdownTextItem } from 'shared/components/inputs/nv-dropdown';
import { RecordingFormat, SubmissionPrivacySetting, ScenarioReusabilitySetting, VideoPracticeOption, VideoPracticeScenario, VideoPracticeActivity } from 'redux/schemas/models/video-practice';

import ChooseVideoPracticeOption from './choose-video-practice-option';
import CreateVideoPracticeOption from './create-video-practice-option';
import SelectVideoPracticeOption from './select-video-practice-option';
import ViewPracticeScenarioOption from './view-practice-scenario-option';
import { convertToSeconds } from './video-practice-utils';

enum VideoPracticeOptionsModalStep {
  CHOOSE,
  SELECT,
  CREATE,
  VIEW,
}

type VideoPracticeOptionsModalProps = {
  setTitle: (title: string) => void;
  createVideoPractice: (e?: any) => void;
  editVideoPractice: (e?: any) => void;
  closeModal: () => void;
  catalogId: string;
  editBasics?: boolean;
  editScenario?: boolean;
  formData?: FormData;
};

type InsightCriterion = {
  keyphrasesToCover?: string[]
  keyphrasesToAvoid?: string[]
};

export type CreateVideoPracticeOptionForm = {
  title: string;
  textPrompt?: string;
  videoPrompt?: NovoEdFile;
  recordingLimit: {
    min: NvDropdownTextItem,
    sec: NvDropdownTextItem,
  }
  recordingFormat: RecordingFormat;
  privacy: SubmissionPrivacySetting;
  reusable: ScenarioReusabilitySetting;
  scenarioId?: number;
  insightCriterion: InsightCriterion
  isInsightsEnabled?: boolean
};

export type ChooseVideoPracticeOptionForm = {
  videoPracticeOption: VideoPracticeOption;
  maxTries: number;
};

export type FormData = CreateVideoPracticeOptionForm & ChooseVideoPracticeOptionForm;

export const VideoPracticeOptionsModalContext = createContext<VideoPracticeOptionsModalProps>(null);

const VideoPracticeOptionsModal = (props: VideoPracticeOptionsModalProps) => {
  const [modalStep, setModalStep] = useState<VideoPracticeOptionsModalStep>(props.editScenario ? VideoPracticeOptionsModalStep.CREATE : VideoPracticeOptionsModalStep.CHOOSE);
  const [previousModalSteps, setPreviousModalSteps] = useState<VideoPracticeOptionsModalStep[]>([]);
  const [formData, setFormData] = useState<FormData>(props.formData ?? {
    videoPracticeOption: null,
    maxTries: 1,
    title: '',
    textPrompt: '',
    recordingLimit: {
      min: {
        type: 'text',
        id: 3,
        text: '3 min',
        value: 3,
      },
      sec: {
        type: 'text',
        id: 0,
        text: '0 sec',
        value: 0,
      },
    },
    recordingFormat: RecordingFormat.VIDEO,
    privacy: SubmissionPrivacySetting.SHARED,
    reusable: ScenarioReusabilitySetting.REUSABLE,
    insightCriterion: {},
    isInsightsEnabled: false,
  });

  const [scenario, setScenario] = useState<any>(null);

  const [availableScenarios, setAvailableScenarios] = useState<VideoPracticeScenario[]>([]);
  const [totalResultsCount, setTotalResultsCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(searchScenarios({
      callback: ({ scenarios, resultsCount }) => {
        setLoading(false);
        setAvailableScenarios(scenarios);
        setTotalResultsCount(resultsCount);
      },
    }));
  }, [dispatch]);

  const onClickNext = (data) => {
    if (props.editBasics) {
      props.editVideoPractice(data);
    } else {
      setPreviousModalSteps(steps => [...steps, modalStep]);
      setModalStep(VideoPracticeOptionsModalStep.SELECT);
      setFormData(prevData => merge(prevData, data));
    }
  };

  const onCancel = (data) => {
    setModalStep(nth(previousModalSteps, -1));
    setPreviousModalSteps(steps => steps.splice(-1, 1));
    setFormData(prevData => merge(prevData, data));
  };

  const onViewDetails = (data) => {
    setScenario(data);
    setPreviousModalSteps(steps => [...steps, modalStep]);
    setModalStep(VideoPracticeOptionsModalStep.VIEW);
  };

  const onCreateNew = () => {
    setPreviousModalSteps(steps => [...steps, modalStep]);
    setModalStep(VideoPracticeOptionsModalStep.CREATE);
  };

  const onSubmit = (data?: CreateVideoPracticeOptionForm) => {
    if (!isEmpty(data.videoPrompt)) {
      const { name, size, type, uniqueId } = data.videoPrompt;
      data.videoPrompt = { name, size, type, uniqueId };
    } else {
      delete data.videoPrompt;
    }

    if (!data.isInsightsEnabled) {
      data.insightCriterion = null;
    }

    const deleteVideoPrompt = !data.videoPrompt;
    const recordingLimit = typeof data.recordingLimit === 'number' ? data.recordingLimit : convertToSeconds(data.recordingLimit.min.value, data.recordingLimit.sec.value);

    const scenario = {
      ...omit(data, ['videoPracticeOption', 'maxTries', 'isInsightsEnabled']),
      reusable: data.reusable ?? formData.reusable,
      recordingLimit,
      deleteVideoPrompt,
    };

    if (props.editScenario) {
      props.editVideoPractice({ ...scenario, id: formData.scenarioId });
    } else {
      const videoPractice = {
        videoPracticeOption: formData.videoPracticeOption,
        maxTries: formData.maxTries,
        scenario,
      };

      props.createVideoPractice(videoPractice);
    }
  };

  const getModalView = () => {
    switch (modalStep) {
      case VideoPracticeOptionsModalStep.SELECT:
        return (
          <SelectVideoPracticeOption
            availableScenarios={availableScenarios}
            totalResultsCount={totalResultsCount}
            onCreateNew={onCreateNew}
            onSubmit={onSubmit}
            onViewDetails={onViewDetails}
            availableScenariosLoading={loading}
          />
        );
      case VideoPracticeOptionsModalStep.CREATE:
        return (
          <CreateVideoPracticeOption
            onCancel={onCancel}
            onSubmit={onSubmit}
            formData={formData as unknown as CreateVideoPracticeOptionForm & VideoPracticeScenario}
          />
        );
      case VideoPracticeOptionsModalStep.VIEW:
        return (
          <ViewPracticeScenarioOption
            onCancel={onCancel}
            onSubmit={onSubmit}
            scenario={scenario}
          />
        );
      default:
        return (
          <ChooseVideoPracticeOption
            onSubmit={onClickNext}
            formData={formData as ChooseVideoPracticeOptionForm}
          />
        );
    }
  };

  return (
    <VideoPracticeOptionsModalContext.Provider value={props}>
      {getModalView()}
    </VideoPracticeOptionsModalContext.Provider>
  );
};

export default VideoPracticeOptionsModal;
