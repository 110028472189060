// we need to use this more involved Google Drive service (which involves a redirect to the backend endpoint) when we need to save a refresh token so that the backend can

import store from '../../redux/store';

// do complicated things for the app on behalf of the user behind the scenes.
/* @ngInject */
export default function MetaTagsService(
  ReportsManager,
  CurrentCourseManager,
  StatementsManager,
  InstitutionsManager,
  CurrentUserManager,
) {
  return {
    getStateData,
    getDescription,
    getPageTitle,
  };


  function getStateData(stateName) {
    if (stateName === 'individual-submission-page') {
      return ReportsManager.currentReport ? ReportsManager.currentReport.getMetaTags() : { };
    } if (stateName === 'statement-page' || stateName === 'statement-modal') {
      return StatementsManager.getMetaTags();
    } if (stateName === 'course-flyer') {
      return CurrentCourseManager.getMetaTags();
    }
    return { };
  }

  function getPageTitle(lastState) {
    const state = store.getState();
    const currentLectureId = state.app.lecturePage?.currentLectureId;

    if (lastState?.parent === 'lecture-page-parent'
      && currentLectureId
      && state.models.lecturePages
      && state.models.lecturePages[currentLectureId]?.title
      && CurrentCourseManager.course) {
      return `${state.models.lecturePages[currentLectureId]?.title} | ${CurrentCourseManager.course.name}`;
    }

    if (CurrentCourseManager.course) {
      return `${CurrentCourseManager.course.name} | ${InstitutionsManager?.institution?.name
        || CurrentUserManager?.currentInstitution?.name
        || CurrentCourseManager.course.institution?.name}`;
    }

    if (InstitutionsManager?.institution?.name) {
      return InstitutionsManager.institution.name;
    }

    if (CurrentUserManager?.currentInstitution?.name) {
      return CurrentUserManager.currentInstitution.name;
    }

    return '';
  }

  function getDescription(stateName) {
    const stateData = getStateData(stateName);
    return stateData ? stateData['og:description'] : '';
  }
}
