import React, { useCallback, useRef } from 'react';
import t from 'react-translate';

// redux
import {
  NQuizQuestion,
  QuizQuestionType,
  ResponseOption,
} from 'redux/schemas/models/progressive-quiz';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { getQuizQuestionOptions } from 'redux/selectors/quizzes';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

import useForceUpdate from 'shared/hooks/use-force-update';
import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import { css } from '@emotion/core';
import { gray5 } from 'styles/global_defaults/colors';
import { doubleSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import QuizQuestionContent from './quiz-question-content';
import QuizImage from './quiz-image';
import NumberAnswerSection from './question-types/number-answer-question/number-answer-section';
import MultipleChoiceAnswerSection from './question-types/multiple-choice-question/multiple-choice-answer-section';
import ShortAnswerSection from './question-types/short-answer-question/short-answer-section';
import QuizHint from './quiz-hint';
import QuizSubmitSection from './quiz-submit-section';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion;
  className?: string;
};

const QuizQuestion = (props: QuizQuestionProps) => {
  const { className, currentQuestion } = props;

  const dispatch = useAppDispatch();


  const [isCurrentFeedbackShown, setIsCurrentFeedbackShown] = React.useState(false);
  const forceUpdate = useForceUpdate();

  const {
    reveal,
    scrollRef,
    closeModal,
    forwardOnModalClose,
    currentQuestionResponse,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isEditMode,
    isAnswerMode,
    isReviewMode,
    isMultipleChoiceQuestion,
  } = useQuizModeAndQuestionType();


  // This state update is to trigger the recalculation of the CTA button position when image is loaded
  const handleImageLoad = () => {
    setTimeout(() => {
      forceUpdate();
    }, 0);
  };

  const [answerState, setAnswerState] = React.useState(null);

  const currentQuestionId = currentQuestion.id;

  const unsetResponse = React.useCallback(() => {
    setAnswerState(null);
  }, []);

  React.useLayoutEffect(() => {
    unsetResponse();
  }, [currentQuestionId, unsetResponse]);

  React.useLayoutEffect(() => {
    if (currentQuestionResponse) {
      setAnswerState(currentQuestionResponse.feedback.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
    }
  }, [currentQuestionResponse]);

  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const getQuizAnswerSection = useCallback(() => {
    switch (currentQuestion.type) {
      case QuizQuestionType.MULTIPLE_CHOICE:
      case QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER:
        return (
          <MultipleChoiceAnswerSection className={isEditMode ? '' : 'mb-5'} />
        );
      case QuizQuestionType.STATEMENT:
        return null;
      case QuizQuestionType.SHORT_TEXT_QUESTION:
        return (
          <ShortAnswerSection
            currentQuestion={currentQuestion}
          />
        );
      case QuizQuestionType.NUMBER_QUESTION:
        return (
          <NumberAnswerSection
            currentQuestion={currentQuestion}
          />
        );
      default:
        return null;
    }
  }, [currentQuestion, isEditMode]);

  const areAllOptionsCorrect = responseOptions?.every((responseOption) => !responseOption.isCorrect) ?? false;


  const questionContextValue = {
    answerState,
    setAnswerState,
    responseOptions,
    currentQuestion,
    areAllOptionsCorrect,
    currentQuestionResponse,
  };

  const isObject = (variable) => typeof variable === 'object' && variable !== null;
  const hasSelectedAtLeastOneOptions = isObject(answerState) ? Object.values(answerState).some((value) => value) : false;

  const showCorrectAnswer = isReviewMode && reveal;

  const isAtLeastOneBottomSectionElementShown = isCurrentFeedbackShown || showCorrectAnswer;
  const quizQuestionRef = useRef(null);
  const buttonContainerRef = useRef(null);
  const quizQuestionRect = quizQuestionRef?.current?.getBoundingClientRect();
  const buttonContainerRect = buttonContainerRef?.current?.getBoundingClientRect();

  const getPosition = () => {
    // used to indicate that the bottom margin of the button has become greater than 40px.
    const isButtonBottomMarginExceeds = (scrollRef?.current?.getBoundingClientRect()?.bottom - buttonContainerRect?.bottom) > 40;
    // used to indicate that the top margin has become less than 60px.
    const isRequiredButtonTopMarginReduced = (buttonContainerRect?.top - (quizQuestionRect?.height + quizQuestionRect?.top)) < 60;
    if (!isButtonBottomMarginExceeds && isRequiredButtonTopMarginReduced) {
      return 'static';
    }
    return 'fixed';
  };

  const button = buttonContainerRef?.current?.querySelector('button');
  const styles = css`
    .bottom-section {
      ${!isEditMode && isAtLeastOneBottomSectionElementShown && css`
        border-top: 4px solid ${gray5};
      `};
    }
    .button-container {
      position: ${getPosition()};
      left: calc(50% - ${button?.offsetWidth / 2}px);
      margin-top: ${tripleSpacing}px;
      bottom: ${window.innerHeight - scrollRef?.current?.getBoundingClientRect()?.bottom + doubleSpacing}px;
    }
  `;

  const unsubmittedAnswer = hasSelectedAtLeastOneOptions && !currentQuestionResponse;

  React.useEffect(() => forwardOnModalClose((event) => {
    if (isAnswerMode && unsubmittedAnswer) {
      event.preventDefault();

      dispatch(openConfirmationDialog({
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => closeModal(true),
        title: t.QUIZZES.ARE_YOU_SURE_TO_EXIT(),
      }));
    }
  }), [
    dispatch,
    closeModal,
    isAnswerMode,
    unsubmittedAnswer,
    forwardOnModalClose,
  ]);

  return (
    <QuestionContext.Provider value={questionContextValue} key={currentQuestion.id}>
      <div ref={quizQuestionRef} css={styles} className={className}>
        <QuizQuestionContent className='mb-5' />
        {(isEditMode ? true : !!currentQuestion.picture?.cdnUrl) && (
          <div className='d-flex justify-content-center mb-5'>
            <QuizImage
              handleImageLoad={handleImageLoad}
              className='question-image'
            />
          </div>
        )}
        {getQuizAnswerSection()}
        <QuizHint
          isCurrentFeedbackShown={isCurrentFeedbackShown}
          setIsCurrentFeedbackShown={setIsCurrentFeedbackShown}
        />
        {isAnswerMode
          && (
            <QuizSubmitSection
              currentQuestion={currentQuestion}
              buttonContainerRef={buttonContainerRef}
              unsetResponse={unsetResponse}
            />
          )}
      </div>
    </QuestionContext.Provider>
  );
};

export default QuizQuestion;
