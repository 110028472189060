
import { createReducer } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { each, map, some, uniq, values } from 'underscore';
import mergeWith from 'lodash/mergeWith';
import { replaceArrays } from 'shared/lodash-utils';

import {
  getFlyoutScenarios,
  getScenario,
  setPracticeRoomState,
  editScenario,
  getVideoPracticeSubmissions,
  likeVideoPracticeSubmission,
  undoLikeVideoPracticeSubmission,
  getVideoPracticeSubmissionLikers,
  startVideoPracticeSubmission,
  completeVideoPracticeSubmission,
  getComments,
  postComment,
  likeComment,
  undoLikeComment,
  getCommentLikers,
  deleteComment,
  updateComment,
  toggleFeatureSubmission,
  leaveVideoPracticeSubmission,
  setFilteredComments,
  removeHighlight,
  postVideoHasViewed,
  getProfileSubmissions,
  getMentionableUsers,
  deleteSubmission,
  resetPracticeRoomSubmissionData,
  getPracticeSubmission,
  resetPracticeComments,
  getComment,
  clearNewNotification,
  generateInsights,
  setPracticeFeedbackData,
} from 'redux/actions/video-practice';
import {
  PracticeSubmissionEntities,
  PracticeSubmissionListSchema,
  PracticeScenarioListSchema,
  PracticeScenariosEntities,
  FetchPracticeCommentsSchema,
  MentionableUsersListSchema,
  PracticeRoomStateParams,
  PracticeScenarioSchema,
} from 'redux/schemas/api/video-practice';
import { MySubmissionsState, StatCountType, UserSubmissionsState, VideoPracticeSubmissionsState } from 'redux/schemas/app/video-practice';
import {
  InsightStatus,
  PracticeRoomTab,
  PracticeSubmissionComment,
  PracticeSubmissionCommentsEntities,
  PracticeSubmissionCommentsNormalized,
  StatType,
  VideoPracticeScenariosNormalized,
  VideoPracticeSubmissionsNormalized,
} from 'redux/schemas/models/video-practice';
import { RootState } from 'redux/schemas';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getFlyoutScenarios.pending, (state, action) => {
      state.app.practiceRoom.scenariosList.isLoading = true;
    })
    .addCase(getFlyoutScenarios.fulfilled, (state, action) => {
      state.app.practiceRoom.scenariosList.isOnceLoaded = true;
      state.app.practiceRoom.scenariosList.isLoading = false;

      const { resultsCount: totalCount, perPage } = action.payload;
      state.app.practiceRoom.scenariosList.hasMore = (totalCount - (perPage * action.meta.arg.page)) > 0;

      if (action.payload?.scenarios) {
        const normalizedData = normalize<VideoPracticeScenariosNormalized, PracticeScenariosEntities>(action.payload?.scenarios, PracticeScenarioListSchema);

        mergeWith(state.models.practiceScenarios, normalizedData.entities.practiceScenarios, replaceArrays);
        state.app.practiceRoom.scenariosList.scenarioIds = uniq([...state.app.practiceRoom.scenariosList.scenarioIds, ...normalizedData.result ?? []]);
      }
    })
    .addCase(setPracticeRoomState, (state, action) => {
      // Convert any number param to int type
      const params = {};
      each(action.payload, (param, key) => {
        params[key] = param && !Number.isNaN(+param) ? +param : param;
      });

      const payload = action.payload as PracticeRoomStateParams;

      if (payload?.userId) {
        if (!state.app.practiceRoom.userSubmissions[payload.scenarioId]) {
          state.app.practiceRoom.userSubmissions[payload.scenarioId] = {};
        }
        if (!state.app.practiceRoom.userSubmissions[payload.scenarioId]?.[payload.userId]) {
          state.app.practiceRoom.userSubmissions[payload.scenarioId][payload.userId] = {
            isLoading: false,
            submissionIds: [],
            hasMore: false,
            isOnceLoaded: false,
            counts: {
              [StatType.FEATURED]: 0,
              [StatType.COMMENT]: 0,
              [StatType.LIKE]: 0,
              [StatType.VIEW]: 0,
            },
          };
        }
      }
      Object.assign(state.app.practiceRoom.params, params);
    })
    .addCase(setFilteredComments, (state, action) => {
      if (!action.payload?.submissionId) {
        state.app.practiceRoom.filteredComments = {};
        return;
      }
      state.app.practiceRoom.filteredComments[action.payload.submissionId] = action.payload.filteredComments;
    })
    .addCase(getScenario.pending, (state, action) => {
      initializeScenarioAppState(state, action.meta.arg.scenarioId);
    })
    .addCase(getScenario.fulfilled, (state, action) => {
      state.app.practiceRoom.scenarioLoaded[action.meta.arg.scenarioId] = true;

      const normalizedData = normalize(
        action.payload, PracticeScenarioSchema,
      );
      // To not to erase the existing mentionable users data if already loaded
      mergeWith(state.models.practiceScenarios, normalizedData.entities.practiceScenarios, replaceArrays);
      mergeWith(state.models.skillTags, normalizedData.entities.skillTags, replaceArrays);
      mergeWith(state.models.skillTaggings, normalizedData.entities.skillTaggings, replaceArrays);
    })
    .addCase(editScenario.fulfilled, (state, action) => {
      const practiceActivitiesWithScenario = values(state.models.practiceActivities).filter(activity => activity.scenarioId === action.payload.id);

      map(practiceActivitiesWithScenario, practiceActivity => {
        if (!practiceActivity.customTitle) {
          state.models.practiceActivities[practiceActivity.id].title = action.payload.title;
        }
      });

      Object.assign(state.models.practiceScenarios[action.payload.id], action.payload);
    })
    .addCase(getVideoPracticeSubmissions.pending, (state, action) => {
      const { scenarioId, tab, option, userId, page } = action.meta.arg;

      initializeScenarioAppState(state, action.meta.arg.scenarioId);

      if (userId) {
        if (!state.app.practiceRoom.userSubmissions[scenarioId]?.[action.meta.arg.userId]) {
          state.app.practiceRoom.userSubmissions[scenarioId][action.meta.arg.userId] = {
            isLoading: true,
            submissionIds: [],
            hasMore: false,
            isOnceLoaded: false,
            counts: {
              [StatType.FEATURED]: 0,
              [StatType.COMMENT]: 0,
              [StatType.LIKE]: 0,
              [StatType.VIEW]: 0,
            },
          };
        }
      } else if (tab === PracticeRoomTab.FEATURED) {
        state.app.practiceRoom.featuredSubmissions[scenarioId].isLoading = true;
      } else if (tab === PracticeRoomTab.MY_PRACTICE) {
        state.app.practiceRoom.mySubmissions[scenarioId].isLoading = true;
      } else {
        state.app.practiceRoom.gallerySubmissions[scenarioId].isLoading = true;
        if (option) {
          if (state.app.practiceRoom.gallerySubmissions[scenarioId].sortOrFilteredOption !== option) {
            state.app.practiceRoom.gallerySubmissions[scenarioId].isOnceLoaded = false;
            state.app.practiceRoom.gallerySubmissions[scenarioId].submissionIds = [];
          }

          state.app.practiceRoom.gallerySubmissions[scenarioId].sortOrFilteredOption = option;
        }
      }
    })
    .addCase(getVideoPracticeSubmissions.fulfilled, (state, action) => {
      const { scenarioId, activityId, tab, page, userId, miniGallery } = action.meta.arg;
      const { submissionId } = state.app.practiceRoom.params;
      const { recentSubmissionId } = state.app.practiceRoom.mySubmissions[scenarioId];
      let tabKey: string = '';

      if (tab === PracticeRoomTab.FEATURED) {
        tabKey = 'featuredSubmissions';
      } else if (tab === PracticeRoomTab.MY_PRACTICE) {
        tabKey = 'mySubmissions';
        // recentSubmissionId is the id of the submission that the learner has recently practiced,
        // it was only setting when the learner practiced through practice again,
        // It is used to check for pusher events on recent submission
        // Here setting it from the URL param, as the user is pointing to that submission,
        // and might entered from lecture page after just submitting the practice

        if (!recentSubmissionId) {
          state.app.practiceRoom.mySubmissions[scenarioId].recentSubmissionId = submissionId;
        }
      } else if (tab === PracticeRoomTab.GALLERY) {
        tabKey = 'gallerySubmissions';
      }

      const {
        resultsCount: totalCount,
        perPage,
        submissions,
        totalFeaturedCount,
        totalCommentsCount,
        totalLikesCount,
        totalViews,
        remainingSubmissions,
      } = action.payload;

      const scenario = state.models.practiceScenarios[scenarioId];
      scenario.submissionIds = scenario?.submissionIds ?? [];

      if (tabKey) {
        state.app.practiceRoom[tabKey][scenarioId].isLoading = false;
        state.app.practiceRoom[tabKey][scenarioId].isOnceLoaded = true;
        state.app.practiceRoom[tabKey][scenarioId].hasMore = (totalCount - (perPage * page)) > 0;
      }

      if (submissions) {
        const normalizedData = normalize<VideoPracticeSubmissionsNormalized, PracticeSubmissionEntities>(
          submissions, PracticeSubmissionListSchema,
        );
        mergeWith(state.models.practiceSubmissions, normalizedData.entities.practiceSubmissions, replaceArrays);

        if (activityId) {
          const activity = state.models.practiceActivities[activityId];
          activity.remainingSubmissions = remainingSubmissions;
          activity.submissionIds = [...(activity.submissionIds || []), ...normalizedData.result];
          activity.submissionsLoaded = true;
        }

        if (tabKey) {
          if (page === 1) {
            /**
             * concating will append the latest id to the end of the array,
             * instead keeping the array as returned from API for page 1
             * because the page 1 contains recent submissions.
             */
            state.app.practiceRoom[tabKey][scenarioId].submissionIds = normalizedData.result;
          } else {
            state.app.practiceRoom[tabKey][scenarioId].submissionIds = uniq([
              ...state.app.practiceRoom[tabKey][scenarioId].submissionIds,
              ...normalizedData.result ?? [],
            ]);
          }
        }
        if (tabKey && tab !== PracticeRoomTab.MY_PRACTICE) {
          const userIdsToFetch: number [] = state.app.practiceRoom[tabKey][scenarioId]?.userIds ?? [];
          map(normalizedData.entities.practiceSubmissions, (submission) => {
            userIdsToFetch.push(submission?.user?.id);
          });
          state.app.practiceRoom[tabKey][scenarioId].userIds = uniq(userIdsToFetch);
        }
        // Assign submissionIds to the corresponding scenario model
        scenario.submissionIds = uniq([...scenario.submissionIds, ...normalizedData.result ?? []]);
        const counts: StatCountType = {
          [StatType.PRACTICE]: 0,
          [StatType.FEATURED]: totalFeaturedCount,
          [StatType.COMMENT]: totalCommentsCount,
          [StatType.LIKE]: totalLikesCount,
          [StatType.VIEW]: totalViews,
        };

        if (userId) {
          state.app.practiceRoom.userSubmissions[scenarioId][userId].user = submissions[0]?.user;
          state.app.practiceRoom.userSubmissions[scenarioId][userId].isTeamMember = submissions[0]?.isTeamMember;
          state.app.practiceRoom.userSubmissions[scenarioId][userId].teamName = submissions[0]?.teamName;
          state.app.practiceRoom.userSubmissions[scenarioId][userId].isLoading = false;
          state.app.practiceRoom.userSubmissions[scenarioId][userId].isOnceLoaded = true;
          state.app.practiceRoom.userSubmissions[scenarioId][userId].submissionIds = normalizedData.result;
          counts[StatType.PRACTICE] = totalCount;
          state.app.practiceRoom.userSubmissions[scenarioId][userId].counts = counts;
        }
        if (tab === PracticeRoomTab.MY_PRACTICE) {
          state.app.practiceRoom.mySubmissions[scenarioId].counts = counts;
        }
        if (miniGallery) {
          each(state.models.practiceFeedbackActivities, (value, key) => {
            if (activityId === value.activityId && value.hasSubmittedRequirement && !value.feedbackGalleryIds?.length) {
              state.models.practiceFeedbackActivities[key].feedbackGalleryIds = normalizedData.result;
            }
          });
          each(state.models.exerciseSkillsRatingActivities, (value, key) => {
            if (activityId === value.activityId && value.hasSubmittedRequirement && !value.feedbackGalleryIds?.length) {
              state.models.exerciseSkillsRatingActivities[key].feedbackGalleryIds = normalizedData.result;
            }
          });
        }
      }
    })
    .addCase(likeVideoPracticeSubmission.fulfilled, (state, action) => {
      const { submissionId } = action.meta.arg;
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      practiceSubmission.numLikes = action.payload.numLikes;
      practiceSubmission.isLikedByCurrentUser = true;
      updateCounts(state, 'add', StatType.LIKE);
    })
    .addCase(undoLikeVideoPracticeSubmission.fulfilled, (state, action) => {
      const { submissionId } = action.meta.arg;
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      practiceSubmission.numLikes = action.payload.numLikes;
      practiceSubmission.isLikedByCurrentUser = false;
      updateCounts(state, 'delete', StatType.LIKE);
    })
    .addCase(getVideoPracticeSubmissionLikers.fulfilled, (state, action) => {
      const { submissionId } = action.meta.arg;
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      practiceSubmission.likedUsers = action.payload;
      // Update the numLikes if there's a mismatch
      if (practiceSubmission.numLikes !== action.payload.length) {
        practiceSubmission.numLikes = action.payload.length;
      }
    })
    .addCase(startVideoPracticeSubmission.fulfilled, (state, action) => {
      const { activityId } = action.meta.arg;
      const activity = state.models.practiceActivities[activityId];
      if (activity) {
        activity.progress = 'in_progress';
        activity.submissionId = action.payload.id;
      }
      state.app.videoPractice.submissionId = action.payload.id;
    })
    .addCase(completeVideoPracticeSubmission.fulfilled, (state, action) => {
      const { activityId, scenarioId } = action.meta.arg;
      const activity = state.models.practiceActivities[activityId];
      const submission = action.payload;

      if (activity) {
        activity.progress = 'completed';
      }
      each(state.models.practiceFeedbackActivities, (value, key) => {
        if (value.activityId === activityId) {
          state.models.practiceFeedbackActivities[key].activity.progress = 'completed';
        }
      });
      each(state.models.exerciseSkillsRatingActivities, (value, key) => {
        if (value.activityId === activityId) {
          state.models.exerciseSkillsRatingActivities[key].activity.progress = 'completed';
          state.models.exerciseSkillsRatingActivities[key].hasSubmittedRequirement = true;
        }
      });
      state.models.practiceSubmissions[submission.id] = submission;
      if (state.app.practiceRoom.mySubmissions?.[scenarioId]) {
        state.app.practiceRoom.mySubmissions[scenarioId].recentSubmissionId = submission.id;
      }
    })
    .addCase(leaveVideoPracticeSubmission, (state, action) => {
      const { activityId } = action.payload;
      const activity = state.models.practiceActivities[activityId];
      activity.progress = 'missed';
    })
    .addCase(clearNewNotification, (state, action) => {
      delete state.app.practiceRoom.params.newNotification;
    })
    .addCase(getComments.pending, (state, action) => {
      const { submissionId, page } = action.meta.arg;
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      if (page === 1 && practiceSubmission) {
        practiceSubmission.submissionComments = [];
        state.app.videoPracticeSubmissions[submissionId] = {
          ...state.app.videoPracticeSubmissions[submissionId],
          commentsOnceLoaded: false,
          commentsLoading: true,
        };
      }
    })
    .addCase(getComments.fulfilled, (state, action) => {
      const { submissionId } = action.meta.arg;
      const { posts, total } = action.payload;

      const practiceSubmission: any = state.models.practiceSubmissions[submissionId] ?? state.models.submissions[submissionId] ?? {};
      practiceSubmission.submissionComments = state.models.practiceSubmissions[submissionId]?.submissionComments ?? [];

      state.app.videoPracticeSubmissions[submissionId] = {
        ...state.app.videoPracticeSubmissions[submissionId],
        commentsOnceLoaded: true,
        commentsLoading: false,
      };

      if (posts) {
        const normalizedData = normalize<PracticeSubmissionCommentsNormalized, PracticeSubmissionCommentsEntities>(posts, FetchPracticeCommentsSchema);
        Object.assign(state.models.comments, normalizedData.entities.comments);
        practiceSubmission.submissionComments = uniq([...normalizedData.result, ...practiceSubmission.submissionComments]);
        const submissionComments = practiceSubmission.submissionComments?.map(id => state.models.comments?.[id]);
        practiceSubmission.hasReviewed = some(submissionComments, (feedback: PracticeSubmissionComment) => (
          feedback?.isUserFirstReview && feedback?.user?.id === state.app?.currentUserId
        ));
      }
    })
    .addCase(getComment.fulfilled, (state, action) => {
      const { submissionId } = action.meta.arg;
      const comment = action.payload;
      state.models.comments[comment.id] = comment;
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      if (practiceSubmission?.submissionComments) {
        practiceSubmission.submissionComments = uniq([...practiceSubmission.submissionComments, comment.id]);
      }
    })
    .addCase(postComment.fulfilled, (state, action) => {
      const { submissionId, practiceFeedbackCriteriaId } = action.meta.arg;
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      if (action.payload) {
        const comment = action.payload;
        comment.highlighted = true;
        state.models.comments[comment.id] = comment;
        if (!practiceSubmission.submissionComments) {
          practiceSubmission.submissionComments = [];
        }
        /** sometimes getcomments fullfills earlier than postcomment,
         * and this will cause duplication of entries
         * if pushed the newly added comment without checking
         */
        if (!some(practiceSubmission?.submissionComments, (id) => id === comment.id)) {
          practiceSubmission.submissionComments.push(comment.id);
          practiceSubmission.numComments += 1;
          updateCounts(state, 'add', StatType.COMMENT);
        }
        if (practiceFeedbackCriteriaId) {
          // Updating redux models

          const practiceFeedbackActivity = state.models.practiceFeedbackActivities?.[practiceFeedbackCriteriaId];
          if (practiceFeedbackActivity?.progress !== 'missed') {
            practiceSubmission.hasReviewed = true;
            if (comment.isUserFirstReview) {
              // TODO: Fix this numReviewesCompleted bug related to comment on a video
              practiceFeedbackActivity.numReviewesCompleted += 1;
              const { numReviewesCompleted, requiredToComplete } = practiceFeedbackActivity;
              practiceFeedbackActivity.progress = numReviewesCompleted >= requiredToComplete ? 'completed' : 'in_progress';
            }
          }
        }
      }
    })
    .addCase(likeComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      const comment = state.models.comments[commentId];
      if (action.payload) {
        comment.votesCount = action.payload.numLikes;
        comment.liked = true;
      }
    })
    .addCase(undoLikeComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      const comment = state.models.comments[commentId];
      if (action.payload) {
        comment.votesCount = action.payload.numLikes;
        comment.liked = false;
      }
    })
    .addCase(getCommentLikers.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      const comment = state.models.comments[commentId];
      if (action.payload) {
        comment.likers = action.payload;
        // Update the votesCounts if there's a mismatch
        if (comment.votesCount !== action.payload.length) {
          comment.votesCount = action.payload.length;
        }
      }
    })
    .addCase(deleteComment.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      const { commentId, submissionId } = action.meta.arg;
      // Remove from practiceSubmission model
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      practiceSubmission.submissionComments = practiceSubmission.submissionComments.filter(id => id !== commentId);
      // Remove comment model
      delete state.models.comments[commentId];
      practiceSubmission.numComments = practiceSubmission?.submissionComments?.length;
      if (practiceSubmission.numComments <= 0) {
        practiceSubmission.hasReviewed = false;
      }
      updateCounts(state, 'delete', StatType.COMMENT);
    })
    .addCase(updateComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;
      if (action.payload) {
        const comment = action.payload;
        comment.highlighted = true;
        state.models.comments[commentId] = comment;
      }
    })
    .addCase(toggleFeatureSubmission.fulfilled, (state, action) => {
      const { submissionId, featured } = action.meta.arg;
      if (action.payload) {
        const practiceSubmission = state.models.practiceSubmissions[submissionId];
        practiceSubmission.featured = featured;
        updateCounts(state, featured ? 'add' : 'delete', StatType.FEATURED);
      }
    })
    .addCase(deleteSubmission.fulfilled, (state, action) => {
      const { scenarioId, submissionId } = action.meta.arg;

      if (state.app.practiceRoom.gallerySubmissions[scenarioId]) {
        state.app.practiceRoom.gallerySubmissions[scenarioId].submissionIds = state.app.practiceRoom.gallerySubmissions[scenarioId].submissionIds.filter(id => id !== submissionId);
      }
      if (state.app.practiceRoom.featuredSubmissions[scenarioId]) {
        state.app.practiceRoom.featuredSubmissions[scenarioId].submissionIds = state.app.practiceRoom.featuredSubmissions[scenarioId].submissionIds.filter(id => id !== submissionId);
      }
      if (state.app.practiceRoom.mySubmissions[scenarioId].submissionIds) {
        state.app.practiceRoom.mySubmissions[scenarioId].submissionIds = state.app.practiceRoom.mySubmissions[scenarioId].submissionIds.filter(id => id !== submissionId);
      }

      const userId = state.models.practiceSubmissions[submissionId]?.user?.id;
      if (userId && state.app.practiceRoom.userSubmissions[scenarioId]?.[userId]) {
        state.app.practiceRoom.userSubmissions[scenarioId][userId].submissionIds = state.app.practiceRoom.userSubmissions[scenarioId][userId].submissionIds.filter(id => id !== submissionId);
      }

      state.models.practiceScenarios[scenarioId].submissionIds = state.models.practiceScenarios[scenarioId].submissionIds.filter(id => id !== submissionId);
      delete state.models.practiceSubmissions[submissionId];
    })
    .addCase(removeHighlight, (state, action) => {
      const practiceComment = state.models.comments[action.payload.commentId];
      practiceComment.highlighted = false;
    })
    .addCase(postVideoHasViewed.fulfilled, (state, action) => {
      const { submissionId } = action.meta.arg;
      const practiceSubmission = state.models.practiceSubmissions[submissionId];
      practiceSubmission.numViews = action.payload.numViews;
      updateCounts(state, 'add', StatType.VIEW);
    })
    .addCase(getProfileSubmissions.pending, (state, action) => {
      if (!state.app.orgProfile[action.meta.arg.userId]) {
        state.app.orgProfile[action.meta.arg.userId] = {
          practiceSubmissions: {
            isLoading: true,
            hasMore: false,
            totalCount: 0,
            practiceSubmissionIds: [],
            isOnceLoaded: false,
          },
        };
      }
      state.app.orgProfile[action.meta.arg.userId].practiceSubmissions.isLoading = true;
    })
    .addCase(getProfileSubmissions.fulfilled, (state, action) => {
      const { page, userId } = action.meta.arg;
      const {
        perPage,
        resultsCount,
        submissions,
      } = action.payload;
      if (submissions) {
        const data = normalize<VideoPracticeSubmissionsNormalized, PracticeSubmissionEntities>(
          action.payload.submissions, PracticeSubmissionListSchema,
        );
        state.app.orgProfile[userId].practiceSubmissions.hasMore = (resultsCount - (perPage * page)) > 0;
        state.app.orgProfile[userId].practiceSubmissions.totalCount = resultsCount;
        state.app.orgProfile[userId].practiceSubmissions.isLoading = false;
        state.app.orgProfile[userId].practiceSubmissions.isOnceLoaded = true;
        state.app.orgProfile[userId].practiceSubmissions.practiceSubmissionIds = uniq([
          ...(state.app.orgProfile[userId]?.practiceSubmissions?.practiceSubmissionIds || []),
          ...data.result,
        ]);

        mergeWith(state.models.practiceSubmissions, data.entities.practiceSubmissions, replaceArrays);
      }
    })
    .addCase(getMentionableUsers.pending, (state, action) => {
      if (!state.app.videoPracticeSubmissions[action.meta.arg.submissionId].mentionables) {
        state.app.videoPracticeSubmissions[action.meta.arg.submissionId].mentionables = {
          isLoading: true,
          userIds: [],
          onceLoaded: false,
        };
      }
      state.app.videoPracticeSubmissions[action.meta.arg.submissionId].mentionables.isLoading = true;
    })
    .addCase(getMentionableUsers.fulfilled, (state, action) => {
      const data = normalize(action.payload, MentionableUsersListSchema);
      Object.assign(state.models.users, data.entities.mentionableUsers);
      state.app.videoPracticeSubmissions[action.meta.arg.submissionId].mentionables.userIds = data.result;
      state.app.videoPracticeSubmissions[action.meta.arg.submissionId].mentionables.onceLoaded = true;
      state.app.videoPracticeSubmissions[action.meta.arg.submissionId].mentionables.isLoading = false;
    })
    .addCase(resetPracticeRoomSubmissionData, (state) => {
      const submissionsData: VideoPracticeSubmissionsState = {};
      each(state.app.videoPracticeSubmissions, (value, key) => {
        submissionsData[key] = {
          ...value,
          commentsOnceLoaded: false,
          feedbackOnceLoaded: false,
          feedbackByUserLoading: false,
          feedbackByUserOnceLoaded: false,
          skillTaggings: {},
          authorFeedback: {
            ratings: [],
            createdAt: null,
          },
          ratingsByUser: {},
        };
      });
      state.app.videoPracticeSubmissions = submissionsData;
    })
    .addCase(getPracticeSubmission.fulfilled, (state, action) => {
      const { submissionId, scenarioId } = action.meta.arg;
      if (action.payload) {
        state.models.practiceSubmissions[submissionId] = action.payload;
        if (!state.models.practiceSubmissions[submissionId]) {
          state.models.practiceSubmissions[submissionId] = action.payload;
        } else {
          state.models.practiceSubmissions[submissionId] = {
            ...state.models.practiceSubmissions[submissionId],
            ...action.payload,
          };
        }
        if (state.app.practiceRoom.mySubmissions?.[scenarioId]?.recentSubmissionId === submissionId) {
          if (state.models.practiceSubmissions[submissionId].transcriptionInsightsStatus === InsightStatus.COMPLETED
            && state.models.practiceSubmissions[submissionId].fillerInsightStatus === InsightStatus.COMPLETED
          ) {
            state.app.practiceRoom.mySubmissions[scenarioId].recentSubmissionId = null;
          }
        }
      }
    })
    .addCase(resetPracticeComments, (state, action) => {
      if (state.app.videoPracticeSubmissions?.[action.payload.submissionId]?.commentsOnceLoaded) {
        state.app.videoPracticeSubmissions[action.payload.submissionId].commentsOnceLoaded = false;
      }
      if (state.models.practiceSubmissions[action.payload.submissionId]?.submissionComments) {
        state.models.practiceSubmissions[action.payload.submissionId].submissionComments = [];
      }
    })
    .addCase(generateInsights.pending, (state, action) => {
      const { submissionId, generateFillerWords, scenarioId } = action.meta.arg;
      state.app.practiceRoom.mySubmissions[scenarioId].recentSubmissionId = submissionId;
      if (generateFillerWords) {
        state.models.practiceSubmissions[submissionId].fillerInsightStatus = InsightStatus.IN_PROGRESS;
      } else {
        state.models.practiceSubmissions[submissionId].transcriptionInsightsStatus = InsightStatus.IN_PROGRESS;
        state.models.practiceSubmissions[submissionId].fillerInsightStatus = InsightStatus.IN_PROGRESS;
      }
    })
    .addCase(generateInsights.rejected, (state, action) => {
      const { submissionId, generateFillerWords, scenarioId } = action.meta.arg;
      state.app.practiceRoom.mySubmissions[scenarioId].recentSubmissionId = null;
      if (generateFillerWords) {
        state.models.practiceSubmissions[submissionId].fillerInsightStatus = InsightStatus.NOT_STARTED;
      } else {
        state.models.practiceSubmissions[submissionId].transcriptionInsightsStatus = InsightStatus.NOT_STARTED;
        state.models.practiceSubmissions[submissionId].fillerInsightStatus = InsightStatus.NOT_STARTED;
      }
    })
    .addCase(setPracticeFeedbackData, (state, action) => {
      state.app.videoPracticeFeedback.practiceFeedbackData = action.payload;
    });
});

const initializeScenarioAppState = (state, scenarioId: number) => {
  if (!scenarioId) {
    return;
  }

  if (!state.app.practiceRoom.gallerySubmissions[scenarioId]) {
    state.app.practiceRoom.gallerySubmissions[scenarioId] = {
      isLoading: false,
      submissionIds: [],
      hasMore: false,
      isOnceLoaded: false,
      userIds: [],
    };
  }
  if (!state.app.practiceRoom.featuredSubmissions[scenarioId]) {
    state.app.practiceRoom.featuredSubmissions[scenarioId] = {
      isLoading: false,
      submissionIds: [],
      hasMore: false,
      isOnceLoaded: false,
      userIds: [],
    };
  }
  if (!state.app.practiceRoom.mySubmissions[scenarioId]) {
    state.app.practiceRoom.mySubmissions[scenarioId] = {
      isLoading: false,
      submissionIds: [],
      hasMore: false,
      isOnceLoaded: false,
      counts: {
        [StatType.FEATURED]: 0,
        [StatType.COMMENT]: 0,
        [StatType.LIKE]: 0,
        [StatType.VIEW]: 0,
      },
    };
  }
  if (!state.app.practiceRoom.userSubmissions[scenarioId]) {
    state.app.practiceRoom.userSubmissions[scenarioId] = {};
  }
};

const updateCounts = (state: RootState, action: 'delete' | 'add', stat: StatType) => {
  const { scenarioId, selectedTab, userId } = state.app.practiceRoom.params;
  let tabState: MySubmissionsState | UserSubmissionsState;

  if (selectedTab === PracticeRoomTab.MY_PRACTICE) {
    tabState = state.app.practiceRoom.mySubmissions[scenarioId];
  } else if ((selectedTab === PracticeRoomTab.GALLERY
    || selectedTab === PracticeRoomTab.FEATURED
  ) && userId) {
    tabState = state.app.practiceRoom.userSubmissions[scenarioId]?.[userId];
  }

  if (!tabState) {
    return;
  }

  if (!tabState?.counts?.[stat]) {
    tabState.counts[stat] = 0;
  }

  if (action === 'delete') {
    if (tabState.counts[stat] > 0) {
      tabState.counts[stat] -= 1;
    } else {
      // This won't happen. But just a fail proof-
      // to don't display a negative value.
      tabState.counts[stat] = 0;
    }
  }

  if (action === 'add') {
    tabState.counts[stat] += 1;
  }
};

