import { createContext } from 'react';

/**
 * Context to be used in popovers to add the flexibility to set a custom
 * container via context API.
 */
// eslint-disable-next-line import/prefer-default-export
export const PopoversContainerContext = createContext(undefined);

type RefType<T> = ((instance: T) => void) | React.MutableRefObject<T>;

/**
 * Utility to attach more refs to a single element. Supports the callback ref
 * and the ref object. Useful e.g. to support forwardRef and useRef at the same
 * time
 */
/* @ngInject */
export function mergeRefs<T>(...refs: RefType<T>[]) {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref !== null && ref !== undefined) {
        (ref as React.MutableRefObject<T>).current = value;
      }
    });
  };
}

/**
* Copying a string to the clipboard
*/
/* @ngInject */
export function copyToClipboard(value: string) {
  navigator.clipboard.writeText(value);
}
