import { primary } from 'styles/global_defaults/colors';
import { MockDefinition, MockTypes } from '../axios/axios-mocks';

const coursesEmerald = [
  {
    id: 6787,
    catalogId: 'trlj1',
    name: 'ForTrans2',
    thumbnail: '/assets/brand/temp.png',
    releaseDate: '2022-11-19T18:30:00Z',
    endDate: null,
    officialReleaseDate: null,
    registrationCloseDate: null,
    closeDate: null,
    isClosed: false,
    isJourney: true,
    isSelfPaced: null,
    typeOfRegistrationInWords: 'Open within your organization',
    headerColor: null,
    nameFontColor: '#fff',
    logo: null,
    enrollmentLimitInDays: null,
    type: 'Journey',
    enrolledStudentsCount: 6,
    offeredTo: [],
    typeOfRegistration: 4,
    headerBackground: null,
    numEnrolled: 6,
    userCourse: null,
    entitlements: [],
    canTakeCourse: true,
    inJourneys: [],
    matchingEntitlements: false,
  },
  {
    id: 6791,
    catalogId: 'transnov21',
    name: 'transNov21',
    thumbnail: '/assets/brand/temp.png',
    releaseDate: '2022-11-19T18:30:00Z',
    endDate: null,
    officialReleaseDate: null,
    registrationCloseDate: null,
    closeDate: null,
    isClosed: false,
    isJourney: true,
    isSelfPaced: null,
    typeOfRegistrationInWords: 'Open within your organization',
    headerColor: null,
    nameFontColor: '#fff',
    logo: null,
    enrollmentLimitInDays: null,
    type: 'Journey',
    enrolledStudentsCount: 2,
    offeredTo: [],
    typeOfRegistration: 4,
    headerBackground: null,
    numEnrolled: 2,
    userCourse: null,
    entitlements: [],
    canTakeCourse: true,
    inJourneys: [],
    matchingEntitlements: false,
  },
  {
    id: 6804,
    catalogId: 'compli',
    name: 'RIFFBLkJRY',
    thumbnail: '/assets/brand/temp.png',
    releaseDate: '2022-11-21T18:30:00Z',
    endDate: null,
    officialReleaseDate: null,
    registrationCloseDate: null,
    closeDate: null,
    isClosed: false,
    isJourney: true,
    isSelfPaced: null,
    typeOfRegistrationInWords: 'Open within your organization',
    headerColor: null,
    nameFontColor: '#fff',
    logo: {
      url: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/logos/big_header/1a6f62faff669fdb9c4ef15865d469686a7963cd.png?1669173772',
      fileName: 'favicon-16x16.png',
    },
    enrollmentLimitInDays: null,
    type: 'Journey',
    enrolledStudentsCount: 4,
    offeredTo: [],
    typeOfRegistration: 4,
    headerBackground: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/aa835e074d53908e1bac4e364ccedad6e950ee51.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH&Expires=1697923449&Signature=xkP3xiZ2qpyhjQpnzt0Ox4x6D%2FI%3D',
    numEnrolled: 4,
    userCourse: null,
    entitlements: [],
    canTakeCourse: true,
    inJourneys: [],
    matchingEntitlements: false,
  },
];

const coursesLocal = [
  {
    id: 2,
    catalog_id: 'first_lj',
    name: 'First LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-07-09T10:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: primary,
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 3,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 3,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 8,
    catalog_id: 'second_lj',
    name: 'Second LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-08-31T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#a1b26c',
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 12,
    catalog_id: 'third_lj',
    name: 'Third LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-09-30T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/7a118f434213ae42d64ab5aece67a6df3d953b31.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=hmXOl7cyS%2F%2FtsdP5eShfG7EPiww%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 20,
    catalog_id: 'clone_third_lj',
    name: 'NEW NAVIGATION IS HERE this is to test the long name in the pop over in lecture page.',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-02-01T05:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/43194720d4ead3890d3bbec4c49e822b59766c81.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=ZTihnuY9kVZztA0aOfR1SU05V8I%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 27,
    catalog_id: 'twelfth_course_4',
    name: 'Twelfth Course - Single Completion',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-05-01T05:00:00Z',
    end_date: null,
    official_release_date: '2023-05-01T05:00:00Z',
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: false,
    is_self_paced: false,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Course',
    enrolled_students_count: 0,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 0,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 21,
    catalog_id: 'first_lj',
    name: 'First LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-07-09T10:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: primary,
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 3,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 3,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 81,
    catalog_id: 'second_lj',
    name: 'Second LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-08-31T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#a1b26c',
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 121,
    catalog_id: 'third_lj',
    name: 'Third LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-09-30T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/7a118f434213ae42d64ab5aece67a6df3d953b31.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=hmXOl7cyS%2F%2FtsdP5eShfG7EPiww%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 201,
    catalog_id: 'clone_third_lj',
    name: 'NEW NAVIGATION IS HERE this is to test the long name in the pop over in lecture page.',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-02-01T05:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/43194720d4ead3890d3bbec4c49e822b59766c81.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=ZTihnuY9kVZztA0aOfR1SU05V8I%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 271,
    catalog_id: 'twelfth_course_4',
    name: 'Twelfth Course - Single Completion',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-05-01T05:00:00Z',
    end_date: null,
    official_release_date: '2023-05-01T05:00:00Z',
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: false,
    is_self_paced: false,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Course',
    enrolled_students_count: 0,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 0,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 22,
    catalog_id: 'first_lj',
    name: 'First LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-07-09T10:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: primary,
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 3,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 3,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 82,
    catalog_id: 'second_lj',
    name: 'Second LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-08-31T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#a1b26c',
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 122,
    catalog_id: 'third_lj',
    name: 'Third LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-09-30T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/7a118f434213ae42d64ab5aece67a6df3d953b31.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=hmXOl7cyS%2F%2FtsdP5eShfG7EPiww%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 202,
    catalog_id: 'clone_third_lj',
    name: 'NEW NAVIGATION IS HERE this is to test the long name in the pop over in lecture page.',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-02-01T05:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/43194720d4ead3890d3bbec4c49e822b59766c81.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=ZTihnuY9kVZztA0aOfR1SU05V8I%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 272,
    catalog_id: 'twelfth_course_4',
    name: 'Twelfth Course - Single Completion',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-05-01T05:00:00Z',
    end_date: null,
    official_release_date: '2023-05-01T05:00:00Z',
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: false,
    is_self_paced: false,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Course',
    enrolled_students_count: 0,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 0,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 23,
    catalog_id: 'first_lj',
    name: 'First LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-07-09T10:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: primary,
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 3,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 3,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 83,
    catalog_id: 'second_lj',
    name: 'Second LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-08-31T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#a1b26c',
    name_font_color: '#000',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 123,
    catalog_id: 'third_lj',
    name: 'Third LJ',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2022-09-30T22:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: '2023-12-31T05:00:00Z',
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/7a118f434213ae42d64ab5aece67a6df3d953b31.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=hmXOl7cyS%2F%2FtsdP5eShfG7EPiww%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 203,
    catalog_id: 'clone_third_lj',
    name: 'NEW NAVIGATION IS HERE this is to test the long name in the pop over in lecture page.',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-02-01T05:00:00Z',
    end_date: null,
    official_release_date: null,
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: true,
    is_self_paced: null,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Journey',
    enrolled_students_count: 1,
    offered_to: [],
    type_of_registration: 4,
    header_background: 'https://d2lyqn1wunb6x9.cloudfront.net/courses/header_backgrounds/43194720d4ead3890d3bbec4c49e822b59766c81.jpeg?AWSAccessKeyId=AKIAX2L5PPJEXNHPYSNH\u0026Expires=1698164745\u0026Signature=ZTihnuY9kVZztA0aOfR1SU05V8I%3D',
    num_enrolled: 1,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
  {
    id: 273,
    catalog_id: 'twelfth_course_4',
    name: 'Twelfth Course - Single Completion',
    thumbnail: '/assets/brand/temp.png',
    release_date: '2023-05-01T05:00:00Z',
    end_date: null,
    official_release_date: '2023-05-01T05:00:00Z',
    registration_close_date: null,
    close_date: null,
    is_closed: false,
    is_journey: false,
    is_self_paced: false,
    type_of_registration_in_words: 'Open within your organization',
    header_color: '#748499',
    name_font_color: '#fff',
    logo: null,
    enrollment_limit_in_days: null,
    type: 'Course',
    enrolled_students_count: 0,
    offered_to: [],
    type_of_registration: 4,
    header_background: null,
    num_enrolled: 0,
    user_course: null,
    entitlements: [],
    can_take_course: true,
    in_journeys: [],
    matching_entitlements: false,
  },
];

const getResult = (page, pageSize) => {
  const totalCourses = coursesLocal.length;
  const coursesNumber = page * pageSize;
  const initialLimit = coursesNumber - pageSize;

  const result = {
    total: totalCourses,
    hasMore: coursesNumber < totalCourses,
    items: coursesLocal.slice(initialLimit, coursesNumber),
  };

  return result;
};

const DiscoveryMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/courses/discovery.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: [...coursesLocal, ...coursesLocal, ...coursesLocal, ...coursesLocal],
    },
  },
  {
    type: MockTypes.GET,
    url: '/courses/discovery.json?page=1&page_size=3',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: coursesLocal.slice(0, 3),
    },
  },
  {
    type: MockTypes.GET,
    url: '/courses/discovery.json?page=1&page_size=7',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: getResult(1, 7),
    },
  },
  {
    type: MockTypes.GET,
    url: '/courses/discovery.json?page=2&page_size=7',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: getResult(2, 7),
    },
  },
  {
    type: MockTypes.GET,
    url: '/courses/discovery.json?page=3&page_size=7',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: getResult(3, 7),
    },
  },
  {
    type: MockTypes.GET,
    url: '/courses/discovery.json?page=4&page_size=5',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: coursesLocal.slice(14, 20),
    },
  },
  {
    type: MockTypes.GET,
    url: '/courses/discovery.json?page=4&page_size=20',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: [...coursesLocal, ...coursesLocal, ...coursesLocal, ...coursesLocal],
    },
  },
];
export default DiscoveryMocks;
