/* @ngInject */
export default function NvNotificationsPopover(
  $timeout,
  $document,
  $stateParams,
  $state,
  nvUtil,
  RailsRoutes,
  CurrentUserManager,
  DashboardManager,
  CurrentPermissionsManager,
  $uibModal,
  _,
  config,
  CurrentCourseManager,
) {
  const ARROW_WIDTH = 10;
  const RIGHT_TOP_PADDING = 5;

  return {
    restrict: 'A',
    scope: {
      templateUrl: '=',
      popoverId: '@?',
      currentInstitution: '=?',
      gridController: '=?',
      popoverPlacement: '@',
      popoverOpenCallback: '&?',
      popoverCloseCallback: '&?',
      arrowSelector: '@?',
      popoverLabel: '@?',
    },
    transclude: true,
    templateUrl: 'shared/templates/nv-notifications-popover.html',
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      _.extend(this, {
        $stateParams,
        $state,
        RailsRoutes,
        CurrentUserManager,
        CurrentPermissionsManager,
        currentUser: CurrentUserManager.user,
        config,
        DashboardManager,
        CurrentCourseManager,

        showPopover: false,
        togglePopover,
        popoverClick,
        closePopover,
        showLanguageModal,
        currentCourseCatalogId,
        popoverStyles,
        popoverArrowStyles,
      });

      /** Popover Control * */
      function togglePopover() {
        if (vm.showPopover) {
          closePopover();
        } else {
          openPopover();
        }
      }

      function openPopover() {
        $timeout(() => {
          vm.showPopover = true;
          $scope.$emit('setPopoverId', vm.popoverId);
          $document.on('click keypress', closePopover);
          vm.popoverOpenCallback?.();
        });
      }

      function closePopover(event) {
        // The 'branding-header' id is coming from branding-header component to know when a click is being programmaticaly dispatched by that component and prevent the popover to close.
        if ((event?.type === 'keypress' && event?.which !== 13) || event?.target?.id === 'branding-header') {
          return;
        }
        $element.find('.popover').hide();
        vm.showPopover = false;
        $timeout(() => {
          $scope.$emit('setPopoverId', '');
          $document.off('click keypress', closePopover);
          vm.popoverCloseCallback?.();
        });
      }

      function popoverClick(event) {
        event.stopPropagation();
      }

      /** Popover Positioning * */
      function popoverTop() {
        if (vm.popoverPlacement === 'bottom') {
          return $element.position().top + $element.outerHeight();
        }
        if (vm.popoverPlacement === 'right' || vm.popoverPlacement === 'left') {
          return $element.position().top - RIGHT_TOP_PADDING;
        }
        return null;
      }

      function popoverStyles() {
        if (nvUtil.isRtl()) {
          return {
            top: popoverTop(),
            right: popoverLeft(),
          };
        }
        return {
          top: popoverTop(),
          left: popoverLeft(),
        };
      }


      function popoverArrowStyles() {
        if (nvUtil.isRtl()) {
          return {
            top: popoverArrowTop(),
            right: popoverArrowLeft(),
          };
        }
        return {
          top: popoverArrowTop(),
          left: popoverArrowLeft(),
        };
      }

      function popoverLeft() {
        if (vm.popoverPlacement === 'bottom') {
          return '10px';
        }
        if (vm.popoverPlacement === 'right' || vm.popoverPlacement === 'left') {
          return $element.position().left + $element.outerWidth();
        }
        return null;
      }

      function popoverArrowTop() {
        if (vm.popoverPlacement === 'right' || vm.popoverPlacement === 'left') {
          return getArrowTarget().outerHeight() / 2 + RIGHT_TOP_PADDING;
        }
        return null;
      }

      function popoverArrowLeft() {
        if (vm.popoverPlacement === 'bottom') {
          if (nvUtil.isRtl()) {
            // Arrow doesn't work as expected with positioning from right.
            // LTR `left: 0` andRTL `right: 0` positions different.
            // So need to add a 13px correction
            return $(window).width() - $element.offset().left - $element.outerWidth() / 2 - ARROW_WIDTH - 13;
          }
          // arrow is 20px wide
          return $element.position().left + $element.outerWidth() / 2 - ARROW_WIDTH;
        }
        return null;
      }

      // helper
      function getArrowTarget() {
        const arrowElem = $element.find(`${vm.arrowSelector}:visible`);

        if (arrowElem.length) {
          return arrowElem;
        }
        return $element;
      }

      /** Setup * */
      function closeListener() {
        if (vm.showPopover) {
          $timeout(() => {
            closePopover();
          });
        }
      }

      function showLanguageModal() {
        const context = { isLearner: true };
        const LanguageModal = $uibModal.open({
          templateUrl: 'shared/templates/language-modal.html',
          controller: 'LanguageModalCtrl as vm',
          resolve: {
            currentUser: vm.currentUser,
            context,
          },
        });
      }

      function currentCourseCatalogId() {
        if (vm.CurrentUserManager.currentUserCourse) {
          return vm.CurrentUserManager.coursesHash[vm.CurrentUserManager.currentUserCourse.courseId].catalogId;
        }
        return null;
      }

      $scope.$on('lhsScroll', closeListener);
      $scope.$on('closeLhsPopover', closeListener);

      /** Cleanup * */
      $scope.$on('$destroy', () => {
        $document.off('click', closePopover);
      });
    },
    controllerAs: 'popoverVm',
    bindToController: true,
  };
}
