import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';
import getTicksArray from '../../shared/services/ticks-for-sliders';

/* @ngInject */
export default function EvaluationsFormController(
  $scope,
  $stateParams,
  $state,
  $window,
  $timeout,
  AlertMessages,
  ScrollFocusConnectorFactory,
  validationConstants,

  CurrentCourseManager,
  EvaluationsTaskManager,
  CurrentUserManager,
  ReportsManager,
  StateManager,
  ExercisesManager,
  $uibModal,
  TimelinesManager,
  ReactTimelineService,
  PageLevelManager,
  CurrentPermissionsManager,
  config,
  ReactLecturePageContext,
) {
  const vm = this;

  vm.validationConstants = validationConstants;
  vm.manager = EvaluationsTaskManager;
  vm.ExercisesManager = ExercisesManager;
  vm.ReportsManager = ReportsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentPermissionsManager = CurrentPermissionsManager;
  vm.$stateParams = $stateParams;
  vm.initializeEvaluationScroll = initializeEvaluationScroll;
  vm.PageLevelManager = PageLevelManager;
  vm.config = config;
  vm.afterSuccessState = (vm.$stateParams.mentorDashboard === true ? 'mentor-dashboard' : 'lecture-page');
  vm.getTicks = getTicks;
  vm.ticksArrays = [];

  function getTicks(floor, ceil, i) {
    if (!vm.ticksArrays[i]) {
      const maxElements = 5;
      vm.ticksArrays[i] = getTicksArray(floor, ceil, maxElements);
    }
    return vm.ticksArrays[i];
  }

  initializeEvaluationScroll();

  vm.submit = function (isSave) {
    const pointsReceivedFromPreviousAttempt = EvaluationsTaskManager.currentPeerReview.pointsReceived;

    EvaluationsTaskManager.currentPeerReview.submit(isSave)
      .then(() => {
        if (!isSave) {
          if (vm.CurrentCourseManager.course.gamificationEnabled && !pointsReceivedFromPreviousAttempt && EvaluationsTaskManager.currentPeerReview.pointsReceived
                && EvaluationsTaskManager.currentPeerReview.customQuestions.numEvaluated <= EvaluationsTaskManager.currentPeerReview.customQuestions.targetGoal) {
            ReactTimelineService.updateTimeline(EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId);
            TimelinesManager.updateComponentPointsAndProgress(EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId,
              'exercise_peer_review', EvaluationsTaskManager.currentPeerReview.customQuestions.id,
              EvaluationsTaskManager.currentPeerReview.pointsReceived, null,
              EvaluationsTaskManager.currentPeerReview.customQuestions.progress);

            $uibModal.open({
              templateUrl: 'shared/templates/points-modal.html',
              windowClass: 'points-modal',
              controller: 'PointsModalCtrl as vm',
              resolve: {
                pointsReceived: EvaluationsTaskManager.currentPeerReview.pointsReceived,
                leaderboardPoints: EvaluationsTaskManager.currentPeerReview.leaderboardPoints,
                leaderboardRank: EvaluationsTaskManager.currentPeerReview.leaderboardRank,
                priorLeaderboardRank: EvaluationsTaskManager.currentPeerReview.priorLeaderboardRank,
                extras: null,
              },
            }).closed.then(() => {
              vm.redirectAfterSuccess();
            });
          } else {
            AlertMessages.success('FORM.SUCCESS_BANG', 'EVALUATIONS.SUBMITTED_SUCCESSFULLY');
            ReactTimelineService.updateTimeline(EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId);
            TimelinesManager.updateComponentProgress(null, 'exercise_peer_review', EvaluationsTaskManager.currentPeerReview.customQuestions.id,
              EvaluationsTaskManager.currentPeerReview.customQuestions.progress);
            vm.redirectAfterSuccess();
          }

          // Scroll to the top of the evaluation form after submitting each evaluation.
          $('.evaluation-content').scrollTop(0);
        } else {
          ReactTimelineService.updateTimeline(EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId);
          TimelinesManager.updateComponentProgress(null, 'exercise_peer_review', EvaluationsTaskManager.currentPeerReview.customQuestions.id,
            EvaluationsTaskManager.currentPeerReview.customQuestions.progress);
        }

        ReactLecturePageContext?.updateLecturePage?.();
      }).catch(
        (error) => {
          // Check if deadline expired, then reload to task page
          if (error.data.error?.code === 'report.error.expired') {
            $state.go('lecture-page', {
              id: EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId,
              lectureActivityId: EvaluationsTaskManager.currentPeerReview.customQuestions.id,
            });
          } else {
            AlertMessages.error('', 'FORM.ERROR');
          }
        },
      );

    vm.form.$setPristine();
  };

  vm.redirectAfterSuccess = function () {
    if ((EvaluationsTaskManager.currentPeerReview.customQuestions.numEvaluated >= EvaluationsTaskManager.currentPeerReview.customQuestions.targetGoal
            && !vm.CurrentPermissionsManager.isMentor() && !vm.CurrentUserManager.isAdmin())
          || !EvaluationsTaskManager.currentPeerReview.customQuestions.moreReviewablesExist
          || ($stateParams.reportId && !vm.CurrentPermissionsManager.isMentor())) {
      if (vm.afterSuccessState === 'lecture-page') {
        $state.go('lecture-page', {
          id: EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId,
          lectureActivityId: EvaluationsTaskManager.currentPeerReview.customQuestions.id,
        });
      } else {
        $state.go('mentees', {
          ownerType: 'User',
          ownerId: vm.CurrentUserManager.user.id,
          filter: 'needsPrivateFeedback',
          sort: 'progress',
          sortOrder: 'desc',
        });
      }
    } else {
      EvaluationsTaskManager.getNewPeerReview(CurrentCourseManager.course.catalogId, $stateParams.exerciseId)
        .then(() => {
          vm.initializeEvaluationScroll();
        }, () => {
          if (vm.afterSuccessState === 'lecture-page') {
            $state.go('lecture-page', {
              id: EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId,
              lectureActivityId: EvaluationsTaskManager.currentPeerReview.customQuestions.id,
            });
          } else {
            $state.go('mentees', {
              ownerType: 'User',
              ownerId: vm.CurrentUserManager.user.id,
              filter: 'needsPrivateFeedback',
              sort: 'progress',
              sortOrder: 'desc',
            });
          }
        });
    }
  };

  vm.skipCurrentPeerReview = function () {
    EvaluationsTaskManager.currentPeerReview.skip()
      .then(() => {
        if ($state.is('evaluation-edit')) {
          $state.go('evaluation-new', { catalogId: $stateParams.catalogId, exerciseId: $stateParams.exerciseId });
        } else {
          EvaluationsTaskManager.getNewPeerReview(CurrentCourseManager.course.catalogId, $stateParams.exerciseId)
            .then(() => {

            }, () => {
              if (vm.afterSuccessState === 'lecture-page') {
                $state.go('lecture-page', {
                  id: EvaluationsTaskManager.currentPeerReview.customQuestions.lecturePageId,
                  lectureActivityId: EvaluationsTaskManager.currentPeerReview.customQuestions.id,
                });
              } else {
                $state.go('mentees', {
                  ownerType: 'User',
                  ownerId: vm.CurrentUserManager.user.id,
                });
              }
            });
        }
      });

    vm.form.$setPristine();
  };

  function initializeEvaluationScroll() {
    ScrollFocusConnectorFactory.register($('.submission-content'), $('.lightbox-panel'), EvaluationsTaskManager.currentPeerReview.id, 'peer-review-submission');
    ScrollFocusConnectorFactory.register($('.evaluation-content'), $('.lightbox-panel'), EvaluationsTaskManager.currentPeerReview.id, 'peer-review-evaluation');
  }


  vm.scrollToSubmission = function () {
    ScrollFocusConnectorFactory.scrollTo(EvaluationsTaskManager.currentPeerReview.id, 'peer-review-submission', { offset: 55 });
  };

  vm.scrollToEvaluation = function () {
    ScrollFocusConnectorFactory.scrollTo(EvaluationsTaskManager.currentPeerReview.id, 'peer-review-evaluation', { offset: 55 });
  };

  vm.launchAssignmentIntroductionModal = function () {
    $uibModal.open({
      templateUrl: 'submissions/templates/assignment-introduction-modal.html',
      windowClass: 'assignment-intro-modal full-screen-modal-handheld full-screen-modal-tablet',
      controller: 'AssignmentIntroModalController as introModalCtrl',
      resolve: {
        exercise: vm.ExercisesManager.currentExercise,
      },
    });
  };


  /* Warning when you navigate away with unsaved changes */
  const msg = 'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY';

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges, 'shared/templates/modal-navigate-away.html', msg);

  $scope.$on('$destroy', () => {
    ScrollFocusConnectorFactory.deregister(EvaluationsTaskManager.currentPeerReview.id, 'peer-review-submission');
    ScrollFocusConnectorFactory.deregister(EvaluationsTaskManager.currentPeerReview.id, 'peer-review-evaluation');

    vm.deregisterStateChangeStart?.();
  });

  $scope.$on('slideEnded', () => {
    $timeout(() => {
      vm.form.$setDirty();
    });
  });

  // fix slider timing issue
  $timeout(() => {
    $scope.$broadcast('reCalcViewDimensions');
  }, 500, false);

  /** Private Helpers * */
  function checkUnsavedChanges() {
    return vm.form?.$dirty;
  }
}
