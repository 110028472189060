import { css } from '@emotion/react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

import { ClickableContainerProps } from 'components/clickable-container';
import { NovoAIItemType } from 'redux/schemas/models/lecture-page';
import NvGradientBorderButton from '../../../../../../shared/components/button/nv-gradient-border-button';
import GradientAiIcon from './gradient-ai-icon';
import { config } from '../../../../../../../config/config.json';

type GenerateButtonProps = {
  text?: string
  onClick: ClickableContainerProps['onClick'],
  className?: string;
};

const styles = css`
  .ai-btn {
    // Considering the border width with 220px
    max-width: 224px;
  }
`;

const GenerateButton = (props: GenerateButtonProps) => {
  const {
    text = t.LECTURE_PAGES.LHS.NOVO_AI.GENERATE_NOW(),
    onClick,
    className,
  } = props;

  const novoAI = useSelector(state => state.app.lecturePage.novoAI);

  const getWarningText = () => {
    if (
      [NovoAIItemType.SUMMARY, NovoAIItemType.KEY_TAKEAWAYS].includes(novoAI?.itemType)
      && novoAI?.lectureComponentToReplace
    ) {
      return t.LECTURE_PAGES.LHS.NOVO_AI.AI_REGENERATE_WARNING();
    }

    return t.LECTURE_PAGES.LHS.NOVO_AI.AI_RESULT_WARNING();
  };

  return (
    <div
      className={`d-flex flex-column mt-4 text-center align-items-center ${className ?? ''}`}
      css={styles}
    >
      <NvGradientBorderButton
        onClick={onClick}
        className='ai-btn justify-content-center align-items-center py-2 px-5'
        dataQa={config.pendo.novoAi.generateNow}
      >
        <GradientAiIcon icon='gen-ai' size='small' className='pr-2' />
        <span className='text-large-regular bold'>{text}</span>
      </NvGradientBorderButton>
      <div className='mt-2 text-gray-2 text-small semi-bold'>
        {getWarningText()}
      </div>
    </div>
  );
};

export default GenerateButton;
