import { css } from '@emotion/react';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { isEmpty, values } from 'underscore';
import { useAppDispatch } from 'redux/store';

// Actions
import {
  currentSendingNowCommunication,
  getScheduledCommunications,
  getScheduledDueDates,
  hidePastScheduledCommunications,
  resetScheduledCommunicationsTimeline,
  updateCommunicationPartially,
} from 'redux/actions/course-communications';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

// Schemas
import { ItemState, ScheduledTimeLineLoadingType } from 'redux/schemas/models/course-communication';
import {
  isScheduledCommunicationsLoaded,
  getScheduledCommunications as getScheduledCommunicationsState,
} from 'redux/selectors/course-communications';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';

// Styles
import { gray4 } from 'styles/global_defaults/colors';
import { quarterSpacing, doubleSpacing, largeSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

// Components
import LoadingPlaceholder from 'communications/course_communications/components/loading-placeholder';
import ScheduledDayHeader from 'communications/course_communications/components/scheduled-day-header';
import CommunicationItem from 'communications/course_communications/components/communication-item/communication-item';
import NvIcon from 'shared/components/nv-icon';
import PusherService from 'shared/services/pusher-service';

const styles = css`
  .view-more-row {
    width: 100%;
    height: ${largeSpacing}px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    :before {
      position: absolute;
      content: '';
      width: ${quarterSpacing}px;
      height:100%;
      top: 0;
      left: 0;
      background-color: ${gray4};
    }
  }
  .no-communications {
    color: ${gray4};
  }
  .no-journey-communications {
    display: flex;
    align-items: center;
    padding: 0px ${standardSpacing}px;
    .icon {
      color: ${gray4};
      padding: ${standardSpacing}px;
    }
  }
  .no-future-comms-row {
    color: ${gray4};
    height: ${doubleSpacing}px;
  }
  .icons-wrapper {
    .icon {
      color: ${gray4};
      height: ${doubleSpacing}px;
      width: ${doubleSpacing}px;
    }
  }
  .intro-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const ViewMoreLink = (props: {
  displayText: string,
  onClick: Function,
}) => (
  <div className='text-primary mt-6' css={styles} onClick={() => props.onClick()}>
    <div className='view-more-row px-4'>{props.displayText}</div>
  </div>
);

// Backend needs a reference to start. We are keeping it as today's start time
const START_TIME = moment().startOf('D');
const SCROLL_PADDING = 20;

const ScheduledCommunications = () => {
  const { RailsRoutes } = useContext(AngularServicesContext);
  const { isJourney, id: courseId } = useSelector((state) => getCurrentCourse(state));
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentSendingCommunication = useSelector((state) => state.app.currentSendingNowCommunication);
  const [pastPageNo, setPastPageNo] = useState(0);
  const [futurePageNo, setFuturePageNo] = useState(1);

  const scrollingElement = document.getElementById('communications-tab-content');
  const isPageBottom = useInfiniteScroll(scrollingElement, SCROLL_PADDING);

  const isTimelineLoaded = useSelector((state) => isScheduledCommunicationsLoaded(state));
  const aliases = useSelector((state) => getCourseAliases(state));
  const {
    dates: dateGrouped,
    totalInPast,
    leftInPast: pastCommunicationsToLoad,
    leftInFuture: futureCommunicationsToLoad,
    isFutureLoading,
    isPastLoading,
    totalInFuture,
  } = useSelector(state => getScheduledCommunicationsState(state));

  const sortedDates = Object.keys(dateGrouped).sort((a, b) => +moment(a) - +moment(b));
  const totalCount = totalInPast + totalInFuture;

  const dispatch = useAppDispatch();
  const fetchScheduledCommunications = useCallback((props) => {
    dispatch(getScheduledCommunications(props));
  }, []);
  const fetchScheduledDueDates = useCallback((props) => {
    dispatch(getScheduledDueDates(props));
  }, []);
  const hidePastCommunications = useCallback((props) => {
    dispatch(hidePastScheduledCommunications(props));
  }, []);
  const resetTimeline = useCallback(() => {
    dispatch(resetScheduledCommunicationsTimeline());
  }, []);

  // Fetch all scheduled due dates
  useEffect(() => {
    if (catalogId) {
      fetchScheduledDueDates({ catalogId });
    }
  }, [catalogId, fetchScheduledDueDates]);

  // Reset timeline on mount
  useEffect(() => {
    if (catalogId) {
      resetTimeline();
    }
  }, [catalogId, resetTimeline]);

  // Check if there is a recently created Communication to be sent now
  useEffect(() => {
    if (currentSendingCommunication) {
      const pusherChannel = PusherService.initializeCourseChannel(courseId);
      if (currentSendingCommunication.state === ItemState.SENT) {
        pusherChannel.unbind('sent_course_communication_notification');
        dispatch(currentSendingNowCommunication());
      } else {
        pusherChannel.bind('sent_course_communication_notification', ({ course_communication: courseCommunication }) => {
          dispatch(updateCommunicationPartially({
            id: courseCommunication.id,
            state: courseCommunication.state,
          }));
        });
      }
    }
  }, [currentSendingCommunication]);

  // Method to fetch paginated communications
  const fetchCommunications = useCallback((
    display: ScheduledTimeLineLoadingType,
    page: number,
    showCommunications: boolean = true,
  ) => {
    if (catalogId && page > 0) {
      fetchScheduledCommunications({
        catalogId,
        startTime: START_TIME.toISOString(),
        page,
        display,
        showCommunications,
      });
    }
  }, [catalogId, fetchScheduledCommunications]);

  // If past page change, fetch past ones
  useEffect(() => {
    fetchCommunications(ScheduledTimeLineLoadingType.PAST, pastPageNo);
  }, [pastPageNo, fetchCommunications]);

  // If future page change, fetch future ones
  useEffect(() => {
    fetchCommunications(ScheduledTimeLineLoadingType.FUTURE, futurePageNo);
  }, [futurePageNo, fetchCommunications]);

  // A request need to made on first load, to display the count of past
  // communications
  useEffect(() => {
    fetchCommunications(ScheduledTimeLineLoadingType.PAST, 1, false);
  }, [fetchCommunications]);

  // On View More, increment page numbers
  const onViewMoreCommunication = useCallback((display: ScheduledTimeLineLoadingType) => {
    if (display === ScheduledTimeLineLoadingType.PAST) {
      setPastPageNo((prevPageNo) => prevPageNo + 1);
    } else {
      setFuturePageNo((prevPageNo) => prevPageNo + 1);
    }
  }, []);

  // Hide past communications
  const hidePast = useCallback(() => {
    setPastPageNo(0);
    hidePastCommunications({
      startTime: START_TIME.toISOString(),
    });
  }, [hidePastCommunications]);

  useEffect(() => {
    if (scrollingElement
      && isPageBottom // fetch communications when user scrolled to bottom
      && futureCommunicationsToLoad > 0
      && !isFutureLoading
      && !isEmpty(sortedDates)) {
      onViewMoreCommunication(ScheduledTimeLineLoadingType.FUTURE);
    }
  }, [
    isPageBottom,
    onViewMoreCommunication,
    sortedDates,
    isFutureLoading,
    scrollingElement,
    futureCommunicationsToLoad,
  ]);
  const [prefix, suffix] = t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.SCHEDULED.INTRO_THIRD().split('LEGACY_EMAILS_LINK');

  const getNoContentComponent = () => {
    if (isJourney) {
      return (
        <div className='no-journey-communications bg-info mt-6 text-center'>
          <div className='bold'>
            <NvIcon size='large' icon='calendar' />
          </div>
          <div className='text-regular'>
            {t.JOURNEY_COMMUNICATIONS.NO_CONTENT.SCHEDULED()}
          </div>
        </div>
      );
    }
    return (
      <div className='no-communications mt-6 bold text-center'>
        <NvIcon size='ultra-large pb-4' icon='calendar' />
        {t.COURSE_COMMUNICATIONS.NO_CONTENT.SCHEDULED()}
      </div>
    );
  };

  return (
    <React.Fragment>
      {!isTimelineLoaded && <LoadingPlaceholder />}
      {isTimelineLoaded && (
        <React.Fragment>
          <div css={styles}>
            <div className='page-title mt-6 text-center'>{(isJourney ? t.JOURNEY_COMMUNICATIONS : t.COURSE_COMMUNICATIONS).TABS.TITLE.SCHEDULED()}</div>
            <div className='text-body my-4 intro-description'>
              {
                isJourney ? (
                  <p>{t.JOURNEY_COMMUNICATIONS.TABS.DESCRIPTION.SCHEDULED.INTRO_FIRST()}</p>
                )
                  : (
                    <React.Fragment>
                      <p>{t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.SCHEDULED.INTRO_FIRST({ ...aliases.lectureAliases })}</p>
                      <p>{t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.SCHEDULED.INTRO_SECOND({ ...aliases.lectureAliases })}</p>
                      <p>
                        <span className='bold'>{t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.SCHEDULED.PLEASE_NOTE()}</span>
                        {prefix}
                        <a
                          href={RailsRoutes.contactStudentsPath(catalogId)}
                          className='text-primary'
                        >
                          {t.COURSE_COMMUNICATIONS.TABS.DESCRIPTION.SCHEDULED.LEGACY_EMAILS()}
                        </a>
                        {suffix}
                      </p>
                    </React.Fragment>
                  )
              }
            </div>
          </div>
          {totalCount === 0
            ? (
              <div css={styles}>
                {getNoContentComponent()}
              </div>
            ) : (
              <React.Fragment>
                {pastCommunicationsToLoad > 0 && (
                  isPastLoading
                    ? <LoadingPlaceholder />
                    : (
                      <ViewMoreLink
                        displayText={t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.VIEW_MORE.PAST(pastCommunicationsToLoad)}
                        onClick={() => onViewMoreCommunication(ScheduledTimeLineLoadingType.PAST)}
                      />
                    )
                )}
                {totalInPast > 0 && pastCommunicationsToLoad === 0 && (
                  <ViewMoreLink
                    displayText={t.COURSE_COMMUNICATIONS.SCHEDULED_TAB.VIEW_MORE.HIDE_PAST()}
                    onClick={() => hidePast()}
                  />
                )}
                <div className='mt-4'>
                  { sortedDates?.map(date => (
                    dateGrouped[date].canBeShown
                      ? (
                        <div className='mb-6' key={date}>
                          <ScheduledDayHeader
                            date={date}
                            activities={values(dateGrouped[date]?.activities)}
                            lessons={values(dateGrouped[date]?.lessons)}
                          />
                          {dateGrouped[date]?.communicationIds?.map(communicationId => (
                            <CommunicationItem
                              key={communicationId.toString()}
                              communicationId={communicationId}
                              showOwnerActivity
                            />
                          ))}
                        </div>
                      )
                      : ''
                  ))}
                </div>
              </React.Fragment>
            )}
          {!isPastLoading && isFutureLoading && <LoadingPlaceholder />}
          {totalCount !== 0 && totalInFuture === 0 ? (
            <div className='mt-6' css={styles}>
              <div className='view-more-row no-future-comms-row icons-wrapper px-4  bold'>
                <NvIcon size='large mr-4' icon='calendar' />
                {(isJourney ? t.JOURNEY_COMMUNICATIONS : t.COURSE_COMMUNICATIONS).NO_CONTENT.SCHEDULED_FUTURE()}
              </div>
            </div>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ScheduledCommunications;
