import { css } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { getCurrentCourse } from 'redux/selectors/course';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import useClickOutside from 'shared/hooks/use-click-outside';
import { useSelector } from 'react-redux';
import { hexToRgbaString, black, white } from 'styles/global_defaults/colors';
import { lectureTopGutterZIndex } from 'styles/global_defaults/scaffolding';

import { RootState } from 'redux/schemas';
import ClickableContainer from 'components/clickable-container';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import { getLinkedCourses } from 'redux/selectors/collections';
import LinkedCourseModal from 'content-library/components/collection-home/linked-course-modal';
import { getSanitizedStyles } from 'shared/utils';
import { config } from '../../../../config/config.json';
import { LecturePageMode } from '..';
import { getLectureHeaderHeight, LecturePageEditContentParams, useLecturePageMainPanel } from '../lecture-page-content';
import LectureContentAdminDropdown from './lecture-content-admin-dropdown';
import LectureContentEditHeaderButton from './lecture-content-edit-header-button';
import LectureContentReleaseDate from './lecture-content-release-date';
import LectureContentLinkedDropdown from './lecture-content-linked-dropdown';

const styles = css`
  // TODO: I don't think this works in IE11; let's make a fallback for that
  position: sticky;
  top: 0;
  z-index: ${lectureTopGutterZIndex};
  height: 40px;

  [name="lesson-header-color-input"] {
    height: 40px;
  }

  .has-linked-courses {
    text-decoration: underline;
  }
`;

type LectureContentTopGutterParams = {
  isHoverOnHeader: boolean;
  setIsHoverOnHeader: (newValue: boolean) => void;
  contentTrigger: number;
} & LecturePageEditContentParams;

/** A region at the to of the lecture page content area that contains exclusively admin (non-learner) tools */
export const LectureContentTopGutter = (props: LectureContentTopGutterParams) => {
  const {
    lecturePage, mode, catalogId, isHoverOnHeader, setIsHoverOnHeader,
  } = props;
  const course = useSelector((state) => getCurrentCourse(state));

  const { CurrentPermissionsManager, RailsRoutes, InteroperabilityRoutes } = useContext(AngularServicesContext);
  const [showLockPopover, setShowLockPopover] = useState(false);
  const popoverRef = useRef(null);

  const isEditMode = mode === LecturePageMode.EDIT;
  // If false, hides all course admin tools. We do this check in here instead of optionally rendering the entirety of the top gutter so that we still reserve the vertical space
  // for this gutter area. Omitting this would render the title too high on the page
  const canViewAdminTools = !course.isContentManagementCollection ? CurrentPermissionsManager.hasCourseAdminPermissions() : true;
  const canViewAdminCog = course.isContentManagementCollection
    ? CurrentPermissionsManager.hasCollectionBuilderPermissions()
    : CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isCourseBuilder() || CurrentPermissionsManager.isConfigAndRegistrationRole();

  const [textColor, setTextColor] = useState<string>();
  const [showLinkedCoursesModel, setShowLinkedCoursesModel] = useState<boolean>(false);

  const lecturePageMainPanel = useLecturePageMainPanel();

  const isOnContent = useInfiniteScroll(lecturePageMainPanel, props.contentTrigger, false);
  const linkedCourses = useSelector((state: RootState) => getLinkedCourses(state, lecturePage.id));

  const hoverForegroundStyles = canViewAdminTools ? css`
    ${isEditMode && css`
      :hover:before {
        content: "";
        position: absolute;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
      }
    `};

    ${isHoverOnHeader && css`
      color: ${textColor} !important;
      background-color: ${isOnContent ? hexToRgbaString(white, 0.9) : hexToRgbaString(black, 0.2)};
    `};

    .lesson-lock {
      left: 50%;
      transform: translateX(-50%);
    }
  ` : null;

  const scrollable = useRef(null);

  // Initializing the ref of the parent scrollable div
  useEffect(() => {
    scrollable.current = document.getElementsByClassName('main-panel-scrollable')?.[0];
  }, []);

  // Change the text color according to the settings
  useEffect(() => {
    if (lecturePage.showTitle) {
      // Set the settings color only if the header is displayed
      setTextColor(lecturePage.viewOptions?.titleColor);
    } else {
      // Else always set the color to black
      setTextColor(hexToRgbaString(black));
    }
  }, [lecturePage.showTitle, lecturePage.viewOptions?.titleColor]);

  const setTextColorOnScroll = useCallback(() => {
    const headerHeight = Number(getLectureHeaderHeight(lecturePage).replace('px', ''));

    // On scroll set the color according to the current scroll top. If it is
    // scrolled after the header section then set the color always to black
    setTextColor(!lecturePage.showTitle || scrollable.current.scrollTop >= headerHeight
      ? hexToRgbaString(black)
      : lecturePage.viewOptions?.titleColor);
  }, [lecturePage]);

  useEffect(() => {
    const scrollNode = scrollable.current;
    if (scrollNode) {
      scrollNode.addEventListener('scroll', setTextColorOnScroll);
    }

    return () => {
      if (scrollNode) {
        scrollNode.removeEventListener('scroll', setTextColorOnScroll);
      }
    };
  }, [setTextColorOnScroll]);

  useClickOutside(popoverRef, () => {
    if (showLockPopover) {
      setShowLockPopover(false);
    }
  });

  const getPopoverText = () => {
    let popoverText = '';
    const params = {
      learnersAlias: course.learnersName.downcasedPluralized,
      activityAlias: course.lectureName.downcasedSingularized,
      action: 'other',
    };

    if (lecturePage.studentPrerequisites.length === 1) {
      popoverText = t.ACTIVITY.LOCKED_POPOVER_SINGLE({
        ...params,
        activityName: lecturePage.studentPrerequisites[0].title,
        activityLink: `${InteroperabilityRoutes.activityPath(
          course,
          lecturePage.studentPrerequisites[0],
          CurrentPermissionsManager.hasCourseAdminPermissions(),
        )}`,
      });
    } else {
      const translateText = lecturePage.studentPrerequisites[0].type === lecturePage.studentPrerequisites[1].type
        ? t.ACTIVITY.LOCKED_POPOVER_MULTIPLE_SAME
        : t.ACTIVITY.LOCKED_POPOVER_MULTIPLE_DIFFERENT;

      popoverText = translateText({
        ...params,
        action1: 'other',
        activityFirstName: lecturePage.studentPrerequisites[0].title,
        activityFirstLink: `${InteroperabilityRoutes.activityPath(
          course,
          lecturePage.studentPrerequisites[0],
          CurrentPermissionsManager.hasCourseAdminPermissions(),
        )}`,
        activitySecondName: lecturePage.studentPrerequisites[1].title,
        activitySecondLink: `${InteroperabilityRoutes.activityPath(
          course,
          lecturePage.studentPrerequisites[1],
          CurrentPermissionsManager.hasCourseAdminPermissions(),
        )}`,
      });
    }

    return popoverText;
  };

  return (
    <div
      css={getSanitizedStyles([styles, hoverForegroundStyles])}
      className='w-100 d-flex align-items-center'
      style={{ color: textColor }}
      onMouseEnter={() => setIsHoverOnHeader(true)}
      onMouseLeave={() => setIsHoverOnHeader(false)}
    >
      {canViewAdminTools && (
      <React.Fragment>
        {isEditMode && (
          <LectureContentEditHeaderButton visible={isHoverOnHeader} {...props} />
        )}
        {/* TODO: Add a notice about date conflicts here */}
        {lecturePage?.studentPrerequisites?.length > 0 && (
          <ClickableContainer className='lesson-lock position-absolute'>
            <NvPopover
              placement='bottom'
              content={(
                <div className='text-medium text-center' ref={popoverRef}>
                  <div>{getPopoverText()}</div>
                  <a
                    href={RailsRoutes.adminDashboardPath(catalogId, true)}
                    className='text-primary'
                  >
                    {t.ACTIVITY.EDIT_PREREQUISITES()}
                  </a>
                </div>
              )}
              show={showLockPopover}
            >
              <NvIcon
                icon='locked'
                size='small'
                onClick={() => setShowLockPopover(!showLockPopover)}
              />
            </NvPopover>
          </ClickableContainer>
        )}
        <div className='ml-auto d-none d-sm-flex align-items-center'>
          {course.isContentManagementCollection ? (
            CurrentPermissionsManager.hasCollectionViewerPermissions() && (
              <div className='text-small pr-2 d-flex  align-items-center'>
                <span>
                  {t.INSTITUTIONS.CONTENT_LIBRARY.LECTURE_PAGE.LINKED_LESSONS()}
                </span>
                <ClickableContainer
                  onClick={() => setShowLinkedCoursesModel(true)}
                  className={`ml-1 font-weight-bolder ${linkedCourses.length > 0 ? 'has-linked-courses' : ''} ${!canViewAdminCog ? 'mr-4' : ''}`}
                  disabled={linkedCourses.length === 0}
                  isFocusable={linkedCourses.length === 0}
                  data-qa={linkedCourses.length > 0 ? config.pendo.contentManagement.linkedLessonCountFromLesson : ''}
                >
                  {linkedCourses.length}
                </ClickableContainer>
              </div>
            )
          ) : (
            <div className='mr-2'>
              <LectureContentReleaseDate {...props} />
            </div>
          )}
          {lecturePage.isLinked && <LectureContentLinkedDropdown />}
          {canViewAdminCog && <LectureContentAdminDropdown />}
        </div>
      </React.Fragment>
      )}
      <LinkedCourseModal
        lessonId={lecturePage.id}
        showLinkedCourseModel={showLinkedCoursesModel}
        closeLinkedCourseModel={() => setShowLinkedCoursesModel(false)}
      />
    </div>
  );
};

export default LectureContentTopGutter;
