import React, { useEffect, useState } from 'react';
import t from 'react-translate';

import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { setBottomBarVisibility, setLeftPanelAiMode } from 'redux/actions/lecture-pages';
import { getCurrentLecture } from 'redux/selectors/lecture-page';
import { getCourseAliases } from 'redux/selectors/course';

import { ComponentType, LectureComponentCommon } from 'redux/schemas/models/lecture-component';
import { NLecturePage, NovoAIItemType } from 'redux/schemas/models/lecture-page';
import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';

import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import { hideAddUI } from 'lecture_pages/templates/components/nv-add-component';

const componentAIItemType = {
  [ComponentType.QUIZ]: NovoAIItemType.QUIZ,
};

type RegenerateAIParams = {
  lectureComponent: LectureComponentCommon<any>,
};

const RegenerateAIComponent = ({
  lectureComponent,
}: RegenerateAIParams) => {
  const dispatch = useAppDispatch();
  const currentLecture: NLecturePage = useSelector(getCurrentLecture);
  const aliases: CourseAliases = useSelector((state: RootState) => getCourseAliases(state));
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    hideAddUI(dispatch);
    setTimeout(() => {
      dispatch(setBottomBarVisibility(false));
      dispatch(setLeftPanelAiMode({
        itemType: lectureComponent.aiOrigin !== NovoAIItemType.NO_AI
          ? lectureComponent.aiOrigin
          : componentAIItemType[lectureComponent.type],
        content: undefined,
        itemTrueType: lectureComponent.trueType,
        lectureComponentToReplace: lectureComponent,
      }));
    });
  };

  useEffect(() => {
    // Disabling if the quiz LC is inside a released lesson
    if (lectureComponent.trueType === ComponentType.QUIZ && currentLecture?.released) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentLecture?.released, lectureComponent.trueType]);

  const getTooltipText = () => {
    switch (lectureComponent.trueType) {
      case ComponentType.QUIZ:
        return isDisabled
          ? t.LECTURE_PAGES.COMPONENTS.QUIZ.REGENERATE_WITH_AI_DISABLE({ lectureAlias: aliases.lectureAliases.lectureAlias })
          : t.LECTURE_PAGES.COMPONENTS.QUIZ.REGENERATE_WITH_AI();
      default:
        return t.LECTURE_PAGES.COMPONENTS.AI_REGENERATE();
    }
  };

  return (
    <NvTooltip text={getTooltipText()}>
      <div>
        <ClickableContainer
          onClick={handleClick}
          aria-label={t.LECTURE_PAGES.COMPONENTS.AI_REGENERATE_ARIA_LABEL()}
          disabled={isDisabled}
          isFocusable={!isDisabled}
        >
          <NvIcon
            icon='gen-ai'
            size='xss-smallest'
            className={`text-${isDisabled ? 'gray-4' : 'primary'}`}
          />
        </ClickableContainer>
      </div>
    </NvTooltip>
  );
};

export default RegenerateAIComponent;
