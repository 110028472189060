import React, { useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { useAppDispatch } from 'redux/store';
import { AngularContext, AngularServicesContext } from 'react-app';
import AdvancedSettingsSwitch from 'institutions/components/advanced-settings-switch';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import { config } from '../../../../../config/pendo.config.json';
import SectionContent from '../section-content';
import AppPromotion from './app-promotion';

const styles = css`
  input[type=checkbox] + label:before {
    top: 5px;
  }
`;
const PrivacySecurity = () => {
  const { $uibModal, CurrentUserManager, $scope } = useContext(AngularServicesContext);
  const { injectServices } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager', '$parse']);
  const [searchPrivacyOptions, setSearchPrivacyOptions] = useState($scope.$eval('vm.InstitutionsManager.institution.enableSearchAllUsers'));
  const [nameEditsDisabled, setNameEditsDisabled] = useState(!$scope.$eval('vm.InstitutionsManager.institution.enableBasicSsoInfoEdit'));
  const [tos, setTos] = useState($scope.$eval('vm.InstitutionsManager.institution.tos'));

  const dispatch = useAppDispatch();

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.enableSearchAllUsers', (newValue) => {
      setSearchPrivacyOptions(newValue);
    });
  }, [$scope]);

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.enableBasicSsoInfoEdit', (newValue) => {
      setNameEditsDisabled(!newValue);
    });
  }, [$scope]);

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.tos', (newValue) => {
      setTos(newValue);
    });
  }, [$scope]);

  const configureTOS = () => {
    $uibModal.open({
      templateUrl: 'institutions/templates/configure-tos-modal.html',
      controller: 'ConfigureTOSModalCtrl as vm',
      resolve: {
      },
    });
  };

  const configureSSO = () => {
    InstitutionsManager.backupProviderData();

    $uibModal.open({
      templateUrl: 'institutions/templates/configure-sso-modal.html',
      controller: 'ConfigureSSOModalCtrl as vm',
      resolve: {
      },
    });
  };

  const switchAvailabilityFileDownload = (value) => {
    InstitutionsManager.institution.updateFromReact({
      allowFileDownloads: value,
    });

    const failureCallback = () => {
      dispatch(addAlertMessage({ type: AlertMessageType.ERROR, header: t.FORM.OOPS(), message: t.FORM.ERROR_SOMETHING_WRONG() }));
      InstitutionsManager.institution.updateFromReact({
        allowFileDownloads: !value,
      });
    };

    InstitutionsManager.toggleAvailabilityFileDownload(value)
      .then((success) => {
        if (!success) {
          failureCallback();
        }
      }, () => {
        failureCallback();
      });
  };

  const switchSSOLoginViaExternalId = (value) => {
    InstitutionsManager.setSSOWithoutEmail(value);
  };

  const onSearchSettingChanged = () => {
    InstitutionsManager.toggleOrgSearchAllUsers();
  };

  const nameEditsCheckboxClicked = (value) => {
    InstitutionsManager.setBasicSSOInfoEdit(!value);
  };

  return (
    <div css={styles}>
      {/* Privacy Policy */}
      <div className='border-bottom border-gray-5 pb-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.TOS_TITLE()}
          description={[t.INSTITUTIONS.ADVANCED_SETTINGS.TOS_DESCRIPTION()]}
          showCTAButton
          ctaText={tos ? t.FORM.UPDATE() : t.FORM.ADD()}
          onAction={configureTOS}
          dataQa={config.pendo.settings.tos.addPrivacyPolicy}
        />
      </div>
      {/* Single Sign-On Configuration */}
      {InstitutionsManager?.institution?.identityProviders?.length ? (
        <div className='border-bottom border-gray-5 py-6'>
          <SectionContent
            header={t.INSTITUTIONS.ADVANCED_SETTINGS.SSO_TITLE()}
            description={[t.INSTITUTIONS.ADVANCED_SETTINGS.SSO_DESCRIPTION()]}
            extras={(
              <div className='d-flex flex-column'>
                <Button
                  variant='primary'
                  onClick={configureSSO}
                  className='align-self-center my-4'
                  data-qa={config.pendo.settings.sso.ssoViewIdp}
                >
                  {t.INSTITUTIONS.ADVANCED_SETTINGS.VIEW_IDP()}
                </Button>
                <div className='mb-4'>
                  <AdvancedSettingsSwitch
                    onChange={switchSSOLoginViaExternalId}
                    value='vm.InstitutionsManager.institution.allowsSsoWithoutEmail'
                    dataQa={config.pendo.settings.sso.toggleAllowSsoLogins}
                    disabled={!CurrentUserManager.isNovoedAdmin() || $scope.vm?.identityProviders?.length > 1}
                    labelText={t.INSTITUTIONS.ADVANCED_SETTINGS.SSO_THROUGH_EXTERNAL_ID_SWITCH_LABEL()}
                    tooltipText={!CurrentUserManager.isNovoedAdmin()
                      ? t.INSTITUTIONS.ADVANCED_SETTINGS.SSO_NOT_NOVOED_ADMIN_TOOLTIP()
                      : t.INSTITUTIONS.ADVANCED_SETTINGS.SSO_MULTIPLE_IDP_TOOLTIP()}
                    tooltipEnabled={!CurrentUserManager.isNovoedAdmin() || $scope.vm?.identityProviders?.length > 1}
                  />
                </div>
                <div className='text-body'>{t.INSTITUTIONS.ADVANCED_SETTINGS.SSO_THROUGH_EXTERNAL_ID_NOTE()}</div>
              </div>
            )}
          />
        </div>
      ) : null}
      {/* Editing Name and Email */}
      <div className='border-bottom border-gray-5 py-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.EDITING_IN_ORGANIZATIONS()}
          description={[
            t.INSTITUTIONS.ADVANCED_SETTINGS.EDITING_DESCRIPTION_1(),
            t.INSTITUTIONS.ADVANCED_SETTINGS.EDITING_DESCRIPTION_2(),
          ]}
          extras={(
            <NvCheckbox
              name='nameEditsDisabled'
              onChange={(e) => nameEditsCheckboxClicked(e.target.checked)}
              checked={nameEditsDisabled}
              label={t.INSTITUTIONS.ADVANCED_SETTINGS.PREVENT_EDITING_IN_THIS_ORGANIZATION()}
              labelClassName='text-body align-items-start'
              dataQa={config.pendo.settings.toggleNameEdit}
            />
          )}
        />
      </div>
      {/* Promote Native Mobile Apps */}
      <div className='border-bottom border-gray-5 py-6'>
        <AppPromotion />
      </div>
      {/* User Search Privacy and Security */}
      <div className='border-bottom border-gray-5 py-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.SEARCH_PRIVACY_TITLE()}
          description={[t.INSTITUTIONS.ADVANCED_SETTINGS.SEARCH_PRIVACY_DESCRIPTION()]}
          extras={(
            <div className='d-flex flex-column align-self-start'>
              <NvRadioButton
                labelClassName='text-body'
                value='true'
                name='searchPrivacyOptions'
                label={t.INSTITUTIONS.ADVANCED_SETTINGS.ENABLE_SEARCH_ALL()}
                onChange={onSearchSettingChanged}
                checked={searchPrivacyOptions}
                data-qa={config.pendo.settings.enableSearchForAll}
              />
              <NvRadioButton
                labelClassName='text-body'
                value=''
                name='searchPrivacyOptions'
                label={t.INSTITUTIONS.ADVANCED_SETTINGS.ENABLE_SEARCH_ONLY()}
                onChange={onSearchSettingChanged}
                checked={!searchPrivacyOptions}
                data-qa={config.pendo.settings.enableSearchForAdminOnly}
              />
            </div>
          )}
        />
      </div>
      {/* File resource download */}
      <div className='border-bottom border-gray-5 py-6'>
        <SectionContent
          header={t.INSTITUTIONS.ADVANCED_SETTINGS.FILE_RESOURCE_DOWNLOAD.TITLE()}
          description={[t.INSTITUTIONS.ADVANCED_SETTINGS.FILE_RESOURCE_DOWNLOAD.DESCRIPTION()]}
          extras={(
            <div className='d-flex align-self-start'>
              <AdvancedSettingsSwitch
                onChange={switchAvailabilityFileDownload}
                dataQa={config.pendo.settings.toggleFileResourceDownload}
                value='vm.InstitutionsManager.institution.allowFileDownloads'
                labelText={t.INSTITUTIONS.ADVANCED_SETTINGS.FILE_RESOURCE_DOWNLOAD.LABEL_TEXT()}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default PrivacySecurity;
