/** @jsx */
import { css } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import { AngularContext, AngularServicesContext } from 'react-app';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'underscore';
import omit from 'lodash/omit';

// Actions
import { useAppDispatch } from 'redux/store';
import {
  getUserDetails,
  getUserEnrollmentData,
  getUserEnrollmentDownloadDetails,
  exportUserEnrollmentData,
  updateExportStatus,
} from 'redux/actions/org-users';

// Schemas
import { EnrollmentExportStatus, UserEnrollment, CompletionStatusEnum } from 'redux/schemas/models/org-users';
import { FlagType } from 'redux/schemas/models/org-mentors';

// Selectors
import { getCurrentMentees } from 'redux/selectors/org-mentors';
import getOrgUserDetails from 'redux/selectors/org-users';
import { getCurrentInstitution } from 'redux/selectors/institutions';

import PusherService from 'shared/services/pusher-service';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

import { Button } from 'react-bootstrap';
import NvLoadingPlaceholder, { PlaceholderType, LineSize, AvatarSize, Shape } from 'shared/components/nv-loading-placeholder';
import NvUserAvatar from 'components/nv-user-avatar';
import { quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import NvModalPagination from 'org_mentor/components/nv-modal-pagination';
import EnrollmentRowFlag from 'org_mentor/components/enrollment-row-flag';
import { handheld, notHandheld } from 'styles/global_defaults/media-queries';
import { NvCourseAvatar } from 'components/nv-course-avatar';
import NvTooltip from 'shared/components/nv-tooltip';
import EmptyList from 'shared/components/empty-list';
import { gray4, primary } from 'styles/global_defaults/colors';
import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import CourseProgress from './course-progress-component';
import EditRow from './edit-row-component';

const INITIAL_PAGE = 1;

const LearnerEnrollmentModal = () => {
  const { $state, $uibModal, CurrentCourseManager } = useContext(AngularServicesContext);

  const [nextUser, setNextUser] = useState<number>();
  const [prevUser, setPrevUser] = useState<number>();
  const [isLoadingEnrollments, setIsLoadingEnrollments] = useState(false);
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const [userId] = useState<number>(parseInt($state.params.userId, 10));
  const dispatch = useAppDispatch();
  const { injectServices } = React.useContext(AngularContext);

  const [CourseModel] = injectServices(['CourseModel']);

  const learnerDetails = useSelector((state) => getOrgUserDetails(state, userId));
  const currentInstitution = useSelector(getCurrentInstitution);
  const courseRoles = useSelector((state) => state.models.roles);

  const isGalleryModeEnabled = $state?.current?.data?.level === 4 && $state.params.galleryMode === 'true';
  const mentees = useSelector(getCurrentMentees);
  const scrollingElement: HTMLElement = document.getElementById('modal-content-wrapper');
  const isPageBottom = useInfiniteScroll(scrollingElement, 40);
  const hasMore = learnerDetails?.totalEnrollments > learnerDetails?.enrollments?.length;
  useEffect(() => {
    if (isPageBottom && hasMore && !isLoadingEnrollments) {
      setIsLoadingEnrollments(true);
      setCurrentPage(currentPage + 1);
    }
  }, [isPageBottom, isLoadingEnrollments]);

  useEffect(() => {
    if (isGalleryModeEnabled) {
      const index = (mentees ?? []).findIndex((item) => item === userId);
      if (mentees.length < 2) {
        setPrevUser(null);
        setNextUser(null);
      } else if (index <= 0) {
        setNextUser(mentees[1]);
        setPrevUser(null);
      } else if (index > 0) {
        setNextUser(mentees[index + 1 >= mentees.length ? null : index + 1]);
        setPrevUser(mentees[index - 1]);
      }
    }
  }, [isGalleryModeEnabled, mentees, userId]);

  useEffect(() => {
    dispatch(getUserDetails(userId));
    dispatch(getUserEnrollmentData({ userId, institutionId: currentInstitution.id, demoCoursesRequested: true, page: currentPage })).then(() => {
      setIsLoadingEnrollments(false);
    });
    dispatch(getUserEnrollmentDownloadDetails({ userId, institutionId: currentInstitution.id }));
  }, [dispatch, userId, currentInstitution.id, currentPage]);

  const exportData = () => {
    dispatch(exportUserEnrollmentData({
      institutionId: currentInstitution.id,
      exportType: 'user_enrollment',
      userId,
      startTime: currentInstitution.createdAt,
      endTime: moment().toISOString(),
      period: 'creation_time',
      demoCoursesRequested: true,
    }));
  };

  useEffect(() => {
    const pusherChannel = PusherService.setupChannel(`public-institution-${currentInstitution.id}`);
    pusherChannel.bind('institution_report_event_name', (result) => {
      if (result.report_status === 'completed' || result.report_status === 'failed') {
        if (result.url) {
          dispatch(updateExportStatus({
            userId,
            data: {
              exportStatus: EnrollmentExportStatus.DOWNLOAD,
              exportDownloadLink: result.url,
              exportDate: result.completed_at,
            },
          }));
        } else {
          dispatch(updateExportStatus({
            userId,
            data: {
              exportStatus: EnrollmentExportStatus.READY,
            },
          }));
        }
      }
    });
    return () => {
      pusherChannel.unbind('institution_report_event_name');
    };
  }, [currentInstitution.id, userId, dispatch]);

  const goToUser = (id: number, ltr: number) => {
    $state.go('user-enrollment-details-modal', {
      userId: id,
      institutionId: currentInstitution.id,
      galleryMode: true,
      ltr,
    });
  };

  const openCourseHome = ({ isJourney, catalogId }) => {
    const homeRoute = isJourney ? 'learning-journey-home' : 'course-home';
    window.open($state.href(homeRoute, { catalogId }), '_blank');
  };

  const getLastActive = (lastActivity: string, completionProgress: CompletionStatusEnum) => {
    let textClass = 'text-black';
    let duration = '';
    const activeWarningStatuses = [
      CompletionStatusEnum.ENROLLED,
      CompletionStatusEnum.IN_PROGRESS,
    ];

    if (!isEmpty(lastActivity)) {
      if (Math.round(moment().diff(moment(lastActivity), 'days', true)) > 7
        && activeWarningStatuses.includes(completionProgress)) {
        textClass = 'text-danger';
      }
      duration = moment().diff(lastActivity) > 0
        ? moment(lastActivity).fromNow()
        : moment().fromNow();
    }

    return (
      <div className={`last-active-cell ${textClass}`}>
        {duration}
      </div>
    );
  };

  const isStatusEnrolled = (completionProgress: CompletionStatusEnum) => completionProgress === CompletionStatusEnum.ENROLLED;

  const showPointsBreakDown = (enrollment: UserEnrollment) => {
    // To correctly reflect the nv-completion-criteria completion status, we
    // need to pass a course with "userCourse" property because of how it's
    // architectonically designed.
    const courseForPointsBreakdownModal = new CourseModel({
      ...enrollment.course,
      userCourse: omit(enrollment, ['course']),
    });

    CurrentCourseManager.setCourse(enrollment.course);
    $uibModal.open({
      templateUrl: 'shared/templates/points-breakdown-modal.html',
      controller: 'PointsBreakdownModalController',
      controllerAs: 'vm',
      windowClass: 'large-modal',
      resolve: {
        userId,
        course: courseForPointsBreakdownModal,
        showOutline: true,
        selectedTab: () => 'Outline',
      },
    }).closed.then(() => {
      if ($state.current.name !== 'lecture-page') {
        CurrentCourseManager.resetCourse();
      }
    });
  };

  const styles = css`
    border-top: ${quarterSpacing}px solid ${currentInstitution.brandColor};

    .top-section {
      background-color: white;
      .dot {
        width: ${quarterSpacing}px;
        height: ${quarterSpacing}px;
        border-radius: 50%;

        ${handheld(css`
          display: none;
        `)};
      }
    }

    .bottom-section {

      .text-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .table-row {
        .edit-cell {
          .disabled {
            color: ${gray4};
            text-decoration: none;
          }

          .options-btn {
            width: ${standardSpacing * 2}px;
            height: ${standardSpacing * 3}px
          }
        }
        .view-points-break-down {
          display: none;
        }
      }

      .table-row:not(.status-enrolled):hover {
        box-shadow: 0 0 10px 0 rgba(29,33,38,0.2);
        border: 0;

        .completion-status:not(.learning-journey) {
          display: none;
        }

        .view-points-break-down:not(.learning-journey) {
          display: block
        }
      }

      ${handheld(css`
        .mobile-header {
          display: block;
        }
        .desktop-header {
          display: none;
        }

        .name-cell, progress-cell {
          display: flex;
          align-items: center;
        }

        .role-cell, .registration-date-cell, .first-login-cell, .last-active-cell, .flags-cell {
          display: none;
        }

        .progress-cell {
            display: inline-block;
            min-height: 40px;
            width: 80%;
            margin-right: 20px;
        }
        .edit-cell {
          right: 0;
          position: absolute;
          display: inline-block;
          height: 40px;
        }


      `)};
      ${notHandheld(css`
        .mobile-header {
          display: none;
        }
        .table-row, .table-header {
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: 25% 10% 15% 10% 10% 10% calc(20% - ${standardSpacing * 2}px) ${standardSpacing * 2}px;
          grid-template-columns: 25% 10% 15% 10% 10% 10% calc(20% - ${standardSpacing * 2}px) ${standardSpacing * 2}px
        }



        .name-cell, .role-cell, .progress-cell, .registration-date-cell, .first-login-cell, .last-active-cell, .flags-cell, .edit-cell {
          display: flex;
          align-items: center;
        }

        .name-cell {
          -ms-grid-column: 1;
          grid-column: 1;

          .name-text {
            width: calc(100% - 60px);
          }

          .badge {
            width: fit-content;
            .icon {
              padding-left: ${quarterSpacing / 2}px;
              padding-right: ${quarterSpacing}px;
            }
            margin-bottom: ${quarterSpacing}px;
          }

          p.text-small {
            margin-bottom: 0px;
          }

          span.text-small {
            padding-bottom: ${quarterSpacing}px;
          }
        }

        .role-cell {
          -ms-grid-column: 2;
          grid-column: 2;
        }

        .progress-cell {
          -ms-grid-column: 3;
          grid-column: 3;
        }

        .registration-date-cell {
          -ms-grid-column: 4;
          grid-column: 4;
        }

        .first-login-cell {
          -ms-grid-column: 5;
          grid-column: 5;
        }

        .last-active-cell {
          -ms-grid-column: 6;
          grid-column: 6;
        }

        .flags-cell {
          -ms-grid-column: 7;
          grid-column: 7;
        }

        .edit-cell {
          -ms-grid-column: 8;
          grid-column: 8;
        }
      `)};
    }

    .empty-list-wrapper {
      padding-top: ${tripleSpacing}px;
    }

    .course-name:hover {
      color: ${primary};
    }

    .disabled-progress {
      pointer-events: none;
    }
  `;

  const LoadingEnrollments = () => (
    <div className='table-row border-bottom border-gray-5 px-4'>
      <div className='name-cell'>
        <NvLoadingPlaceholder type={PlaceholderType.AVATAR} size={AvatarSize.LARGE} shape={Shape.SQUARE} />
        <div className='name-text px-2 d-flex flex-column justify-content-center'>
          <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} />
        </div>
      </div>
      <div className='role-cell'><NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} /></div>
      <div className='progress-cell'><NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} /></div>
      <div className='registration-date-cell'><NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} /></div>
      <div className='first-login-cell'><NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} /></div>
      <div className='last-active-cell'><NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} /></div>
      <div className='flags-cell'><NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.FULL} /></div>
      <div className='edit-cell' />
    </div>
  );

  const Badge = ({ course }) => {
    if (course.isJourney) {
      return (
        <div className='badge bg-primary d-flex'>
          <NvIcon icon='path' size='xss-smallest' />
          <span>{t.OFFERINGS.CARD.BADGE.LEARNING_JOURNEY()}</span>
        </div>
      );
    }
    return null;
  };

  const getCourseJourneyName = (course) => (course.isJourney ? t.INSTITUTIONS.ORG_MENTORS.CLICK_TO_VISIT_JOURNEY() : t.INSTITUTIONS.ORG_MENTORS.CLICK_TO_VISIT_COURSE());

  return (
    <NvModalPagination
      show={isGalleryModeEnabled}
      onNext={nextUser ? () => goToUser(nextUser, 1) : null}
      onPrev={prevUser ? () => goToUser(prevUser, 0) : null}
    >
      <div css={styles} className='h-100 bg-gray-7'>
        <div className='top-section py-4'>
          <div className='d-flex justify-content-center pb-1'>
            { learnerDetails?.userDataLoaded ? (
              <NvUserAvatar
                borderType='round'
                size='xl'
                user={learnerDetails.user}
                directToOrgLevelProfile
                tooltipText={t.ORG_LEVEL_PROFILE.VIEW_PROFILE()}
                tooltipPlacement='top'
              />
            ) : (
              <NvLoadingPlaceholder type={PlaceholderType.AVATAR} size={AvatarSize.EXTRA_LARGE} />
            ) }
          </div>
          <div className='d-flex align-items-center flex-column flex-md-row justify-content-center pb-4'>
            { learnerDetails?.userDataLoaded ? (
              <>
                <div className='font-weight-bolder py-1'>{learnerDetails.user.fullName}</div>
                <div className='dot mx-3 bg-gray-4' />
                <div className='font-weight-bolder py-1'>{learnerDetails.user.email}</div>
                { learnerDetails.user.externalId ? (
                  <>
                    <div className='dot mx-3 bg-gray-4 py-1' />
                    <div className='font-weight-bolder py-1'>{learnerDetails.user.externalId}</div>
                  </>
                ) : ''}
              </>
            ) : (
              <NvLoadingPlaceholder type={PlaceholderType.LINE} size={LineSize.SHORT} />
            ) }
          </div>
          <div className='d-flex flex-column align-items-center'>
            { learnerDetails?.exportInfo?.exportStatus === EnrollmentExportStatus.READY ? (
              <Button
                variant='primary'
                onClick={exportData}
              >
                {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.READY()}
              </Button>
            ) : ''}
            { learnerDetails?.exportInfo?.exportStatus === EnrollmentExportStatus.ACTIVE ? (
              <Button
                variant='primary'
                disabled
              >
                {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.ACTIVE()}
              </Button>
            ) : ''}
            { learnerDetails?.exportInfo?.exportStatus === EnrollmentExportStatus.DOWNLOAD ? (
              <>
                <a
                  className='btn btn-primary'
                  target='_blank'
                  rel='noreferrer'
                  href={learnerDetails?.exportInfo?.exportDownloadLink}
                >
                  {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.ENROLLMENT_DATA.DOWNLOAD()}
                </a>
                <p className='text-small d-flex align-items-center pt-1'>
                  {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.EXPORTED(moment(learnerDetails?.exportInfo?.exportDate).format('MMM DD, YYYY h:mm A'))}
                  <Button
                    variant='link'
                    onClick={exportData}
                    className='text-small p-0 pl-1'
                  >
                    {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.REFRESH()}
                  </Button>
                </p>
              </>
            ) : ''}
          </div>
        </div>
        <div className='bottom-section text-small w-100 bg-gray-7'>
          {learnerDetails?.enrollmentLoaded ? (
            <React.Fragment>
              {learnerDetails.enrollments.length > 0 ? (
                <React.Fragment>
                  <div className='desktop-header table-header border-bottom border-gray-5 pt-4 pb-2 align-items-end px-4'>
                    <div className='name-cell'>{t.COURSES.DASHBOARD_TABLE.GENERAL_OFFERING_NAME()}</div>
                    <div className='role-cell'>{t.CUSTOM_DOWNLOADS.DROPDOWN.ROLE()}</div>
                    <div className='progress-cell'>{t.INSTITUTIONS.ORG_MENTORS.PROGRESS()}</div>
                    <div className='registration-date-cell'>{t.INSTITUTIONS.LICENSES.REGISTRATION_DATE()}</div>
                    <div className='first-login-cell'>{t.INSTITUTIONS.LICENSES.FIRST_LOGIN()}</div>
                    <div className='last-active-cell'>{t.INSTITUTIONS.ROLES.LAST_ACTIVE()}</div>
                    <div className='flags-cell'>{t.MENTOR_DASHBOARD.TABLE_HEADER_NOTES()}</div>
                    <div className='edit-cell' />
                  </div>
                  <div className='mobile-header border-bottom border-gray-5 pt-6 pb-2'>
                    <div className='w-100 '>{t.NOVOED.COURSES()}</div>
                  </div>
                  {learnerDetails.enrollments.map((enrollment) => (
                    <div
                      className={`table-row border-bottom border-gray-5 px-4 ${isStatusEnrolled(enrollment.completionProgress) ? 'status-enrolled' : ''}`}
                      key={enrollment.id}
                    >
                      <div className='name-cell py-1'>
                        <NvTooltip
                          text={enrollment.course.isProgram
                            ? t.INSTITUTIONS.ORG_MENTORS.CLICK_TO_VISIT_PROGRAM()
                            : getCourseJourneyName(enrollment.course)}
                        >
                          <ClickableContainer
                            onClick={() => openCourseHome(enrollment.course)}
                            /**
                             * The blue border focus indication is not showing properly
                             * because of the default outline-offset of -2px. So
                             * unsetting the outline-offset.
                             */
                            css={css`outline-offset: unset !important;`}
                          >
                            <NvCourseAvatar course={enrollment.course} institution={currentInstitution} />
                          </ClickableContainer>
                        </NvTooltip>
                        <div className='name-text px-2 d-flex flex-column justify-content-center'>
                          <Badge course={enrollment.course} />
                          <ClickableContainer
                            onClick={() => openCourseHome(enrollment.course)}
                          >
                            <NvTooltip
                              text={enrollment.course.isProgram
                                ? t.INSTITUTIONS.ORG_MENTORS.CLICK_TO_VISIT_PROGRAM()
                                : getCourseJourneyName(enrollment.course)}
                              preventOverflow={false}
                            >
                              <span
                                className='text-small black font-weight-bolder text-ellipsis course-name'
                              >
                                {enrollment.course.name}
                              </span>
                            </NvTooltip>
                          </ClickableContainer>
                          <p className='text-small gray-2 text-ellipsis'>{enrollment.course.catalogId}</p>
                        </div>
                      </div>
                      <div className='role-cell'>
                        {enrollment.courseRoleId ? courseRoles[enrollment.courseRoleId].name : ''}
                      </div>
                      <div className='progress-cell'>
                        {
                          enrollment.course.isJourney
                            ? (
                              <CourseProgress enrollment={enrollment} />
                            )
                            : (
                              <ClickableContainer
                                className={`w-100 ${isStatusEnrolled(enrollment.completionProgress) ? 'disabled-progress' : ''}`}
                                onClick={() => showPointsBreakDown(enrollment)}
                              >
                                <CourseProgress enrollment={enrollment} />
                              </ClickableContainer>
                            )
                        }
                      </div>
                      <div className='registration-date-cell'>
                        { moment(enrollment.enrolledAt).format('L') }
                      </div>
                      <div className='first-login-cell'>
                        {enrollment.courseHomeFirstVisitedAt ? moment(enrollment.courseHomeFirstVisitedAt).format('L') : ''}
                      </div>
                      <React.Fragment>
                        {getLastActive(enrollment.lastActivity, enrollment.completionProgress)}
                      </React.Fragment>
                      <div className='flags-cell flex-column align-items-start justify-content-center'>
                        {enrollment.highlights && Object.keys(enrollment.highlights).map((flagType: FlagType) => (
                          <React.Fragment
                            key={flagType}
                          >
                            {((flagType === FlagType.MISSED_DEADLINE && !isEmpty(enrollment.highlights[flagType]))
                            || (flagType !== FlagType.MISSED_DEADLINE && enrollment.highlights[flagType]))
                            && (
                              <EnrollmentRowFlag
                                activities={flagType === FlagType.MISSED_DEADLINE ? enrollment.highlights[flagType] : null}
                                flagType={flagType}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className='edit-cell pl-4 pr-2'>
                        <EditRow enrollment={enrollment} />
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ) : (
                <div className='empty-list-wrapper'>
                  <EmptyList
                    icon='course'
                    description={t.INSTITUTIONS.ORG_MENTORS.EMPTY_ENROLLMENT_MODAL()}
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            <LoadingEnrollments />
          )}
          {
            hasMore && isLoadingEnrollments && (
              <LoadingEnrollments />
            )
          }
          {learnerDetails?.enrollmentLoaded
            && !hasMore
            && (
              <p className='text-small gray-2 pt-2 ml-4'>{t.SEARCH.ALL_LOADED()}</p>
            )}
        </div>
      </div>
    </NvModalPagination>
  );
};

export default LearnerEnrollmentModal;
