import { MockDefinition, MockTypes } from 'shared/axios/axios-mocks';

const courseAnalytics = {
  token: 'eyJraWQiOiJBNUpzZUJSMHZ2Qkh5RV9zRUlFWnpUa01TSE42X2VKbG1kY2NiMVk1ZE1rIiwiZW5jIjoiQTEyOENCQy1IUzI1NiIsImFsZyI6IlBCRVMyLUhTMjU2K0ExMjhLVyIsInAyYyI6NDAyMCwicDJzIjoiTzF1dW83aXJlMWY5WGdlRnB2SXFwdyJ9.7jMwKksXtfJI3SDv6n0UlZS0PjD6hVF2HrPw-4m4XG1D1PleZmno-A.MVS8ZWb7lhiqWXaWebIaVw.Jirwxqvte1hZW0p5SW9hKYFp8Ih9LmEBb6rcg1zyOjhTn1iCHvPce8zqYYb-zKvFHNA-zuBOKcDp3K2q3ZxVUk9tfYbSeaM7cNcJBTISDvQpchrNzJmSXPKPr9iHRTzSQzxNs7GhzzkxyORSbINhUD3JgjJdDe457MQAn1tIo_02K4YaqFXe6cOtMBq0eSD6QAVEV9B2uBjwjkoMTVD2y54uq57YtJlUh-I6aQuLEEaFXJdD95AjA09d8h_eUzijkAN6gaytXV86Ch0Ycjb0AuxI4GmbAsYfsc2hqBCO60x6TOD2g9BhifVDUJOFW-05M_c8NOm-Qmdufhejc6oHVFZFHS8FEwIqTh9aWb2uHmQDzbQK7ji0bCtpq1LOdbGm5G_nTE2sHK3_cu1mPHtn9G2F2kY4Y2Qeuskp7tg_MvM.7Bfg0y4I6JoRKxstgK-JUw',
  app_id: 'UkGV0yNFr',
  user_id: 'LPW0MqI1y',
  host: 'https://lytics.maroon.novoed.com',
  page_ids: {
    overview: 'ViheCoFAE33SI0bfrYWAi',
    activities: 'aJUWaJjP7Na7bYI2g1gK9',
    skills: '2x7hYq42a1hXUqTLZAXlu',
    cohorts: 'KjzsN3lNrPQTgtuGbpt8B',
  },
};

const CourseAnalyticsMocks: MockDefinition[] = [
  {
    type: MockTypes.GET,
    url: '/analytics/1/course_analytics_access/test.json',
    status: 200,
    response: {
      message: 'Successfully completed the request',
      result: courseAnalytics,
    },
  },
];

export default CourseAnalyticsMocks;
