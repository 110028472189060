import store, { cloneDeepSerializable } from '../../redux/store';
import { updateLectureComponentFromAngular } from '../../redux/actions/lecture-components';
import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';

// eslint-disable-next-line max-params
/* @ngInject */
export default function TimedQuizController(
  $window,
  $scope,
  $stateParams,
  nvUtil,
  $q,
  moment,
  StateManager,
  CurrentCourseManager,
  CurrentUserManager,
  ConfirmationOverlays,
  TimedQuizzesManager,
  OfflineManager,
  PusherManager,
  $uibModal,
  $uibModalInstance,
  questionSetId,
  quiz,
  CountdownTimerManager,
  TimelinesManager,
  ReactTimelineService,
  humps,
  $timeout,
  $uibModalStack,
  AlertMessages,
  adminPreview,
  config,
  lectureComponent,
  _,
) {
  const vm = this;

  vm.manager = TimedQuizzesManager;
  vm.$stateParams = $stateParams;
  vm.OfflineManager = OfflineManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.nvUtil = nvUtil;
  vm.questionSetId = questionSetId;
  vm.quiz = quiz;
  vm.actionState = vm.quiz.actionState;

  vm.quizForm = null;
  vm.currentMoment = moment();
  vm.deregisterStateChangeStart = null;
  vm.loading = true;
  vm.confirmingClose = false;
  vm.modalClosing = false;
  vm.inactiveSession = false;
  vm.submitting = false;
  vm.autoSubmitting = false;
  vm.saving = false;
  vm.saved = false;
  vm.offlineWarning = false;
  vm.windowId = null;
  vm.adminPreview = adminPreview;

  vm.config = config;

  initialize();

  function initialize() {
    if (vm.adminPreview) {
      vm.manager.setQuiz($stateParams.catalogId, vm.questionSetId, vm.windowId, vm.actionState, vm.quiz);
      vm.loading = false;
    } else {
      // Fetch quiz questions
      vm.manager.getQuiz($stateParams.catalogId, vm.questionSetId, vm.windowId, vm.actionState, adminPreview).then(() => {
        vm.releaseResultsAtMoment = moment(vm.manager.currentQuiz.releaseResultsAt);

        vm.manager.currentQuiz.calculatedTimeRemaining = vm.manager.currentQuiz.calculateTimeRemaining(
          vm.manager.currentQuiz.startedExamAt, quiz.timeLimit, quiz.timeInterval, quiz.hardDeadline && quiz.expirationDate,
        );

        // If answers already submitted
        if (vm.manager.currentQuiz.questionsSubmitted) {
          showAlreadySubmittedWarning();
        } else {
          vm.loading = false;
          vm.windowId = vm.manager.currentQuiz.windowId;
          CountdownTimerManager.initialize(
            vm.quiz.duration,
            vm.manager.currentQuiz.calculatedTimeRemaining,
            vm.quiz.timeInterval,
            // Automatically submit when time up
            () => {
              if (vm.inactiveSession) {
                vm.confirmingClose = true;
                $uibModalStack.dismissAll('cancel');
              } else {
                automaticallySubmitExam();
              }
            },
          );

          // If previous responses exist, fill them in
          if (vm.manager.currentQuiz.responses) {
            vm.manager.setCurrentSubmission(vm.manager.currentQuiz);
            vm.saved = true;
          }

          PusherManager.currentUserChannel().bind('timed_quiz_new_window', multipleSessionsHandler);
          PusherManager.currentUserChannel().bind('timed_quiz_submission', responseSubmittedAlreadyHandler);

          vm.OfflineManager.timedQuizInProgress = true;

          autosave();
        }
      }, () => {
        vm.confirmingClose = true;
        $uibModalStack.dismissAll('cancel');
        AlertMessages.error('', 'FORM.ERROR');
      });
    }
  }

  /* Autosave every couple few seconds */
  /* With this approach, function may not be every second, but guaranteed that previous data will have finished processing before the next interval kicks off. */
  function autosave() {
    vm.saveTimeout = $timeout(() => {
      if (checkUnsavedChanges() && !vm.OfflineManager.isOffline) {
        vm.save();
      } else if (!vm.offlineWarning && vm.OfflineManager.isOffline && CountdownTimerManager.timer.status === 'danger') {
        showOfflineWarning();
      }

      autosave();
    }, 3000);
  }

  /* Offline warning close to < 5% of time remaining */
  function showOfflineWarning() {
    vm.offlineWarning = true;
    const modalInstance = ConfirmationOverlays.openConfirmationModal('quizzes/templates/timed_quiz/offline-warning-overlay.html');
  }

  function automaticallySubmitExam() {
    let modalInstance;

    $timeout.cancel(vm.saveTimeout);
    vm.autoSubmitting = true;
    if (!vm.OfflineManager.isOffline && vm.manager.currentQuiz.unansweredCount() !== vm.manager.currentQuiz.questions.length) {
      modalInstance = ConfirmationOverlays.openConfirmationModal('quizzes/templates/timed_quiz/automatically-submitting-overlay.html');
      vm.submit();
    } else {
      $timeout(() => {
        vm.confirmingClose = true;
        $uibModalStack.dismissAll('cancel');
      }, 0).then(() => {
        showAutomaticallySubmittedOverlay();
      });
    }
  }

  function showAutomaticallySubmittedOverlay() {
    let modalInstanceTemplate;
    const releaseResultsAt = vm.manager.currentQuiz;
    const resultsAvailableAlready = moment().isSameOrAfter(vm.releaseResultsAtMoment);
    const { isOffline } = vm.OfflineManager;

    /* If no responses inputed, show missed window overlay */
    if (vm.manager.currentQuiz.unansweredCount() === vm.manager.currentQuiz.questions.length) {
      modalInstanceTemplate = 'quizzes/templates/timed_quiz/missed-time-limit-warning-overlay.html';
    } else {
      modalInstanceTemplate = 'quizzes/templates/timed_quiz/automatically-submitted-overlay.html';
    }

    // eslint-disable-next-line no-shadow
    const modalInstanceCtrl = ['$scope', 'releaseResultsAt', 'resultsAvailableAlready', 'isOffline', function ($scope, releaseResultsAt, resultsAvailableAlready, isOffline) {
      $scope.releaseResultsAt = releaseResultsAt;
      $scope.resultsAvailableAlready = resultsAvailableAlready;
      $scope.isOffline = isOffline;
    }];

    const modalInstance = ConfirmationOverlays.openConfirmationModal(modalInstanceTemplate, modalInstanceCtrl,
      { releaseResultsAt, resultsAvailableAlready, isOffline });
    modalInstance.result.then(() => {
      vm.confirmingClose = true;
      $uibModalInstance.close();
    });
  }

  vm.updateSavingAndSaved = function (saving, saved) {
    vm.saving = saving;
    vm.saved = saved;
  };

  vm.save = function () {
    vm.updateSavingAndSaved(true, false);
    vm.quizForm.$setPristine();
    TimedQuizzesManager.save(vm.windowId)
      .then((submission) => {
        vm.updateSavingAndSaved(false, true);

        _.extend(lectureComponent.quiz, submission.quiz);
        lectureComponent.quiz.pointsReceived = submission.pointsReceived;
        lectureComponent.quiz.preprocess();

        store.dispatch(updateLectureComponentFromAngular({
          quiz: lectureComponent.quiz,
        }));
      }).catch(
        (error) => {
          vm.saving = false;
          vm.quizForm.$setDirty();
        },
      );
  };


  vm.submit = function () {
    $timeout.cancel(vm.saveTimeout);
    vm.submitting = true;

    TimedQuizzesManager.submit(vm.windowId)
      .then((submission) => {
        _.extend(lectureComponent.quiz, submission.quiz);
        lectureComponent.quiz.pointsReceived = submission.pointsReceived;
        lectureComponent.quiz.preprocess();

        store.dispatch(updateLectureComponentFromAngular({
          quiz: lectureComponent.quiz,
        }));

        if (vm.autoSubmitting) {
          $timeout(() => {
            vm.confirmingClose = true;
            $uibModalStack.dismissAll('cancel');
          }, 0).then(() => {
            showAutomaticallySubmittedOverlay();
          });
        } else {
          $uibModalInstance.close();
          if (CurrentCourseManager.course.gamificationEnabled) {
            ReactTimelineService.updateTimeline(lectureComponent.lecturePageId);
            TimelinesManager.updateComponentPointsAndProgress(
              lectureComponent.lecturePageId,
              lectureComponent.type,
              lectureComponent.id,
              submission.pointsReceived,
              null,
              submission.quiz.progress,
            );
          } else {
            ReactTimelineService.updateTimeline(lectureComponent.lecturePageId);
            TimelinesManager.updateComponentProgress(
              lectureComponent.lecturePageId,
              lectureComponent.type,
              lectureComponent.id,
              submission.quiz.progress,
            );
          }
        }

        const totalPoints = vm.nvUtil.getCurrentTotalPoints(vm.quiz.totalPoints,
          vm.quiz.releaseDate, vm.CurrentCourseManager.course.isDecayEnabled());

        vm.OfflineManager.timedQuizInProgress = false;

        if (moment().isSameOrAfter(vm.releaseResultsAtMoment) && vm.CurrentCourseManager.course.gamificationEnabled
                  && vm.manager.currentQuiz.submission.pointsReceived
                  && totalPoints) {
          const quizScoreInfo = {
            submissionScore: vm.manager.currentQuiz.submission.score,
            maxScore: vm.manager.currentQuiz.submission.maxScore,
            rewardsPointsProportionally: vm.quiz.pointsConfiguration.rewardsPointsProportionally,
            threshold: vm.quiz.pointsConfiguration.threshold,
            pointsReceivedFromPreviousAttempt: null,
            lowerScoreThanPreviousAttempt: false,
          };

          /* Extras has score info for quizzes, and null in other places */
          $uibModal.open({
            templateUrl: 'shared/templates/points-modal.html',
            windowClass: 'points-modal',
            controller: 'PointsModalCtrl as vm',
            resolve: {
              pointsReceived: vm.manager.currentQuiz.submission.pointsReceived,
              leaderboardPoints: vm.manager.currentQuiz.submission.leaderboardPoints,
              leaderboardRank: vm.manager.currentQuiz.submission.leaderboardRank,
              priorLeaderboardRank: vm.manager.currentQuiz.submission.priorLeaderboardRank,
              extras: quizScoreInfo,
            },
          });
        }
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
        },
      );
  };


  vm.onClickSubmit = function () {
    let modalInstance;
    const unansweredCount = vm.manager.currentQuiz.unansweredCount();
    const unansweredCountModalDeffered = $q.defer();
    const finalAttemptModalDeffered = $q.defer();
    let modalInstanceCtrl;

    /* Submit with blank responses */
    if (unansweredCount) {
      // eslint-disable-next-line no-shadow
      modalInstanceCtrl = ['$scope', 'unansweredCount', function ($scope, unansweredCount) {
        $scope.unansweredCount = unansweredCount;
      }];

      const modalInstanceTemplate = 'quizzes/templates/timed_quiz/questions-blank-warning-overlay.html';
      modalInstance = ConfirmationOverlays.openConfirmationModal(modalInstanceTemplate, modalInstanceCtrl, { unansweredCount });
      modalInstance.result.then(() => {
        unansweredCountModalDeffered.resolve();
        finalAttemptModalDeffered.resolve();
      }, () => {
        unansweredCountModalDeffered.reject();
      });
    } else {
      unansweredCountModalDeffered.resolve();

      /* Confirmation before submitting */
      modalInstance = ConfirmationOverlays.openConfirmationModal('quizzes/templates/timed_quiz/submit-timed-quiz-confirmation-overlay.html');
      modalInstance.result.then(() => {
        finalAttemptModalDeffered.resolve();
      }, () => {
        finalAttemptModalDeffered.reject();
      });
    }

    $q.all([unansweredCountModalDeffered.promise, finalAttemptModalDeffered.promise]).then(() => {
      vm.submit();
    });
  };

  /* Private Helpers */
  function checkUnsavedChanges() {
    return vm.quizForm?.$dirty && !vm.submitting;
  }


  function responseSubmittedAlreadyHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    /* Event is timed_quiz_submission so don't need to check action here */
    if (vm.questionSetId === pusherData.questionSet && vm.windowId !== pusherData.windowId) {
      showAlreadySubmittedWarning();
    }
  }

  function showAlreadySubmittedWarning() {
    $timeout(() => {
      vm.confirmingClose = true;
      $uibModalStack.dismissAll('cancel');
    }, 0).then(() => {
      const releaseResultsAt = vm.manager.currentQuiz;
      const resultsAvailableAlready = moment().isSameOrAfter(vm.releaseResultsAtMoment);

      // eslint-disable-next-line no-shadow
      const modalInstanceCtrl = ['$scope', 'releaseResultsAt', 'resultsAvailableAlready', function ($scope, releaseResultsAt, resultsAvailableAlready) {
        $scope.releaseResultsAt = releaseResultsAt;
        $scope.resultsAvailableAlready = resultsAvailableAlready;
      }];

      const modalInstance = ConfirmationOverlays.openConfirmationModal('quizzes/templates/timed_quiz/already-submitted-timed-quiz-overlay.html', modalInstanceCtrl,
        { releaseResultsAt, resultsAvailableAlready });
      modalInstance.result.then(() => {
        vm.confirmingClose = true;
        $uibModalInstance.close();
      });
    });
  }

  function multipleSessionsHandler(pusherData) {
    pusherData = humps.camelizeKeys(pusherData);

    // activate_window when new window is open
    if (vm.questionSetId === pusherData.questionSet && vm.windowId !== pusherData.windowId && pusherData.action === 'activate_window') {
      vm.inactiveSession = true;
      const modalInstance = ConfirmationOverlays.openConfirmationModal('quizzes/templates/timed_quiz/multiple-sessions-warning-overlay.html');
      modalInstance.result.then(() => {
        vm.confirmingClose = true;
        $uibModalInstance.dismiss();
      });
    }
  }

  /* Warning for navigating without submitting */
  if (!vm.adminPreview) {
    const checkPreventNavigation = () => !vm.confirmingClose && !vm.submitting && !vm.inactiveSession && !vm.modalClosing;

    // Not a hook.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useAngularPreventLecturePageNavigation($scope, checkPreventNavigation);

    vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
      checkPreventNavigation,
      'quizzes/templates/timed_quiz/leave-without-submit-warning-overlay.html',
    );

    $scope.$on('modal.closing', ($event) => {
      vm.modalClosing = true;
      if (!vm.confirmingClose && !vm.submitting && !vm.inactiveSession) {
        $event.preventDefault();
        vm.save(); /* save before navigating away */
        const modalOverlay = ConfirmationOverlays.openConfirmationModal('quizzes/templates/timed_quiz/leave-without-submit-warning-overlay.html');
        modalOverlay.result.then(() => {
          vm.confirmingClose = true;
          vm.submitting = false;

          $uibModalInstance.dismiss();
        });
      }
    });
  }

  $scope.$on('$destroy', () => {
    if (!vm.adminPreview) {
      PusherManager.currentUserChannel().unbind('timed_quiz_new_window');
      PusherManager.currentUserChannel().unbind('timed_quiz_submission');

      /* Clean up timer interval */
      CountdownTimerManager.stopTimer();
      CountdownTimerManager.hideTimer();

      $timeout.cancel(vm.saveTimeout);

      angular.element($window).off('beforeunload');
      vm.deregisterStateChangeStart?.();
    }
  });

  /* For likert question */
  $scope.$on('slideEnded', () => {
    vm.quizForm.$setDirty();
  });
}
