/* @ngInject */
export default function DashboardHeaderController(
  CurrentPermissionsManager,
  CurrentUserManager,
  InstitutionsManager,
  DashboardManager,
  RolesService,
  $scope,
) {
  const institutionHasDiscoveryEnabled = InstitutionsManager.institution.discoveryEnabled;
  const vm = {
    CurrentPermissionsManager,
    DashboardManager,
    tabs: [],
    InstitutionsManager,
    hasDashboardTab: (CurrentUserManager.user.enrollments.length > 0 || institutionHasDiscoveryEnabled),
  };
  const isMentor = vm.CurrentPermissionsManager.hasOrgMentorPermissions();
  const isCourseAdmin = CurrentUserManager.isCourseAdminInAtLeastOneCourse();
  const isLearnerInAtLeastOneCourse = CurrentUserManager.user.enrollments.some(enrollment => RolesService.isLearner(enrollment.roles));

  // Show only one tab, and it says 'My Dashboard', when the user is only a learner or a only an org mentor
  if (vm.hasDashboardTab) {
    if (isCourseAdmin) {
      const courseAdminTab = {
        state: 'course-admin-dashboard',
        name: 'COURSE_ADMIN_DASHBOARD.TITLE',
      };

      vm.tabs.push(courseAdminTab);
    }

    // When Org with Discovery disabled, Admin users (Course admin, Org admin, Course manager, Org mentor and Course mentors) should not see 'Learner Dashboard'
    if (institutionHasDiscoveryEnabled || isLearnerInAtLeastOneCourse) {
      vm.tabs.push({
        name: isMentor || isCourseAdmin ? 'DASHBOARD.TITLE' : 'LHS.ACCOUNT_MENU.MY_DASHBOARD',
        state: 'dashboard',
      });
    }
  }

  if (isMentor) {
    const mentorTab = { state: 'mentor-dashboard', name: 'LHS.ACCOUNT_MENU.MY_DASHBOARD' };

    if (vm.hasDashboardTab) {
      mentorTab.name = 'MENTOR_DASHBOARD.TITLE';
      mentorTab.translateValues = {
        MentorTitleAlias: InstitutionsManager.institution.roleAliases.mentor.singularizedTitleized,
      };
    }

    vm.tabs.push(mentorTab);
  }

  $scope.$watch(
    () => vm.InstitutionsManager.institution.roleAliases.mentor.singularizedTitleized,
    (newValue, oldValue) => {
      if (vm.hasDashboardTab && vm.CurrentPermissionsManager.hasOrgMentorPermissions()) {
        vm.tabs[vm.tabs.length - 1].translateValues.MentorTitleAlias = newValue;
      }
    },
  );

  vm.titleBackgroundColor = 'linear-gradient(315.42deg, rgba(220, 253, 253, 0.3) 0%, rgba(247, 251, 232, 0.3) 99.28%), #FFFFFF';

  return vm;
}
