import { jsx } from '@emotion/react';
import { ConditionalWrap } from 'components/conditional-wrap';
import { SetStateAction, Dispatch, createContext, useContext, useEffect, useState, useRef } from 'react';
import { AngularServicesContext } from 'react-app';
import { useSelector } from 'react-redux';
import { cloneDeep, kebabCase, mergeWith } from 'lodash';
import { ComponentTrueType, isTemporaryLectureComponent, LectureComponent, TemporaryLectureComponent } from 'redux/schemas/models/lecture-component';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { useAppDispatch } from 'redux/store';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import useErrorBoundary from 'use-error-boundary';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { DeepPartial } from 'utility-types';
import { adminWorkflowNavigate, ModalWorkflow, WorkflowParams, WorkflowMode } from '.';
import getDefaultNewComponentData from '../data/new-component-defaults';
import { AddComponentCallback } from '../left-panel/create-new-component';
import AngularModalContent from './angular-modal-content';
import { replaceArrays } from 'shared/lodash-utils';

export type ModalWorkflowSettings = {
  /** The translation function to call to get the modal title. Receives course aliases as input */
  title: (mode: WorkflowMode, courseAliases?) => string,
  modalWidth?: number,
  /** If set, will render an Angularjs controller into this modal as a child of renderModal() */
  angularConfig?: {
    /** The string name of the Angularjs controller. Defaults to 'LectureComponentModalCtrl */
    controllerFunction?: any, // This 'should' be Parameters<IControllerService>[0], but Typescript is unable to infer param types from overloads: https://github.com/microsoft/TypeScript/issues/32164
    templateUrl: string,
    /** Many of the Angularjs component modals specify a different form name for inputs in the modal
     * TODO: We could refactor these all to be the same instead of using this property */
    formName?: string,
  }
};

/** This context should be used to customize the workflow's presentation after it's initally created. For instance, this allows new component modals to change
 * the modal's title text */
export const ModalWorkflowContext = createContext<{
  mode: WorkflowMode,
  settings: ModalWorkflowSettings,
  setSettings: Dispatch<SetStateAction<ModalWorkflowSettings>>
}>(null);

/** The core behavior for the ModalWorkflow, but is always wrapped by ConditionalWrapModalWorkflow */
export type ModalWorkflowHandlerProps = {
  workflow: ModalWorkflow,
  lectureComponent: Partial<LectureComponent>,
  children: React.ReactNode,
  save: (LectureComponent: DeepPartial<LectureComponent>, customComponentType?: ComponentTrueType) => ReturnType<AddComponentCallback>,
  mode: WorkflowMode,
};

const ModalWorkflowHandler = (props: ModalWorkflowHandlerProps) => {
  const [show, setShow] = useState(false);
  const params = useLecturePageParams();
  const currentCourse = useSelector(state => state.models.courses[state.app.currentCatalogId]);
  const courseAliases = useSelector(state => getFlatCourseAliases(state, params.catalogId));
  const { ErrorBoundary } = useErrorBoundary();
  const dispatch = useAppDispatch();
  const forwardedOnModalCloseRef = useRef<(e: Event, closeModal: Function) => void>();

  const [settings, setSettings] = useState(props.workflow.initialSettings);

  /** Whether or not this is an Angularjs managed modal workflow */
  const usingAngular = !!settings.angularConfig;
  const angularServices = useContext(AngularServicesContext);

  // TODO: See the todo below; this is a hack and needs removed
  const getLectureComponent = () => {
    // The angularjs model only exists when we're editing; this might need to change per
    // comments below
    if (props.mode === 'edit'
      && usingAngular
      && angularServices.$scope[`lectureComponent${props.lectureComponent.id}`]) {
      return angularServices.$scope[`lectureComponent${props.lectureComponent.id}`];
    }
    return props.lectureComponent;
  };

  /* TODO: My original design for this has broken down. The original intent was for the lecture component object here to always be a flat, non-instance object
  But, the angularjs modals need an instance of a lecture component model b/c they assume they
  can call various instance methods on the component. This draft was intended to be the "working state", non-saved
  version of this component which doesn't make sense when you're mutating an instance
  For now I'm just going to instance this to the angularjs lecture component model when we're
  using an AngularJS modal controller and will revisit this when we build React modal UIs here */
  const [lectureComponentDraft, setLectureComponentDraft] = useState(getLectureComponent());

  const onConfirm = (
    localLectureComponent?: DeepPartial<LectureComponent>,
    skipSave?: boolean,
    customLectureComponent?: ComponentTrueType,
    customWorkflow?: ModalWorkflow<ComponentTrueType>,
  ) => {
    const workflow = mergeWith(cloneDeep(props.workflow), customWorkflow, replaceArrays);

    if (skipSave) {
      closeModal();
      return Promise.resolve();
    }

    return props.save(localLectureComponent, customLectureComponent).then(actionResult => {
      closeModal();

      // Unwrap the lc data returned from the action. If payload doesn't exist
      // then this was returned via Angular
      // TODO: Very brittle & likely to break, see if we can rework this
      const resultActionComponent = (actionResult?.payload ?? actionResult) as LectureComponent;

      // Navigate to the defined Admin 1.0 page after creation, if set
      const postCreateActions = () => {
        const extraDeps = usingAngular ? { angularServices } : undefined;

        const lectureComponent = localLectureComponent;

        mergeWith(lectureComponent, resultActionComponent, replaceArrays);

        workflow.afterSave?.(dispatch, props.mode, lectureComponent, extraDeps);

        if (props.mode === 'new') {
          adminWorkflowNavigate(workflow, params.catalogId, resultActionComponent as any);
        }
      };

      // TODO: This is a legacy step for Angularjs components that need to show a modal after creation.
      // Long-term we should make this a configurable part of the workflow
      const postCreateModalTemplate = workflow.showCreateConfirmation?.(resultActionComponent, currentCourse, props.mode);
      if (postCreateModalTemplate) {
        angularServices.$uibModal.open({
          templateUrl: postCreateModalTemplate,
          backdropClass: 'modal-overlay-backdrop',
          windowClass: 'modal-overlay',
          keyboard: false,
          controller: 'AttachModalResolvesToVmCtrl as vm',
          resolve: {
            vmResolves: {
              CurrentCourseManager: angularServices.CurrentCourseManager,
              lectureComponent: resultActionComponent,
            },
          },
        }).closed.then(() => {
          postCreateActions();
        });
      } else {
        postCreateActions();
      }
    });
  };

  const onCancel = () => {
    closeModal();
  };

  const onModalClose = (e) => {
    forwardedOnModalCloseRef.current?.(e, closeModal);

    if (!e.defaultPrevented) {
      if (settings.angularConfig) {
        angularServices.$scope.$broadcast('react.modal.closing');
      } else {
        onCancel();
      }
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    angularServices.$scope.$on('modal.content.closed', closeModal);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset the draft back to the initial lectureComponent data
  useEffect(() => {
    if (!show) {
      // TODO: I think we want to cloneDeep here when not using an angularjs modal
      setLectureComponentDraft(getLectureComponent());
    }
  }, [props.lectureComponent, show]);

  return (
    <div>
      <ModalWorkflowContext.Provider value={{ mode: props.mode, settings, setSettings }}>
        <NvModal
          header={settings.title(props.mode, courseAliases)}
          body={(
            <ErrorBoundary
              render={() => props.workflow.renderModal({
                ...props,
                onCancel,
                onConfirm,
                forwardOnModalClose: (fn) => {
                  forwardedOnModalCloseRef.current = fn;
                },
                // TODO: We should only be displaying AngularModalContent if the angular settings are defined
                children: (
                  <AngularModalContent
                    {...props}
                    lectureComponent={lectureComponentDraft}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                  />),
              })}
              renderError={({ error }) => <div className='border border-danger'>{error.message}</div>}
            />
            )}
          type={ModalType.DYNAMIC}
          fullHeight={false}
          show={show}
          width={settings.modalWidth}
          onClose={(e) => onModalClose(e)}
          // Apply different CSS classes to the NvModal elements if we're rendering AngularJS modal content. This makes us use
          // the B3 classe for the body (non-prefixed) and compent specific class for the base element
          rootClassName={settings.angularConfig ? `lecture-component-modal ${kebabCase(props.lectureComponent.type)}-modal` : ''}
          bodyClassName={settings.angularConfig ? 'modal-body' : ''}
        />
      </ModalWorkflowContext.Provider>
      <div onClick={() => setShow(true)}>
        {props.children}
      </div>
    </div>
  );
};

const ConditionalWrapModalWorkflow = (props: WorkflowParams) => {
  const condition = props.metadata?.workflow.type === 'modal';

  // The initial data set for the new component; gets passed into the Angularjs modals if they're
  // specified and eventually passed into createNewComponent() to post against the API
  const lectureComponent = props.mode === 'edit' ? props.lectureComponent : getDefaultNewComponentData(props.componentType);

  // const { currentCatalogId } = useSelector(state => state.app);

  return (
    <ConditionalWrap
      condition={condition}
      wrap={condition && ((children) => (
        <ModalWorkflowHandler
          lectureComponent={lectureComponent as Partial<LectureComponent>}
          workflow={props.metadata.workflow as ModalWorkflow}
          save={(savedLc, customType) => props.save(savedLc, customType)}
          mode={props.mode}
        >
          {children}
        </ModalWorkflowHandler>
      ))}
    >
      {props.children}
    </ConditionalWrap>
  );
};

export default ConditionalWrapModalWorkflow;
