import React from 'react';

// Angular
import { AngularContext, AngularServicesContext } from 'react-app';

// Redux
import { useSelector } from 'react-redux';
import { getCurrentCourse } from 'redux/selectors/course';
import { LoginProviderData, UserLogin } from 'redux/schemas/models/sign-in';
import { Course, CourseRegistrationType } from 'redux/schemas/models/course';
import { getMyAccount } from 'redux/actions/users';

// Styles
import { css } from '@emotion/react';
import { white } from 'styles/global_defaults/colors';

// Components
import { cloneDeepSerializable, useAppDispatch } from 'redux/store';
import EnterPassword from './nv-enter-password';
import EnterSubdomain from './nv-enter-subdomain';
import EmailAddressSignIn from './nv-sign-in-email';
import NoSSOSignIn from './nv-sign-in-no-sso';
import SSOSignIn from './nv-sign-in-sso';
import Redirecting from './nv-redirecting';

enum Steps {
  'EmailAddressSignIn',
  'EnterPassword',
  'EnterSubdomain',
  'NoSSOSignIn',
  'SSOSignIn',
}

const maxAttempts = 10;

const styles = css`
  .bs4-input-group {
    .bs4-input-group-append.input-required {
      .bs4-input-group-text {
        background: ${white};
      }
    }
  }
`;

// Renders the proper sign in component based on the scenario
const NvSignIn = () => {
  const { injectServices } = React.useContext(AngularContext);
  const angularServices = React.useContext(AngularServicesContext);

  const [email, setEmail] = React.useState<string>('');
  const [currentStep, setCurrentStep] = React.useState<Steps>();
  const [previousStep, setPreviousStep] = React.useState<Steps>();
  const [redirecting, setRedirecting] = React.useState<boolean>(false);

  const [UserAuthentication] = injectServices(['UserAuthentication']);
  const { InstitutionsManager, CurrentUserManager, $state } = angularServices;
  const { institution } = InstitutionsManager;

  const dispatch = useAppDispatch();
  const currentCourse: Course = useSelector(state => getCurrentCourse(state));
  const showSignUpLink = currentCourse?.typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT;

  const alreadyJoined = $state.params.alreadyJoined === 'true';

  const onEmailAddressSignInSuccess = (data: LoginProviderData) => {
    setEmail(data.email);
    setPreviousStep(currentStep);

    if (data.provider) {
      setRedirecting(true);
      window.location = UserAuthentication.getSSOSignInUrl(data.provider);
    } else if (!data.domain && !data.withPassword) setCurrentStep(Steps.EnterSubdomain);
    else if (!data.provider && data.withPassword) setCurrentStep(Steps.EnterPassword);
  };

  const onBack = () => {
    setCurrentStep(previousStep);
  };

  const onSuccessLogin = (data: UserLogin) => {
    const { user, returnToUrl } = data;
    CurrentUserManager.setNewUser(user);
    CurrentUserManager.reinitialize();
    dispatch(getMyAccount({}));
    UserAuthentication.redirectAfterAuthentication(returnToUrl);
  };

  const onSSOClick = () => {
    setPreviousStep(currentStep);
    setCurrentStep(Steps.EnterSubdomain);
  };

  const onValidDomain = (host) => {
    setRedirecting(true);
    window.location.host = host;
  };

  const getComponent = () => {
    if (redirecting) return <Redirecting />;
    switch (currentStep) {
      case Steps.EmailAddressSignIn: return (
        <EmailAddressSignIn
          email={email}
          course={currentCourse}
          alreadyJoined={alreadyJoined}
          onSuccess={onEmailAddressSignInSuccess}
          onSSOClick={onSSOClick}
        />
      );
      case Steps.EnterPassword: return (
        <EnterPassword
          email={email}
          maxAttempts={maxAttempts}
          onSuccess={onSuccessLogin}
          goBack={onBack}
        />
      );
      case Steps.EnterSubdomain: return (
        <EnterSubdomain
          domain='.novoed.com'
          email={email}
          onSuccess={onValidDomain}
          goBack={onBack}
        />
      );
      case Steps.NoSSOSignIn: return (
        <NoSSOSignIn
          maxAttempts={maxAttempts}
          course={currentCourse}
          alreadyJoined={alreadyJoined}
          onSuccess={onSuccessLogin}
          canCreateNewAccount={showSignUpLink}
        />
      );
      case Steps.SSOSignIn: return (
        <SSOSignIn
          course={currentCourse}
          alreadyJoined={alreadyJoined}
          onSuccess={onEmailAddressSignInSuccess}
        />
      );
      default: return '';
    }
  };

  React.useEffect(() => {
    let initialStep = Steps.EmailAddressSignIn;
    if (institution) {
      initialStep = institution.ssoLogin ? Steps.SSOSignIn : Steps.NoSSOSignIn;
    }
    setCurrentStep(initialStep);
  }, [institution, setCurrentStep]);

  return (
    <div css={styles}>
      {getComponent()}
    </div>
  );
};

export default NvSignIn;
