import { createReducer } from '@reduxjs/toolkit';

import { fetchSinglePost } from 'redux/actions/posts';
import { initialRootState } from '.';


export default createReducer(initialRootState, builder => {
  builder
    .addCase(fetchSinglePost.fulfilled, (state, action) => {
      const { postId } = action.meta.arg;
      const post = action.payload;

      Object.assign(state.models.posts[postId], post);
    });
});
