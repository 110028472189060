import { css } from '@emotion/react';
import React from 'react';

import { info } from 'styles/global_defaults/colors';
import { halfSpacing, largeSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

import { NvUserAvatar } from 'components/nv-user-avatar';
import { User } from 'redux/schemas/models/my-account';

type BadgeMeta = {
  time?: number
  count?: number
  user?: User
  isHighlighted?: boolean
  extras?: any
};

export type Badge = {
  point: number
  meta?: BadgeMeta
};

type NvSeekbarBadgesProps = {
  playerId: string
  badges: Badge[]
  onClickOnBadge: Function
  badgePendoTag?: string
};

const styles = css`
  .nv-seekbar-badge {
    &:hover {
      z-index: 3;
    }

    .badge-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${standardSpacing}px;
      width:${standardSpacing}px;
      border-radius: ${halfSpacing}px;
      opacity: 0.8;
      background: linear-gradient(54.55deg, #009AC0 0%, #00CCBC 58.31%, #60F3AB 100%);
    }

    &.highlighted {
      z-index: 2;
      box-shadow:  0 0 ${halfSpacing}px ${quarterSpacing}px ${info};
      height: ${largeSpacing}px;
      width: ${largeSpacing}px;
      border-radius: ${threeQuartersSpacing}px;

      .badge-text {
        height: ${largeSpacing}px;
        width:${largeSpacing}px;
        border-radius: ${threeQuartersSpacing}px;
      }
    }
  }
`;

const NvSeekbarBadges = ({ playerId, badges, onClickOnBadge: onClickOnBadgeCallback, badgePendoTag }: NvSeekbarBadgesProps) => (
  <div css={styles}>
    {badges.map((badge) => (
      <div
        key={badge.meta?.time || badge.point}
        className={`nv-seekbar-badge jw-reset ${badge.meta?.isHighlighted ? 'highlighted' : ''}`}
        style={{ left: `${badge.point}%` }}
        onClick={() => onClickOnBadgeCallback(badge)}
      >
        {badge.meta?.count && (
          <div className='badge-text label text-white'>
            {badge.meta?.count}
          </div>
        )}
        {!badge.meta?.count && badge.meta?.user && (
          <div
            className='badge-avatar'
            pendo-tag-name={badgePendoTag}
          >
            <NvUserAvatar
              user={badge.meta?.user}
              size={badge.meta?.isHighlighted ? 'sm' : 'xs'}
              borderType='round'
            />
          </div>
        )}
      </div>
    ))}
  </div>
);

export default NvSeekbarBadges;
