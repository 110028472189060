/* @ngInject */
export default function PointsModalController(
  _,
  $state,
  $stateParams,
  $uibModalInstance,
  $timeout,

  CurrentCourseManager,
  CurrentUserManager,
  ConfettiAnimation,

  pointsReceived,
  leaderboardPoints,
  leaderboardRank,
  priorLeaderboardRank,
  extras,
  config,
) {
  const vm = this;
  _.extend(this, {
    $stateParams,
    CurrentCourseManager,
    CurrentUserManager,

    pointsReceived,
    leaderboardPoints,
    leaderboardRank,
    priorLeaderboardRank,
    extras,
    config,
  });

  initialize();

  function initialize() {
    if ($state.params?.catalogId && CurrentCourseManager?.course?.catalogId !== $state.params?.catalogId) {
      vm.course = CurrentUserManager.getCurrentCourse();
    } else {
      vm.course = CurrentCourseManager.course;
    }

    if (!CurrentUserManager.isAdminForCurrentCourse() && vm.leaderboardRank && vm.leaderboardRank <= vm.course.gamificationLeaderboardNumStudentsToShow
        && (!vm.priorLeaderboardRank || (vm.priorLeaderboardRank && vm.priorLeaderboardRank > vm.course.gamificationLeaderboardNumStudentsToShow))) {
      vm.isNewToLeaderboard = true;
      ConfettiAnimation.showConfetti();
    } else {
      vm.isNewToLeaderboard = false;
    }
  }

  // Introduce timeout here to close modal on its own
  $timeout($uibModalInstance.close, 3000);
}
