/* @ngInject */
export default function InstitutionsResource(

  $resource,
) {
  return $resource(
    '/institutions/:id',
    { id: '@id' },
    {
      updateBrand: { method: 'PUT', url: 'institutions/:id/update_brand' },
      disableWelcomeEmail: { method: 'PUT', url: 'institutions/:id/disable_welcome_email' },
      enableWelcomeEmail: { method: 'PUT', url: 'institutions/:id/enable_welcome_email' },
      updateEmailTemplate: { method: 'POST', url: 'institutions/:id/update_email_template' },
      getCloningRequests: { method: 'GET', url: 'institutions/:id/duplication_commands' },
      saveTos: { method: 'PUT', url: 'institutions/:id/save_tos' },
      getBasicInfo: { method: 'GET', url: '/basic_info' },
      updateProviders: { method: 'PUT', url: '/institutions/:id/providers' },
      setAttributes: { method: 'PUT', url: 'institutions/:id/set_attributes' },
      toggleSearchAllUsers: { method: 'PUT', url: '/institutions/:id/toggle_search_all_users.json' },
      toggleFileDownload: { method: 'POST', url: '/institutions/:id/save_allow_download_setting.json' },
      toggleGenerativeAiCapabilities: { method: 'PUT', url: '/institutions/:id/toggle_has_ai_component_generation.json' },
      toggleAppPromotion: { method: 'PUT', url: '/institutions/:id/native_app_promotion/configure.json' },
      saveLiveSessionSettings: { method: 'POST', url: '/institutions/:id/save_live_session_settings.json' },
      disableLiveSessionSettings: { method: 'POST', url: '/institutions/:id/disable_live_session_settings.json' },
      saveDegreedSettings: { method: 'POST', url: '/institutions/:id/save_degreed_settings.json' },
      disableCalendarSettings: { method: 'POST', url: '/institutions/:id/disable_calendar_settings.json' },
      saveCapabilityTags: { method: 'POST', url: '/institutions/:id/set_degreed_tags.json' },
      searchUsers: { method: 'POST', url: '/users/search/organization.json' },
      saveLmsAuthentication: { method: 'POST', url: '/institutions/:id/lms_connections/authentication.json' },
      saveLmsDomain: { method: 'POST', url: '/institutions/:id/lms_connections/domains.json' },
      deleteLmsIntegration: { method: 'DELETE', url: '/institutions/:id/lms_connections.json' },
      validateLmsActivity: { method: 'POST', url: '/institutions/:id/lms_connections/validate_lms_activity.json' },
      toggleMsBot: { method: 'POST', url: '/institutions/:id/toggle_bot_settings' },
    },
  );
}
