import { schema } from 'normalizr';
import { MinimalLecturePage } from './lecture-page';

export interface LearnerProgressSearch{
  result: LearnerProgress[],
  resultCount: number
}
export interface LearnerProgress {
  id: number,
  userId: number,
  email: string,
  firstName: string,
  lastName: string,
  enrolledAt: string, // "2023-11-21T10:01:29.000Z",
  courseHomeFirstVisitedAt?: string | null, // "2023-11-21T10:23:40.000Z",
  lastActivity?: string | null, // "2023-11-21T10:23:50.000Z"
  completionStatus?: CompletionTypeFilters;
  autoPassedAt?: 'string'
  numRequiredAssignmentsCompleted: number,
  numRequiredTodosCompleted: number,
  pointsReceived: number,
  totalPoints: number,
  lectureSubsections: SectionProgress[],
  lectureSections: SectionProgress[],
}

export enum CompletionTypeFilters {
  NOT_COMPLETED = 0,
  AUTO_COMPLETED = 1,
  MANUALLY_COMPLETED = 2,
}

export interface SectionProgress {
  id: number,
  progress?: string,
  progressPercentage?: number,
}

export interface LearnerProgressesNormalized {
  [id: string]: LearnerProgress,
}

export interface ILearnerProgressEntities {
  learnerProgresses: LearnerProgressesNormalized,
}

const learnerProgressSchema = new schema.Entity<LearnerProgress>('learnerProgresses');
export const learnerProgressesSchema = new schema.Array(learnerProgressSchema);

export interface SectionSubsection {
  id: number,
  index: number,
  title: string,
  type: 'LectureSection' | 'LectureSubsection',
  // this release date is referring to the most recent release date of a lesson page underneath the section/subsection
  releaseDate?: string,
  lectureSubsections: SectionSubsection[],
  lecturePages: MinimalLecturePage[],
  hasLessonChildren?: boolean,
  checked?: boolean,
  parentTitle?: string,
}

export interface LearnerProgressDashboardState {
  learnerCounts: LearnerProgressLearnerCounts,
}

interface LearnerProgressLearnerCounts {
  totalLearners: number,
  completed: number,
  inProgress: number,
  missedDeadlines: number,
  inactiveInPastSevenDays: number,
  neverActive: number,
}
