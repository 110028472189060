import { css } from '@emotion/react';
import t from 'react-translate';
import React, { useRef, useCallback, useState, useEffect, MutableRefObject, ReactElement } from 'react';
import { AngularContext } from 'react-app';

// redux
import { BookmarkProps } from 'redux/schemas/models/bookmark';
import { getCurrentCourse } from 'redux/selectors/course';
import { useSelector } from 'react-redux';

// components
import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { hexToRgbaString, gray1 } from 'styles/global_defaults/colors';
import useWindowResize from 'shared/hooks/use-window-resize';
import { handheld, isHandheld } from 'styles/global_defaults/media-queries';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

import _ from 'underscore';
import { ImageDisplayInputs, calculateImageDisplay, calculateImageWidth, ImageWidth, getBaseWidthForRotation } from './image-component-utils';
import ClickableContainer from 'components/clickable-container';

export type ImageComponentImageProps = {
  onClick?: () => void,
  /** TODO: This is optional, but failing to specify it will throw an exception when attempting to calculate the iamge size  */
  imageWidth?: ImageWidth,
  /** Makes this image stretch to the width of its container */
  fitToContainer?: boolean,
  imageUrl: string,
  altText: string,
  displayInputs: Omit<ImageDisplayInputs, 'calculatedImageWidth'>,
  /** If specified, this ref is used for width calculations instead of the .image-container ref
   * Currently used to accomplish 'full' sizing on the ImageLectureComponent */
  sizeContainerRef?: MutableRefObject<HTMLDivElement>,
  /** Children of this component are actually rendered beneath the component, but have style
   * applied to fit their width and position to match the .image-container */
  maxImageWidth?: number,
  tooltip?: string,
  tooltipEnabled?: boolean,
  children?: ReactElement,
  onImageReady?: () => void,
};

const defaultProps: Partial<ImageComponentImageProps> = {
  maxImageWidth: 800,
};

export const ImageComponentImage = (props: ImageComponentImageProps & BookmarkProps) => {
  const { injectServices } = React.useContext(AngularContext);
  const [CurrentUserManager] = injectServices(['CurrentUserManager']);
  const [isImageSettled, setIsImageSettled] = React.useState(false);
  const containerRef = useRef(null);
  const currentCourse = useSelector(getCurrentCourse);

  const onImageReadyRef = useRef<() => void>();
  onImageReadyRef.current = props.onImageReady;

  const hasNotifiedImageReadyRef = React.useRef(false);

  /** Default the width to either the crop size or image size
   * (in pixels, non stretched) */
  let containerWidth = 0;

  if (props.displayInputs.imageIsRotatedSideways) {
    containerWidth = props.displayInputs.imageCropping?.height || props.displayInputs.imageBaseHeight;
  } else {
    containerWidth = props.displayInputs.imageCropping?.width || props.displayInputs.imageBaseWidth;
  }

  /** A dynamically calculated width for the image used in the sizing calcs */
  const [calculatedImageWidth, setCalculatedImageWidth] = useState(containerWidth);
  const [resizeToContainer, setResizeToContainer] = useState(props.fitToContainer);

  const [resizeListener, setResizeListener] = useState(null);

  // TODO: Isn't this identical to containerWidth above?


  const nodeRef = useRef(null);

  const [baseDisplayWidth, setBaseDisplayWidth] = useState(getBaseWidthForRotation(
    props.displayInputs.imageBaseWidth,
    props.displayInputs.imageBaseHeight,
    props.displayInputs.imageCropping,
    props.displayInputs.imageIsRotatedSideways,
  ));

  useEffect(() => {
    setBaseDisplayWidth(getBaseWidthForRotation(
      props.displayInputs.imageBaseWidth,
      props.displayInputs.imageBaseHeight,
      props.displayInputs.imageCropping,
      props.displayInputs.imageIsRotatedSideways,
    ));
  }, [props.displayInputs.imageBaseHeight, props.displayInputs.imageBaseWidth, props.displayInputs.imageCropping, props.displayInputs.imageIsRotatedSideways]);

  const imgRef = useCallback(node => {
    // Avoid calculating the image size before the image has loaded
    if (node && baseDisplayWidth && props.displayInputs.imageBaseWidth) {
      let calcedImageWidth = null;

      if (props.imageUrl) {
        // Used to disable scaling of images when the base width is too small
        const imageIsSmall = baseDisplayWidth > 0 && baseDisplayWidth < props.maxImageWidth;

        // Images cannot be stretched above their native width, or the specified max width, whichever is larger
        if (props.displayInputs.imageBaseWidth && imageIsSmall) {
          calcedImageWidth = baseDisplayWidth;
          setCalculatedImageWidth(baseDisplayWidth);
          setResizeToContainer(false);
        } else {
          calcedImageWidth = calculateImageWidth(containerRef, props.sizeContainerRef ?? containerRef, props.imageWidth ?? '100%', setCalculatedImageWidth);
          setResizeToContainer(true);
        }
      }

      // TODO: Testing recalcing this here instead of using passed prop value
      const imageIsRotatedSideways = props.displayInputs.imageRotation % 90 === 0 && props.displayInputs.imageRotation % 180 !== 0;

      setDisplayProps(calculateImageDisplay(
        { current: node },
        {
          ...props.displayInputs,
          imageIsRotatedSideways,
          calculatedImageWidth: calcedImageWidth,
        },
        props.imageUrl,
      ));

      nodeRef.current = node;
    }
  // We depend on the displayInput properties individually to prevent unnecessary rerendering when the displayInput object changes with the same values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseDisplayWidth, props.imageUrl, props.maxImageWidth, props.sizeContainerRef, props.imageWidth, props.displayInputs.imageBaseHeight, props.displayInputs.imageBaseWidth, props.displayInputs.imageCropping, props.displayInputs.imageRotation, props.displayInputs.renderId]);

  useWindowResize(() => {
    // Used to disable scaling of images when the base width is too small
    const imageIsSmall = baseDisplayWidth > 0 && baseDisplayWidth < props.maxImageWidth;

    if (resizeToContainer && nodeRef?.current && !imageIsSmall) {
      imgRef(nodeRef.current);
    }
  }, 100, false, [baseDisplayWidth, props.displayInputs.imageBaseHeight, props.displayInputs.imageBaseWidth, props.displayInputs.imageCropping, props.displayInputs.imageRotation, props.imageWidth, props.maxImageWidth, props.sizeContainerRef, resizeListener, resizeToContainer]);

  // Resize image width when hiding or displaying the timeline
  useEffect(() => {
    if (resizeToContainer && nodeRef?.current) {
      imgRef(nodeRef.current);
    }
  }, [CurrentUserManager.user.isTimelineExpanded, imgRef, resizeToContainer]);

  const [displayProps, setDisplayProps] = useState<ReturnType<typeof calculateImageDisplay>>(null);

  const {
    transformOrigin = '',
    rotationOffset = '',
    alignmentOffset = '',
    cropContainerStyle = {},
    imgStyle = {},
    cropTranslate = '',
  } = displayProps ?? {};

  const imageWidth = imgStyle.width;

  useEffect(() => {
    if (imageWidth && isImageSettled) {
      onImageReadyRef.current?.();
    }
  }, [imageWidth, isImageSettled]);

  const styles = css`
    width: 100%;

    .image-container, .image-children {
      width: ${props.fitToContainer ? '100%' : `${calculatedImageWidth}px`};
      /* width: 100%; */
      display: flex;
      /* Set to column so we can use align-self to set the image
      alignment */
      flex-direction: column;
      /* This seems to be required to make writing-mode: vertical-rtl correctly position the image
      in this container after rotation */
      position: relative;
    }

    .crop-container {
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .show-on-hover {
        display: none;
      }

      &:hover {
        .show-on-hover {
          display: flex;
        }
      }

      ${handheld(css`
        .show-on-hover {
          display: flex;
        }
      `)};
    }

    .bookmark-icon-wrapper {
      position: absolute;
      top: 0;
      right: ${standardSpacing}px;
      background-color: ${hexToRgbaString(gray1, 0.4)};
      width: 28px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      color: white;
    }

    img {
      position: absolute;
      transform: ${rotationOffset} rotate(${props.displayInputs.imageRotation}deg) ${alignmentOffset} ${cropTranslate};
      transform-origin: ${transformOrigin};
      max-width: none;
    }
  `;

  const settleImage = () => setIsImageSettled(true);

  return (
    <div css={styles}>
      <div className='w-100' ref={containerRef}>
        {/* A container div is used for alignment because we cannot do flex alignment based on the root div here since the ImageEditPopover contains this element */}
        <div className='image-container'>
          <NvTooltip text={props.tooltip ?? ''} enabled={!!props.tooltip && props.tooltipEnabled} preventOverflow={false}>
            {/* This div masks the img beneath it */}
            <ClickableContainer className='crop-container' style={cropContainerStyle} onClick={() => props.onClick?.()} aria-label={props.tooltip}>
              {!props.bookmarkHidden && !currentCourse.isContentManagementCollection && (
                <div className={`bookmark-icon-wrapper ${!props.bookmarkId ? 'show-on-hover' : ''}`}>
                  <BookmarkIcon
                    tooltipDisabled
                    size='smallest'
                    bookmarked={!!props.bookmarkId}
                    onCreate={props.onCreateBookmark}
                    onHighlight={props.onHighlightBookmark}
                    colors={{
                      default: 'white',
                      bookmarked: 'white',
                    }}
                  />
                </div>
              )}
              <img
                src={props.imageUrl}
                alt={props.altText}
                title={props.altText}
                ref={imgRef}
                style={imgStyle}
                onLoad={settleImage}
                onError={settleImage}
              />
            </ClickableContainer>
          </NvTooltip>
        </div>
      </div>
      {/* This panel is for displaying items below the image that should fit the image's final render  width
      This is added here b/c I wasn't able to reasonably get that width from outside of this component
      TODO: Consider reworking this component into a hook to expose that width */}
      <div className='image-children'>
        <div style={{ width: cropContainerStyle.width }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

ImageComponentImage.defaultProps = defaultProps;

export default ImageComponentImage;
