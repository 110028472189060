/* eslint-disable jsx-a11y/alt-text */
import { css } from '@emotion/react';
import { useState } from 'react';
import t from 'react-translate';
import { UploadedFile } from 'redux/schemas/models/file-uploads';
import NvVideoPreview from 'shared/components/nv-video-preview';
import { gray1, gray4, hexToRgbaString } from 'styles/global_defaults/colors';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import prodPathReplace from '../prod-path-rewrite';
import { config } from '../../../config/config.json';


const normalizeFileType = (fileType, fileName) => {
  fileType = fileType.split(';codecs')[0];
  if (!fileType) {
    const extension = `.${fileName.split('.').reverse()[0]}`;
    fileType = config.files.extensionToMimeType[extension];
  }
  if (fileType === 'image' || config.files.images.split(',').includes(fileType)) {
    return 'image';
  } if (config.files.videos.split(',').includes(fileType)) {
    return 'video';
  } if (config.files.audios.split(',').includes(fileType)) {
    return 'audio';
  } if (config.files.pdfs.split(',').includes(fileType)) {
    return 'pdf';
  } if (config.files.documents.split(',').includes(fileType)) {
    return 'document';
  } if (config.files.excels.split(',').includes(fileType)) {
    return 'spreadsheet';
  } if (config.files.presentations.split(',').includes(fileType)) {
    return 'presentation';
  } if (config.files.zips.split(',').includes(fileType)) {
    return 'zip';
  }
  return 'other';
};

const getImageSrc = (fileType) => {
  // NOTE: have to return the full path string so gulp will be able to process properly
  switch (fileType) {
    case 'pdf':
      return prodPathReplace('images/file-pdf.png');
    case 'image':
      return prodPathReplace('images/file-image.png');
    case 'video':
      return prodPathReplace('images/file-video.png');
    case 'audio':
      return prodPathReplace('images/file-audio.png');
    case 'document':
      return prodPathReplace('images/file-doc.png');
    case 'spreadsheet':
      return prodPathReplace('images/file-excel.png');
    case 'presentation':
      return prodPathReplace('images/file-powerpoint.png');
    case 'externaldocument':
      return prodPathReplace('images/file-externaltools.png');
    case 'zip':
      return prodPathReplace('images/file-zip.png');
    case 'other':
      return prodPathReplace('images/file-other.png');
    default:
      return null;
  }
};

type NvUploadedFileProps = {
  file: UploadedFile,
  // editable?: any,
  displayInline?: any,
  // displayInlineStrict?: any,
  // displayNameOnly?: any,
  // downloadAlwaysVisible?: any,
  onDelete?: any,
  // clickHandler?: any,
  originalUrl?: any,
  showConfirmation?: any,
  displayNameOnly?: any;
  editable?: boolean
  className?: string
  downloadDataQa?: string
  deleteDataQa?: string
  confirmDeleteDataQa?: string
  cancelDeleteDataQa?: string
};

const NvUploadedFile = ({
  file,
  displayInline,
  displayNameOnly,
  onDelete,
  editable,
  className,
  downloadDataQa,
  deleteDataQa,
  confirmDeleteDataQa,
  cancelDeleteDataQa,
}: NvUploadedFileProps) => {
  const styles = css`
    .editable {
      border: 1px dashed transparent;
      left: -10px;
      padding: 10px;
      width: 100%;
    }
    .file-container {
      position: relative;
      box-sizing: content-box;
      min-height: 60px;
      .image {
        width: auto;
        height: auto !important;
        max-width: 100%;

        .preview {
          vertical-align: baseline;
        }
      }
      &:hover, &:focus-within {
        .file-container {
          border-color: ${gray4};
        }
      }
      .deletion-confirmation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${hexToRgbaString(gray1, 0.95)};
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .deletion-confirmation-content {
          ${displayNameOnly && css`
            display: flex;
          `}

          .confirmation-text {
            margin-bottom: ${halfSpacing}px;

            ${displayNameOnly && css`
              margin-right: ${standardSpacing}px;
              margin-bottom: 0;
              display: flex;
              align-items: center;
            `}
          }
        }

        .btn-danger {
          margin-left: ${halfSpacing}px;
        }
      }
    }

    &:hover {
      .file-container {
        border: 1px dashed ${gray4};
      }
    }

    &.confirming-delete {
      .delete-icon, .download-icon {
        visibility: hidden;
      }

      .file-container {
        border-color: transparent;
      }
    }

    .display-name {
      img {
        height: 60px;
      }
    }
  `;


  const getContent = (fileType) => {
    switch (fileType) {
      case 'video':
        return <NvVideoPreview file={file} />;
      case 'pdf':
      case 'image':
        return (
          <div className='image'>
            <img
              className='preview'
              src={file.url}
            />
          </div>
        );
      case 'audio':
      case 'document':
      case 'spreadsheet':
      case 'presentation':
      case 'externaldocument':
      case 'zip':
      case 'other':
      default:
        return null;
    }
  };

  const downloadFile = () => {
    if (file?.url) {
      window.open(file.url, '_blank');
    }
  };

  const isFileAvailableToDownload = () => (
    (typeof file.isDownloadable === 'undefined' || file.isDownloadable === null) ? true : file.isDownloadable
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const normalizedFileType = normalizeFileType(file.type, file.name);

  return (
    <div className={`${className} ${showConfirmation ? 'confirming-delete' : ''}`} css={styles}>
      <div className='d-flex mb-1'>
        {isFileAvailableToDownload && (
          <div
            className='d-flex download-icon text-primary mr-4'
            onClick={downloadFile}
            data-qa={downloadDataQa}
          >
            <i className='icon icon-smallest icon-download cursor-pointer' />
            <p className='ml-1 mb-1 font-weight-bold cursor-pointer'>{t.FILE_UPLOAD.DOWNLOAD()}</p>
          </div>
        )}
        {onDelete && (
          <div
            className='d-flex delete-icon text-primary'
            onClick={() => setShowConfirmation(true)}
            data-qa={deleteDataQa}
          >
            <i className='icon icon-smallest icon-trash cursor-pointer' />
            <p className='ml-1 mb-1 font-weight-bold cursor-pointer'>{t.NOVOED.DELETE()}</p>
          </div>
        )}
      </div>
      <div className={`file-container ${editable ? 'editable' : ''}`}>
        {displayNameOnly ? (
          <div className='bg-gray-7 display-name'>
            <img src={getImageSrc(normalizedFileType)}/>
            <span className='file-name text-small pl-4'>{file.name}</span>
          </div>
        ) : getContent(normalizedFileType)}
        {showConfirmation && (
          <div className='deletion-confirmation'>
            <div className='deletion-confirmation-content'>
              <div className='confirmation-text'>{t.FILE_UPLOAD.CONFIRM_DELETE()}</div>
              <button
                type='button'
                className='btn btn-default btn-sm dark-theme'
                onClick={() => setShowConfirmation(false)}
                data-qa={cancelDeleteDataQa}
              >{t.FORM.CANCEL()}
              </button>
              <button
                type='button'
                className='btn btn-danger btn-sm dark-theme'
                onClick={() => { setShowConfirmation(false); onDelete(); }}
                data-qa={confirmDeleteDataQa}
              >{t.FORM.DELETE()}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NvUploadedFile;
