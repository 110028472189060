import { css } from '@emotion/react';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import { useForm, FormProvider, useFieldArray, useWatch, Control } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from 'redux/store';

import { each, every } from 'underscore';
import NvFroala from 'froala/components/nv-froala';
import { ToolbarOptions, FroalaViewMode, UploadType } from 'froala/helpers/nv-froala-constants';
import { SanitizationLevel } from 'froala/helpers/sanitizer';
import NvStarRating from 'shared/components/nv-star-rating';
import { Button } from 'react-bootstrap';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { AddSkillRatingsParams } from 'redux/schemas/api/skills-feedback';
import { useSelector } from 'react-redux';
import { addRating } from 'redux/actions/skills-feedback';
import { SkillTag } from 'redux/schemas/models/skill-tag';

import { gray4, primary } from 'styles/global_defaults/colors';
import { mobile } from 'styles/global_defaults/media-queries';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import _ from 'lodash';
import { config } from '../../../../config/pendo.config.json';

interface SkillRatingProps {
  skillTags?: SkillTag[],
  showForm?: Dispatch<SetStateAction<boolean>>,
  onSubmit?: (data?: any) => void,
  onCancel?: (isDirty: boolean) => void,
  ownerId: number,
  ownerType: string,
  feedbackCriteriaId?: number,
  catalogId?: string, // Only for assignments
  highlightMode?: boolean,
}

type RatingData = {
  id: number,
  name: string,
  body?: string,
  rating?: number
};

type FormData = {
  ratings: RatingData[]
};

interface AddSkillRatingProps {
  control: Control<FormData>
  index: number
  highlightMode?: boolean
}

const getInitialFormData = (tags: SkillTag[]): RatingData[] => {
  if (tags?.length) {
    const ratings = [];
    each(tags, tag => {
      ratings.push({ ...tag, rating: 0, body: '' });
    });

    return ratings;
  }
  return [];
};

const styles = css`
  .tag-badge {
    border-radius: 20px;
  }
  .highlight {
    .nv-froala-origami {
      margin-left: 0 !important;
    }
    .tag-badge, .froala-container {
      border: 1px solid ${gray4};
    }
    &.focused {
      .tag-badge, .froala-container {
        border: 1px solid ${primary};
      }
    }
  }
  ${mobile(css`
    .skills-feedback-cancel-btn {
      margin-bottom: ${halfSpacing}px;
    }
  `)}
`;

const AddSkillRating = ({ control, index, highlightMode }: AddSkillRatingProps) => {
  const { rating, name } = useWatch({ control, name: `ratings.${index}` });
  const [isFocused, setIsFocused] = useState(false);
  const [selectedRating, setSelectedRating] = useState(0);

  const froalaRef = useRef(null);

  const onStarHover = (hovered: number) => {
    if (selectedRating !== hovered) setIsFocused(false);
  };

  const onRatingChange = (selected: number) => {
    if (selected === 0) setIsFocused(false);
    setSelectedRating(selected);
  };

  useEffect(() => {
    if (rating > 0) {
      froalaRef.current?.focus();
      setIsFocused(true);
    }
  }, [rating]);

  return (
    <div className={`d-flex flex-column my-4 ${highlightMode ? 'highlight' : ''} ${isFocused ? 'focused' : ''}`}>
      <div className='d-flex justify-content-between'>
        <div className='tag-badge bg-gray-7 mr-2 py-2 px-3 text-black ellipsis'>
          {name}
        </div>
        <NvStarRating
          starSize='medium'
          name={`ratings.${index}.rating`}
          withForm
          tooltipText={t.SKILLS_FEEDBACK.REMOVE_RATING()}
          tooltipIndex={rating}
          onChange={onRatingChange}
          onHover={onStarHover}
        />
      </div>
      <div className={rating > 0 ? 'mt-4' : 'd-none'}>
        <NvFroala
          withForm
          toolbarButtons={[
            ToolbarOptions.BOLD,
            ToolbarOptions.ITALIC,
            ToolbarOptions.UNDERLINE,
            ToolbarOptions.FORMAT_UL,
            ToolbarOptions.FORMAT_OL,
          ]}
          name={`ratings.${index}.body`}
          placeholder={t.FORM.TYPE_HERE()}
          className='text-body'
          sanitizationLevel={SanitizationLevel.SIMPLE}
          preset={FroalaViewMode.INLINE}
          uploadType={UploadType.NONE}
          minHeight={100}
          ref={froalaRef}
        />
      </div>
    </div>
  );
};

interface RatingSchema {
  rating: number;
  body: string | null;
}

const SkillFeedback = ({
  showForm,
  skillTags,
  onSubmit: onSubmitFeedback,
  onCancel: onCancelFeedback,
  ownerId,
  ownerType,
  feedbackCriteriaId,
  catalogId,
  highlightMode,
}: SkillRatingProps) => {
  const dispatch = useAppDispatch();
  const [mainKey, setMainKey] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentInstitutionId = useSelector(
    (state) => state.app.currentInstitutionId,
  );

  const ratingSchema: yup.Schema<RatingSchema> = yup.object().shape({
    rating: yup.number().required(),
    body: yup.string().nullable(),
  });
  const validationSchema = yup.object().shape({
    ratings: yup.array().of(ratingSchema),
  }).test(
    'rating',
    '',
    (ratings) => every(ratings.ratings, item => item.rating > 0),
  );

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: { ratings: getInitialFormData(skillTags) },
    resolver: yupResolver(validationSchema),
  });

  const { fields } = useFieldArray<FormData, 'ratings', 'id'>({
    name: 'ratings',
    control: methods.control,
    keyName: 'id',
  });

  const { handleSubmit, formState, reset } = methods;

  const { isDirty, isValid } = formState;

  const onSubmit = (data: FormData) => {
    const originalData = _.cloneDeep(data);
    const submitData = () => {
      const pickedData = originalData?.ratings?.map(item => ({
        skillTagId: item.id,
        body: item.body ?? '',
        rating: item.rating,
      })).filter(rating => (rating.rating > 0));
      const skillRatings: AddSkillRatingsParams = {
        institutionId: currentInstitutionId,
        ratings: pickedData,
        ownerId,
        ownerType,
        feedbackCriteriaId,
        catalogId,
      };
      dispatch(addRating(skillRatings)).then((res) => {
        setIsSubmitting(false);
        onSubmitFeedback(res);
        showForm?.(false);
      });
    };

    setIsSubmitting(true);
    dispatch(openConfirmationDialog({
      onConfirm: () => submitData(),
      onCancel: () => setIsSubmitting(false),
      cancelText: t.FORM.CANCEL(),
      confirmText: t.FORM.YES_SURE(),
      title: t.PRACTICE_ROOM.SKILL_FEEDBACK.SUBMIT_CONFIRMATION.TITLE(),
      bodyText: t.PRACTICE_ROOM.SKILL_FEEDBACK.SUBMIT_CONFIRMATION.DESCRIPTION(),
      confirmButtonVariant: 'primary',
      icon: 'info',
    }));
  };

  const onCancel = () => {
    if (isDirty) {
      dispatch(openConfirmationDialog({
        onConfirm: () => {
          if (onCancelFeedback) onCancelFeedback(isDirty);
          else showForm?.(false);
        },
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.DISCARD_CHANGES(),
        title: t.FORM.UNSAVED_CHANGES.NAVIGATE_AWAY_CHANGES(),
      }));
    } else if (onCancelFeedback) onCancelFeedback(isDirty);
    else showForm?.(false);
  };

  useEffect(() => {
    reset({ ratings: getInitialFormData(skillTags) });
    setMainKey(Math.random());
  }, [ownerId]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} css={styles}>
        <div
          className={`mt-2 w-100 ${highlightMode ? 'pt-2 pr-2 pb-2' : 'p-2'}`}
        >
          {fields.map((skill, index) => (
            <AddSkillRating control={methods.control} index={index} key={skill.id + mainKey} highlightMode={highlightMode} />
          ))}
        </div>
        <div className='button-bar mt-5'>
          <Button
            className='skills-feedback-cancel-btn'
            variant='secondary'
            onClick={onCancel}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            className='skills-feedback-submit-btn'
            variant='primary'
            disabled={!isValid || !isDirty || isSubmitting}
            type='submit'
            data-qa={config.pendo.skillsFeedback.practiceSkillsFeedbackSubmit}
          >
            {isSubmitting ? t.FORM.SUBMITTING() : t.FORM.SUBMIT()}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default SkillFeedback;
