import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import OfferingCard from 'offerings/components/offering-card';
import t from 'react-translate';
import NvNoResults from 'shared/components/nv-no-results-panel';
import {
  lightFontWeight,
  openSans,
  semiBoldFontWeight,
  textSmallFontSize,
} from 'styles/global_defaults/fonts';
import {
  cardSize,
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { fetchDiscovery } from 'redux/actions/courses';
import { AllLoaded } from 'lhs/components/loading-placeholders';
import { LoaderOptions, LoaderType } from 'shared/components/loading-placeholder';
import { Course, InfiniteLoadingState } from 'redux/schemas/models/course';
import { NvLoadingPlaceholderCard } from 'shared/components/nv-loading-placeholder';
import { useInView } from 'react-intersection-observer';
import { useAppDispatch } from 'redux/store';
import { NvSearchBar } from 'shared/components/nv-search-bar';
import { black, gray5 } from 'styles/global_defaults/colors';
import { NvDropdownCheckbox } from 'shared/components/inputs/nv-dropdown';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { fetchSkillTagsForProdCourses } from 'redux/actions/skill-tags';
import { mobile, tablet } from 'styles/global_defaults/media-queries';
import { getDiscoveryParams } from 'redux/selectors/course';
import { InfiniteLoadingParams } from 'redux/schemas/api/shared';
import { replace, startCase } from 'lodash';
import { FiltersData, NvFiltersBar } from 'shared/components/filters/nv-filters-bar';
import { getSkillTagsForProdCourses } from 'redux/selectors/skills-feedback';
import { config } from '../../../../config/pendo.config.json';

enum FilterKey {
  LEARNING_TYPE = 'learning_type',
  SKILL_TAG_IDS = 'skill_tag_ids',
}

enum LearningTypes {
  COURSE = 'course',
  LEARNING_JOURNEY = 'journey',
}

const INITIAL_PARAMS: InfiniteLoadingParams = {
  page: 1,
  page_size: 15,
  query: '',
  filters: {
    [FilterKey.LEARNING_TYPE]: [],
    [FilterKey.SKILL_TAG_IDS]: [],
  },
};

const commonStyles = css`
  height: 100%;
  padding: 0 ${standardSpacing}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  ${mobile(css`
    padding: 0px ${quarterSpacing}px;
  `)};

  .no-results-panel {
    padding-top: ${doubleSpacing * 2}px;
    gap: ${standardSpacing}px;

    .icon.icon-course {
      margin: 0;
      font-size: ${textSmallFontSize * 5}px;
    }

    .clear-text {
      margin: 0;
      font-weight: ${semiBoldFontWeight};
    }
  }

  .total-results {
    margin: ${standardSpacing}px 0;
    font-family: ${openSans};
    font-weight: ${lightFontWeight};
  }
`;

const loadingContainerStyles = css`
  margin: ${standardSpacing}px 0;
  font-family: ${openSans};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${standardSpacing}px;
`;

const offeringsContainerStyles = css`
  max-width: calc((${cardSize}px * 3) + (${standardSpacing}px * 2));
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${standardSpacing}px;
`;

const LoadingPlaceholders = ({ loaderType }: LoaderOptions) => {
  const loadingPlaceholders = Array.from({ length: loaderType === LoaderType.FULL ? 6 : 3 }).map((_, i) => <NvLoadingPlaceholderCard key={`card-${String(i)}`} />);

  return (
    <section css={loadingContainerStyles}>
      { loaderType === LoaderType.FULL && <p className='text-medium'>{t.LEARNING_CATALOG.LOADING_RESULTS()}</p>}
      <div css={offeringsContainerStyles}>
        { loadingPlaceholders }
      </div>
    </section>
  );
};

const ModalContent = () => {
  const dispatch = useAppDispatch();
  const { id: currentInstitutionId } = useSelector(getCurrentInstitution);
  const discoveryParams: InfiniteLoadingState = useSelector(getDiscoveryParams);
  const [query, setQuery] = React.useState(discoveryParams.query);
  const [filters, setFilters] = React.useState(INITIAL_PARAMS.filters);
  /* The 'searchedQuery' only gets updated during hitting search,
  It is to prevent the search term is being applied without hitting enter if user scrolls results */
  const [searchedQuery, setSearchedQuery] = React.useState(discoveryParams.query);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const searchBarRef = React.useRef(null);
  const { ref, inView } = useInView({ threshold: 0.7 });

  const searchBarStyles = css`
    padding: ${standardSpacing}px;
    border-bottom: 1px solid ${gray5};
    flex-direction: 'row';
    gap: ${quarterSpacing}px;

    input {
      width: 100%;
    }

    .search-button i {
      color: ${black} !important;
    }
  `;

  // Getting the height of the content section so the overflow can work as expected
  const calculateContentHeight = () => {
    if (contentRef.current) {
      // Calculate the new heights of the parent section and the content section
      // Modal height - modal header height
      const newSectionHeight = contentRef.current.offsetParent.clientHeight - contentRef.current.offsetParent.children[0].clientHeight;
      // New section height - search bar height - filters bar height
      const newContentHeight = newSectionHeight - contentRef.current.parentElement.children[0].clientHeight - contentRef.current.parentElement.children[1].clientHeight;

      // Setting the new heights of the sections
      contentRef.current.parentElement.style.height = `${newSectionHeight}px`;
      contentRef.current.style.height = `${newContentHeight}px`;
    }
  };

  React.useEffect(() => {
    calculateContentHeight();
    window.addEventListener('resize', calculateContentHeight);
    return () => window.removeEventListener('resize', calculateContentHeight);
  }, []);

  // Getting the pagination state
  const {
    discovery: {
      loading,
      loadingMore,
      firstPageLoaded,
      hasMore,
      total,
      ids,
      currentPage,
      filters: appliedFilters,
    },
  } = useSelector(state => state.app);

  // Implementing the inifinite loading
  React.useEffect(() => {
    const shouldLoad = ((inView && hasMore) || (currentPage === INITIAL_PARAMS.page && !firstPageLoaded))
      && !loading
      && !loadingMore;

    if (shouldLoad) {
      dispatch(fetchDiscovery({
        page: currentPage,
        page_size: INITIAL_PARAMS.page_size,
        query: searchedQuery,
        filters,
      }));
    }
  }, [hasMore, loading, loadingMore, currentPage, firstPageLoaded, inView]);

  // Getting the items from the state.models
  const discoveryOfferings: Course[] = useSelector(
    state => state.models.discoveryOfferings,
  );

  // Identifying if there aren't items
  const noItems = Object.keys(ids)?.length === 0 && firstPageLoaded;

  // Setting the learning types
  const learningTypes: Array<{ id: string, name: string }> = [
    {
      id: LearningTypes.COURSE,
      name: startCase(replace(Object.keys(LearningTypes)[0].toLowerCase(), '_', ' ')),
    },
    {
      id: LearningTypes.LEARNING_JOURNEY,
      name: startCase(replace(Object.keys(LearningTypes)[1].toLowerCase(), '_', ' ')),
    },
  ];

  // Getting the skill tags of the current institution
  const skillTags: Array<{ id: number, name: string }> = useSelector(getSkillTagsForProdCourses);

  React.useEffect(() => {
    dispatch(fetchSkillTagsForProdCourses({ institutionId: currentInstitutionId }));
  }, []);

  // Generates a filter title with an optional count of selected items
  // If no items are selected, returns the base title; otherwise, appends the count of selected items to the title.
  const getFilterTitle = (title: string, selectedItems: number) => (selectedItems === 0 ? title : `${title} (${selectedItems})`);

  // Check if the item is stored in the filters state to sync it with the checkbox UI
  const isItemChecked = (filterKey: FilterKey, itemId: string | number) => filters[filterKey].some(item => item === itemId);

  // Handling the selection or deselection of a filter item and updates the filters state accordingly.
  const onItemSelected = (isChecked: boolean, filterKey: FilterKey, filterId: number | string) => {
    if (isChecked && !filters[filterKey].includes(filterId)) {
      setFilters({
        ...filters,
        [filterKey]: [...filters[filterKey], filterId],
      });
    } else {
      setFilters({
        ...filters,
        [filterKey]: filters[filterKey].filter(id => id !== filterId),
      });
    }
  };

  // Setting the filters data structure
  const filtersData: Array<FiltersData> = [
    {
      title: getFilterTitle(t.LEARNING_CATALOG.LEARNING_TYPE(), filters[FilterKey.LEARNING_TYPE].length),
      selected: !!filters[FilterKey.LEARNING_TYPE].length,
      items: [
        {
          type: 'header',
          title: t.LEARNING_CATALOG.LEARNING_TYPE(),
          class: 'title',
        },
        ...learningTypes.map(learningType => ({
          type: 'checkbox',
          id: learningType.id,
          label: learningType.name,
          name: learningType.name,
          class: 'filter-item',
          checked: isItemChecked(FilterKey.LEARNING_TYPE, learningType.id),
          onChanged: (isChecked) => onItemSelected(isChecked, FilterKey.LEARNING_TYPE, learningType.id),
        })),
      ] as NvDropdownCheckbox[],
    },
  ];

  if (skillTags.length > 0) {
    filtersData.push(
      {
        title: getFilterTitle(t.LEARNING_CATALOG.SKILLS(), filters[FilterKey.SKILL_TAG_IDS].length),
        selected: !!filters[FilterKey.SKILL_TAG_IDS].length,
        items: [
          { type: 'header', title: t.LEARNING_CATALOG.SKILLS(), class: 'title' },
          ...skillTags.map(skillTag => ({
            type: 'checkbox',
            id: skillTag.id,
            label: skillTag.name,
            name: skillTag.name,
            class: 'filter-item',
            checked: isItemChecked(FilterKey.SKILL_TAG_IDS, skillTag.id),
            onChanged: (isChecked) => onItemSelected(isChecked, FilterKey.SKILL_TAG_IDS, skillTag.id),
          })),
        ] as NvDropdownCheckbox[],
      },
    );
  }

  // Checking if any filter is selected or applied
  const hasFiltersSelectedOrApplied = filtersData.some(filter => filter.selected) || Object.keys(appliedFilters).some(key => appliedFilters[key].length > 0);

  // Handling the search by reseting the params and setting the query value
  const handleSearch = (value: string) => {
    setQuery(value);
    setSearchedQuery(value);
    contentRef.current?.scrollTo({ top: 0 });

    dispatch(fetchDiscovery({
      page: INITIAL_PARAMS.page,
      page_size: INITIAL_PARAMS.page_size,
      query: value,
      filters,
    }));
  };

  // Handling the escape key of the search bar to remove the input focus
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      if (searchBarRef.current && document.activeElement === searchBarRef.current) {
        // Prevent the event from reaching the modal's ESC key handler
        searchBarRef.current.blur();
        e.stopPropagation();
      }
    }
  };

  const handleOnClearFilters = () => {
    setFilters(INITIAL_PARAMS.filters);

    dispatch(fetchDiscovery({
      page: INITIAL_PARAMS.page,
      page_size: INITIAL_PARAMS.page_size,
      query,
      filters: INITIAL_PARAMS.filters,
    }));
  };

  const handleOnApplyFilters = () => {
    contentRef.current?.scrollTo({ top: 0 });

    dispatch(fetchDiscovery({
      page: INITIAL_PARAMS.page,
      page_size: INITIAL_PARAMS.page_size,
      query,
      filters,
    }));
  };

  const clearSearchAndFilters = () => {
    setQuery(INITIAL_PARAMS.query);
    setSearchedQuery(INITIAL_PARAMS.query);
    setFilters(INITIAL_PARAMS.filters);
    contentRef.current?.scrollTo({ top: 0 });

    dispatch(fetchDiscovery({
      page: INITIAL_PARAMS.page,
      page_size: INITIAL_PARAMS.page_size,
      query: INITIAL_PARAMS.query,
      filters: INITIAL_PARAMS.filters,
    }));
  };

  return (
    <section>
      <NvSearchBar
        ref={searchBarRef}
        css={searchBarStyles}
        name='search-learning-catalog'
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        onSearch={handleSearch}
        autoComplete='off'
        placeholder={t.LEARNING_CATALOG.SEARCH()}
        clearButtonEnabled={!!query}
        onClearButton={() => handleSearch(INITIAL_PARAMS.query)}
        data-qa={config.pendo.learningCatalog.search}
      />

      <NvFiltersBar
        filters={filtersData}
        hasFiltersSelectedOrApplied={hasFiltersSelectedOrApplied}
        onApplyFilters={handleOnApplyFilters}
        onClearFilters={handleOnClearFilters}
      />

      <section ref={contentRef} css={commonStyles}>
        {loading && <LoadingPlaceholders loaderType={LoaderType.FULL} />}
        {!loading && noItems && (
          <NvNoResults
            action={clearSearchAndFilters}
            noResultsTextComponent={(
              <span className='text-xl light' aria-live='assertive'>
                {t.SEARCH.NO_RESULTS_FOUND()}
              </span>
            )}
            noResultsClearTextDataQA={config.pendo.learningCatalog.clearAll}
            clearText={t.SEARCH.CLEAR_ALL()}
          />
        )}
        {!loading && !noItems && (
          <p
            className='total-results text-xl'
            aria-live='assertive'
            data-qa={config.pendo.learningCatalog.totalResults}
          >
            {t.LEARNING_CATALOG.TOTAL_RESULTS(total)}
          </p>
        )}
        {!loading && !noItems && (
          <div css={offeringsContainerStyles}>
            {ids.map((id, index) => {
              const isLastItem = index === ids.length - 1;

              return (
                <OfferingCard
                  key={id}
                  offering={discoveryOfferings[id]}
                  ref={isLastItem ? ref : null}
                  style={{
                    width: '280px',
                  }}
                  openInNewTab
                />
              );
            })}
          </div>
        )}
        {loadingMore && <LoadingPlaceholders loaderType={LoaderType.HALF} />}
        {!hasMore && !noItems && <AllLoaded />}
      </section>
    </section>
  );
};

export default ModalContent;
