import moment from 'moment';
import { setTranslationCategory, getMenteeName } from '../../user_management/controllers/upload-users-csv-controller';

export const SCHEDULE_OPTIONS = {
  SEND_WHEN_ENROLLED: 1,
  RELEASE_DATE: 2,
  START_DATE: 3,
  SCHEDULE_FOR_LATER: 4,
};

export const PARSING_MODES = {
  COURSE_USERS: 1,
  MENTEES_SINGLE_MENTOR: 2,
  MENTEES_MULTI_MENTOR: 3,
  BULK_UNENROLL: 4,
};

export default {
  bindings: {
    class: '@?',
    displayAvatar: '<?',
    onUserUploaded: '&?',
    onUserUnenrolled: '&?',
    loadMentees: '&?',
    dismissModal: '<?',
    parsingMode: '<',
    mentor: '<',
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/nv-csv-upload-users.html',
  controller: function ctrl(
    _,
    $scope,
    $translate,
    $timeout,
    CurrentCourseManager,
    CourseRolesManager,
    AlertMessages,
    CurrentPermissionsManager,
    InstitutionsManager,
    Papa,
    validationConstants,
    nvUtil,
  ) {
'ngInject';
    const vm = this;

    vm.emailRegex = new RegExp(validationConstants.EMAIL_REGEX);
    vm.nameRegex = new RegExp(validationConstants.NAME_REGEX);
    /** @type { Array<{ row: number, msg: string }> } */
    vm.validationErrors = [];
    vm.backendErrors = [];

    vm.backendSuccessKey = '';
    vm.backendSuccessValues = {};
    vm.backendErrorKey = '';
    vm.backendErrorValues = {};

    vm.allowsSsoWithoutEmail = InstitutionsManager.institution.allowsSsoWithoutEmail;

    vm.SSO_LOGIN_MODES = {
      NOT_SSO_LOGIN: 0,
      IS_SSO_LOGIN: 1,
    };

    vm.templateHeaders = {};

    vm.PARSING_MODES = PARSING_MODES;
    vm.SCHEDULE_OPTIONS = SCHEDULE_OPTIONS;

    // Course Users
    vm.templateHeaders[vm.PARSING_MODES.COURSE_USERS] = {};
    vm.templateHeaders[vm.PARSING_MODES.COURSE_USERS][vm.SSO_LOGIN_MODES.NOT_SSO_LOGIN] = [
      ['First Name', 'firstName'],
      ['Last Name', 'lastName'],
      ['Email', 'email'],
    ];
    vm.templateHeaders[vm.PARSING_MODES.COURSE_USERS][vm.SSO_LOGIN_MODES.IS_SSO_LOGIN] = [
      ['First Name', 'firstName'],
      ['Last Name', 'lastName'],
      ['Email', 'email'],
      [`${vm.allowsSsoWithoutEmail ? 'External ID' : 'External ID (Optional)'}`, 'externalId'],
    ];

    // Single mentor
    vm.templateHeaders[vm.PARSING_MODES.MENTEES_SINGLE_MENTOR] = {};
    vm.templateHeaders[vm.PARSING_MODES.MENTEES_SINGLE_MENTOR][vm.SSO_LOGIN_MODES.NOT_SSO_LOGIN] = [
      ['Learner Email', 'learnerEmail'],
    ];
    vm.templateHeaders[vm.PARSING_MODES.MENTEES_SINGLE_MENTOR][vm.SSO_LOGIN_MODES.IS_SSO_LOGIN] = [
      ['Learner Email', 'learnerEmail'],
      ['External ID (Optional)', 'learnerExternalId'],
    ];

    // Multi mentor
    vm.templateHeaders[vm.PARSING_MODES.MENTEES_MULTI_MENTOR] = {};
    vm.templateHeaders[vm.PARSING_MODES.MENTEES_MULTI_MENTOR][vm.SSO_LOGIN_MODES.NOT_SSO_LOGIN] = [
      ['Mentor Email', 'mentorEmail'],
      ['Learner Email', 'learnerEmail'],
    ];
    vm.templateHeaders[vm.PARSING_MODES.MENTEES_MULTI_MENTOR][vm.SSO_LOGIN_MODES.IS_SSO_LOGIN] = [
      ['Mentor Email', 'mentorEmail'],
      ['Mentor External ID (Optional)', 'mentorExternalId'],
      ['Learner Email', 'learnerEmail'],
      ['Learner External ID (Optional)', 'learnerExternalId'],
    ];

    // Bulk unenroll
    vm.templateHeaders[vm.PARSING_MODES.BULK_UNENROLL] = {};
    vm.templateHeaders[vm.PARSING_MODES.BULK_UNENROLL][vm.SSO_LOGIN_MODES.NOT_SSO_LOGIN] = [
      ['Email', 'email'],
    ];
    vm.templateHeaders[vm.PARSING_MODES.BULK_UNENROLL][vm.SSO_LOGIN_MODES.IS_SSO_LOGIN] = [
      ['Email', 'email'],
      ['External ID (optional - Use when SSO with External ID configured only)', 'externalId'],
    ];

    vm.ssoLoginMode = CurrentCourseManager.course.institution.ssoLogin ? vm.SSO_LOGIN_MODES.IS_SSO_LOGIN : vm.SSO_LOGIN_MODES.NOT_SSO_LOGIN;

    // Whether sending data to the backend errored
    vm.submitFailed = false;
    vm.submitting = false;

    // The mentors users are being assigned to. Not used by PARSING_MODES.COURSE_USERS
    // This holds a single User Model object when in single mentor assignment mode, and a list of
    // mentor emails when in multi mentor assignment mode (since we don't have user models for those
    // mentors).
    vm.mentors = [];
    /**
      * @type {
      *  number: {
      *    email: string
      *    externalId: string
      *  }
      * }
    */
    vm.mentees = {};
    vm.replaceMentees = false;

    // Init the mentors and mentees list for single mentor assignment mode.
    if (vm.mentor) {
      vm.mentors.push(vm.mentor);
      vm.mentees[vm.mentor.email] = [];
    }

    vm.VIEWS = {
      UPLOAD_CSV: 0,
      FRONTEND_VALIDATION_FAILED: 1,
      CONFIRM: 2,
      BACKEND_VALIDATION_FAILED: 3,
      PARSING_CSV: 4,
    };

    vm.currentView = vm.VIEWS.UPLOAD_CSV;

    vm.users = [];
    vm.selectedRoleId = CourseRolesManager.noRolesRole.id;
    vm.uploadFailed = false;
    vm.noUsersInCSV = false;

    vm.learnersOnly = !CurrentPermissionsManager.isConfigAndRegistrationRole() && (CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isLearnerRegistrationRole());

    vm.hideIgnoreAndContinueButton = function () {
      const noUsers = !vm.users.length;
      const noMentees = !_.some(_.keys(vm.mentees), (mentor) => vm.mentees[mentor].length);
      return noUsers && noMentees;
    };

    vm.onRoleChanged = (role) => {
      vm.selectedRoleId = role.id === CourseRolesManager.noRolesRole.id ? null : role.id;
    };

    vm.CurrentCourseManager = CurrentCourseManager;
    vm.CourseRolesManager = CourseRolesManager;

    vm.uploadAll = function () {
      vm.submitFailed = false;
      vm.submitting = true;
      vm.getSubmitMethod()().then((response) => {
        vm.submitting = false;
        // If threshold is a property on the result object, then either 50+ users have been added or removed
        if (response.result?.threshold) {
          let suffix;
          switch (vm.parsingMode) {
            case vm.PARSING_MODES.COURSE_USERS:
              suffix = 'USERS_BEING_ADDED';
              break;
            case vm.PARSING_MODES.BULK_UNENROLL:
              suffix = 'USERS_BEING_UNENROLLED';
              break;
            default:
              suffix = 'USERS_BEING_ASSIGNED';
              break;
          }

          const key = `USER_MANAGEMENT.CSV_UPLOAD_MODAL.${suffix}`;
          AlertMessages.success('FORM.SUCCESS_BANG', key);

          vm.dismissModal();
          return;
        }

        const { result } = response;

        if (result.errors.length) {
          vm.currentView = vm.VIEWS.BACKEND_VALIDATION_FAILED;
          vm.processBackendErrors(result);
        }

        if (vm.parsingMode === vm.PARSING_MODES.COURSE_USERS) {
          vm.getUserAddCompleteMessage(vm.users.length, result.existing, result.newlyEnrolled, result.rolesChanged);
        } else if (vm.parsingMode === vm.PARSING_MODES.BULK_UNENROLL) {
          vm.getUsersUnenrolledMessage(vm.users.length, result.unenrolledUsers, result.existingUnenrolledUsers);
        } else {
          vm.getMenteesAssignedMessage(vm.mentors, vm.mentees, result);
        }

        // Show success/warning message versions of the summaries if no errors occurred, since we
        // won't be viewing the backend validation modal
        if (!vm.backendErrors.length) {
          // Show a warning if there are existing un-enrolled users
          if (vm.parsingMode === vm.PARSING_MODES.BULK_UNENROLL
              && vm.users.length !== result.unenrolledUsers.length) {
            AlertMessages.warning('', vm.backendSuccessKey, {}, vm.backendSuccessValues);

          // Show a warning if no users were added/assigned
          } else if (vm.parsingMode !== vm.PARSING_MODES.BULK_UNENROLL
              && !(result.newlyEnrolled?.length)
              && !(result.created?.length)) {
            AlertMessages.warning('', vm.backendSuccessKey, {}, vm.backendSuccessValues);

          // Show success
          } else {
            AlertMessages.success('FORM.SUCCESS_BANG', vm.backendSuccessKey, {}, vm.backendSuccessValues);

            if (vm.parsingMode === vm.PARSING_MODES.BULK_UNENROLL) {
              vm.onUserUnenrolled();
            }
          }

          if (result.newlyEnrolled && result.newlyEnrolled.length) {
            vm.onUserUploaded({
              newlyEnrolled: result.newlyEnrolled,
            });
          }
        } else if (vm.loadMentees) {
          vm.loadMentees();
        }
      }).catch((reason) => {
        vm.submitFailed = true;
        vm.submitting = false;
      });
    };

    vm.getSubmitMethod = function () {
      switch (vm.parsingMode) {
        case vm.PARSING_MODES.COURSE_USERS:
          return vm.enrollUsers;
        case vm.PARSING_MODES.MENTEES_MULTI_MENTOR:
        case vm.PARSING_MODES.MENTEES_SINGLE_MENTOR:
          return vm.assignMentees;
        case vm.PARSING_MODES.BULK_UNENROLL:
          return vm.bulkUnenroll;
        default:
          return null;
      }
    };

    vm.assignMentees = function () {
      const mentorsToMentees = vm.mentors.map((currentMentor) => ({
        email: currentMentor.email,
        externalId: currentMentor.mentorExternalId,
        mentees: vm.mentees[currentMentor.email].map((mentee) => ({
          email: mentee.learnerEmail,
          externalId: mentee.learnerExternalId,
        })),
      }));

      return CurrentCourseManager.course.assignMentees(mentorsToMentees, vm.replaceMentees);
    };

    vm.enrollUsers = function () {
      return CurrentCourseManager.course.enrollWithRole(vm.users, vm.selectedRoleId, vm.selectedSchedule.date, true);
    };

    vm.bulkUnenroll = function () {
      return CurrentCourseManager.course.bulkUnenroll(vm.users);
    };

    vm.getUserAddCompleteMessage = function (usersCount, existing, newlyEnrolled, rolesChanged) {
      // See https://projects.invisionapp.com/d/#/console/15561396/351513859/preview for different popup versions
      const translationValues = _.extend(CurrentCourseManager.course.getAliases(), {
        count: usersCount, // Total # of users in the upload
        addedCount: newlyEnrolled.length, // # of new users added to the course
        rolesChangedCount: rolesChanged.length, // # of users already in the course with roles changed.
        existingCount: existing.length, // # of users already in the course that did not have their roles changed
      });

      // Show an alternative success message if many users were added, which signals
      // that they're being added in a background queue
      if (!newlyEnrolled.length && !rolesChanged.length) {
        // No users added
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_EXIST';
      } else if (newlyEnrolled.length && newlyEnrolled.length === usersCount) {
        // Users added, no users already existed in course
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED';
      } else if (newlyEnrolled.length && !rolesChanged.length) {
        // Users added, no role changes, some users already in course
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED_SOME_EXIST';
      } else if (newlyEnrolled.length && rolesChanged.length && translationValues.existingCount) {
        // Users added, some role changes, some users already in course
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED_EXIST_ROLE_CHANGES';
      } else if (newlyEnrolled.length && rolesChanged.length && !translationValues.existingCount) {
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED_ROLE_CHANGES';
      } else if (rolesChanged.length && translationValues.existingCount) {
        // Some role changes, some users already existed
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ROLES_CHANGED_SOME_EXIST';
      } else if (rolesChanged.length) {
        // Some role changes, all users already in course
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ROLES_CHANGED';
      } else {
        // Fallback case, shouldn't happen in practice
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.ADDED_SUCCESS';
      }

      vm.backendSuccessValues = translationValues;
    };

    vm.getUsersUnenrolledMessage = function (usersCount, unenrolled, existing) {
      const translationValues = _.extend(CurrentCourseManager.course.getAliases(), {
        count: usersCount, // Total # of users in the upload
        unenrolledCount: unenrolled.length, // # of users that are unenroll after the process
        existingCount: existing.length, // # of users that are already unenroll, no change to the existing enrollment
      });

      if (translationValues.existingCount !== 0) {
        // Some users have already been removed
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.SOME_ALREADY_REMOVED';
      } else if (translationValues.unenrolledCount > 0) {
        // Some or all users removed
        vm.backendSuccessKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_REMOVED';
      }

      vm.backendSuccessValues = translationValues;
    };

    vm.getMenteesAssignedMessage = function (mentors, mentees, result) {
      const assignedCount = result.created ? result.created.length : 0;
      const existingCount = result.existing ? result.existing.length : 0;

      if (!assignedCount && !existingCount) {
        return;
      }

      const prefix = vm.parsingMode === vm.PARSING_MODES.MENTEES_SINGLE_MENTOR
        ? 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.SINGLE_MENTOR' : 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.MULTI_MENTOR';

      if (assignedCount && !existingCount) {
        vm.backendSuccessKey = `${prefix}.MENTEES_ASSIGNED`;
      } else if (assignedCount && existingCount) {
        vm.backendSuccessKey = `${prefix}.MENTEES_ASSIGNED_ALREADY_ASSIGNED`;
      } else if (!assignedCount && existingCount) {
        vm.backendSuccessKey = `${prefix}.MENTEES_EXISTED`;
      }

      let mentorName = null;

      if (vm.parsingMode === vm.PARSING_MODES.MENTEES_SINGLE_MENTOR) {
        mentorName = `${mentors[0].firstName} ${mentors[0].lastName}`;
      }

      vm.backendSuccessValues = {
        assignedCount,
        existingCount,
        mentorName,
      };
    };

    vm.chooseCsv = function (files) {
      vm.noUsersInCSV = false;

      if (!files.length) {
        return;
      }

      vm.currentView = vm.VIEWS.PARSING_CSV;

      vm.csvHeaders = [];

      Papa.parse(files[0], {
        delimiter: ',',
        header: true,
        complete: vm.onParsingComplete.bind(vm),
        skipEmptyLines: true,
        transformHeader: vm.transformHeader.bind(vm),
      });
    };

    vm.transformHeader = function (header) {
      vm.currentHeaderIndex += 1;
      vm.csvHeaders.push(header);
      return header;
    };

    vm.onParsingComplete = function (results, file) {
      $timeout(() => {
        // Adds the mentee to a mapping of mentor emails to mentee info
        const addMentee = mentee => {
          // The email address of the mentor for this mentee
          let mentorKey = null;
          if (vm.parsingMode === vm.PARSING_MODES.MENTEES_SINGLE_MENTOR) {
            vm.mentees[vm.mentor.email].push(mentee);
          } else if (vm.parsingMode === vm.PARSING_MODES.MENTEES_MULTI_MENTOR) {
            mentorKey = mentee.mentorEmail
              ? mentee.mentorEmail
              : mentee.mentorExternalId;
            if (!Object.prototype.hasOwnProperty.call(vm.mentees, mentorKey)) {
              vm.mentees[mentorKey] = [];
              vm.mentors.push({
                email: mentorKey,
              });
            }

            vm.mentees[mentorKey].push(mentee);
          }
        };

        results.data.forEach((dataRow, rowNum) => {
          vm.csvHeaders.forEach((header, i) => {
            // Get the property name for the value in this column. Note that we don't validate the column header matches what was specified in
            // the CSV template - we pull data out by position instead to give CSV files some wiggle room on what exact text they use in the header.
            const column = vm.templateHeaders[vm.parsingMode][vm.ssoLoginMode][i];

            const expectedHeadersLength = vm.templateHeaders[vm.parsingMode][vm.ssoLoginMode].length;
            if (vm.csvHeaders.length !== expectedHeadersLength) {
              if (!results.errors) {
                results.errors = [];
              }

              if (vm.csvHeaders.length < expectedHeadersLength) {
                results.errors.push({
                  row: rowNum,
                  message: $translate.instant(
                    'USER_MANAGEMENT.CSV_UPLOAD_MODAL.ERRORS.TOO_FEW_COLUMNS',
                  ),
                });
              } else {
                results.errors.push({
                  row: rowNum,
                  message: $translate.instant(
                    'USER_MANAGEMENT.CSV_UPLOAD_MODAL.ERRORS.TOO_MANY_COLUMNS',
                  ),
                });
              }

              return;
            }

            // Set the value for this column on the camelCase property name, and delete the non camel cased prop.
            // Failing to delete the non camel cased prop will server errors when we pass the users or mentors to API
            const [, prop] = column;
            dataRow[prop] = dataRow[header];
            delete dataRow[header];
          });
        });

        // Send to the frontend validaiton failed screen if serious issues occurred that won't let us add even a subset of the CSV data
        if (results.errors.length) {
          vm.currentView = vm.VIEWS.FRONTEND_VALIDATION_FAILED;
          vm.processFrontendParseErrors(results, file);
          return;
        }

        // Build the data models of users or mentees that will be used when submitting the CSV data
        results.data.forEach((result, i) => {
          if (vm.validateRow(result, i)) {
            switch (vm.parsingMode) {
              case vm.PARSING_MODES.COURSE_USERS:
              case vm.PARSING_MODES.BULK_UNENROLL:
                vm.users.push(result);
                break;
              case vm.PARSING_MODES.MENTEES_SINGLE_MENTOR:
              case vm.PARSING_MODES.MENTEES_MULTI_MENTOR:
                addMentee(result);
                break;
              default:
                break;
            }
          }
        });

        if (vm.validationErrors.length) {
          vm.currentView = vm.VIEWS.FRONTEND_VALIDATION_FAILED;
        } else {
          vm.currentView = vm.VIEWS.CONFIRM;
        }

        // Show a special 'no users uploaded' message if we can't find any validation errors
        // but still have not users or mentees parsed.
        if (
          !vm.validationErrors.length
          && !vm.users.length
          && !_.flatten(_.values(vm.mentees)).length
        ) {
          vm.showNoUsersInCSVError();
        }
      });
    };

    vm.ignoreAndContinue = function () {
      vm.currentView = vm.VIEWS.CONFIRM;
    };

    /**
       * Tests a data row from the parsed CSV against regexes to ensure they're in the
       * correct format. Appends errors to vm.validationErrors to be used on the frontend
       * validation failure view.
       * @param {*} data
       * @param {number} i the row index (1-based)
       */
    vm.validateRow = function (data, i) {
      let isValid = true;

      // Pushes an error for this row to the error list
      const pushError = (msg) => {
        isValid = false;
        // Adjust the row index by 2, 1 for the header and 1 for non zero-based indexing
        vm.validationErrors.push({ row: (i + 2), msg });
      };

      // Wraps pushError to print a different message if the email is incorrect while there's no valid
      // external ID given
      const pushEmailOrIDError = (externalId, email, emailErr) => {
        if (!externalId && !email) {
          if (vm.allowsSsoWithoutEmail) {
            pushError($translate.instant('USER_MANAGEMENT.CSV_UPLOAD_MODAL.ERRORS.REQUIRED_FIELDS_MISSING'));
          } else {
            pushError($translate.instant('VALIDATION.NEED_EMAIL_OR_EXTERNAL_ID'));
          }
        } else {
          pushError(emailErr);
        }
      };

      // Check values for validity, saving errors as appropriate
      switch (vm.parsingMode) {
        case vm.PARSING_MODES.COURSE_USERS:
          if (!data.firstName || !vm.nameRegex.test(data.firstName)) {
            pushError($translate.instant('VALIDATION.FIELD_LENGTH', {
              // TODO: Do we need to translate these (and the CSV headers)? We never translated these in previous iterations of this flow
              fieldName: 'First Name',
              minLength: 1,
              maxLength: 50,
            }));
          }

          if (!data.lastName || !vm.nameRegex.test(data.lastName)) {
            pushError($translate.instant('VALIDATION.FIELD_LENGTH', {
              fieldName: 'Last Name',
              minLength: 1,
              maxLength: 50,
            }));
          }

          // Only validate email if no external ID is present. External IDs are not validated and can be in any format
          if (!data.externalId && !vm.emailRegex.test(data.email)) {
            pushEmailOrIDError(data.externalId, data.email, $translate.instant('VALIDATION.EMAIL'));
          }
          break;
        case vm.PARSING_MODES.MENTEES_SINGLE_MENTOR:
        case vm.PARSING_MODES.MENTEES_MULTI_MENTOR:
          if (!data.learnerExternalId && !vm.emailRegex.test(data.learnerEmail)) {
            pushEmailOrIDError(data.learnerExternalId, data.learnerEmail, $translate.instant('VALIDATION.FIELD_EMAIL_INVALID', {
              fieldName: 'Learner Email',
            }));
          }

          if (vm.parsingMode === vm.PARSING_MODES.MENTEES_MULTI_MENTOR && !data.mentorExternalId && !vm.emailRegex.test(data.mentorEmail)) {
            pushEmailOrIDError(data.mentorExternalId, data.mentorEmail, $translate.instant('VALIDATION.FIELD_EMAIL_INVALID', {
              fieldName: 'Mentor Email',
            }));
          }
          break;
        case vm.PARSING_MODES.BULK_UNENROLL:
          // Only validate email if no external ID is present. External IDs are not validated and can be in any format
          if (!data.externalId && !vm.emailRegex.test(data.email)) {
            pushEmailOrIDError(data.externalId, data.email, $translate.instant('VALIDATION.EMAIL'));
          }
          break;
        default: break;
      }

      return isValid;
    };

    vm.processFrontendParseErrors = function (results, file) {
      results.errors.forEach((error) => {
        if (error.code && error.code === 'UndetectableDelimiter') {
          vm.showNoUsersInCSVError();
          return;
        }

        vm.validationErrors.push({
          row: error.row + 2, // Add two to account for the header row & 1-based indexing
          msg: error.message,
        });
      });
    };

    vm.processBackendErrors = function (result) {
      const errorStrForCode = (code) =>
        // TODO: Accessing these keys from the config became awkward b/c the prop names are different from the vals, but we get the
        // vals back from APi. So we'd need to reverse lookup the props from the given value... to no real purpose sine we already have the val.
        // Needs redesigned.
        // let errorKey = config.errorCodes.userManagement[humps.camelize(code)];
        // eslint-disable-next-line implicit-arrow-linebreak
        `USER_MANAGEMENT.CSV_UPLOAD_MODAL.ERRORS.${code}`.toUpperCase();

      // The error object is a list where each item has email address & external id info for a user's data that couldn't be assigned/added. It
      // also has an error code describing the kind of error that occurred. But when assignming mentees this error code property is replaced by
      // a list of inner nested errors that have mentee information and the error code. this logic pulls out all of those nested errors into a single
      // flattened array.
      let flattenedErrors = [];
      _.forEach(result.errors, (error) => {
        if (error.length) {
          flattenedErrors = flattenedErrors.concat(error);
        } else {
          flattenedErrors.push(error);
        }
      });

      _.forEach(flattenedErrors, (error) => {
        // eslint-disable-next-line no-shadow
        let mentor = '';
        if (error.mentorExternalId) {
          mentor = error.mentorExternalId;
        }

        if (error.mentorEmail) {
          mentor = error.mentorEmail;
        }

        vm.backendErrors.push({
          email: error.email,
          externalId: error.externalId,
          msg: $translate.instant(errorStrForCode(error.error), {
            courseAlias: CurrentCourseManager.course.getAliases().courseAlias,
            mentor,
          }),
        });
      });

      const failedUsersCount = vm.backendErrors.length; // TODO: this is wrong, revise

      switch (vm.parsingMode) {
        case vm.PARSING_MODES.COURSE_USERS:
          vm.backendErrorKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.CANNOT_BE_ENROLLED';
          vm.backendErrorValues = {
            count: failedUsersCount,
          };
          break;
        case vm.PARSING_MODES.MENTEES_SINGLE_MENTOR:
        case vm.PARSING_MODES.MENTEES_MULTI_MENTOR:
          vm.backendErrorKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.CANNOT_BE_ASSIGNED';
          vm.backendErrorValues = {
            count: failedUsersCount,
          };
          break;
        case vm.PARSING_MODES.BULK_UNENROLL:
          vm.backendErrorKey = 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.CANNOT_BE_UNENROLLED';
          vm.backendErrorValues = {
            count: failedUsersCount,
          };
          break;
        default:
          break;
      }
    };

    vm.getMentorName = function () {
      if (vm.parsingMode === vm.PARSING_MODES.MENTEES_SINGLE_MENTOR && vm.mentors.length === 1) {
        return `${vm.mentors[0].firstName} ${vm.mentors[0].lastName}`;
      }

      return null;
    };

    vm.getMenteeName = function () {
      return getMenteeName(vm.parsingMode, vm.PARSING_MODES, vm.mentors);
    };

    /** Generates the CSV template file based on the values in vm.templateHeaders */
    vm.downloadTemplate = function () {
      const headers = vm.templateHeaders[vm.parsingMode][vm.ssoLoginMode].map((header) => header[0]);
      const fields = vm.templateHeaders[vm.parsingMode][vm.ssoLoginMode].map((header) => header[1]);
      const data = [headers];

      const dummyData = {
        firstNames: ['Jane', 'John'],
        lastNames: ['Doe', 'Doe'],
        emails: ['jane.doe@email.com', 'john.doe@email.com'],
        learnerEmails: ['learner1@email.com', 'learner2@email.com'],
        mentorEmails: ['mentor1@email.com', 'mentor2@email.com'],
        // Duplicate values for external id, if a change is needed in future
        externalIds: ['ID_ASSIGNED_BY_CORPORATE_IDENTITY_PROVIDER', 'ID_ASSIGNED_BY_CORPORATE_IDENTITY_PROVIDER'],
      };

      _.forEach([0, 1], (index) => {
        const row = [];
        _.forEach(fields, (field) => {
          switch (field) {
            case 'firstName':
              row.push(dummyData.firstNames[index]);
              break;
            case 'lastName':
              row.push(dummyData.lastNames[index]);
              break;
            case 'email':
              row.push(dummyData.emails[index]);
              break;
            case 'learnerEmail':
              row.push(dummyData.learnerEmails[index]);
              break;
            case 'mentorEmail':
              row.push(dummyData.mentorEmails[index]);
              break;
            case 'externalId':
            case 'mentorExternalId':
            case 'learnerExternalId':
              row.push(dummyData.externalIds[index]);
              break;
            default:
              break;
          }
        });
        data.push(row);
      });

      nvUtil.downloadCSV(data);
    };

    /** Resets the CSV upload flow to the first page */
    vm.uploadNewCSV = function () {
      vm.currentView = vm.VIEWS.UPLOAD_CSV;
      vm.users = [];
      vm.mentees = [];
      vm.submitFailed = false;
      vm.backendSuccessKey = '';
      vm.backendErrorKey = '';
      vm.submitting = false;

      if (vm.parsingMode !== vm.PARSING_MODES.MENTEES_SINGLE_MENTOR) {
        vm.mentors = [];
      } else if (vm.parsingMode === vm.PARSING_MODES.MENTEES_SINGLE_MENTOR) {
        vm.mentees[vm.mentor.email] = [];
      }


      vm.validationErrors = [];
    };

    vm.showNoUsersInCSVError = function () {
      vm.uploadNewCSV();
      vm.noUsersInCSV = true;
    };

    vm.okClicked = function () {
      vm.onUserUploaded({ newlyEnrolled: [] });
      vm.dismissModal();
    };

    vm.translationCategory = setTranslationCategory(vm.parsingMode, vm.PARSING_MODES);

    vm.scheduleOptions = [
      { id: vm.SCHEDULE_OPTIONS.SEND_WHEN_ENROLLED, name: 'SEND_WHEN_ENROLLED', date: null },
      { id: vm.SCHEDULE_OPTIONS.RELEASE_DATE, name: 'RELEASE_DATE', key: 'releaseDate', date: vm.CurrentCourseManager.course.releaseDate },
      { id: vm.SCHEDULE_OPTIONS.START_DATE, name: 'START_DATE', key: 'officialReleaseDate', date: vm.CurrentCourseManager.course.officialReleaseDate },
      { id: vm.SCHEDULE_OPTIONS.SCHEDULE_FOR_LATER, name: 'SCHEDULE_FOR_LATER', date: moment().toISOString() },
    ];

    vm.selectedSchedule = vm.scheduleOptions[0];

    vm.scheduleSelected = function (id) {
      vm.selectedSchedule = _.find(vm.scheduleOptions, (option) => (option.id === id));
    };

    vm.isValidDate = function (date) {
      if (typeof date === 'boolean') return date;
      const days = moment().diff(date, 'days');
      return date && days <= 0;
    };

    vm.formatDate = function (date) {
      return moment(date).format('LLL');
    };

    vm.shouldShowScheduling = function () {
      return vm.CurrentCourseManager?.course?.welcomeEmailEnabled && vm.parsingMode === vm.PARSING_MODES.COURSE_USERS;
    };

    return vm;
  },
};
