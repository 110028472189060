import { css } from '@emotion/react';
import React, { useContext, useState, useEffect, ReactElement } from 'react';
import t from 'react-translate';
import { connect, useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { RolesService } from 'institutions/services/roles-service';
import { primary, success, yellow, warning, gray1, gray4 } from 'styles/global_defaults/colors';

import { User, UserRoles } from 'redux/schemas/models/my-account';
import {
  semiBoldFontWeight,
  lightFontWeight,
  boldFontWeight,
  textSmallFontSize,
  textXSFontSize,
  textMediumFontSize,
} from 'styles/global_defaults/fonts';
import { quarterSpacing, halfSpacing, standardSpacing, extraLargeSpacing } from 'styles/global_defaults/scaffolding';
import NvTooltip from 'shared/components/nv-tooltip';

import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { NvAvatar, NvAvatarProps, NvAvatarDefaultBkg, sizes } from './nv-avatar';
import ClickableContainer from './clickable-container';

export enum RoleBadgePlacement {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export type NvUserAvatarProps = {
  user: User;
  notifications?: number | boolean;
  messages?: number;
  inlineName?: boolean;
  directToProfile?: boolean;
  directToOrgLevelProfile?: boolean;
  alignNameRight?: boolean;
  displayName?: boolean;
  className?: string;
  displayRoleBadge?: boolean;
  roleBadgePlacement?: RoleBadgePlacement;
  pendoTagName?: string;
  tooltipText?: string;
  tooltipPlacement?: Placement;
  /** The user profle will open in the new tab if 'openInNewTab' is present with
   * 'directToOrgLevelProfile' or 'directToProfile' */
  openInNewTab?: boolean;
  tooltipEnabled?: boolean;
  dataQa?: string;
} & Pick<NvAvatarProps, 'size' | 'borderType'>;

type AnimationProps = {
  animationDisplay?: ReactElement;
  animate?: boolean | number;
};

export const NvUserAvatar = (props: NvUserAvatarProps & AnimationProps) => {
  const isExtraSmall = props.size === 'xs';
  const isSmall = props.size === 'sm';
  const isMedium = props.size === 'md';
  const IsSmallOrMedium = isSmall || isMedium;
  const isMediumOrSmaller = sizes[props.size] < 60;
  const animatedCircleSize = sizes[props.size] + halfSpacing;
  const [isAnimating, setIsAnimating] = useState(false);

  const { $state } = useContext(AngularServicesContext) || {};

  const [animationClassName, setClassName] = useState('animated-circle');

  // find the enrollment for `props.user` for the current course
  const enrollmentForCurrentUser = useSelector((state) => Object.values(state.models.enrollments).find((enrollment: any) => enrollment.userId === props.user.id && enrollment.courseId === state.app.currentCourseId));

  // we implemented this approach after a bug in the polls project: https://novoed.atlassian.net/browse/NOV-67376
  // the user.roles of the current user was being overwritten by the GET_MY_ACCOUNT redux action with a wrong value
  // in which case we are still able to get right value with this approach.
  const userRoles = props.user.roles || enrollmentForCurrentUser;
  const courseRole = useSelector((state) => state.models.roles[userRoles?.courseRoleId]);

  const catalogId = useSelector((state) => state.app.currentCatalogId);

  const addAnimation = () => {
    setClassName(c => `${c} add-animation`);
    setTimeout(() => {
      setClassName('animated-circle');
      setIsAnimating(false);
    }, 1500);
  };

  useEffect(() => {
    if (props.animate) {
      addAnimation();
      setIsAnimating(true);
    }
  }, [props.animate]);

  const getBadgeClass = (role: UserRoles) => {
    if (!role) {
      return '';
    } if (RolesService.isAdminRole(role)) {
      return 'admin';
    } if (RolesService.isMentor(role)) {
      return 'mentor';
    }
    return 'learner-role';
  };

  const getRolesBadge = (role: UserRoles) => {
    if (courseRole) {
      return role.roles.badge;
    }

    if (RolesService.isMentor(role)) {
      return 'M';
    }

    return null;
  };

  const getRolesName = (role: UserRoles) => {
    if (courseRole) {
      return role.roles.name?.capitalizedSingularized;
    }

    if (RolesService.isMentor(role)) {
      return t.DISCUSSIONS.ROLES.MENTOR();
    }

    return null;
  };

  const getInitials = () => {
    if (!props.user) {
      return '';
    }

    return props.user.initials ?? (props.user?.firstName[0]?.toUpperCase() ?? '') + (props.user?.lastName[0]?.toUpperCase() ?? '');
  };

  const roleName = getRolesName(userRoles);
  const roleBadge = props.displayRoleBadge && getRolesBadge(userRoles);

  const getTopOffset = () => {
    switch (props.size) {
      case 'xs':
        return 2;
      case 'sm':
        return 0;
      case 'md':
        return -2;
      default:
        return 0;
    }
  };

  const getRightOffSet = () => {
    switch (props.size) {
      case 'xs':
        return 2;
      case 'sm':
        return 0;
      case 'md':
        return -2;
      default:
        return 0;
    }
  };

  const getLeftOffset = () => {
    switch (props.size) {
      case 'sm':
        return -5;
      case 'md':
      default:
        return 0;
    }
  };

  const getBottomOffset = () => {
    if (props.roleBadgePlacement) {
      switch (props.roleBadgePlacement) {
        case RoleBadgePlacement.TOP: return 10;
        case RoleBadgePlacement.CENTER: return 5;
        default: return 0;
      }
    } else {
      switch (props.size) {
        case 'sm':
        case 'md':
        default:
          return 5;
      }
    }
  };

  const styles = css`
    display: block;

    .profile-wrapper {
      display: flex;
      justify-content: center;

      .avatar-container {
        position: relative;

        a {
          display: block;
        }
      }

      .avatar-wrapper {
        position: relative;
      }

      .notifications-badge {
        z-index: 1;
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        top: ${getTopOffset()}px;
        right: ${getRightOffSet()}px;
        color: white;
        background-color: ${warning};
        border-radius: 50%;
        font-size: ${textSmallFontSize}px;
        font-weight: ${boldFontWeight};
        width: ${isMedium ? standardSpacing : halfSpacing}px;
        height: ${isMedium ? standardSpacing : halfSpacing}px;
        line-height: ${isMedium ? standardSpacing : halfSpacing}px;
      }

      .roles-badge {
        z-index: 1;
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: ${getBottomOffset()}px;
        left: ${getLeftOffset()}px;
        color: white;
        background-color: ${warning};
        width: 21px;
        height: 21px;
        border-radius: 50%;
        font-size: ${textSmallFontSize}px;
        line-height: 21px;
        font-weight: ${boldFontWeight};
        left: ${isMedium ? -quarterSpacing : -halfSpacing}px;
        font-family: 'Courier New', Courier, monospace;

        &.admin {
          background-color: ${primary};
        }

        &.learner-role {
          background-color: ${success};
        }

        &.mentor {
          background-color: ${yellow};
        }
      }

      .congratulations {
        position: absolute;
        color: ${warning};
        font-weight: ${boldFontWeight};
        z-index: 2;
        opacity: 0;
        left: ${sizes[props.size] / 2}px;
        display: none;

        .icon-like {
          margin-right: ${quarterSpacing}px;
        }
      }

      .animated-circle {
        background-color: transparent;
        border: 0;
        position: relative;
        height: ${animatedCircleSize}px;
        width: ${animatedCircleSize}px;
        border-radius: 100%;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before,
        &::after {
          top: 0;
          left: 0;
          box-sizing: inherit;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }

        &::before {
          border: 2px solid transparent;
        }

        &::after {
          border: 0 solid transparent;
        }

        &.add-animation {
          &::before {
            border-top-color: ${warning};
            border-right-color: ${warning};
            border-bottom-color: ${warning};
            transition: border-top-color 0.15s linear,  border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s;
          }

          &::after {
            border-top: 2px solid ${warning};
            border-right-width: 2px;
            border-left-width: 2px;
            transform: rotate(270deg);
            transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
          }

          .congratulations {
            animation-name: fadeInOut;
            animation-duration: 1s;
            animation-timing-function: cubic-bezier(.3,1.01,.7,-0.01);
            animation-direction: normal;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            display: block;

            @keyframes fadeInOut {
              0% {
                opacity: 0;
              }

              50% {
                opacity: 1;
              }

              100% {
                opacity: 0;
                transform: translateY(-80px);
                display: none;
              }
            }
          }
        }
      }
      &.with-badge {
        min-width: ${extraLargeSpacing}px;
      }
    }

    .user-initials {
      height: ${sizes[props.size]}px                                                           ;
      width: ${sizes[props.size]}px;
      font-size: ${0.5 * sizes[props.size]}px;
      background-color: ${gray4};
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .messages-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        margin-right: ${quarterSpacing}px;
      }
    }

    .name-container {
      margin-top: ${quarterSpacing}px;
      font-size: ${isExtraSmall ? textSmallFontSize : textMediumFontSize}px;

      .first-name {
        font-weight: ${semiBoldFontWeight};
        line-height: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: ${props.alignNameRight ? 'left' : 'center'};
      }

      .last-name {
        font-weight: ${lightFontWeight};
        color: ${gray1};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: ${textXSFontSize}px;
        text-align: ${props.alignNameRight ? 'left' : 'center'};
      }
    }

    &.left-aligned {
      display: flex;

      .name-container {
        text-align: left;
        margin-left: ${halfSpacing}px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }
  `;

  const gotoProfile = () => {
    if (props.directToOrgLevelProfile) {
      if (props.openInNewTab) {
        window.open($state?.href('org-level-profile-modal', { userId: props.user.id }), '_blank');
      } else {
        $state?.go('org-level-profile-modal', { userId: props.user.id });
      }
    } else if (props.directToProfile) {
      if (props.openInNewTab) {
        window.open($state?.href('learner-profile-modal', { catalogId, userId: props.user.id }), '_blank');
      } else {
        $state?.go('learner-profile-modal', { catalogId, userId: props.user.id });
      }
    }
  };

  return (
    <div css={styles} className={`nv-user-avatar ${props.className ?? ''} ${props.alignNameRight ? 'left-aligned' : ''}`}>
      <div className={`profile-wrapper ${roleBadge ? 'with-badge' : ''}`}>
        <div className='avatar-container'>
          <ClickableContainer
            onClick={() => gotoProfile()}
            pendo-tag-name={props.pendoTagName}
            aria-label={props.tooltipText || props.user?.fullName}
            data-qa={props.dataQa}
          >
            <NvTooltip
              enabled={props.tooltipEnabled}
              text={props.tooltipText || props.user?.fullName}
              placement={props.tooltipPlacement || 'bottom'}
            >
              <div className={isAnimating ? animationClassName : undefined}>
                <div className='congratulations'>
                  {props.animationDisplay}
                </div>
                <div className='avatar-wrapper'>
                  {props.user.profilePicture ? (
                    <NvAvatar
                      imageUrl={props.user.profilePicture}
                      size={props.size}
                      borderType={props.borderType}
                      defaultBkg={{ type: NvAvatarDefaultBkg.SOLID_COLOR, color: '#fff' }}
                    />
                  ) : (
                    <div className='user-initials'>{getInitials()}</div>
                  )}
                </div>
              </div>
            </NvTooltip>
            {isMediumOrSmaller && props.notifications > 0 && <div className='notifications-badge'>{isMedium && props.notifications}</div>}
            {roleName && roleBadge && IsSmallOrMedium && (
              <NvTooltip
                text={roleName}
                placement='bottom'
              >
                <div className={`roles-badge ${getBadgeClass(userRoles)}`}>{roleBadge}</div>
              </NvTooltip>
            )}
          </ClickableContainer>
        </div>
      </div>
      {props.messages > 0 && (
        <div className='messages-container'>
          <NvIcon size='xss-smallest' icon='comments' />
          <div className='messages'>{props.messages}</div>
        </div>
      )}
      {!props.messages && props.displayName && (
        <div className='name-container'>
          {props.inlineName ? (
            <div className='inline-name'>{props.user.fullName}</div>
          ) : (
            <React.Fragment>
              <div className='first-name'>{props.user.firstName}</div>
              <div className='last-name'>{props.user.lastName}</div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

NvUserAvatar.defaultProps = {
  displayRoleBadge: true,
  tooltipEnabled: true,
};

export default connect(
  (state: RootState) => ({
    roles: state.models.roles,
  }),
)(NvUserAvatar);
