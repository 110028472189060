/* @ngInject */
export default function PointsBreakdownModalController(
  CurrentUserManager,
  TimelinesManager,
  CurrentCourseManager,
  $stateParams,
  $state,
  userId,
  course,
  showOutline,
  selectedTab,
  $q,
  _,
  $scope,
  CourseModel,
) {
  const vm = this;

  // Controller Is loaded from react. Initialize course Modal with the data passed
  if (!course.loadCourseCompletionCriteria) {
    course = new CourseModel(course);
  }
  if (!CurrentCourseManager.course.loadCourseCompletionCriteria) {
    CurrentCourseManager.setCourse(new CourseModel(CurrentCourseManager.course));
  }

  this.manager = TimelinesManager;

  this.manager.selectedTab = selectedTab;

  this.CurrentCourseManager = CurrentCourseManager;
  this.showOutline = showOutline;
  this.course = course;
  this.userId = userId;

  vm.COMPLETIONSTATUS = {
    AUTO: 1,
    MANUAL: 2,
  };

  let getAdminCreditsPromise = null;
  let getCompletionCriteriaPromise = null;

  this.selectTab = (tab) => {
    this.manager.selectedTab = tab;
  };

  const { fullTimelinePromise } = TimelinesManager.getTimeline(
    course.catalogId,
    userId || $stateParams.userId,
    CurrentUserManager.isAdmin(),
    course,
    { performSummaryLoad: false },
    course,
  );
  getAdminCreditsPromise = TimelinesManager.getAdminCredits(course.catalogId, userId || $stateParams.userId);

  if (!course.completionCriteria) {
    getCompletionCriteriaPromise = course.loadCourseCompletionCriteria();
  }

  $q.all([fullTimelinePromise, getAdminCreditsPromise, getCompletionCriteriaPromise]).then(() => {
    vm.completionCriteria = {
      pointsValue: vm.manager.currentTimeline.pointsReceived + vm.manager.totalCreditsReceived,
      assignmentsValue: vm.manager.currentTimeline.requiredAssignmentsCompleted.length,
      todosValue: vm.manager.currentTimeline.requiredTodosCompleted.length,
      pointsMax: course.completionCriteria?.requiredPoints ?? 0,
      assignmentsMax: course.completionCriteria?.requiredAssignmentsCount ?? 0,
      todosMax: course.completionCriteria?.totalRequiredTodosCount ?? 0,
      ...(course.completionCriteria || {}),
    };

    vm.isCompleted = {
      points: vm.completionCriteria.pointsValue >= vm.completionCriteria.pointsMax,
      assignments: vm.completionCriteria.assignmentsValue >= vm.completionCriteria.assignmentsMax,
      todos: vm.completionCriteria.todosValue >= vm.completionCriteria.todosMax,
    };
  });

  $scope.$on('$destroy', () => {
    // When opening points break down modal of a user, TimelineManager gets replaced with
    // time-line of of the user who's points that we are viewing.
    // So on destroy change it back to CurrentUserManager user
    // This is needed since we are displaying course completion criteria of the current user
    // in course header
    if (CurrentCourseManager.course && CurrentUserManager.user.id
      && (TimelinesManager.currentUserId !== CurrentUserManager.user.id
        || TimelinesManager.currentCatalogId !== $stateParams.catalogId)) {
      // Update Course Timeline and admin rewards credited
      TimelinesManager.getTimeline(
        CurrentCourseManager.course.catalogId,
        CurrentUserManager.user.id,
        CurrentUserManager.isAdmin(),
        CurrentCourseManager.course,
        { performSummaryLoad: false },
      );
      if (TimelinesManager.currentCatalogId !== $stateParams.catalogId) {
        getCompletionCriteriaPromise = CurrentCourseManager.course.loadCourseCompletionCriteria();
      }
      TimelinesManager.getAdminCredits(CurrentCourseManager.course.catalogId, CurrentUserManager.user.id);
    }
  });

  return vm;
}
