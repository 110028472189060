import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import t from 'react-translate';
import { keys, some, values } from 'underscore';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';

// Hooks
import usePrevious from 'shared/hooks/use-previous';

// Schemas
import { RootState } from 'redux/schemas';
import { MySubmissionsState } from 'redux/schemas/app/video-practice';
import { PracticeRoomTab, StatType } from 'redux/schemas/models/video-practice';

// Actions
import { getComment, getComments, getPracticeSubmission, getVideoPracticeSubmissions } from 'redux/actions/video-practice';

// Styles
import { doubleSpacing, largeSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

// Components
import LoadingWrapper, { LoaderType } from 'shared/components/loading-wrapper';
import StatWithStars from 'practice_room/components/shared/stat-with-stars/stat-with-stars';
import PracticeSubmission from 'practice_room/components/shared/practice-submission';
import EmptyTab from 'practice_room/components/shared/empty-tab';
import { getMySubmissions, getSkillTags, getSubmissionIds } from 'redux/selectors/video-practice';
import { getCurrentUser } from 'redux/selectors/users';
import pusherService from 'shared/services/pusher-service';

const styles = () => css`
  .empty-tab-wrapper {
    padding-top: ${2 * largeSpacing}px
  }

  .stars-wrapper {
    .star-item:not(:last-child) {
      margin-right: ${doubleSpacing}px;
    }
    .star-item:last-of-type {
      margin-right: ${quarterSpacing}px;
    }
  }
`;

export const statMeta: {[Key in StatType]?: { key: string, icon: string, class: string }} = {
  [StatType.PRACTICE]: { key: 'PRACTICE', icon: 'media-practice', class: 'text-gray-3' },
  [StatType.FEATURED]: { key: 'FEATURED', icon: 'highlight', class: 'text-warning' },
  [StatType.COMMENT]: { key: 'COMMENT', icon: 'comments', class: 'text-success' },
  [StatType.LIKE]: { key: 'LIKE', icon: 'like', class: 'text-primary' },
  [StatType.VIEW]: { key: 'VIEW', icon: 'view', class: 'text-high-tide' },
};

interface MyPracticeTabProps {
  setShowPracticeAgainModal?: (show: boolean) => void;
}

const MyPracticeTab = ({
  setShowPracticeAgainModal,
}: MyPracticeTabProps) => {
  const dispatch = useAppDispatch();

  const scenarioId = useSelector((state) => state.app.practiceRoom.params.scenarioId);
  const selectedTab = useSelector((state) => state.app.practiceRoom.params.selectedTab);
  const {
    counts,
    isOnceLoaded,
  } = useSelector<RootState, MySubmissionsState>((state) => getMySubmissions(state));
  const {
    newNotification,
  } = useSelector((state) => state.app.practiceRoom.params);

  const submissionIds = useSelector<RootState, number[]>((state) => getSubmissionIds(state, {
    selectedTab: PracticeRoomTab.MY_PRACTICE,
  }));

  const skillTags = useSelector(state => getSkillTags(state, scenarioId));
  const loggedInUser = useSelector(getCurrentUser);
  const recentSubmissionId = useSelector(state => state.app.practiceRoom.mySubmissions[scenarioId].recentSubmissionId);

  useEffect(() => {
    if (selectedTab === PracticeRoomTab.MY_PRACTICE) {
      dispatch(getVideoPracticeSubmissions({
        scenarioId,
        tab: PracticeRoomTab.MY_PRACTICE,
      }));
    }
  }, [selectedTab, scenarioId, dispatch]);

  const channel = `public-${loggedInUser.anonymizedIdentifier.substr(0, 10)}`;

  useEffect(() => {
    const pusherChannel = pusherService.setupChannel(channel);
    if (recentSubmissionId) {
      pusherChannel.bind('transcription_completed', (data) => {
        dispatch(getPracticeSubmission({
          scenarioId,
          submissionId: data?.video_practice_submission_id,
        }));
      });
    }

    return () => {
      pusherChannel.unbind('transcription_completed');
    };
  }, [dispatch, loggedInUser.anonymizedIdentifier, recentSubmissionId, scenarioId]);

  useEffect(() => {
    const pusherChannel = pusherService.setupChannel(channel);
    if (recentSubmissionId) {
      pusherChannel.bind('filler_words_completed', (data) => {
        dispatch(getPracticeSubmission({
          scenarioId,
          submissionId: data?.video_practice_submission_id,
        }));
      });
    }

    return () => {
      pusherChannel.unbind('filler_words_completed');
    };
  }, [dispatch, loggedInUser.anonymizedIdentifier, recentSubmissionId, scenarioId]);

  // It will look like, this can be done in individual submission. But the
  // tab content is not rerendered on each tab switch and thus each submission
  // will not rerendered after initial load.
  // Calling getComments on tab switch so that the data will be refreshed.
  const prevValues = usePrevious({
    selectedTab,
    submissionsCount: submissionIds?.length,
  });
  useEffect(() => {
    if (selectedTab === PracticeRoomTab.MY_PRACTICE
      && submissionIds?.length > 0
      && (prevValues?.selectedTab !== selectedTab
        || prevValues?.submissionsCount !== submissionIds?.length)
    ) {
      submissionIds
        .map((submissionId) => dispatch(getComments({ submissionId, page: 1 })));
    }
  }, [dispatch, newNotification?.commentId, newNotification?.submissionId, prevValues, selectedTab, submissionIds]);
  useEffect(() => {
    if (newNotification?.commentId) {
      dispatch(getComment({ submissionId: newNotification.submissionId, commentId: newNotification.commentId }));
    }
  }, [dispatch, newNotification?.submissionId, newNotification?.commentId, newNotification]);

  const showCelebration = some(values(counts), (stats) => stats > 0);

  return (
    <div
      css={styles}
      className={`mx-auto mb-6 gallery-card-tab-container my-practice ${showCelebration ? 'mt-4' : 'mt-6'}`}
    >
      <LoadingWrapper isLoaded={isOnceLoaded} loaderType={LoaderType.PLACEHOLDER}>
        {submissionIds.length > 0
          ? (
            <React.Fragment>
              <div className='stars-wrapper d-flex'>
                {keys(counts).map((stat: StatType) => (
                  counts[stat] ? (
                    <StatWithStars
                      key={stat}
                      icon={statMeta[stat]?.icon}
                      translateKey={statMeta[stat]?.key}
                      count={counts[stat]}
                      colorClass={statMeta[stat]?.class}
                    />
                  ) : null
                ))}
              </div>
              {submissionIds?.map((submissionId, index) => (
                <div
                  className={showCelebration || (index !== 0) ? 'border-top border-gray-4 pt-5 mt-5' : ''}
                  key={submissionId}
                >
                  <PracticeSubmission
                    submissionId={submissionId}
                    setShowPracticeAgainModal={setShowPracticeAgainModal}
                    /**
                     * As the array is in the descending order of date
                     * the order of the submission will be
                     * submissionIds.length - index
                     */
                    skillTags={skillTags}
                    submissionIds={submissionIds}
                  />
                </div>
              ))}
            </React.Fragment>
          ) : (
            <div className='empty-tab-wrapper'>
              <EmptyTab
                icon='media-practice'
                description={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.NO_CONTENT.DEFAULT()}
              />
            </div>
          )}
      </LoadingWrapper>
    </div>
  );
};

export default MyPracticeTab;
