import store from 'redux/store';
import { updateLectureComponentFromAngular } from 'redux/actions/lecture-components';
import { NvTooltip, TextAlign } from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import t from '../../../react-translate';
import { NvFilePicker } from '../../../shared/components/nv-filepicker';
import { unsetLecturePageAsCached } from '../../../redux/actions/lecture-pages';

export default {
  bindings: {
    lectureComponent: '<',
    markReadBackendAsRead: '&',
    editMode: '<',
    linkedEditMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
  },
  controller: function ctrl(
    $state,
    $controller,
    $scope,
    $stateParams,
    $uibModal,
    AlertMessages,
    ConfirmationOverlays,
    CourseRolesManager,
    CurrentCourseManager,
    CurrentUserManager,
    TeamManager,
    LectureComponentsHelper,
    PusherManager,
    ReportsManager,
    TimelinesManager,
    ReactTimelineService,
    RailsRoutes,
    _,
    nvUtil,
    ReactLecturePageContext,
    $rootScope,
  ) {
'ngInject';
    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    let lastSavedDescription;

    vm.$onInit = () => {
      const { exercise } = vm.lectureComponent;
      const [report] = exercise.submissions;
      lastSavedDescription = exercise.description;

      if (CurrentCourseManager.course.gamificationEnabled && report?.exercise.approvalRequired && report.pointsReceived && report.isApproved() && !report.hasViewed) {
        vm.showPointsModal(report, null)
          .then(() => {
            report.markViewed();
          });
      }

      PusherManager.currentUserChannel().bind('image_rotated', (data) => {
        const [{ inputType }] = exercise.template.sections;
        const reportToCheck = report || exercise.submissions[0];

        // Need to consider only picture or rich text exercise
        if (inputType !== 'Picture' && inputType !== 'Rich Text') {
          return false;
        }

        // Check we got a report to look into
        if (!reportToCheck || !reportToCheck.currentRevision) {
          return false;
        }

        // Make sure the pushed report section exists in current revision
        if (!_.some(reportToCheck.currentRevision.sections, (section) => section.reportSection?.id === data.report_section_id)) {
          return false;
        }

        // Request the report again and update the report section
        ReportsManager.getSingleSubmission(CurrentCourseManager.course.catalogId, reportToCheck.id).then(() => {
          vm.lectureComponent.updateReportSection(ReportsManager.currentReport, data.report_section_id);
        });

        return true;
      });

      $scope.$on('$destroy', () => {
        PusherManager.currentUserChannel().unbind('image_rotated');
      });
    };

    vm.getDisplayTeam = () => {
      const { currentTeam } = vm.lectureComponent.exercise;
      const { assignmentTeam } = vm.lectureComponent.exercise;
      return assignmentTeam && currentTeam && assignmentTeam.id === currentTeam.id ? assignmentTeam : null;
    };

    vm.afterSelfPacedExerciseSave = () => {
      TimelinesManager.updateComponentProgress(vm.lectureComponent.lecturePage.id, vm.lectureComponent.type, vm.lectureComponent.id, vm.lectureComponent.exercise.progress);
    };

    vm.openEvaluationCriteriaModal = (lectureComponent) => $uibModal.open({
      templateUrl: 'evaluations/templates/evaluation-criteria.html',
      controller: 'NvModalCtrl',
      controllerAs: 'vm',
      resolve: {
        modalOptions() {
          return {
            disableConfirm: true,
            disableCancel: true,
            model: {
              exercise: lectureComponent.exercise,
            },
          };
        },
      },
    });

    vm.afterSelfPacedExerciseSubmit = () => {
      const { exercise } = vm.lectureComponent;

      const showSharePrompt = exercise.privacySetting !== 'sharable_with_instructor'
        && exercise.promptShareSubmission
        && exercise.submissionsViewableBeforeDeadline
        && TeamManager.teamsGroupsInCurrentCourse?.length
        && !exercise.approvalRequired
        && exercise.submissions[0].submittingObject?.updatedAt === exercise.submissions[0].lastSavedAt;

      vm.lectureComponent.editingExercise = false;
      if (CurrentCourseManager.course.gamificationEnabled && exercise.submissions[0].newPointsReceived) {
        ReactTimelineService.updateTimeline(vm.lectureComponent.lecturePage.id);
        TimelinesManager.updateComponentPointsAndProgress(vm.lectureComponent.lecturePage.id,
          vm.lectureComponent.type, vm.lectureComponent.id,
          exercise.submissions[0].newPointsReceived);

        vm.showPointsModal(exercise.submissions[0], null).then(() => {
          if (showSharePrompt) {
            $uibModal.open({
              templateUrl: 'lecture_pages/templates/components/exercise-share-with-team-modal.html',
              windowClass: 'points-modal',
              controller: 'ExerciseShareWithTeamModalCtrl as vm',
              resolve: {
                submission: exercise.submissions[0],
              },
            });
          }
        });
      } else {
        ReactTimelineService.updateTimeline(vm.lectureComponent.lecturePage.id);
        TimelinesManager.updateComponentProgress(vm.lectureComponent.lecturePage.id, vm.lectureComponent.type, vm.lectureComponent.id, vm.lectureComponent.exercise.progress);
        AlertMessages.success('', 'EXERCISES.SUBMITTED_SUCCESSFULLY');
        if (showSharePrompt) {
          $uibModal.open({
            templateUrl: 'lecture_pages/templates/components/exercise-share-with-team-modal.html',
            windowClass: 'points-modal',
            controller: 'ExerciseShareWithTeamModalCtrl as vm',
            resolve: {
              submission: exercise.submissions[0],
            },
          });
        }
      }

      if (exercise.skillsRatingFeedback?.id && exercise.progress === 'completed') {
        const currentReduxState = store.getState();
        const exerciseSkillsRatingActivity = currentReduxState?.models?.exerciseSkillsRatingActivities[exercise.skillsRatingFeedback.id];

        if (exerciseSkillsRatingActivity?.lecturePageId) {
          store.dispatch(unsetLecturePageAsCached(exerciseSkillsRatingActivity.lecturePageId));
        }
      }

      if (!CurrentUserManager.isAdmin()) {
        nvUtil.addUserToHeads(CurrentUserManager.user, vm.lectureComponent.exercise.recentSubmitters, 'submission');
      }

      const state = store.getState();
      const addedEvaluation = Object.values(state.models.peerEvaluations).find(
        (evaluation) => evaluation.exerciseId === exercise.id,
      );

      if (!_.isEmpty(addedEvaluation)) {
        store.dispatch(updateLectureComponentFromAngular({
          peerEvaluation: {
            ...addedEvaluation,
            hasSubmittedRequirement: true,
          },
        }));

        $rootScope.$emit('evaluationExerciseCompleted', exercise.id);
      }

      vm.markReadBackendAsRead();

      ReportsManager.markObsolete(vm.lectureComponent.currentReport.id);
    };

    vm.cancelRevise = () => {
      if (vm.lectureComponent.currentReportCopy) {
        angular.copy(vm.lectureComponent.currentReportCopy, vm.lectureComponent.currentReport);
      }
      vm.lectureComponent.editingExercise = false;
    };

    vm.onResumeRevise = (reportId) => {
      vm.lectureComponent.revise(reportId);
    };

    vm.onNewReport = () => {
      vm.lectureComponent.startNewReport();
    };

    vm.goToTeamFormationExercise = ($event) => {
      if (vm.lectureComponent.exercise.teamSet.lecturePageId === vm.lectureComponent.lecturePage.id) {
        $event.preventDefault();
        $('.main-panel-scrollable').scrollToElementAnimated($(`#lecture-component-${vm.lectureComponent.exercise.teamSet.lectureComponentId}`), null, 400);
      }
    };

    vm.editPrivacy = () => {
      vm.lectureComponent.createDraft();

      const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent, {
        editFormTemplateUrl: 'lecture_pages/templates/components/exercise-privacy-edit-modal.html',
        editFormController: 'LectureComponentModalCtrl',
        formName: 'editExercisePrivacyForm',
        setting: vm.lectureComponent.exerciseDraft.privacySetting === 'sharable_with_instructor_and_team' ? 'sharable_with_instructor' : vm.lectureComponent.exerciseDraft.privacySetting,
        shareWithTeam: vm.lectureComponent.exerciseDraft.privacySetting !== 'sharable_with_instructor', // this allows the checkbox to be checked when it first appears
      });

      modalInstance.result.then((result) => {
        if (result.setting === 'sharable_with_instructor' && result.shareWithTeam) {
          vm.lectureComponent.exerciseDraft.privacySetting = 'sharable_with_instructor_and_team';
        } else {
          vm.lectureComponent.exerciseDraft.privacySetting = result.setting;
        }
        const closeModal = LectureComponentsHelper.showSavingOverlay();

        vm.lectureComponent.saveDraft();
        vm.lectureComponent.save().then(closeModal, closeModal);
        vm.setEditMenu();
      });
    };

    vm.editTeamType = () => {
      vm.lectureComponent.exercise.teamType = 'individual';
      vm.lectureComponent.save().then(() => {
        ReactLecturePageContext.updateLecturePage();
        vm.setEditMenu();
      });
    };

    vm.editSubmissionApproval = () => {
      if (vm.lectureComponent.lecturePage.released && !vm.lectureComponent.exercise.approvalRequired) {
        ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/components/exercise-approval-warning-overlay.html',
          'AttachModalResolvesToVmCtrl', {
            vmResolves: () => ({
              course: CurrentCourseManager.course,
              isReleased: true,
            }),
          });
      } else if (vm.lectureComponent.exercise.numberSub > 1) {
        ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/components/exercise-approval-warning-overlay.html',
          'AttachModalResolvesToVmCtrl', {
            vmResolves: () => ({
              course: CurrentCourseManager.course,
              hasMultipleSubmission: true,
            }),
          });
      } else {
        vm.lectureComponent.exercise.getSubmissionReviewers().then(() => {
          vm.lectureComponent.createDraft(_.filter(CourseRolesManager.roles, (role) => role.isInstructor() || role.isTeachingAssistant() || role.isMentor()));

          const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent, {
            editFormTemplateUrl: 'lecture_pages/templates/components/exercise-approval-edit-modal.html',
            editFormController: 'LectureComponentModalCtrl',
            formName: 'editExerciseApprovalForm',
          });

          modalInstance.result.then(() => {
            const closeModal = LectureComponentsHelper.showSavingOverlay();

            vm.lectureComponent.saveSubmissionReviewers().then(closeModal, closeModal).then((result) => {
              store.dispatch(updateLectureComponentFromAngular({
                exercise: {
                  id: vm.lectureComponent.exercise.id,
                  approvalRequired: vm.lectureComponent.exercise.approvalRequired,
                },
              }));
              vm.setEditMenu();
            });
          });
        });
      }
    };

    vm.editAdvancedOptions = () => {
      vm.lectureComponent.createDraft();
      const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent, {
        editFormTemplateUrl: 'lecture_pages/templates/components/exercise-advanced-options-edit-modal.html',
        editFormController: 'LectureComponentModalCtrl',
        formName: 'editExerciseAdvancedOptionsForm',
      });

      modalInstance.result.then(() => {
        const closeModal = LectureComponentsHelper.showSavingOverlay();

        vm.lectureComponent.saveAdvancedOptions().then(closeModal, closeModal);
      });
    };

    vm.saveTitleOnBlur = function () {
      vm.lectureComponent.save(false).then(() => {
        /** With the current implementation without LecturePageManager the title is always updated in the state.model,
         * so this part of code, updating the state.app, is not necessary */
      });
    };

    function updateExerciseDeadline() {
      const { exercises } = store.getState().app.lecturePage;
      const exercise = _.clone(_.findWhere(exercises, { id: vm.lectureComponent.exercise.id }));
      if (exercise) {
        exercise.deadline = vm.lectureComponent.exercise.deadline;
        exercise.hardDeadline = vm.lectureComponent.exercise.hardDeadline;
      }
    }

    vm.toggleDeadline = () => {
      if (vm.lectureComponent.hasDeadline()) {
        if (vm.lectureComponent.scheduledCommunicationsCount > 0) {
          ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/due-date-change-warning-overlay.html',
            'AttachModalResolvesToVmCtrl', {
              vmResolves: () => ({
                scheduledCommunicationsCount: vm.lectureComponent.scheduledCommunicationsCount,
                isRemoving: true,
              }),
            }).result.then(() => vm.lectureComponent.removeDeadline().then(updateExerciseDeadline));
        } else {
          vm.lectureComponent.removeDeadline().then(updateExerciseDeadline);
        }
      } else {
        vm.lectureComponent.addDefaultDeadline().then(updateExerciseDeadline);
      }
    };

    vm.saveDeadline = () => {
      vm.lectureComponent.save().then(updateExerciseDeadline);
    };

    vm.descriptionBlurCallback = () => {
      if (lastSavedDescription !== vm.lectureComponent.exercise.description) {
        lastSavedDescription = vm.lectureComponent.exercise.description;
        vm.lectureComponent.save();
      }
    };

    vm.deleteAttachment = (attachment) => {
      vm.lectureComponent.exercise.deleteAttachment(attachment);
    };

    vm.setEditMenu = (teamType = null) => {
      const { sharedProps } = this.context;
      const extraOptions = [];
      const { isContentManagementCollection } = CurrentCourseManager.course;
      const { isLinked = false } = vm.lectureComponent.lecturePage;

      const courseAliases = CurrentCourseManager.course.getAliases();

      if (vm.lectureComponent.exercise.teamSet) {
        extraOptions.push(sharedProps.extraOptions.getEditOption());
      }

      if (vm.lectureComponent.exercise.teamSet && !vm.lectureComponent.exercise.teamSet.formedByStudents) {
        extraOptions.push({
          type: 'link',
          text: t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.EDIT_TEAMS(courseAliases),
          link: RailsRoutes.editTeamsPath($stateParams.catalogId, vm.lectureComponent.exercise.teamSet.id),
        });
      }

      if (!CurrentCourseManager.course.isSelfPaced) {
        extraOptions.push({
          type: 'text',
          text: vm.lectureComponent.hasDeadline() ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
          callback: () => {
            vm.toggleDeadline();
            vm.setEditMenu();
          },
          disabled: isContentManagementCollection,
          tooltip: {
            enabled: isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            offset: 20,
            textAlign: TextAlign.LEFT,
          },
        });
      }

      extraOptions.push({
        type: 'custom',
        customItem: (
          <NvFilePicker
            accept={vm.lectureComponent.exercise.allowedFileTypes}
            onChange={(files) => vm.lectureComponent.exercise.uploadAttachements(files)}
            onClick={() => {
              /**
               * Triggering a click event on the lecture component to close the
               * admin edit dropdown after selecting the resource file.
               */
              document.getElementById(`lecture-component-${vm.lectureComponent.id}`).click();
            }}
            disabled={isLinked}
          >
            <NvTooltip
              enabled={isLinked}
              text={t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP()}
              placement='left'
              textAlign={TextAlign.LEFT}
            >
              <div className={`bs4-dropdown-item ${isLinked ? 'text-gray-5' : ''}`}>
                { t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.ADD_RESOURCES() }
              </div>
            </NvTooltip>
          </NvFilePicker>
        ),
      });

      if (vm.lectureComponent.exercise.customQuestions) {
        extraOptions.push({
          type: 'link',
          text: t.LECTURE_PAGES.COMPONENTS.EXERCISE.VIEW_RELATED_FEEDBACK(),
          link: $state.href('lecture-page-edit', { id: vm.lectureComponent.exercise.customQuestions.lecturePageId, lectureActivityId: vm.lectureComponent.exercise.customQuestions.ownerId }),
        });
      }

      extraOptions.push({
        type: 'divider',
      },
      {
        type: 'custom',
        customItem: (
          <NvTooltip
            enabled={isLinked}
            text={t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP()}
            placement='left'
            textAlign={TextAlign.LEFT}
          >
            <ClickableContainer
              className={`bs4-dropdown-item flex-column ${isLinked ? 'text-gray-5' : ''}`}
              onClick={() => vm.editPrivacy()}
              disabled={isLinked}
            >
              <div>{t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.SUBMISSION_PRIVACY()}</div>
              <div className={`text-small text-gray-${isLinked ? '5' : '2'}`}>
                {t.LECTURE_PAGES.COMPONENTS.EXERCISE.PRIVACY_MODAL[vm.lectureComponent.exercise.privacySetting.toUpperCase()](courseAliases)}
              </div>
            </ClickableContainer>
          </NvTooltip>
        ),
      });

      if (!vm.lectureComponent.lecturePage.released) {
        if (vm.lectureComponent.exercise.teamSet || (teamType && teamType !== 'individual')) {
          extraOptions.push({
            type: 'text',
            text: t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.CHANGE_TO_INDIVIDUAL_ASSIGNMENT(courseAliases),
            callback: () => vm.editTeamType(),
          });
        } else {
          extraOptions.push(sharedProps.extraOptions.getEditOption(
            t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.CHANGE_TO_TEAM_ASSIGNMENT(courseAliases),
            isContentManagementCollection || isLinked,
          ));
        }
      }

      extraOptions.push({
        type: 'custom',
        customItem: (
          <NvTooltip
            enabled={isContentManagementCollection}
            text={t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP()}
            placement='left'
            textAlign={TextAlign.LEFT}
          >
            <ClickableContainer
              className={`bs4-dropdown-item ${isContentManagementCollection ? 'text-gray-5' : ''}`}
              onClick={() => vm.editSubmissionApproval()}
              disabled={isContentManagementCollection}
            >
              {t.SUBMISSION_APPROVAL.SUBMISSION_APPROVAL_STATUS()}
              &nbsp;
              <span className={vm.lectureComponent.exercise.approvalRequired ? 'text-warning' : ''}>
                {t.SUBMISSION_APPROVAL.ENABLED_DISABLED(vm.lectureComponent.exercise.approvalRequired)}
              </span>
            </ClickableContainer>
          </NvTooltip>
        ),
      });

      extraOptions.push({
        type: 'text',
        text: t.LECTURE_PAGES.COMPONENTS.EXERCISE.DROPDOWN.ADVANCED_OPTIONS(),
        callback: () => vm.editAdvancedOptions(),
      });

      sharedProps.extraOptions = {
        ...sharedProps.extraOptions,
        ...{
          options: extraOptions,
        },
      };
    };

    vm.setEditMenu();
    $scope.$watch('vm.lectureComponent.exercise.teamType', (teamType) => {
      vm.setEditMenu(teamType);
    });

    $scope.$watch('vm.lectureComponent.lecturePage.released', () => {
      vm.setEditMenu();
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-exercise-lecture-component.html',
};
